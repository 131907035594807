import React from 'react';
import { Row, Col, PreCode, Details, isNumber, numberFrom } from '../Util';
import SampleData from '../SampleData';

export function csv2json(s) {
  if (typeof s === 'string' && s.length > 0) {
    const lines = s.split('\n');
    if (Array.isArray(lines) && lines.length > 0) {
      const keys = lines[0].split(',').map((e) => e.trim());
      const list = [];
      lines.slice(1).forEach((e) => {
        if (typeof e === 'string' && e.length > 0) {
          const entry = {};
          const values = e.split(',').map((e) => e.trim());
          keys.forEach((k, i) => {
            const v = values[i];
            if (isNumber(v)) entry[k] = numberFrom(v);
            else entry[k] = v;
          });
          list.push(entry);
        }
      });
      return list;
    }
  }
  return [];
}

//=========================================//

function DataEditor(props) {
  const {
    list,
    onChange,
    //opts={},
    indexed = false,
    //signed=false,
    //hsigned=false,
    striped = true,
    bordered = false,
    borderless = false,
    hover = true,
    sm = false,
    rounded = false,
    shadow = false,
    className = '',
    //sum=false,
    //style={},
  } = props;

  const [ij, setIj] = React.useState({});
  const [cache, setCache] = React.useState('');

  const inputEl = React.useRef();

  if (Array.isArray(list) && list.length > 0) {
    const first = list[0];
    if (typeof first === 'object' && first !== null) {
      const keys = Object.keys(first);

      const classNames = ['table', 'table-responsive', className];

      if (striped) classNames.push('table-striped');
      if (bordered) classNames.push('table-bordered');
      if (borderless) classNames.push('table-borderless');
      if (hover) classNames.push('table-hover');
      if (sm) classNames.push('table-sm');
      if (rounded) classNames.push('rounded');
      if (shadow) classNames.push('shadow');

      return (
        <table className={classNames.join(' ')}>
          <thead className="thead-dark">
            <tr>
              {indexed && (
                <th scope="col" className="text-center">
                  #
                </th>
              )}
              {keys.map((e, i) => {
                return (
                  <th key={i} scope="col" className="text-center">
                    {e}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {list.map((e, i) => {
              return (
                <tr key={i}>
                  {indexed && (
                    <th scope="row" className="text-center">
                      {i + 1}
                    </th>
                  )}
                  {keys.map((k, j) => {
                    const value = e[k];

                    function handleClick() {
                      setIj({ i, j });
                      setCache(value);

                      setTimeout(() => {
                        const { current } = inputEl;
                        if (typeof current === 'object' && current !== null) {
                          current.focus();

                          /*document.addEventListener('keyup', e=>{
                            if (e.code==='Enter') {
                              setIj({i,j:j+1})
                            }
                          })*/
                        }
                      }, 250);
                    }

                    if (ij.i === i && ij.j === j) {
                      function handleSubmit(event) {
                        event.preventDefault();
                        if (typeof onChange === 'function') {
                          const v = isNumber(cache) ? numberFrom(cache) : cache;
                          onChange([
                            ...list.slice(0, i),
                            { ...e, [k]: v },
                            ...list.slice(i + 1),
                          ]);
                        }
                        setIj({});
                        setCache('');
                      }

                      return (
                        <td key={j}>
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              value={cache}
                              onChange={(e) => setCache(e.target.value)}
                              className="form-control"
                              ref={inputEl}
                            />
                          </form>
                        </td>
                      );
                    }

                    if (isNumber(value)) {
                      const n = numberFrom(value);
                      return (
                        <td
                          key={j}
                          className="text-right"
                          onClick={handleClick}
                        >
                          {n}
                        </td>
                      );
                    }
                    return (
                      <td key={j} onClick={handleClick}>
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return (
      <div className="text-center text-muted">
        First entry of the array is not an object or null
      </div>
    );
  }
  return (
    <div className="text-center text-muted">Data is not an array or empty</div>
  );
}

//const CSV = SampleData['TechCrunchcontinentalUSA.csv']
const CSV = SampleData['CalendarEvents.csv'];

export default function CsvEditorPage(props) {
  const [csv, setCsv] = React.useState(CSV);
  const [data, setData] = React.useState();
  const [output, setOutput] = React.useState('');

  React.useEffect(() => {
    if (typeof csv === 'string' && csv.length > 0) {
      setData(csv2json(csv));
    }
  }, [csv]);

  React.useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const first = data[0];
      if (typeof first === 'object' && first !== null) {
        const keys = Object.keys(first);
        const list = [
          keys,
          ...data.map((e) => {
            const entry = [];
            keys.forEach((k) => {
              const v = e[k];
              if (v === undefined || v === null) entry.push('');
              else entry.push(v);
            });
            return entry.join(',');
          }),
        ];
        setOutput(list.join('\n'));
      }
    }
  }, [data]);

  return (
    <div className="container-lg">
      <h1 className="text-center">{'\u{1f5c2}'} CSV Editor</h1>

      <Details lead="Input (CSV)" open>
        <Row>
          <Col s={6}>
            <textarea
              value={csv}
              rows={10}
              className="form-control"
              onChange={(e) => setCsv(e.target.value)}
            />
          </Col>
          <Col s={6}>
            <PreCode>{csv}</PreCode>
          </Col>
        </Row>
      </Details>
      <Details lead="JSON">
        <PreCode>{data}</PreCode>
      </Details>

      <Details lead="Output (CSV)">
        <textarea
          value={output}
          rows={1}
          className="form-control mb-3"
          readOnly
        />
        <PreCode>{output}</PreCode>
      </Details>

      <DataEditor
        list={data}
        onChange={setData}
        indexed
        bordered
        rounded
        shadow
        className="bg-white"
      />
    </div>
  );
}
