import React from 'react';
import { randomBgGrandientDarkStyle, randomEffect } from '../Util';

function match(props, n = 128560) {
  if (typeof props === 'object' && props !== null) {
    const { match } = props;
    if (typeof match === 'object' && match !== null) {
      const { params } = match;
      if (typeof params === 'object' && params !== null) {
        const { a } = params;
        if (typeof a === 'string' && a.length > 0) {
          const n = parseInt(a);
          if (typeof n === 'number' && !isNaN(n)) {
            return n;
          }
        }
      }
    }
  }
  return n;
}

/*function toHex(v) {
  if (typeof(v)==='number'&&!isNaN(v)) {
    return v.toString(16)
  }
  if (typeof(v)==='string'&&v.length>0) {
    try {
      const n = parseInt(v)
      return toHex(n)
    } catch(e) {

    }
  }
  return ''
}*/

export default function UnicodeExplorer(props) {
  const [from, setFrom] = React.useState(match(props));
  // 128583, 128560
  // animals: 129408
  // map: 128506
  // transport: 128640
  // funny emoji: 129296
  // jobs + sports: 129332
  // food: 129360
  // diversity: 129488
  // rock-paper-scissors: 9994
  // chess: 9812
  // circled alphabets: 9398
  // circled numbers: 9312
  // backspace: 9003
  // MJ: 11374, \u2c6e
  const [size, setSize] = React.useState(16);
  /*React.useEffect(()=>{
    console.log(size)
  },[size])*/
  return (
    <div className="UnicodeExplorer container-lg mb-5">
      <h1 className="text-center">{'\u{1f6e1}'} Unicode Explorer</h1>

      <div className="mb-3">
        <div
          className="p-3 shadow rounded text-white"
          style={{ background: randomBgGrandientDarkStyle() }}
        >
          <div className="row mb-3">
            <div className="col">
              <label className="label-sm">Start from</label>
              {/*<input type="text" value={typeof(from)==='number'?from.toString(16):''} className="form-control " onChange={e=>{
                  const value = parseInt(e.target.value,16)
                  setFrom(value)
                }} />*/}
              <input
                type="number"
                value={from}
                className="form-control shadow-inset mb-2"
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setFrom(value);
                }}
              />
              {/*<input type="text" value={toHex(from)} className="form-control shadow-inset" onChange={e=>{
                  const {value} = e.target
                  if (typeof(value)==='string'&&value.length>0) {
                    try {
                      const from = parseInt(value,16)
                      setFrom(from)
                    } catch(err) {
                    }
                  }
                }} />*/}
            </div>
            <div className="col-4">
              <label className="label-sm">Count per page</label>
              <select
                value={size}
                className="form-control float-right shadow-inset"
                onChange={(e) => {
                  const size = parseInt(e.target.value, 10);
                  setSize(size);
                }}
              >
                <option>12</option>
                <option>16</option>
                <option>24</option>
                <option>36</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-outline-light btn-prev shadow"
                onClick={(e) => setFrom(from - size)}
              >
                Prev
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-outline-light btn-next shadow float-right"
                onClick={(e) => setFrom(from + size)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <UnicodeExplorerHelper from={from} size={size} />
      </div>
    </div>
  );
}

function UnicodeExplorerHelper(props) {
  const [effect] = React.useState(randomEffect());
  const { from, size } = props;

  const valueOf = (n) => {
    if (typeof n === 'number') {
      const s = n.toString(16);
      if (s.length > 4) {
        return `\\u{${s}}`;
      }
      return `\\u${s}`;
    }
    return;
  };

  return (
    <div className="row">
      {new Array(size).fill(0).map((e, i) => {
        //const v = from+i
        //const s = '\\u{'+v+'}'
        return (
          <div key={i} className="col-6 col-sm-2 text-center">
            <div
              className={`h1 unicode animated ${effect}`}
              dangerouslySetInnerHTML={{ __html: `&#${from + i};` }}
            />
            <div className="small">{valueOf(from + i)}</div>
            <div className="small">{from + i}</div>
          </div>
        );
      })}
    </div>
  );
}
