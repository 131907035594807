import React from 'react';
import { P, Details, randInt, randColor } from '../Util';

export default function PreviewImageOnUpload(props) {
  const [file, setFile] = React.useState();

  const handleChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const input = React.useRef();

  const handleClick = () => {
    if (typeof input === 'object' && input !== null) {
      const { current } = input;
      if (typeof current === 'object' && current !== null) {
        current.click();
      }
    }
  };

  return (
    <div className="container-lg mb-5">
      <h1 className="text-center">{'\u269b'} Preview Image on Upload</h1>

      <P lead center>
        React component for preview an image on upload
      </P>

      <h5>Choose an image</h5>

      <div
        className="form-group p-3 shadow rounded"
        style={{
          background: `linear-gradient(${randInt(
            360,
          )}deg,${randColor()},${randColor()})`,
        }}
      >
        <button className="btn btn-success shadow" onClick={handleClick}>
          Upload an image file
        </button>
        <input
          ref={input}
          type="file"
          onChange={handleChange}
          className="form-control p-1"
          style={{ display: 'none' }}
        />

        {file && (
          <div className="mt-3">
            <h5>Preview</h5>
            <img
              src={file}
              className="w-100 rounded shadow"
              alt="Preview on upload"
            />
          </div>
        )}
      </div>

      <div className="mb-5">
        <Details lead="See the Code" className="lead">
          <iframe
            height="904"
            style={{ width: '100%' }}
            scrolling="no"
            title="ReactJS: Preview image on upload"
            src="//codepen.io/mjunaidi/embed/XvWvXe/?height=904&theme-id=0&default-tab=js"
            frameborder="no"
            allowtransparency
            allowFullScreen
          >
            See the Pen{' '}
            <a href="https://codepen.io/mjunaidi/pen/XvWvXe/">
              ReactJS: Preview image on upload
            </a>{' '}
            by M Junaidi (<a href="https://codepen.io/mjunaidi">@mjunaidi</a>)
            on <a href="https://codepen.io">CodePen</a>.
          </iframe>
        </Details>
      </div>
    </div>
  );
}
