import React from 'react'
import {Button,randomEffect,randomBgGrandientLightStyle} from '../Util'

export default function Fav(props) {
  const {list,onClick} = props
  const [effect] = React.useState(randomEffect())
  if (Array.isArray(list)&&list.length>0) {
    const clearFav=e=>{
      e.preventDefault()
      if (typeof(onClick)==='function') {
        for(let i=list.length-1;i>=0;i--) {
          onClick(i)
        }
      }
    }
    return (
      <div className="p-3 rounded shadow" style={{background:randomBgGrandientLightStyle()}}>
        <Button light sm clear className="shadow float-right" label={`\u{1F5D1} Clear`} onClick={clearFav} />
        <h2 className="text-center">Favorite</h2>
        <p className="lead text-center">To compare which font to pick</p>
        {
          list.map((e,i)=>(
            <div key={i} className={`animated ${effect} faster`}>
              <hr/>
              <Button danger label={'\u2715'} onClick={e=>onClick(i)} sm className="float-right shadow btn-remove" />
              <div style={{fontFamily:e}}>
                <h3>{e}</h3>
                <p>{`A B C D E F G H I J K L M N O P Q R S T U V W X Y Z a b c d e f g i j k l m n o p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 0 Foo Bar Baz`}</p>
              </div>
            </div>
          ))
        }
      </div>
    )
  }
  return null
}
