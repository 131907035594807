import React from 'react'
import {Badge,PreCode} from '../Util'

const BADGE_TYPES = ['primary','secondary','info','success','warning','danger','light','dark']

export default function ReactXBootstrapBadge(props) {
  return (
    <div className="container-fluid pt-5 mb-5" id="Badge">
      <div className="pt-2 text-center">
        <h1>Badge</h1>
      </div>
      <hr/>

      <div className="mb-3">
        <details>
          <summary><span className="h3">The Code</span></summary>
          <PreCode>{`
function Badge(props) {
  const {children,className='',pill,title='',shadow=false} = props
  const classNames = ['badge',className]
  if (pill) {
    classNames.push('badge-pill')
  }
  Object.keys(props).forEach(e=>{
    if (['primary','secondary','info','success','warning','danger','light','dark'].indexOf(e)>=0) {
      classNames.push(\`badge-\${e}\`)
    }
    if (['h1','h2','h3','h4','h5','h6'].indexOf(e)>=0) {
      classNames.push(e)
    }
  })
  if (shadow) classNames.push('shadow')
  return (
    <span className={classNames.join(' ')} title={title}>{children}</span>
  )
}
            `}</PreCode>
        </details>
      </div>

      <div>
        <h3>Usage</h3>
        <PreCode>{`<Badge primary></Badge>`}</PreCode>
        <p>Replace <Badge light>primary</Badge> with either below types:</p>
        {
          BADGE_TYPES.map((e,i)=>{
            const b = {}
            b[e] = true
            return (
              <Badge key={i} {...b} shadow className="mr-3">{e}</Badge>
            )
          })
        }
      </div>

      <div>
        <h4>Using rounded <Badge secondary pill>pill</Badge> badge</h4>
        <p>Simply add <Badge light>pill</Badge></p>
        <PreCode>{`<Badge primary pill></Badge>`}</PreCode>
        {
          BADGE_TYPES.map((e,i)=>{
            const b = {}
            b[e] = true
            return (
              <Badge key={i} {...b} shadow className="mr-3" pill>{e}</Badge>
            )
          })
        }
      </div>

      {/*<div>
        <h4>Using as Title</h4>
        <p>Simply add either <Badge light className="mr-1">h1</Badge><Badge light className="mr-1">h2</Badge><Badge light className="mr-1">h3</Badge><Badge light className="mr-1">h4</Badge><Badge light className="mr-1">h5</Badge><Badge light className="mr-1">h6</Badge></p>
        <PreCode>{`<Badge primary pill></Badge>`}</PreCode>
        {
          (new Array(6)).fill(0).map((e,i)=>{
            const b = {}
            const key = `h${i+1}`
            b[key] = true
            b['primary'] = true
            return (
              <Badge key={i} {...b} shadow className="mr-3" pill>{key}</Badge>
            )
          })
        }
      </div>*/}
    </div>
  )
}
