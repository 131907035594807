import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeListEntry from './HomeListEntry';
import { HOME_PATHS } from './HOME_PATHS';

function filterBy(list, keyword) {
  return list.filter(({ name, path, description }) => {
    if (typeof keyword === 'string' && keyword.length > 0) {
      const regex = new RegExp(keyword, 'i');
      if (name.search(regex) >= 0) return true;
      if (path.search(regex) >= 0) return true;
      if (description.search(regex) >= 0) return true;
      return false;
    }
    return true;
  });
}

export default function HomeList(props) {
  const { t } = useTranslation();
  const [keyword, setKeyword] = React.useState('');
  const searchBox = React.useRef();

  const list = filterBy(HOME_PATHS, keyword);

  return (
    <div>
      <div className="container mb-5">
        <input
          ref={searchBox}
          type="search"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          className="form-control rounded-pill px-3 mb-3 shadow-inset"
          placeholder={t('Search...')}
        />
      </div>
      <div className="form-row">
        {list.map((e, i) => (
          <div
            key={i}
            className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 animate__animated animate__faster animate__fadeInUp"
            style={{ animationDelay: `${75 * i}ms` }}
          >
            <HomeListEntry {...e} />
          </div>
        ))}

        {list.length <= 0 && (
          <div className="col-12 mb-3 animate__animated animate__faster animate__fadeInUp">
            <div className="HomeListEntry rounded-lg shadow h-100 shadow-hover text-center py-5">
              <div className="mb-3 animate__animated animate__fadeInDown animate__delay-1s">
                <h1 className="animate__animated animate__swing animate__delay-1s animate__slow">
                  <span role="img" aria-label="">
                    &#x1f342;
                  </span>
                </h1>
              </div>
              <h3>Search not found</h3>
              <p className="mb-3">
                {t('Can\'t find anything with "{{keyword}}"', { keyword })}
              </p>
              <button
                className="btn btn-outline-dark rounded-pill px-5"
                onClick={(e) => {
                  setKeyword('');
                  searchBox.current.focus();
                }}
              >
                {t('Clear search keyword')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
