import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ColorPaletteEntry(props) {
  const { t } = useTranslation();
  const { name, url, description, colors } = props;
  return (
    <div className="p-4 bg-white rounded-lg shadow-sm">
      <div className="text-center">
        <h3>{t(name)}</h3>
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          className="small"
        >
          {t('Reference')}
        </a>
        <p className="lead">{t(description)}</p>
      </div>

      <div className="form-row">
        {colors.map(({ name, hex, rgb, hsl, lumin }) => (
          <div key={name} className="col-12 col-sm-3 mb-2">
            <div
              className="px-1 py-3 text-center place-items-center shadow-sm h-100 rounded-lg shadow-sm"
              style={{ background: hex, color: lumin }}
            >
              <div>
                <h6>{name}</h6>
                <div className="mb-2">{hex}</div>
                <div className="small">{rgb}</div>
                <div className="small">{hsl}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
