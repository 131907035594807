export function db() {
  return {
    size: 49,
    list: [
      {
        i: 0,
        clue: 'Cardi B feat. Bad Bunny & J Balvin',
        q: 'I Like It',
        count: 7,
      },
      { i: 1, clue: 'Drake', q: 'Nice for What', count: 11 },
      { i: 2, clue: 'Ariana Grande', q: 'No Tears Left to Cry', count: 16 },
      { i: 3, clue: 'Childish Gambino', q: 'This Is America', count: 13 },
      { i: 4, clue: 'Kendrick Lamar & SZA', q: 'All the Stars', count: 11 },
      { i: 5, clue: 'Troye Sivan', q: 'My My My!', count: 6 },
      { i: 6, clue: 'Zedd, Maren Morris & Grey', q: 'The Middle', count: 9 },
      { i: 7, clue: 'Janelle Monáe', q: 'Make Me Feel', count: 10 },
      { i: 9, clue: 'Calvin Harris & Dua Lipa', q: 'One Kiss', count: 7 },
      { i: 10, clue: 'Kacey Musgraves', q: 'High Horse', count: 9 },
      { i: 11, clue: 'Kim Petras', q: 'Heart to Break', count: 12 },
      { i: 12, clue: 'Bruno Mars & Cardi B', q: 'Finesse', count: 7 },
      { i: 13, clue: 'Ella Mai', q: "Boo'd Up", count: 6 },
      { i: 14, clue: 'Drake', q: "God's Plan", count: 8 },
      { i: 15, clue: 'Migos', q: 'Stir Fry', count: 7 },
      {
        i: 16,
        clue: 'Kali Uchis feat. Tyler the Creator & Bootsy Collins',
        q: 'After the Storm',
        count: 13,
      },
      { i: 17, clue: 'Charlie Puth feat. Kehlani', q: 'Done for Me', count: 9 },
      { i: 18, clue: 'Camila Cabello', q: 'Never Be the Same', count: 14 },
      { i: 19, clue: 'James Bay', q: 'Pink Lemonade', count: 12 },
      { i: 20, clue: 'BTS', q: 'Fake Love', count: 8 },
      { i: 21, clue: 'Shawn Mendes', q: 'Lost in Japan', count: 11 },
      { i: 22, clue: 'Nicky Jam & J Balvin', q: 'X', count: 1 },
      { i: 23, clue: 'Rae Sremmurd & Juicy J', q: 'Powerglide', count: 10 },
      { i: 24, clue: 'Azealia Banks', q: 'Anna Wintour', count: 11 },
      { i: 25, clue: 'Marshmello & Anne-Marie', q: 'Friends', count: 7 },
      { i: 26, clue: 'John Mayer', q: 'New Light', count: 8 },
      { i: 27, clue: 'Cardi B', q: 'Be Careful', count: 9 },
      {
        i: 28,
        clue: 'Christine and the Queens feat. Dâm-Funk',
        q: 'Girlfriend',
        count: 10,
      },
      { i: 29, clue: 'Bazzi', q: 'Mine', count: 4 },
      { i: 30, clue: '6lack, Khalid & Ty Dolla $ign', q: 'OTW', count: 3 },
      {
        i: 31,
        clue: 'Kendrick Lamar, Jay Rock, Future & James Blake',
        q: "King's Dead",
        count: 9,
      },
      { i: 32, clue: 'Parquet Courts', q: 'Wide Awake', count: 9 },
      {
        i: 33,
        clue: 'Ashley McBryde',
        q: 'A Little Dive Bar in Dahlonega',
        count: 25,
      },
      { i: 34, clue: 'Nicki Minaj', q: 'Chun-Li', count: 6 },
      { i: 35, clue: 'Hayley Kiyoko', q: 'Curious', count: 7 },
      { i: 36, clue: 'Jessie Reyez', q: 'Body Count', count: 9 },
      { i: 37, clue: 'Florence + the Machine', q: 'Hunger', count: 6 },
      { i: 38, clue: 'Leon Bridges', q: 'Bad Bad News', count: 10 },
      { i: 39, clue: 'Khalid & Normani', q: 'Love Lies', count: 8 },
      { i: 40, clue: 'A$AP Rocky feat. Moby', q: 'A$AP Forever', count: 10 },
      { i: 41, clue: 'The Weeknd', q: 'Call Out My Name', count: 13 },
      { i: 42, clue: 'Arctic Monkeys', q: 'Four Out of Five', count: 13 },
      {
        i: 43,
        clue: 'Casper Magico, Nio Garcia, Darell, Nicky Jam, Ozuna & Bad Bunny',
        q: 'Te Boté',
        count: 5,
      },
      { i: 44, clue: 'Jordan Davis', q: 'Singles You Up', count: 12 },
      { i: 45, clue: 'BlocBoy JB feat. Drake', q: 'Look Alive', count: 9 },
      { i: 46, clue: 'Snail Mail', q: 'Pristine', count: 8 },
      {
        i: 47,
        clue: 'SAINt JHN',
        q: 'I Heard You Got Too Litt Last Night',
        count: 28,
      },
      { i: 48, clue: 'Jade Bird', q: 'Lottery', count: 7 },
      {
        i: 49,
        clue: 'Justin Timberlake feat. Chris Stapleton',
        q: 'Say Something',
        count: 12,
      },
    ],
  };
}
