import React from 'react'
import {A,Badge,Button,PreCode,Callout} from '../Util'

export default function ReactXBootstrapButton(props) {

  const [model,setModel] = React.useState({name:'John Donohue'})

  const reset = e=>{
    setModel({name:'John Donohue'})
  }

  return (
    <div className="container-fluid pt-5 mb-5" id="Button">
      <div className="pt-2 text-center">
        <h1>Button</h1>
      </div>
      <hr/>

      <div className="mb-3">
        <details>
          <summary><span className="h3">The Code</span></summary>
          <PreCode>{`
function Button(props) {
  const {children, className='', outline=false, sm=false, lg=false, block=false, disabled=false, shadow=false, label='', title='', button=true, submit=false, reset=false, onClick} = props
  const classNames = ['btn',className]
  Object.keys(props).forEach(e=>{
    if (['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'light', 'dark', 'link'].indexOf(e)>=0) {
      classNames.push(\`btn-\${outline?'outline-':''}\${e}\`)
    }
    if (['h1','h2','h3','h4','h5','h6'].indexOf(e)>=0) classNames.push(e)
  })
  if (sm) classNames.push('btn-sm')
  if (lg) classNames.push('btn-lg')
  if (block) classNames.push('btn-block')
  if (shadow) classNames.push('shadow')
  let type = 'button'
  if (submit) type = 'submit'
  else if (reset) type = 'reset'
  return (
    <button className={classNames.join(' ')} onClick={onClick} disabled={disabled} type={type} title={title}>{label||children}</button>
  )
}
            `}</PreCode>
        </details>
      </div>

      <div className="mb-3">
        <h3>Usage</h3>
        <PreCode>{`<Button primary>Label</Button>`}</PreCode>
        <Callout primary shadow>Replace <Badge light>primary</Badge> with other types like below</Callout>
        {
          ['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'light', 'dark','link'].map((e,i)=>{
            const btn = {}
            btn[e] = true
            return (
              <Button key={i} {...btn} shadow className="m-1">{e}</Button>
            )
          })
        }
      </div>

      <div className="mb-3">
        <h3>Outline buttons</h3>
        <p>Simply add <Badge light>outline</Badge></p>
        <PreCode>{`<Button primary outline>Label</Button>`}</PreCode>
        {
          ['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'light', 'dark'].map((e,i)=>{
            const btn = {}
            btn[e] = true
            return (
              <Button key={i} {...btn} outline shadow className="m-1">{e}</Button>
            )
          })
        }
      </div>

      <div className="mb-3">
        <h3>Support label property to make it simpler to define</h3>
        <p>Use label as property instead of define the label as children</p>
        <PreCode>{`<Button primary label="My Label" />`}</PreCode>
        <Button primary shadow label="My Label" />
      </div>

      <div className="mb-3">
        <h3>Using children to use the button as a container</h3>
        <p>For example using h1</p>
        <PreCode>{`
<Button primary>
  <h1>Title</h1>
  <h2>Lead</h2>
  <p>Details</p>
</Button>
          `}</PreCode>
        <Button primary shadow className="mr-3"><h1>Title</h1><h2>Lead</h2><p>Details</p></Button>
        <Button primary shadow outline><h1>Title</h1><h2>Lead</h2><p>Details</p></Button>
      </div>

      <div className="mb-3">
        <h3>Define its action with <Badge light>onClick</Badge> property</h3>
        <PreCode>{`<Button primary label="Say Hello" onClick={e=>alert('Hello')} />`}</PreCode>
        <Button primary shadow label="Say Hello" onClick={e=>alert('Hello')} />
      </div>

      <div className="mb-3">
        <h3>Disabled</h3>
        <p>Simply add <Badge light>disabled</Badge></p>
        <PreCode>{`<Button primary label="Disabled Button" disabled />`}</PreCode>
        <Button primary shadow label="Disabled Button" disabled className="mr-3" />
        <Button primary shadow label="Normal Button" />
      </div>

      <div className="mb-3">
        <h3>Smaller or Larger Button</h3>
        <p>Simply add <Badge light>sm</Badge> for small or <Badge light>lg</Badge> for large button</p>
        <PreCode>{`<Button primary sm label="Small Button" />`}</PreCode>
        <PreCode>{`<Button primary lg label="Large Button" />`}</PreCode>
        <Button primary shadow sm label="Small Button" className="mr-3" />
        <Button primary shadow outline label="Normal size" className="mr-3" />
        <Button primary shadow lg label="Large Button" className="mr-3" />
      </div>

      <div className="mb-3">
        <h3>Full-width Button</h3>
        <p>Simply add <Badge light>block</Badge></p>
        <PreCode>{`<Button primary block label="Full-width Button" />`}</PreCode>
        <Button primary shadow label="Full-width Button" block className="mb-2" />
        <Button primary shadow outline label="Full-width Button" block className="mb-2" />
      </div>

      <div className="mb-3">
        <h3>Add more CSS class</h3>
          <p>Add <Badge light>className</Badge> property and define its value for example <Badge light>className="shadow p-5"</Badge></p>
          <PreCode>{`<Button primary label="Shadow Button" className="shadow-lg p-3 rounded" />`}</PreCode>
          <Callout shadow info><Badge light>shadow</Badge> CSS class is a utility class name defined in Bootstrap 4 [<A href="https://getbootstrap.com/docs/4.3/utilities/shadows" target="_blank">Link</A>]</Callout>
        <Button primary label="Shadow Button" className="shadow-lg p-3 rounded mr-3" />
        <Button primary label="Normal Button" />
      </div>

      <div className="mb-3">
        <h3>Using Button as a submit and reset button</h3>
        <p>Set the type property to <Badge light>submit</Badge> or <Badge light>reset</Badge>, the value will default to <Badge light>button</Badge> if not initialized.</p>
        <PreCode>{`<Button primary submit label="Submit" />`}</PreCode>
        <PreCode>{`<Button secondary reset label="Reset" />`}</PreCode>
        <Callout primary shadow>Wrap <Badge light>submit</Badge> button in a <Badge light>form</Badge> element to make it to be able to trigger <Badge light>onSubmit</Badge> and <Badge light>onReset</Badge> function of the form. Define <Badge light>onSubmit</Badge> and <Badge light>onReset</Badge> on the <Badge light>form</Badge> element. Containing <Badge light>Button</Badge> with <Badge light>submit</Badge> type can trigger <Badge light>onSubmit</Badge> while the Button with <Badge light>reset</Badge> type can trigger <Badge light>onReset</Badge> function of the form.</Callout>
        <form className="p-3 shadow" onSubmit={e=>{e.preventDefault();alert(`Form submitted with model value ${JSON.stringify(model)}`)}} onReset={reset}>
          <div className="form-group">
            <label className="label-sm">Name</label>
            <input value={model.name} onChange={e=>setModel({name:e.target.value})} className="form-control shadow-inset" />
            <small>Change the value and press <Badge secondary>Reset</Badge> button to see the value being reset to the initial value.</small>
          </div>
          <Button primary shadow submit label="Submit" className="mr-3" />
          <Button secondary shadow reset label="Reset" className="mr-3" />
        </form>
      </div>
    </div>
  )
}
