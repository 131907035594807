import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMATS } from './DATE_FORMATS';

const DATE_TODAY = new Date();

// convert DATE_TODAY into moment type
const DATE_TODAY_MOMENT = moment(DATE_TODAY.toISOString(), moment.ISO_8601);

export default function DateFormatting(props) {
  const { t } = useTranslation();

  const [selectedDateFormat, setSelectedDateFormat] = React.useState(
    DATE_FORMATS[0],
  );

  const [selectedDate, setSelectedDate] = React.useState(DATE_TODAY_MOMENT);

  function handleChangeSelectedDate(e) {
    // moment.HTML5_FMT.DATE is 'YYYY-MM-DD'
    // reference: https://momentjs.com/docs/#/parsing/special-formats/
    setSelectedDate(moment(e.target.value, moment.HTML5_FMT.DATE));
  }

  return (
    <div className="container-lg">
      <div className="text-center mb-4">
        <h1>{t('Date Formatting')}</h1>
        <p className="lead">
          {t('Display date in specific format with moment.js')}
        </p>
      </div>

      <div className="container p-3 bg-white rounded-lg shadow-sm mb-4">
        <div className="form-group">
          <label>{t('Please select a date format')}</label>
          <select
            className="form-control shadow-sm"
            value={selectedDateFormat}
            onChange={(e) => setSelectedDateFormat(e.target.value)}
          >
            {DATE_FORMATS.map((dateFormat) => (
              <option key={dateFormat}>{dateFormat}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="container p-3 bg-white rounded-lg shadow-sm mb-4 text-center">
        <div className="form-group mb-3 container-sm">
          <label>{t('Select date')}</label>
          <input
            type="date"
            className="form-control shadow-sm"
            value={selectedDate.format(moment.HTML5_FMT.DATE)}
            onChange={handleChangeSelectedDate}
          />
        </div>

        <div className="mb-3">
          <small className="d-block">{t('Selected date is')}</small>
          <h6>{`${selectedDate}`}</h6>

          <details>
            <summary className="small">
              {t('view in different formats')}
            </summary>
            <div className="animate__animated animate__faster animate__fadeInDown">
              <h6>
                <span className="badge badge-info mr-3">{'ISO 8601'}</span>
                {`${selectedDate.format(moment.DATE)}`}
              </h6>
              <h6>
                <span className="badge badge-info mr-3">
                  {moment.HTML5_FMT.DATE}
                </span>
                {`${selectedDate.format(moment.HTML5_FMT.DATE)}`}
              </h6>
            </div>
          </details>
        </div>

        <div className="mb-3">
          <small className="d-block">{t('Selected date format')}</small>
          <span className="font-weight-bold">{selectedDateFormat}</span>
        </div>

        <div>
          <small className="mr-3">
            {t('Displaying selected date in the selected format')}
          </small>
          <h1>{`${selectedDate.format(selectedDateFormat)}`}</h1>
        </div>
      </div>
    </div>
  );
}
