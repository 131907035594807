import React from 'react';
import { useTranslation } from 'react-i18next';
import UnderConstruction from '../component/UnderConstruction';

const WEIGHT_MEASUREMENT_NOTES = `Kilogram (kg) --> Pounds (lbs)
1 lb = kg * 2.2046
1 kg = lb / 2.2046

Kilogram (kg) --> Stones (st)
1 st = kg * 0.15747
1 kg = st / 0.15747

Grams (g) --> Ounces (oz)
1 oz = g * 0.035274
1 g = oz / 0.035274`;

export default function WeightMeasurement(props) {
  const { t } = useTranslation();
  return (
    <div className="container-lg">
      <div className="text-center mb-4">
        <h1>{t('Weight Measurement')}</h1>
        <div>
          <span className="badge badge-primary badge-pill badge-sm m-1">
            Kilogram (kg)
          </span>
          <span className="badge badge-secondary badge-pill badge-sm m-1">
            Pounds (lbs)
          </span>
          <span className="badge badge-warning badge-pill badge-sm m-1">
            Stones (st)
          </span>
          <span className="badge badge-danger badge-pill badge-sm m-1">
            Ounces (oz)
          </span>
        </div>
      </div>

      <details>
        <summary>{t('FORMULA')}</summary>
        <p
          className="container p-3 rounded-lg bg-dark text-white"
          style={{ whiteSpace: 'break-spaces' }}
        >
          {WEIGHT_MEASUREMENT_NOTES}
        </p>
      </details>

      <UnderConstruction />
    </div>
  );
}
