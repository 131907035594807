import React from 'react';
import {
  COLOR,
  randomInt,
  randomColor,
  Button,
  P,
  Badge,
  PreCode,
  randomAttentionSeeker,
} from '../Util';

function match(props) {
  if (typeof props === 'object' && props !== null) {
    const { match } = props;
    if (typeof match === 'object' && match !== null) {
      const { params } = match;
      if (typeof params === 'object' && params !== null) {
        const { a, b } = params;
        if (typeof a === 'string' && typeof b === 'string') {
          return { a: `#${a}`, b: `#${b}` };
        }
      }
    }
  }
  return {};
}

function ColorSelect(props) {
  const { ab = {} } = props;
  const { a, b } = ab;
  const keys = Object.keys(COLOR);
  return (
    <div className="form-group">
      <label className="text-shadow">Choose color</label>
      <select {...props} className="form-control shadow">
        {keys.map((e, i) => (
          <optgroup key={i} label={keys[i]}>
            {COLOR[e].map((e, j) => (
              <option key={j}>{e}</option>
            ))}
          </optgroup>
        ))}
        {(a || b) && (
          <optgroup label="custom">
            {a && <option>{a}</option>}
            {b && <option>{b}</option>}
          </optgroup>
        )}
      </select>
    </div>
  );
}

export default function GradientColorPair(props) {
  const [effect, setEffect] = React.useState(randomAttentionSeeker());

  const ab = match(props);

  const [a, setA] = React.useState(ab.a || randomColor());
  const [b, setB] = React.useState(ab.b || randomColor());

  const [deg, setDeg] = React.useState(0);

  const [degree, setDegree] = React.useState(88);

  React.useEffect(() => {
    const timer = window.setInterval(
      () =>
        setDeg((n) => {
          if (n > 359) return 0;
          return n + 1;
        }),
      50,
    );
    return () => window.clearInterval(timer);
  }, [deg]);

  const Style = {
    preview: {
      width: 64,
      height: 64,
      margin: '0 auto',
    },
    result: {
      width: 128,
      height: 128,
      background: `linear-gradient(${deg}deg, ${a}, ${b})`,
      margin: '0 auto',
    },
  };

  const randomize = (e) => {
    e.preventDefault();
    setA(randomColor());
    setB(randomColor());
  };

  const cssify = (dir, a, b) => {
    return `background: linear-gradient(${dir}, ${a}, ${b});`;
  };

  React.useEffect(() => {
    setEffect(randomAttentionSeeker());
  }, [a, b]);

  return (
    <div className="container-lg text-center mb-5">
      <div className="pt-2 text-center">
        <h3>{'\u{1f3a8}'} Gradient Color Pairing Tool</h3>
        <p className="lead">A tool to choose your preferred gradient color</p>
      </div>
      <hr />

      <div className="row mb-5">
        <div className="col-6">
          <ColorSelect
            value={a}
            onChange={(e) => setA(e.target.value)}
            ab={ab}
          />
          <h5>{a}</h5>
          <div
            style={{ background: a, ...Style.preview }}
            className="shadow rounded mb-2"
          />
          <Button light sm shadow onClick={(e) => setA(randomColor())}>
            {'\u{1f3b2}'} Randomize
          </Button>
        </div>
        <div className="col-6">
          <ColorSelect
            value={b}
            onChange={(e) => setB(e.target.value)}
            ab={ab}
          />
          <h5>{b}</h5>
          <div
            style={{ background: b, ...Style.preview }}
            className="shadow rounded mb-2"
          />
          <Button light sm shadow onClick={(e) => setB(randomColor())}>
            {'\u{1f3b2}'} Randomize
          </Button>
        </div>
      </div>

      <div className={`mb-5 animated ${effect}`}>
        <div style={{ ...Style.result }} className="shadow rounded mb-3" />
        <Button light sm shadow onClick={randomize}>
          {' '}
          {'\u{1f3b2}'} Randomize both colors
        </Button>
      </div>

      <h4>Generated CSS</h4>
      <P>
        Specify gradient direction to <Badge dark>top</Badge>{' '}
        <Badge dark>bottom</Badge> <Badge dark>left</Badge> or{' '}
        <Badge dark>right</Badge>
      </P>

      <div className="p-3 bg-white shadow rounded mb-3">
        <div className="row mb-3 pb-2 font-weight-bold border-bottom">
          <div className="col-3">Direction</div>
          <div className="col-3">Preview</div>
          <div className="col-6">CSS</div>
        </div>
        {['top', 'bottom', 'left', 'right'].map((e, i) => (
          <div key={i} className={`row ${i < 3 ? 'mb-3' : ''}`}>
            <div className="col-3">
              <P>{`to ${e}`}</P>
            </div>
            <div className="col-3">
              <div
                style={{
                  background: `linear-gradient(to ${e},${a},${b})`,
                  ...Style.preview,
                }}
                className="shadow rounded mb-2"
              />
            </div>
            <div className="col-6 text-left">
              <PreCode className="none">{cssify(`to ${e}`, a, b)}</PreCode>
            </div>
          </div>
        ))}
      </div>

      <P>Or use degree notation</P>

      <div className="p-3 bg-white shadow rounded">
        <div className="row">
          <div className="col-3">
            <label>deg</label>
            <div className="input-group">
              <input
                type="number"
                className="form-control form-control-sm shadow-inset"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
              />
              <div className="input-group-append">
                <Button
                  sm
                  outline
                  secondary
                  shadow
                  title="Randomize deg"
                  onClick={(e) => setDegree(randomInt(360))}
                >
                  {'\u{1f3b2}'}
                </Button>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div
              style={{
                background: `linear-gradient(${degree}deg,${a},${b})`,
                ...Style.preview,
              }}
              className="shadow rounded"
            />
          </div>
          <div className="col-6 text-left">
            <PreCode className="none">{cssify(`${degree}deg`, a, b)}</PreCode>
          </div>
        </div>
      </div>
    </div>
  );
}
