import React from 'react';
import * as lottie from 'lottie-web';

export default function Lottie(props) {
  const el = React.useRef();
  const { path, animationData } = props;

  React.useEffect(() => {
    if (!path && !animationData) {
      return;
    }

    if (animationData) {
      lottie.loadAnimation({
        container: el.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
      return;
    }

    lottie.loadAnimation({
      container: el.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path,
    });
  }, [path, animationData]);

  return <div ref={el}></div>;
}
