import React from 'react';

// FIXME: width and height is for setting it to specific size, not a max value, only will not become larger

export default function SlayteImage(props) {
  const { src, alt = '', height, width } = props;

  if (!src) {
    return null;
  }

  const style = {
    objectFit: 'cover',
  };

  if (height) {
    style.maxHeight = height;
  }

  if (width) {
    style.maxWidth = width;
  }

  return <img src={src} alt={alt} className="SlayteImage" style={style} />;
}
