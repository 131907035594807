import React from 'react';
//import { useTranslation } from 'react-i18next';
import DynamicFormInputs from './DynamicFormInputs';
import DynamicFormInputsEntryArray from './DynamicFormInputsEntryArray';

export default function DynamicFormInputsEntry(props) {
  //const { t } = useTranslation();
  const { k, v, value, onChange } = props;

  function handleChange(k, v) {
    onChange({ ...value, [k]: v });
  }

  if (!v || typeof v === 'string') {
    return (
      <div className="form-group mb-3">
        <label>{k}</label>
        <input
          type="text"
          className="form-control"
          value={v}
          onChange={(e) => handleChange(k, e.target.value)}
        />
      </div>
    );
  }

  if (Array.isArray(v)) {
    return (
      <DynamicFormInputsEntryArray
        k={k}
        v={v}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (typeof v === 'object' && v !== null) {
    return <DynamicFormInputs value={v} onChange={(v) => handleChange(k, v)} />;
  }

  return <div>{JSON.stringify(k)}</div>;
}
