import React from 'react';
import { animated, randomEffect } from '../Util';

const SAMPLE = { name: 'John Donohue', location: 'Cyberjaya', country: 'MY' };

function parseJSON(s, onError) {
  try {
    return JSON.parse(s);
  } catch (error) {
    //if (onError) onError(error)
  }
  return {};
}

function Alert(props) {
  const { className = '', type = 'warning', message = '' } = props;

  if (!message) return null;

  const classNames = [className, 'alert shadow-sm'];
  if (type) {
    classNames.push(`alert-${type}`);
  }
  return <div className={classNames.join(' ')}>{`${message}`}</div>;
}

function JSONEditor(props) {
  const { value, onChange } = props;

  const [alertMessage, setAlertMessage] = React.useState({});
  const [pretty, setPretty] = React.useState(true);

  function handleChange(e) {
    e.preventDefault();
    if (onChange) onChange(e.target.value);
  }

  const json = parseJSON(value, (error) => {
    setAlertMessage({ type: 'warning', message: `${error}` });
  });
  const preview = pretty ? JSON.stringify(json, null, 2) : JSON.stringify(json);

  return (
    <div>
      <Alert {...alertMessage} />

      <div className="row form-row">
        <div className="col-12 col-sm-6">
          <div className="badge badge-dark badge-pill px-3 shadow-sm mb-2">
            JSON value
          </div>

          <textarea
            value={value}
            onChange={handleChange}
            className="form-control bg-light shadow-sm"
            rows={7}
            placeholder={'type JSON value here'}
          />
        </div>
        <div className="col-12 col-sm-6">
          <div className="badge badge-light border badge-pill px-3 shadow-sm mb-2">
            Preview
          </div>

          <div className="float-right">
            <Switch value={pretty} onChange={setPretty} label="Prettify" />
          </div>

          <CopyablePreCode className="text-left bg-dark p-2 rounded shadow-sm">
            {preview}
          </CopyablePreCode>
        </div>
      </div>
    </div>
  );
}

/* start utils */

const PRECODE_STYLE = {
  padding: 0,
  background: 'inherit',
  color: 'inherit',
  border: 'inherit',
};

function CopyablePreCode(props) {
  const { ugly, className = '', style = {}, children } = props;

  const preEl = React.useRef();
  const inputEl = React.useRef();

  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    try {
      const { current } = preEl;
      const { offsetWidth, offsetHeight } = current;
      setWidth(offsetWidth);
      setHeight(offsetHeight);
    } catch (error) {
      console.warn(error);
    }
  }, [preEl]);

  React.useEffect(() => {
    if (!value) return;
    try {
      const { current } = inputEl;
      current.select();
      current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setValue('');
    } catch (error) {
      console.warn(error);
    }
  }, [inputEl, value]);

  if (typeof children === 'object' && children !== null) {
    if (ugly) {
      return <PreCode {...props}>{JSON.stringify(children)}</PreCode>;
    }
    return <PreCode {...props}>{JSON.stringify(children, null, 2)}</PreCode>;
  }

  function copy(e) {
    e.preventDefault();

    try {
      const { current } = preEl;
      const { innerText } = current;
      setValue(innerText);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={`p-3 bg-dark text-light rounded shadow ${className} position-relative`}
      style={style}
    >
      <div className="position-absolute" style={{ right: 0, top: 0 }}>
        <button
          className="btn btn-light text-white"
          style={{ backgroundColor: 'rgba(255,255,255,.25)' }}
          onClick={copy}
        >
          Copy
        </button>
      </div>
      {value ? (
        <textarea
          ref={inputEl}
          value={value}
          style={{ width, height, ...PRECODE_STYLE }}
          readOnly
        />
      ) : (
        <pre ref={preEl} className="bg-dark mb-0" style={{ color: 'lime' }}>
          <code>{children}</code>
        </pre>
      )}
    </div>
  );
}

/*function TableEditor(props) {
  const { value, onChange, className = "" } = props;
  const classNames = ["table-sm", className];
  function handleChange(e, i, j) {
    let v = e.target.value;
    if (
      typeof v === "string" &&
      v.length > 0 &&
      v.charAt(v.length - 1) !== "."
    ) {
      const n = Number(v);
      if (typeof n === "number" && !isNaN(n)) {
        v = n;
      }
    }
    onChange(
      [
        ...value.slice(0, i),
        [...value[i].slice(0, j), v, ...value[i].slice(j + 1)],
        ...value.slice(i + 1)
      ],
      i,
      j
    );
  }
  function render(e, i, j) {
    return (
      <input
        value={e}
        className="form-control"
        onChange={e => handleChange(e, i, j)}
      />
    );
  }
  return (
    <Table value={value} render={render} className={classNames.join(" ")} />
  );
}

function Table(props) {
  const { value, className = "", render } = props;
  if (Array.isArray(value) && value.length > 0) {
    const classNames = ["table", className];
    return (
      <table className={classNames.join(" ")}>
        <tbody>
          {value.map((e, i) => {
            if (typeof e === "object" && e !== null) {
              return (
                <tr key={i}>
                  {Object.keys(e).map((k, j) => {
                    const v = e[k];
                    function renderValue() {
                      if (typeof render === "function") return render(v, i, j);
                      return v;
                    }
                    return <td key={j}>{renderValue()}</td>;
                  })}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    );
  }
  return null;
}

function Details(props) {
  const { summary = "details", children, className = "" } = props;
  return (
    <details className={className}>
      <summary>{summary}</summary>
      <div className="mt-3 animated faster slideInDown">{children}</div>
    </details>
  );
}*/

function PreCode(props) {
  const { ugly, className = '', children } = props;

  if (typeof children === 'object' && children !== null) {
    if (ugly) {
      return <PreCode {...props}>{JSON.stringify(children)}</PreCode>;
    }
    return <PreCode {...props}>{JSON.stringify(children, null, 2)}</PreCode>;
  }

  return (
    <pre className={`p-3 bg-dark text-white rounded shadow ${className}`}>
      <code>{children}</code>
    </pre>
  );
}

function randInt(n = 10) {
  return Math.floor(Math.random() * n);
}

/*function randColor() {
  return `#${[...Array(3).keys()]
    .map(e => (randInt(238) + 17).toString(16))
    .join("")}`;
}

function toNumber(n, d = -1) {
  if (typeof n === "number" && !isNaN(n)) return n;
  if (typeof n === "string" && n.length > 0) {
    if (n.indexOf(".") >= 0) return toNumber(parseFloat(n), d);
    else return toNumber(parseInt(n), d);
  }
  if (Array.isArray(n) && n.length > 0) return toNumber(n[0], d);
  if (typeof n === "object" && n !== null) {
    const keys = Object.keys(n);
    if (Array.isArray(keys) && keys.length > 0) return toNumber(n[keys[0]], d);
  }
  if (typeof d === "number" && !isNaN(d)) return d;
  return -1;
}

function lipsum(n = 10, dot = false) {
  if (typeof n !== "number" || (typeof n === "number" && n < 1)) n = 10;
  const words = [
    "lorem",
    "ipsum",
    "dolor",
    "sit",
    "amet",
    "consectetur",
    "adipiscing",
    "elit",
    "vivamus",
    "et",
    "accumsan",
    "augue",
    "duis",
    "eget",
    "nunc",
    "id",
    "sodales",
    "finibus",
    "vestibulum",
    "sagittis",
    "magna",
    "nec",
    "rutrum",
    "volutpat",
    "risus",
    "tincidunt",
    "justo",
    "non",
    "gravida",
    "tortor",
    "enim",
    "in",
    "urna",
    "ut",
    "vel",
    "metus",
    "pellentesque",
    "porttitor",
    "vitae",
    "nisi",
    "nullam",
    "faucibus",
    "condimentum",
    "quam",
    "imperdiet",
    "class",
    "aptent",
    "taciti",
    "sociosqu",
    "ad",
    "litora",
    "torquent",
    "per",
    "conubia",
    "nostra",
    "inceptos",
    "himenaeos",
    "interdum",
    "malesuada",
    "fames",
    "ac",
    "ante",
    "primis",
    "curabitur",
    "nibh",
    "quis",
    "iaculis",
    "cras",
    "mollis",
    "eu",
    "congue",
    "leo"
  ];
  const count = n;
  const sentence = [];
  const indexes = new Array(count)
    .fill(0)
    .map(index => Math.floor(Math.random() * words.length));
  indexes.forEach((index, i) => {
    const word = words[index];
    if (i === 0) sentence.push(word.charAt(0).toUpperCase() + word.substr(1));
    else sentence.push(word);
  });
  if (dot) return sentence.join(" ").concat(".");
  return sentence.join(" ");
}*/

/*function getDimension() {
  if (typeof window === "object") {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  }
  return {};
}

function useDimension() {
  const [dimension, setDimension] = React.useState(getDimension());
  React.useEffect(() => {
    function handleResize() {
      setDimension(getDimension());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return dimension;
}*/

// usage <Row noGutters>...</Row>
// <Row noGutters><Col></Col><Col></Col></Row>
/*function Row(props) {
  const { className = "", noGutters = false, children } = props;
  const classNames = [className, "row"];
  if (noGutters) classNames.push("no-gutters");
  return <div className={classNames.join(" ")}>{children}</div>;
}

// usage <Col>...</Col>
// <Col n={12} sm={8} md={6} lg={4} xl={2} />
function Col(props) {
  const { className = "", children, n, sm, md, lg, xl } = props;
  const classNames = [className];

  if (typeof n === "number" && n > 0 && n <= 12) classNames.push(`col-${n}`);

  // Extra small <576px	Small ≥576px	Medium ≥768px	Large ≥992px	Extra large ≥1200px
  // Max container width	None (auto)	540px	720px	960px	1140px
  // Class prefix	.col-	.col-sm-	.col-md-	.col-lg-	.col-xl-

  if (typeof sm === "number" && sm > 0 && sm <= 12)
    classNames.push(`col-sm-${sm}`);
  if (typeof md === "number" && md > 0 && md <= 12)
    classNames.push(`col-md-${md}`);
  if (typeof lg === "number" && lg > 0 && lg <= 12)
    classNames.push(`col-lg-${lg}`);
  if (typeof xl === "number" && xl > 0 && xl <= 12)
    classNames.push(`col-xl-${xl}`);

  if (classNames.length < 2) classNames.push("col");

  return <div className={classNames.join(" ")}>{children}</div>;
}

// usage <Box random />
// <Box backgroundColor={randColor()} />
function Box(props) {
  const {
    className = "",
    children,
    style = { minHeight: 50 },
    backgroundColor,
    random = false
  } = props;

  const classNames = [
    className,
    "w-100 d-flex justify-content-center align-items-center"
  ];

  if (typeof backgroundColor === "string" && backgroundColor.length > 0)
    style.backgroundColor = backgroundColor;

  if (random) style.backgroundColor = randColor();

  return (
    <div className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
}

function Section(props) {
  const { children, className = "", center = false } = props;
  const classNames = ["p-3 bg-white rounded shadow", className];
  if (center) classNames.push("text-center");

  const mb = toNumber(props.mb, 3);
  if (typeof mb === "number" && mb >= 0 && mb <= 5) classNames.push(`mb-${mb}`);

  return <div className={classNames.join(" ")}>{children}</div>;
}*/

function generateId() {
  return `_${[...Array(6).keys()].map((i) => randInt()).join('')}`;
}

function Switch(props) {
  const { className = '', label = '', value = false, onChange } = props;
  const [id] = React.useState(generateId());
  return (
    <div className={`custom-control custom-switch ${className}`}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        value={value}
        checked={value}
        onChange={(e) => onChange(!value)}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

/* end utils */

export default function JsonPrettify(props) {
  const [effect] = React.useState(animated(randomEffect()));

  const [jsonValue, setJsonValue] = React.useState(
    JSON.stringify(SAMPLE, null, 2),
  );

  return (
    <div className="container-lg mb-5">
      <h1 className={`text-center ${effect}`}>{'\u{1f4ac}'} JSON Prettify</h1>
      <p className={`lead text-center ${effect}`}>
        JSONEditor with prettify toggle
      </p>

      <JSONEditor value={jsonValue} onChange={setJsonValue} />
    </div>
  );
}
