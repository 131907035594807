import React from 'react';
import { useTranslation } from 'react-i18next';
import { TIMEZONE_OPTIONS } from './TIMEZONE_OPTIONS';

/*
NOTES

yarn add moment-timezone

*/

export default function TimezoneSelector(props) {
  const { t } = useTranslation();

  const [selectedTimezone, setSelectedTimezone] = React.useState();

  return (
    <div className="container-lg mb-5">
      <h1 className="text-center mb-5">{t('Timezone Selector')}</h1>

      <div>
        <details>
          <summary>{t('JSON')}</summary>
          <pre className="p-3 bg-dark text-light rounded-lg mt-3">
            <code>{JSON.stringify(TIMEZONE_OPTIONS, null, 2)}</code>
          </pre>
        </details>
      </div>

      <div className="container p-3 bg-white rounded-lg shadow-sm mb-4">
        <div className="form-group mb-4">
          <label>{t('Select Timezone')}</label>
          <select
            className="form-control rounded-pill shadow-sm px-3"
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
          >
            {TIMEZONE_OPTIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-4">
          <label>{t('Using label with i18n locale')}</label>
          <select
            className="form-control rounded-pill shadow-sm px-3"
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
          >
            {TIMEZONE_OPTIONS.map(({ label, offsetShortLabel, value }) => (
              <option key={value} value={value}>
                (UTC{offsetShortLabel ? ` ${offsetShortLabel}` : ''}) {t(value)}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedTimezone && (
        <div className="p-3 text-center bg-white rounded-lg shadow-sm animate__animated animate__faster animate__lightSpeedInRight">
          <div className="small mb-2">{t('Selected timezone')}</div>
          <h1 className="mb-3">{selectedTimezone}</h1>
          <pre className="text-left p-3 bg-dark text-success rounded-lg">
            <code>
              {JSON.stringify(
                TIMEZONE_OPTIONS.find(
                  ({ value }) => value === selectedTimezone,
                ),
                null,
                2,
              )}
            </code>
          </pre>
        </div>
      )}
    </div>
  );
}
