import React from 'react';

// https://codepen.io/mjunaidi/pen/oNjGYPd

const EFFECT = {
  //in: 'fadeInDown',out: 'fadeOutDown',
  //in: 'fadeInLeft',out: 'fadeOutRight',
  //in: 'flipInX',
  //out: 'flipOutX',
  //in: 'flipInY',
  //out: 'flipOutY',
  //in: 'zoomInDown',out: 'zoomOutDown',
  //in: 'zoomIn',out: 'zoomOut',
  //in: 'bounceIn',out: 'lightSpeedOut',
  in: 'animate__bounceIn',
  out: 'animate__rollOut',
  //in: 'rollIn',out: 'rollOut',
  //in: 'bounceInDown',out: 'bounceOutDown',
  //in: 'slideInDown',out: 'slideOutDown',
};

export function Tick(props) {
  const { className = '', style = {}, value } = props;

  const [a, setA] = React.useState(value);
  const [b, setB] = React.useState(value);
  const [flag, setFlag] = React.useState(false);

  React.useEffect(() => {
    if (value % 2 === 0) {
      setA(value);
    } else {
      setB(value);
    }
  }, [value]);

  React.useEffect(() => {
    setFlag(false);
  }, [a]);

  React.useEffect(() => {
    setFlag(true);
  }, [b]);

  return (
    <div className={`position-relative ${className}`} style={style}>
      <div
        className={`position-absolute w-100 text-center animate__animated animate__faster ${
          flag ? EFFECT.out : EFFECT.in
        }`}
      >
        {a}
      </div>
      <div
        className={`position-absolute w-100 text-center animate__animated animate__faster ${
          flag ? EFFECT.in : EFFECT.out
        }`}
      >
        {b}
      </div>
      <div className="invisible">{value}</div>
    </div>
  );
}
