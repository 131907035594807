import React from 'react';
import { lipsum, Button, randomBgGrandientDarkStyle } from '../Util';

export function jumble(s) {
  if (typeof s === 'string' && s.length > 0) {
    let w = s;
    const l = w.length;
    Array.from(Array(l).keys()).forEach((e, i) => {
      const r = Math.floor(Math.random() * (l + 1));
      const a = w.charAt(i);
      const b = w.charAt(r);
      w = `${w.substr(0, i)}${b}${w.substr(i + 1)}`;
      w = `${w.substr(0, r)}${a}${w.substr(r + 1)}`;
    });
    return w;
  }
  return '';
}

const CASES = ['Preserve case', 'To upper case', 'To lower case'];

export default function WordJumbler(props) {
  const [value, setValue] = React.useState(lipsum(5));
  const [done, setDone] = React.useState(true);
  const [caseOpt, setCaseOpt] = React.useState('');
  const [remSpace, setRemSpace] = React.useState(false);

  const jumbleIt = (e) => {
    e.preventDefault();
    setDone(false);
  };

  React.useEffect(() => {
    if (!done) {
      setDone(true);
    }
  }, [done]);

  const process = () => {
    let v = value;

    if (remSpace) v = value.split(' ').join('');

    if (caseOpt === CASES[0]) return v;
    else if (caseOpt === CASES[1]) return v.toUpperCase();
    else if (caseOpt === CASES[2]) return v.toLowerCase();

    return v;
  };

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f3b2}'} Word Jumbler</h1>
        <p className="lead">
          Jumble a word to another word with the same characters in a random
          order
        </p>
      </div>
      <hr />

      <div
        className="p-3 rounded shadow mb-5 text-white"
        style={{ background: randomBgGrandientDarkStyle() }}
      >
        <Button
          light
          outline
          shadow
          sm
          className="mb-2 float-right"
          onClick={(e) => setValue(lipsum(5))}
        >
          {'\u{1f3b2}'} Randomize Word
        </Button>

        <div className="form-group mb-1">
          <h5>Word</h5>
          <input
            value={value}
            className="form-control shadow-inset"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group mb-1">
              {/*<label>Case option</label>*/}
              <select
                value={caseOpt}
                onChange={(e) => setCaseOpt(e.target.value)}
                className="form-control shadow-inset form-control-sm"
              >
                {CASES.map((e, i) => {
                  return <option key={i}>{e}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-1">
              <input
                type="checkbox"
                value={remSpace}
                className="shadow-inset mr-2"
                id="remSpace"
                onChange={(e) => setRemSpace(e.target.checked)}
              />
              <label htmlFor="remSpace">Remove spaces</label>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters text-center mb-3 text-break">
        <div className="col-5">
          <h5>{process()}</h5>
        </div>
        <div className="col-2">
          <h5>{'\u2192'}</h5>
        </div>
        <div className="col-5">{done && <h5>{jumble(process())}</h5>}</div>
      </div>

      <div className="text-center">
        <Button primary shadow lg onClick={jumbleIt}>
          Re-Jumble the Word
        </Button>
      </div>
    </div>
  );
}
