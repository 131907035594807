import React from 'react'
import {lipsum,A,randomBgGrandientDarkStyle} from '../Util'

const Select=props=>{
  const {options,value,onChange} = props
  if (Array.isArray(options)&&options.length>0) {
    return (
      <select className="form-control" value={value} onChange={event=>{
          if (typeof(onChange)==='function') {
            const {value} = event.target
            onChange(value)
          }
        }}>
        {
          options.map((e,i)=>{
            if (typeof(e)==='string'||typeof(e)==='number') {
              return (
                <option key={i}>{e}</option>
              )
            }
            if (typeof(e)==='object'&&e!==null) {
              return (
                <option key={i} value={e.value}>{e.label}</option>
              )
            }
            return null
          })
        }
      </select>
    )
  }
  return null
}

const Animation = {
  in: [
    'fadeIn', 'fadeInDown', 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceInLeft', 'bounceInRight', 'lightSpeedIn', 'flipInX', 'flipInY', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight', 'rollIn', 'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp',
  ],
  out: [
    'fadeOut', 'fadeOutDown', 'fadeOutLeft', 'fadeOutRight', 'fadeOutUp', 'bounceOutLeft', 'bounceOutRight', 'lightSpeedOut', 'flipOutX', 'flipOutY', 'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight', 'hinge', 'rollOut', 'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp',
  ],
}

const Constant = {
  width:312,
  height:170,
}

export default class ReactXCssImageGallery extends React.Component {
  state={}
  componentDidMount() {
    const weight = 1.8
    const width = parseInt(Constant.width*weight)
    const height = parseInt(Constant.height*weight)
    const list = (new Array(5)).fill(0).map((e,i)=>{
      const seed = Math.floor(Math.random()*1000)+1
      const src = `https://picsum.photos/${width}/${height}/?random&${seed}`
      const title = lipsum(3).slice(0,-1)
      return {src,title}
    })
    const selected = 0
    const outAnimation = Animation.out[0]
    const inAnimation = Animation.in[0]
    const random = true
    const auto = 0
    const style = {background:randomBgGrandientDarkStyle()}
    this.setState({list,selected,outAnimation,inAnimation,random,auto,style})
  }
  done=()=>{
    const {random} = this.state
    if (random) {
      this.setState((prevState,props)=>{
        const outAnimation = Animation.out[Math.floor(Math.random()*Animation.out.length)]
        const inAnimation = Animation.in[Math.floor(Math.random()*Animation.in.length)]
        return {outAnimation,inAnimation}
      },this.auto)
    } else {
      this.auto()
    }
  }
  auto=()=>{
    const {auto} = this.state
    if (typeof(auto)==='number'&&auto>0) {
      setTimeout(this.next,auto)
    }
  }
  choose=i=>{
    const {list,outAnimation,inAnimation,selected} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(i)==='number'&&i>=0&&i<list.length&&i!==selected) {
        const animated = `${outAnimation} animated`
        this.setState({animated},()=>{
          let timeout = 499
          if (outAnimation==='hinge') {
            timeout = 1099
          }
          setTimeout(()=>{
            this.setState({selected:i},()=>{
              const animated = `${inAnimation} animated`
              this.setState({animated},this.done())
            })
          },timeout)
        })
      }
    }
  }
  renderList() {
    const {list,selected} = this.state
    if (Array.isArray(list)&&list.length>0) {
      return (
        <div className="row no-gutters">
          {
            list.map((e,i)=>(
              <div key={i} className={`col p-1 ${i===selected?'border border-primary':''}`}>
                <img src={e.src} alt="" className="rounded w-100 shadow" onClick={event=>{
                    event.preventDefault()
                    const {auto} = this.state
                    if (auto===0) {
                      this.choose(i)
                    }
                  }} />
              </div>
            ))
          }
        </div>
      )
    }
    return null
  }
  renderSelected() {
    const {list,selected,animated} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        const entry = list[selected]
        if (typeof(entry)==='object'&&entry!==null) {
          const {src} = entry
          return (
            <div className="row">
              <div className="col-1 flex-center">
                {this.renderPrev()}
              </div>
              <div className="col-10">
                <div className={animated}>
                  <div className="w-100">
                    <img src={src} alt="" className="img-fluid w-100 rounded shadow"/>
                  </div>
                </div>
              </div>
              <div className="col-1 flex-center">
                <div className="float-right">
                  {this.renderNext()}
                </div>
              </div>
            </div>
          )
        }
      }
    }
    return null
  }
  prev=event=>{
    const {selected,list} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        let prev = selected-1
        if (prev<0) {
          prev = list.length-1
        }
        this.choose(prev)
      }
    }
  }
  next=event=>{
    const {selected,list} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        let next = selected+1
        if (next>=list.length) {
          next=0
        }
        this.choose(next)
      }
    }
  }
  renderPrev() {
    const {auto} = this.state
    const disabled = auto>0
    return (
      <button disabled={disabled} className="btn btn-outline-dark shadow" onClick={this.prev}>{'\u2190'}</button>
    )
  }
  renderNext() {
    const {auto} = this.state
    const disabled = auto>0
    return (
      <button disabled={disabled} className="btn btn-outline-dark shadow" onClick={this.next}>{'\u2192'}</button>
    )
  }
  chosen() {
    const {selected,list} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        return list[selected]
      }
    }
    return null
  }
  renderSelectedTitle() {
    const entry = this.chosen()
    if (typeof(entry)==='object'&&entry!==null) {
      const {title} = entry
      if (typeof(title)==='string') {
        const {animated} = this.state
        return (
          <div className="text-center">
            <div className={animated}><h4 className="text-shadow">{title}</h4></div>
          </div>
        )
      }
    }
    return null
  }
  renderNav() {
    return (
      <div className="">
        <div className="row">
          <div className="col-1">
            {this.renderPrev()}
          </div>
          <div className="col">
            {this.renderSelectedTitle()}
          </div>
          <div className="col-1">
            <div className="float-right">{this.renderNext()}</div>
          </div>
        </div>
      </div>
    )
  }
  setIn=v=>{
    if (typeof(v)==='string'&&v.length>0) {
      this.setState({inAnimation:v})
    }
  }
  setOut=v=>{
    if (typeof(v)==='string'&&v.length>0) {
      this.setState({outAnimation:v})
    }
  }
  toggleRandom=e=>{
    this.setState({random:e.target.checked})
  }
  renderOptions() {
    const {inAnimation,outAnimation,random,style} = this.state
    return (
      <div className="p-3 shadow rounded text-white" style={style}>
        <div className="row">
          <div className="col-8">
            <div className="row no-gutters">
              <div className="col mr-2">
                <div className="form-group">
                  <label className="font-weight-bold label-sm">Exit</label>
                  <Select options={Animation.out} value={outAnimation} onChange={this.setOut} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="font-weight-bold label-sm">Entrance</label>
                  <Select options={Animation.in} value={inAnimation} onChange={this.setIn} />
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className={`form-group form-check mb-0`}>
                <input id="toggleRandom" type="checkbox" className="form-check-input" onChange={this.toggleRandom} value={random} defaultChecked />
                <label htmlFor="toggleRandom" className="form-check-label">Random on change</label>
              </div>
            </div>
          </div>
          <div className="col-4">
            {this.renderAutoChangeTimeoutOptions()}
          </div>
        </div>
      </div>
    )
  }
  renderAutoChangeTimeoutOptions() {
    const {auto} = this.state
    const defaultOptions = [
      {label:'Off',value:0},
    ]
    const options = defaultOptions.concat((new Array(6)).fill(0).map((e,i)=>{
      if (i===0) {
        return {label:'Every half seconds',value:500}
      }
      const value = i*1000
      const label = `Every ${i} second${i>1?'s':''}`
      return {label,value}
    }))
    return (
      <div className="form-group">
        <label className="label-sm">Auto change</label>
        <Select options={options} value={auto} onChange={this.handleChangeAuto}
        />
      </div>
    )
  }
  handleChangeAuto=auto=>{
    if (typeof(auto)==='string'&&auto.length>0) {
      auto = parseInt(auto)
    }
    if (typeof(auto)==='number'&&auto>=0) {
      const prevAuto = this.state.auto
      if (prevAuto===0) {
        this.setState({auto},this.next)
      } else {
        this.setState({auto})
      }
    }
  }
  render() {
    return (
      <div className="container-fluid">
        <h2 className="text-center">Image Gallery with CSS3 Animation</h2>
        <p className="text-center lead">Powered by <A href="https://daneden.github.io/animate.css" blank>Animate.css</A></p>
        {this.renderOptions()}
        {this.renderNav()}
        {this.renderList()}
        {this.renderSelected()}
      </div>
    )
  }
}
