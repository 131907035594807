import React from 'react';
import { useTranslation } from 'react-i18next';
import Constant from '../Constant';
import { Badge } from '../Util';

const { APPS } = Constant;

export default function AboutApps(props) {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-center mb-3">{t('My Apps')}</h3>
      <hr />
      <div className="apps row mb-5 text-center no-gutters">
        {APPS.map((e, i) => {
          const { icon, name, url, tags } = e;
          return (
            <div key={i} className="col p-1">
              <div className="mb-2">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-icon-link"
                >
                  <img src={icon} className="app-icon shadow" alt={name} />
                </a>
              </div>

              <div className="mb-1">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-appstore-btn shadow"
                  alt="Download from App Store!"
                >
                  &nbsp;
                </a>
              </div>

              <div className="mb-1">
                {tags.map((e, i) => (
                  <Badge
                    key={i}
                    light
                    pill
                    shadow
                    className="mr-1 mb-1 font-x-sm"
                  >
                    {e}
                  </Badge>
                ))}
              </div>

              <h6 className="mb-1">{name}</h6>
            </div>
          );
        })}
      </div>
    </div>
  );
}
