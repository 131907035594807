import React from 'react';
import {
  whatos,
  lipsum,
  Badge,
  Button,
  randomBgGrandientDarkStyle,
} from '../Util';
import Font from '../tool/Font';
import Fav from '../tool/Fav';

export default function FontExplorer(props) {
  const [os /*setOS*/] = React.useState(whatos());
  const [list, setList] = React.useState([]);
  const [font, setFont] = React.useState('');

  const init = (cb) => {
    if (os.startsWith('Window')) {
      setList(Font.win);
    } else if (os.startsWith('Mac')) {
      setList(Font.ios);
    } else {
      setList([...Font.win, ...Font.ios]);
    }
    if (typeof cb === 'function') {
      cb();
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [os]);

  React.useEffect(() => {
    if (Array.isArray(list) && list.length > 0) {
      setFont(list[0]);
    }
  }, [list]);

  const handleChange = (e) => {
    setFont(e.target.value);
  };

  const prev = (e) => {
    e.preventDefault();
    if (Array.isArray(list) && list.length > 0 && font.length > 0) {
      const i = list.indexOf(font);
      if (i > 0) setFont(list[i - 1]);
      else setFont(list[list.length - 1]);
    }
  };

  const next = (e) => {
    e.preventDefault();
    if (Array.isArray(list) && list.length > 0 && font.length > 0) {
      const i = list.indexOf(font);
      if (i < list.length - 1) setFont(list[i + 1]);
      else setFont(list[0]);
    }
  };

  // favorite list
  const getFav = () => {
    const f = localStorage.getItem('fav');
    if (typeof f === 'string') {
      const fav = JSON.parse(f);
      if (Array.isArray(fav)) return fav;
    }
    return [];
  };
  const [fav, setFav] = React.useState(getFav());

  const addToFav = (e) => {
    e.preventDefault();
    if (font.length > 0) {
      if (fav.indexOf(font) < 0) {
        setFav([...fav, font]);
      }
    }
  };
  const remFromFav = (e) => {
    e.preventDefault();
    if (font.length > 0) {
      const i = fav.indexOf(font);
      if (i >= 0) {
        fav.splice(i, 1);
        setFav([...fav]);
      }
    }
  };
  const remFromFavSpec = (i) => {
    if (typeof i === 'number' && i >= 0) {
      fav.splice(i, 1);
      setFav([...fav]);
    }
  };
  // save to localstorage
  React.useEffect(() => {
    if (Array.isArray(fav)) {
      localStorage.setItem('fav', JSON.stringify(fav));
    }
  }, [fav]);

  const [txt] = React.useState(lipsum(13));

  const [keyword, setKeyword] = React.useState('');

  const filter = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f58b}'} Font Explorer</h1>
        <p className="lead">
          Click on <Badge success>Add to Favorite</Badge> to short list the font
        </p>
      </div>
      <hr />

      <div className="row">
        <div className="col-6 border-right">
          <div
            className="p-3 rounded shadow text-white"
            style={{ background: randomBgGrandientDarkStyle() }}
          >
            <div className="text-center">
              <Badge dark pill>
                {os}
              </Badge>
            </div>

            <div className="mb-3">
              <label>Filter by</label>
              <input
                className="form-control"
                type="search"
                value={keyword}
                onChange={filter}
              />
            </div>

            <div className="row mb-3">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <Button
                      light
                      outline
                      label={'\u276e'}
                      onClick={prev}
                      className="shadow"
                    />
                  </div>
                  <select
                    className="form-control"
                    onChange={handleChange}
                    value={font}
                  >
                    {list
                      .filter((w) => {
                        if (typeof w === 'string' && w.length > 0) {
                          return (
                            w.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      })
                      .map((e, i) => (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      ))}
                  </select>
                  <div className="input-group-append">
                    <Button
                      light
                      outline
                      label={'\u276f'}
                      onClick={next}
                      className="shadow float-right"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mb-3">
              {fav.indexOf(font) < 0 && (
                <div>
                  <Button
                    success
                    sm
                    label={'\u2606 Add to Favorite'}
                    onClick={addToFav}
                    className="shadow mr-2"
                  />
                </div>
              )}
              {fav.indexOf(font) >= 0 && (
                <Button
                  warning
                  sm
                  label={'\u2605 Remove from Favorite'}
                  onClick={remFromFav}
                  className="shadow mr-auto"
                />
              )}
            </div>
          </div>

          <hr />

          <div style={{ fontFamily: font }}>
            <h2 className="text-center">
              {fav.indexOf(font) >= 0 ? (
                <span className="text-warning text-stroke">{'\u2605'}</span>
              ) : (
                '\u2606'
              )}{' '}
              {font}
            </h2>
            <h3>
              {`A B C D E F G H I J K L M N O P Q R S T U V W X Y Z a b c d e f g i j k l m n o p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 0 Foo Bar Baz`}
            </h3>
            <p className="lead">{txt}</p>
          </div>
        </div>
        <div className="col-6">
          <Fav list={fav} onClick={remFromFavSpec} />
        </div>
      </div>
    </div>
  );
}
