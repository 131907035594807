export const CURRENCIES = [
  {
    name: {
      common: 'Aruba',
      official: 'Aruba',
      native: {
        nld: { official: 'Aruba', common: 'Aruba' },
        pap: { official: 'Aruba', common: 'Aruba' },
      },
    },
    cca2: 'AW',
    currencies: { AWG: { name: 'Aruban florin', symbol: 'ƒ' } },
  },
  {
    name: {
      common: 'Afghanistan',
      official: 'Islamic Republic of Afghanistan',
      native: {
        prs: { official: 'جمهوری اسلامی افغانستان', common: 'افغانستان' },
        pus: { official: 'د افغانستان اسلامي جمهوریت', common: 'افغانستان' },
        tuk: {
          official: 'Owganystan Yslam Respublikasy',
          common: 'Owganystan',
        },
      },
    },
    cca2: 'AF',
    currencies: { AFN: { name: 'Afghan afghani', symbol: '؋' } },
  },
  {
    name: {
      common: 'Angola',
      official: 'Republic of Angola',
      native: { por: { official: 'República de Angola', common: 'Angola' } },
    },
    cca2: 'AO',
    currencies: { AOA: { name: 'Angolan kwanza', symbol: 'Kz' } },
  },
  {
    name: {
      common: 'Anguilla',
      official: 'Anguilla',
      native: { eng: { official: 'Anguilla', common: 'Anguilla' } },
    },
    cca2: 'AI',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Åland Islands',
      official: 'Åland Islands',
      native: { swe: { official: 'Landskapet Åland', common: 'Åland' } },
    },
    cca2: 'AX',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Albania',
      official: 'Republic of Albania',
      native: {
        sqi: { official: 'Republika e Shqipërisë', common: 'Shqipëria' },
      },
    },
    cca2: 'AL',
    currencies: { ALL: { name: 'Albanian lek', symbol: 'L' } },
  },
  {
    name: {
      common: 'Andorra',
      official: 'Principality of Andorra',
      native: { cat: { official: "Principat d'Andorra", common: 'Andorra' } },
    },
    cca2: 'AD',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'United Arab Emirates',
      official: 'United Arab Emirates',
      native: {
        ara: {
          official: 'الإمارات العربية المتحدة',
          common: 'دولة الإمارات العربية المتحدة',
        },
      },
    },
    cca2: 'AE',
    currencies: { AED: { name: 'United Arab Emirates dirham', symbol: 'د.إ' } },
  },
  {
    name: {
      common: 'Argentina',
      official: 'Argentine Republic',
      native: {
        grn: { official: 'Argentine Republic', common: 'Argentina' },
        spa: { official: 'República Argentina', common: 'Argentina' },
      },
    },
    cca2: 'AR',
    currencies: { ARS: { name: 'Argentine peso', symbol: '$' } },
  },
  {
    name: {
      common: 'Armenia',
      official: 'Republic of Armenia',
      native: {
        hye: { official: 'Հայաստանի Հանրապետություն', common: 'Հայաստան' },
      },
    },
    cca2: 'AM',
    currencies: { AMD: { name: 'Armenian dram', symbol: '֏' } },
  },
  {
    name: {
      common: 'American Samoa',
      official: 'American Samoa',
      native: {
        eng: { official: 'American Samoa', common: 'American Samoa' },
        smo: { official: 'Sāmoa Amelika', common: 'Sāmoa Amelika' },
      },
    },
    cca2: 'AS',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: { common: 'Antarctica', official: 'Antarctica', native: {} },
    cca2: 'AQ',
    currencies: [],
  },
  {
    name: {
      common: 'French Southern and Antarctic Lands',
      official: 'Territory of the French Southern and Antarctic Lands',
      native: {
        fra: {
          official:
            'Territoire des Terres australes et antarctiques françaises',
          common: 'Terres australes et antarctiques françaises',
        },
      },
    },
    cca2: 'TF',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Antigua and Barbuda',
      official: 'Antigua and Barbuda',
      native: {
        eng: { official: 'Antigua and Barbuda', common: 'Antigua and Barbuda' },
      },
    },
    cca2: 'AG',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Australia',
      official: 'Commonwealth of Australia',
      native: {
        eng: { official: 'Commonwealth of Australia', common: 'Australia' },
      },
    },
    cca2: 'AU',
    currencies: { AUD: { name: 'Australian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Austria',
      official: 'Republic of Austria',
      native: {
        bar: { official: 'Republik Österreich', common: 'Österreich' },
      },
    },
    cca2: 'AT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Azerbaijan',
      official: 'Republic of Azerbaijan',
      native: {
        aze: { official: 'Azərbaycan Respublikası', common: 'Azərbaycan' },
        rus: { official: 'Азербайджанская Республика', common: 'Азербайджан' },
      },
    },
    cca2: 'AZ',
    currencies: { AZN: { name: 'Azerbaijani manat', symbol: '₼' } },
  },
  {
    name: {
      common: 'Burundi',
      official: 'Republic of Burundi',
      native: {
        fra: { official: 'République du Burundi', common: 'Burundi' },
        run: { official: "Republika y'Uburundi ", common: 'Uburundi' },
      },
    },
    cca2: 'BI',
    currencies: { BIF: { name: 'Burundian franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Belgium',
      official: 'Kingdom of Belgium',
      native: {
        deu: { official: 'Königreich Belgien', common: 'Belgien' },
        fra: { official: 'Royaume de Belgique', common: 'Belgique' },
        nld: { official: 'Koninkrijk België', common: 'België' },
      },
    },
    cca2: 'BE',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Benin',
      official: 'Republic of Benin',
      native: { fra: { official: 'République du Bénin', common: 'Bénin' } },
    },
    cca2: 'BJ',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Burkina Faso',
      official: 'Burkina Faso',
      native: {
        fra: { official: 'République du Burkina', common: 'Burkina Faso' },
      },
    },
    cca2: 'BF',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Bangladesh',
      official: "People's Republic of Bangladesh",
      native: {
        ben: { official: 'বাংলাদেশ গণপ্রজাতন্ত্রী', common: 'বাংলাদেশ' },
      },
    },
    cca2: 'BD',
    currencies: { BDT: { name: 'Bangladeshi taka', symbol: '৳' } },
  },
  {
    name: {
      common: 'Bulgaria',
      official: 'Republic of Bulgaria',
      native: { bul: { official: 'Република България', common: 'България' } },
    },
    cca2: 'BG',
    currencies: { BGN: { name: 'Bulgarian lev', symbol: 'лв' } },
  },
  {
    name: {
      common: 'Bahrain',
      official: 'Kingdom of Bahrain',
      native: { ara: { official: 'مملكة البحرين', common: '‏البحرين' } },
    },
    cca2: 'BH',
    currencies: { BHD: { name: 'Bahraini dinar', symbol: '.د.ب' } },
  },
  {
    name: {
      common: 'Bahamas',
      official: 'Commonwealth of the Bahamas',
      native: {
        eng: { official: 'Commonwealth of the Bahamas', common: 'Bahamas' },
      },
    },
    cca2: 'BS',
    currencies: {
      BSD: { name: 'Bahamian dollar', symbol: '$' },
      USD: { name: 'United States dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Bosnia and Herzegovina',
      official: 'Bosnia and Herzegovina',
      native: {
        bos: { official: 'Bosna i Hercegovina', common: 'Bosna i Hercegovina' },
        hrv: { official: 'Bosna i Hercegovina', common: 'Bosna i Hercegovina' },
        srp: { official: 'Боснa и Херцеговина', common: 'Боснa и Херцеговина' },
      },
    },
    cca2: 'BA',
    currencies: {
      BAM: { name: 'Bosnia and Herzegovina convertible mark', symbol: '' },
    },
  },
  {
    name: {
      common: 'Saint Barthélemy',
      official: 'Collectivity of Saint Barthélemy',
      native: {
        fra: {
          official: 'Collectivité de Saint-Barthélemy',
          common: 'Saint-Barthélemy',
        },
      },
    },
    cca2: 'BL',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Saint Helena, Ascension and Tristan da Cunha',
      official: 'Saint Helena, Ascension and Tristan da Cunha',
      native: {
        eng: {
          official: 'Saint Helena, Ascension and Tristan da Cunha',
          common: 'Saint Helena, Ascension and Tristan da Cunha',
        },
      },
    },
    cca2: 'SH',
    currencies: {
      GBP: { name: 'Pound sterling', symbol: '£' },
      SHP: { name: 'Saint Helena pound', symbol: '£' },
    },
  },
  {
    name: {
      common: 'Belarus',
      official: 'Republic of Belarus',
      native: {
        bel: { official: 'Рэспубліка Беларусь', common: 'Белару́сь' },
        rus: { official: 'Республика Беларусь', common: 'Беларусь' },
      },
    },
    cca2: 'BY',
    currencies: { BYN: { name: 'Belarusian ruble', symbol: 'Br' } },
  },
  {
    name: {
      common: 'Belize',
      official: 'Belize',
      native: {
        bjz: { official: 'Belize', common: 'Belize' },
        eng: { official: 'Belize', common: 'Belize' },
        spa: { official: 'Belice', common: 'Belice' },
      },
    },
    cca2: 'BZ',
    currencies: { BZD: { name: 'Belize dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Bermuda',
      official: 'Bermuda',
      native: { eng: { official: 'Bermuda', common: 'Bermuda' } },
    },
    cca2: 'BM',
    currencies: { BMD: { name: 'Bermudian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Bolivia',
      official: 'Plurinational State of Bolivia',
      native: {
        aym: { official: 'Wuliwya Suyu', common: 'Wuliwya' },
        grn: { official: 'Tetã Volívia', common: 'Volívia' },
        que: { official: 'Buliwya Mamallaqta', common: 'Buliwya' },
        spa: { official: 'Estado Plurinacional de Bolivia', common: 'Bolivia' },
      },
    },
    cca2: 'BO',
    currencies: { BOB: { name: 'Bolivian boliviano', symbol: 'Bs.' } },
  },
  {
    name: {
      common: 'Caribbean Netherlands',
      official: 'Bonaire, Sint Eustatius and Saba',
      native: {
        nld: {
          official: 'Bonaire, Sint Eustatius en Saba',
          common: 'Caribisch Nederland',
        },
        pap: {
          official: 'Boneiru, Sint Eustatius y Saba',
          common: 'Boneiru, Sint Eustatius y Saba',
        },
      },
    },
    cca2: 'BQ',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Brazil',
      official: 'Federative Republic of Brazil',
      native: {
        por: { official: 'República Federativa do Brasil', common: 'Brasil' },
      },
    },
    cca2: 'BR',
    currencies: { BRL: { name: 'Brazilian real', symbol: 'R$' } },
  },
  {
    name: {
      common: 'Barbados',
      official: 'Barbados',
      native: { eng: { official: 'Barbados', common: 'Barbados' } },
    },
    cca2: 'BB',
    currencies: { BBD: { name: 'Barbadian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Brunei',
      official: 'Nation of Brunei, Abode of Peace',
      native: {
        msa: {
          official: 'Nation of Brunei, Abode Damai',
          common: 'Negara Brunei Darussalam',
        },
      },
    },
    cca2: 'BN',
    currencies: {
      BND: { name: 'Brunei dollar', symbol: '$' },
      SGD: { name: 'Singapore dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Bhutan',
      official: 'Kingdom of Bhutan',
      native: { dzo: { official: 'འབྲུག་རྒྱལ་ཁབ་', common: 'འབྲུག་ཡུལ་' } },
    },
    cca2: 'BT',
    currencies: {
      BTN: { name: 'Bhutanese ngultrum', symbol: 'Nu.' },
      INR: { name: 'Indian rupee', symbol: '₹' },
    },
  },
  {
    name: {
      common: 'Bouvet Island',
      official: 'Bouvet Island',
      native: { nor: { official: 'Bouvetøya', common: 'Bouvetøya' } },
    },
    cca2: 'BV',
    currencies: [],
  },
  {
    name: {
      common: 'Botswana',
      official: 'Republic of Botswana',
      native: {
        eng: { official: 'Republic of Botswana', common: 'Botswana' },
        tsn: { official: 'Lefatshe la Botswana', common: 'Botswana' },
      },
    },
    cca2: 'BW',
    currencies: { BWP: { name: 'Botswana pula', symbol: 'P' } },
  },
  {
    name: {
      common: 'Central African Republic',
      official: 'Central African Republic',
      native: {
        fra: {
          official: 'République centrafricaine',
          common: 'République centrafricaine',
        },
        sag: { official: 'Ködörösêse tî Bêafrîka', common: 'Bêafrîka' },
      },
    },
    cca2: 'CF',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Canada',
      official: 'Canada',
      native: {
        eng: { official: 'Canada', common: 'Canada' },
        fra: { official: 'Canada', common: 'Canada' },
      },
    },
    cca2: 'CA',
    currencies: { CAD: { name: 'Canadian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Cocos (Keeling) Islands',
      official: 'Territory of the Cocos (Keeling) Islands',
      native: {
        eng: {
          official: 'Territory of the Cocos (Keeling) Islands',
          common: 'Cocos (Keeling) Islands',
        },
      },
    },
    cca2: 'CC',
    currencies: { AUD: { name: 'Australian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Switzerland',
      official: 'Swiss Confederation',
      native: {
        fra: { official: 'Confédération suisse', common: 'Suisse' },
        gsw: {
          official: 'Schweizerische Eidgenossenschaft',
          common: 'Schweiz',
        },
        ita: { official: 'Confederazione Svizzera', common: 'Svizzera' },
        roh: { official: 'Confederaziun svizra', common: 'Svizra' },
      },
    },
    cca2: 'CH',
    currencies: { CHF: { name: 'Swiss franc', symbol: 'Fr.' } },
  },
  {
    name: {
      common: 'Chile',
      official: 'Republic of Chile',
      native: { spa: { official: 'República de Chile', common: 'Chile' } },
    },
    cca2: 'CL',
    currencies: { CLP: { name: 'Chilean peso', symbol: '$' } },
  },
  {
    name: {
      common: 'China',
      official: "People's Republic of China",
      native: { zho: { official: '中华人民共和国', common: '中国' } },
    },
    cca2: 'CN',
    currencies: { CNY: { name: 'Chinese yuan', symbol: '¥' } },
  },
  {
    name: {
      common: 'Ivory Coast',
      official: "Republic of Côte d'Ivoire",
      native: {
        fra: {
          official: "République de Côte d'Ivoire",
          common: "Côte d'Ivoire",
        },
      },
    },
    cca2: 'CI',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Cameroon',
      official: 'Republic of Cameroon',
      native: {
        eng: { official: 'Republic of Cameroon', common: 'Cameroon' },
        fra: { official: 'République du Cameroun', common: 'Cameroun' },
      },
    },
    cca2: 'CM',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'DR Congo',
      official: 'Democratic Republic of the Congo',
      native: {
        fra: {
          official: 'République démocratique du Congo',
          common: 'RD Congo',
        },
        kon: {
          official: 'Repubilika ya Kongo Demokratiki',
          common: 'Repubilika ya Kongo Demokratiki',
        },
        lin: {
          official: 'Republiki ya Kongó Demokratiki',
          common: 'Republiki ya Kongó Demokratiki',
        },
        lua: {
          official: 'Ditunga dia Kongu wa Mungalaata',
          common: 'Ditunga dia Kongu wa Mungalaata',
        },
        swa: {
          official: 'Jamhuri ya Kidemokrasia ya Kongo',
          common: 'Jamhuri ya Kidemokrasia ya Kongo',
        },
      },
    },
    cca2: 'CD',
    currencies: { CDF: { name: 'Congolese franc', symbol: 'FC' } },
  },
  {
    name: {
      common: 'Republic of the Congo',
      official: 'Republic of the Congo',
      native: {
        fra: { official: 'République du Congo', common: 'République du Congo' },
        kon: { official: 'Repubilika ya Kongo', common: 'Repubilika ya Kongo' },
        lin: { official: 'Republíki ya Kongó', common: 'Republíki ya Kongó' },
      },
    },
    cca2: 'CG',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Cook Islands',
      official: 'Cook Islands',
      native: {
        eng: { official: 'Cook Islands', common: 'Cook Islands' },
        rar: { official: "Kūki 'Āirani", common: "Kūki 'Āirani" },
      },
    },
    cca2: 'CK',
    currencies: {
      CKD: { name: 'Cook Islands dollar', symbol: '$' },
      NZD: { name: 'New Zealand dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Colombia',
      official: 'Republic of Colombia',
      native: {
        spa: { official: 'República de Colombia', common: 'Colombia' },
      },
    },
    cca2: 'CO',
    currencies: { COP: { name: 'Colombian peso', symbol: '$' } },
  },
  {
    name: {
      common: 'Comoros',
      official: 'Union of the Comoros',
      native: {
        ara: { official: 'الاتحاد القمري', common: 'القمر‎' },
        fra: { official: 'Union des Comores', common: 'Comores' },
        zdj: { official: 'Udzima wa Komori', common: 'Komori' },
      },
    },
    cca2: 'KM',
    currencies: { KMF: { name: 'Comorian franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Cape Verde',
      official: 'Republic of Cabo Verde',
      native: {
        por: { official: 'República de Cabo Verde', common: 'Cabo Verde' },
      },
    },
    cca2: 'CV',
    currencies: { CVE: { name: 'Cape Verdean escudo', symbol: 'Esc' } },
  },
  {
    name: {
      common: 'Costa Rica',
      official: 'Republic of Costa Rica',
      native: {
        spa: { official: 'República de Costa Rica', common: 'Costa Rica' },
      },
    },
    cca2: 'CR',
    currencies: { CRC: { name: 'Costa Rican colón', symbol: '₡' } },
  },
  {
    name: {
      common: 'Cuba',
      official: 'Republic of Cuba',
      native: { spa: { official: 'República de Cuba', common: 'Cuba' } },
    },
    cca2: 'CU',
    currencies: {
      CUC: { name: 'Cuban convertible peso', symbol: '$' },
      CUP: { name: 'Cuban peso', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Curaçao',
      official: 'Country of Curaçao',
      native: {
        eng: { official: 'Country of Curaçao', common: 'Curaçao' },
        nld: { official: 'Land Curaçao', common: 'Curaçao' },
        pap: { official: 'Pais Kòrsou', common: 'Pais Kòrsou' },
      },
    },
    cca2: 'CW',
    currencies: { ANG: { name: 'Netherlands Antillean guilder', symbol: 'ƒ' } },
  },
  {
    name: {
      common: 'Christmas Island',
      official: 'Territory of Christmas Island',
      native: {
        eng: {
          official: 'Territory of Christmas Island',
          common: 'Christmas Island',
        },
      },
    },
    cca2: 'CX',
    currencies: { AUD: { name: 'Australian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Cayman Islands',
      official: 'Cayman Islands',
      native: { eng: { official: 'Cayman Islands', common: 'Cayman Islands' } },
    },
    cca2: 'KY',
    currencies: { KYD: { name: 'Cayman Islands dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Cyprus',
      official: 'Republic of Cyprus',
      native: {
        ell: { official: 'Δημοκρατία της Κύπρος', common: 'Κύπρος' },
        tur: { official: 'Kıbrıs Cumhuriyeti', common: 'Kıbrıs' },
      },
    },
    cca2: 'CY',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Czechia',
      official: 'Czech Republic',
      native: {
        ces: { official: 'česká republika', common: 'Česko' },
        slk: { official: 'Česká republika', common: 'Česko' },
      },
    },
    cca2: 'CZ',
    currencies: { CZK: { name: 'Czech koruna', symbol: 'Kč' } },
  },
  {
    name: {
      common: 'Germany',
      official: 'Federal Republic of Germany',
      native: {
        deu: { official: 'Bundesrepublik Deutschland', common: 'Deutschland' },
      },
    },
    cca2: 'DE',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Djibouti',
      official: 'Republic of Djibouti',
      native: {
        ara: { official: 'جمهورية جيبوتي', common: 'جيبوتي‎' },
        fra: { official: 'République de Djibouti', common: 'Djibouti' },
      },
    },
    cca2: 'DJ',
    currencies: { DJF: { name: 'Djiboutian franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Dominica',
      official: 'Commonwealth of Dominica',
      native: {
        eng: { official: 'Commonwealth of Dominica', common: 'Dominica' },
      },
    },
    cca2: 'DM',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Denmark',
      official: 'Kingdom of Denmark',
      native: { dan: { official: 'Kongeriget Danmark', common: 'Danmark' } },
    },
    cca2: 'DK',
    currencies: { DKK: { name: 'Danish krone', symbol: 'kr' } },
  },
  {
    name: {
      common: 'Dominican Republic',
      official: 'Dominican Republic',
      native: {
        spa: {
          official: 'República Dominicana',
          common: 'República Dominicana',
        },
      },
    },
    cca2: 'DO',
    currencies: { DOP: { name: 'Dominican peso', symbol: '$' } },
  },
  {
    name: {
      common: 'Algeria',
      official: "People's Democratic Republic of Algeria",
      native: {
        ara: {
          official: 'الجمهورية الديمقراطية الشعبية الجزائرية',
          common: 'الجزائر',
        },
      },
    },
    cca2: 'DZ',
    currencies: { DZD: { name: 'Algerian dinar', symbol: 'د.ج' } },
  },
  {
    name: {
      common: 'Ecuador',
      official: 'Republic of Ecuador',
      native: { spa: { official: 'República del Ecuador', common: 'Ecuador' } },
    },
    cca2: 'EC',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Egypt',
      official: 'Arab Republic of Egypt',
      native: { ara: { official: 'جمهورية مصر العربية', common: 'مصر' } },
    },
    cca2: 'EG',
    currencies: { EGP: { name: 'Egyptian pound', symbol: '£' } },
  },
  {
    name: {
      common: 'Eritrea',
      official: 'State of Eritrea',
      native: {
        ara: { official: 'دولة إرتريا', common: 'إرتريا‎' },
        eng: { official: 'State of Eritrea', common: 'Eritrea' },
        tir: { official: 'ሃገረ ኤርትራ', common: 'ኤርትራ' },
      },
    },
    cca2: 'ER',
    currencies: { ERN: { name: 'Eritrean nakfa', symbol: 'Nfk' } },
  },
  {
    name: {
      common: 'Western Sahara',
      official: 'Sahrawi Arab Democratic Republic',
      native: {
        ber: {
          official: 'Sahrawi Arab Democratic Republic',
          common: 'Western Sahara',
        },
        mey: {
          official: 'الجمهورية العربية الصحراوية الديمقراطية',
          common: 'الصحراء الغربية',
        },
        spa: {
          official: 'República Árabe Saharaui Democrática',
          common: 'Sahara Occidental',
        },
      },
    },
    cca2: 'EH',
    currencies: {
      DZD: { name: 'Algerian dinar', symbol: 'دج' },
      MAD: { name: 'Moroccan dirham', symbol: 'DH' },
      MRU: { name: 'Mauritanian ouguiya', symbol: 'UM' },
    },
  },
  {
    name: {
      common: 'Spain',
      official: 'Kingdom of Spain',
      native: { spa: { official: 'Reino de España', common: 'España' } },
    },
    cca2: 'ES',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Estonia',
      official: 'Republic of Estonia',
      native: { est: { official: 'Eesti Vabariik', common: 'Eesti' } },
    },
    cca2: 'EE',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Ethiopia',
      official: 'Federal Democratic Republic of Ethiopia',
      native: {
        amh: { official: 'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ', common: 'ኢትዮጵያ' },
      },
    },
    cca2: 'ET',
    currencies: { ETB: { name: 'Ethiopian birr', symbol: 'Br' } },
  },
  {
    name: {
      common: 'Finland',
      official: 'Republic of Finland',
      native: {
        fin: { official: 'Suomen tasavalta', common: 'Suomi' },
        swe: { official: 'Republiken Finland', common: 'Finland' },
      },
    },
    cca2: 'FI',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Fiji',
      official: 'Republic of Fiji',
      native: {
        eng: { official: 'Republic of Fiji', common: 'Fiji' },
        fij: { official: 'Matanitu Tugalala o Viti', common: 'Viti' },
        hif: { official: 'रिपब्लिक ऑफ फीजी', common: 'फिजी' },
      },
    },
    cca2: 'FJ',
    currencies: { FJD: { name: 'Fijian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Falkland Islands',
      official: 'Falkland Islands',
      native: {
        eng: { official: 'Falkland Islands', common: 'Falkland Islands' },
      },
    },
    cca2: 'FK',
    currencies: { FKP: { name: 'Falkland Islands pound', symbol: '£' } },
  },
  {
    name: {
      common: 'France',
      official: 'French Republic',
      native: { fra: { official: 'République française', common: 'France' } },
    },
    cca2: 'FR',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Faroe Islands',
      official: 'Faroe Islands',
      native: {
        dan: { official: 'Færøerne', common: 'Færøerne' },
        fao: { official: 'Føroyar', common: 'Føroyar' },
      },
    },
    cca2: 'FO',
    currencies: {
      DKK: { name: 'Danish krone', symbol: 'kr' },
      FOK: { name: 'Faroese króna', symbol: 'kr' },
    },
  },
  {
    name: {
      common: 'Micronesia',
      official: 'Federated States of Micronesia',
      native: {
        eng: {
          official: 'Federated States of Micronesia',
          common: 'Micronesia',
        },
      },
    },
    cca2: 'FM',
    currencies: [],
  },
  {
    name: {
      common: 'Gabon',
      official: 'Gabonese Republic',
      native: { fra: { official: 'République gabonaise', common: 'Gabon' } },
    },
    cca2: 'GA',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'United Kingdom',
      official: 'United Kingdom of Great Britain and Northern Ireland',
      native: {
        eng: {
          official: 'United Kingdom of Great Britain and Northern Ireland',
          common: 'United Kingdom',
        },
      },
    },
    cca2: 'GB',
    currencies: { GBP: { name: 'British pound', symbol: '£' } },
  },
  {
    name: {
      common: 'Georgia',
      official: 'Georgia',
      native: { kat: { official: 'საქართველო', common: 'საქართველო' } },
    },
    cca2: 'GE',
    currencies: { GEL: { name: 'lari', symbol: '₾' } },
  },
  {
    name: {
      common: 'Guernsey',
      official: 'Bailiwick of Guernsey',
      native: {
        eng: { official: 'Bailiwick of Guernsey', common: 'Guernsey' },
        fra: { official: 'Bailliage de Guernesey', common: 'Guernesey' },
        nfr: { official: 'Dgèrnésiais', common: 'Dgèrnésiais' },
      },
    },
    cca2: 'GG',
    currencies: {
      GBP: { name: 'British pound', symbol: '£' },
      GGP: { name: 'Guernsey pound', symbol: '£' },
    },
  },
  {
    name: {
      common: 'Ghana',
      official: 'Republic of Ghana',
      native: { eng: { official: 'Republic of Ghana', common: 'Ghana' } },
    },
    cca2: 'GH',
    currencies: { GHS: { name: 'Ghanaian cedi', symbol: '₵' } },
  },
  {
    name: {
      common: 'Gibraltar',
      official: 'Gibraltar',
      native: { eng: { official: 'Gibraltar', common: 'Gibraltar' } },
    },
    cca2: 'GI',
    currencies: { GIP: { name: 'Gibraltar pound', symbol: '£' } },
  },
  {
    name: {
      common: 'Guinea',
      official: 'Republic of Guinea',
      native: { fra: { official: 'République de Guinée', common: 'Guinée' } },
    },
    cca2: 'GN',
    currencies: { GNF: { name: 'Guinean franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Guadeloupe',
      official: 'Guadeloupe',
      native: { fra: { official: 'Guadeloupe', common: 'Guadeloupe' } },
    },
    cca2: 'GP',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Gambia',
      official: 'Republic of the Gambia',
      native: { eng: { official: 'Republic of the Gambia', common: 'Gambia' } },
    },
    cca2: 'GM',
    currencies: { GMD: { name: 'dalasi', symbol: 'D' } },
  },
  {
    name: {
      common: 'Guinea-Bissau',
      official: 'Republic of Guinea-Bissau',
      native: {
        por: { official: 'República da Guiné-Bissau', common: 'Guiné-Bissau' },
        pov: { official: 'República da Guiné-Bissau', common: 'Guiné-Bissau' },
      },
    },
    cca2: 'GW',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Equatorial Guinea',
      official: 'Republic of Equatorial Guinea',
      native: {
        fra: {
          official: 'République de la Guinée Équatoriale',
          common: 'Guinée équatoriale',
        },
        por: {
          official: 'República da Guiné Equatorial',
          common: 'Guiné Equatorial',
        },
        spa: {
          official: 'República de Guinea Ecuatorial',
          common: 'Guinea Ecuatorial',
        },
      },
    },
    cca2: 'GQ',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Greece',
      official: 'Hellenic Republic',
      native: { ell: { official: 'Ελληνική Δημοκρατία', common: 'Ελλάδα' } },
    },
    cca2: 'GR',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Grenada',
      official: 'Grenada',
      native: { eng: { official: 'Grenada', common: 'Grenada' } },
    },
    cca2: 'GD',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Greenland',
      official: 'Greenland',
      native: {
        kal: { official: 'Kalaallit Nunaat', common: 'Kalaallit Nunaat' },
      },
    },
    cca2: 'GL',
    currencies: { DKK: { name: 'krone', symbol: 'kr.' } },
  },
  {
    name: {
      common: 'Guatemala',
      official: 'Republic of Guatemala',
      native: {
        spa: { official: 'República de Guatemala', common: 'Guatemala' },
      },
    },
    cca2: 'GT',
    currencies: { GTQ: { name: 'Guatemalan quetzal', symbol: 'Q' } },
  },
  {
    name: {
      common: 'French Guiana',
      official: 'Guiana',
      native: { fra: { official: 'Guyane', common: 'Guyane française' } },
    },
    cca2: 'GF',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Guam',
      official: 'Guam',
      native: {
        cha: { official: 'Guåhån', common: 'Guåhån' },
        eng: { official: 'Guam', common: 'Guam' },
        spa: { official: 'Guam', common: 'Guam' },
      },
    },
    cca2: 'GU',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Guyana',
      official: 'Co-operative Republic of Guyana',
      native: {
        eng: { official: 'Co-operative Republic of Guyana', common: 'Guyana' },
      },
    },
    cca2: 'GY',
    currencies: { GYD: { name: 'Guyanese dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Hong Kong',
      official:
        "Hong Kong Special Administrative Region of the People's Republic of China",
      native: {
        eng: {
          official:
            "Hong Kong Special Administrative Region of the People's Republic of China",
          common: 'Hong Kong',
        },
        zho: { official: '中华人民共和国香港特别行政区', common: '香港' },
      },
    },
    cca2: 'HK',
    currencies: { HKD: { name: 'Hong Kong dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Heard Island and McDonald Islands',
      official: 'Heard Island and McDonald Islands',
      native: {
        eng: {
          official: 'Heard Island and McDonald Islands',
          common: 'Heard Island and McDonald Islands',
        },
      },
    },
    cca2: 'HM',
    currencies: [],
  },
  {
    name: {
      common: 'Honduras',
      official: 'Republic of Honduras',
      native: {
        spa: { official: 'República de Honduras', common: 'Honduras' },
      },
    },
    cca2: 'HN',
    currencies: { HNL: { name: 'Honduran lempira', symbol: 'L' } },
  },
  {
    name: {
      common: 'Croatia',
      official: 'Republic of Croatia',
      native: { hrv: { official: 'Republika Hrvatska', common: 'Hrvatska' } },
    },
    cca2: 'HR',
    currencies: { HRK: { name: 'Croatian kuna', symbol: 'kn' } },
  },
  {
    name: {
      common: 'Haiti',
      official: 'Republic of Haiti',
      native: {
        fra: { official: "République d'Haïti", common: 'Haïti' },
        hat: { official: 'Repiblik Ayiti', common: 'Ayiti' },
      },
    },
    cca2: 'HT',
    currencies: { HTG: { name: 'Haitian gourde', symbol: 'G' } },
  },
  {
    name: {
      common: 'Hungary',
      official: 'Hungary',
      native: { hun: { official: 'Magyarország', common: 'Magyarország' } },
    },
    cca2: 'HU',
    currencies: { HUF: { name: 'Hungarian forint', symbol: 'Ft' } },
  },
  {
    name: {
      common: 'Indonesia',
      official: 'Republic of Indonesia',
      native: { ind: { official: 'Republik Indonesia', common: 'Indonesia' } },
    },
    cca2: 'ID',
    currencies: { IDR: { name: 'Indonesian rupiah', symbol: 'Rp' } },
  },
  {
    name: {
      common: 'Isle of Man',
      official: 'Isle of Man',
      native: {
        eng: { official: 'Isle of Man', common: 'Isle of Man' },
        glv: { official: 'Ellan Vannin or Mannin', common: 'Mannin' },
      },
    },
    cca2: 'IM',
    currencies: {
      GBP: { name: 'British pound', symbol: '£' },
      IMP: { name: 'Manx pound', symbol: '£' },
    },
  },
  {
    name: {
      common: 'India',
      official: 'Republic of India',
      native: {
        eng: { official: 'Republic of India', common: 'India' },
        hin: { official: 'भारत गणराज्य', common: 'भारत' },
        tam: { official: 'இந்தியக் குடியரசு', common: 'இந்தியா' },
      },
    },
    cca2: 'IN',
    currencies: { INR: { name: 'Indian rupee', symbol: '₹' } },
  },
  {
    name: {
      common: 'British Indian Ocean Territory',
      official: 'British Indian Ocean Territory',
      native: {
        eng: {
          official: 'British Indian Ocean Territory',
          common: 'British Indian Ocean Territory',
        },
      },
    },
    cca2: 'IO',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Ireland',
      official: 'Republic of Ireland',
      native: {
        eng: { official: 'Republic of Ireland', common: 'Ireland' },
        gle: { official: 'Poblacht na hÉireann', common: 'Éire' },
      },
    },
    cca2: 'IE',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Iran',
      official: 'Islamic Republic of Iran',
      native: { per: { official: 'جمهوری اسلامی ایران', common: 'ایران' } },
    },
    cca2: 'IR',
    currencies: { IRR: { name: 'Iranian rial', symbol: '﷼' } },
  },
  {
    name: {
      common: 'Iraq',
      official: 'Republic of Iraq',
      native: {
        ara: { official: 'جمهورية العراق', common: 'العراق' },
        arc: { official: 'ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ', common: 'ܩܘܼܛܢܵܐ' },
        ckb: { official: 'کۆماری عێراق', common: 'کۆماری' },
      },
    },
    cca2: 'IQ',
    currencies: { IQD: { name: 'Iraqi dinar', symbol: 'ع.د' } },
  },
  {
    name: {
      common: 'Iceland',
      official: 'Iceland',
      native: { isl: { official: 'Ísland', common: 'Ísland' } },
    },
    cca2: 'IS',
    currencies: { ISK: { name: 'Icelandic króna', symbol: 'kr' } },
  },
  {
    name: {
      common: 'Israel',
      official: 'State of Israel',
      native: {
        ara: { official: 'دولة إسرائيل', common: 'إسرائيل' },
        heb: { official: 'מדינת ישראל', common: 'ישראל' },
      },
    },
    cca2: 'IL',
    currencies: { ILS: { name: 'Israeli new shekel', symbol: '₪' } },
  },
  {
    name: {
      common: 'Italy',
      official: 'Italian Republic',
      native: { ita: { official: 'Repubblica italiana', common: 'Italia' } },
    },
    cca2: 'IT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Jamaica',
      official: 'Jamaica',
      native: {
        eng: { official: 'Jamaica', common: 'Jamaica' },
        jam: { official: 'Jamaica', common: 'Jamaica' },
      },
    },
    cca2: 'JM',
    currencies: { JMD: { name: 'Jamaican dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Jersey',
      official: 'Bailiwick of Jersey',
      native: {
        eng: { official: 'Bailiwick of Jersey', common: 'Jersey' },
        fra: { official: 'Bailliage de Jersey', common: 'Jersey' },
        nrf: { official: 'Bailliage dé Jèrri', common: 'Jèrri' },
      },
    },
    cca2: 'JE',
    currencies: {
      GBP: { name: 'British pound', symbol: '£' },
      JEP: { name: 'Jersey pound', symbol: '£' },
    },
  },
  {
    name: {
      common: 'Jordan',
      official: 'Hashemite Kingdom of Jordan',
      native: {
        ara: { official: 'المملكة الأردنية الهاشمية', common: 'الأردن' },
      },
    },
    cca2: 'JO',
    currencies: { JOD: { name: 'Jordanian dinar', symbol: 'د.ا' } },
  },
  {
    name: {
      common: 'Japan',
      official: 'Japan',
      native: { jpn: { official: '日本', common: '日本' } },
    },
    cca2: 'JP',
    currencies: { JPY: { name: 'Japanese yen', symbol: '¥' } },
  },
  {
    name: {
      common: 'Kazakhstan',
      official: 'Republic of Kazakhstan',
      native: {
        kaz: { official: 'Қазақстан Республикасы', common: 'Қазақстан' },
        rus: { official: 'Республика Казахстан', common: 'Казахстан' },
      },
    },
    cca2: 'KZ',
    currencies: { KZT: { name: 'Kazakhstani tenge', symbol: '₸' } },
  },
  {
    name: {
      common: 'Kenya',
      official: 'Republic of Kenya',
      native: {
        eng: { official: 'Republic of Kenya', common: 'Kenya' },
        swa: { official: 'Republic of Kenya', common: 'Kenya' },
      },
    },
    cca2: 'KE',
    currencies: { KES: { name: 'Kenyan shilling', symbol: 'Sh' } },
  },
  {
    name: {
      common: 'Kyrgyzstan',
      official: 'Kyrgyz Republic',
      native: {
        kir: { official: 'Кыргыз Республикасы', common: 'Кыргызстан' },
        rus: { official: 'Кыргызская Республика', common: 'Киргизия' },
      },
    },
    cca2: 'KG',
    currencies: { KGS: { name: 'Kyrgyzstani som', symbol: 'с' } },
  },
  {
    name: {
      common: 'Cambodia',
      official: 'Kingdom of Cambodia',
      native: {
        khm: { official: 'ព្រះរាជាណាចក្រកម្ពុជា', common: 'Kâmpŭchéa' },
      },
    },
    cca2: 'KH',
    currencies: {
      KHR: { name: 'Cambodian riel', symbol: '៛' },
      USD: { name: 'United States dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Kiribati',
      official: 'Independent and Sovereign Republic of Kiribati',
      native: {
        eng: {
          official: 'Independent and Sovereign Republic of Kiribati',
          common: 'Kiribati',
        },
        gil: { official: 'Ribaberiki Kiribati', common: 'Kiribati' },
      },
    },
    cca2: 'KI',
    currencies: {
      AUD: { name: 'Australian dollar', symbol: '$' },
      KID: { name: 'Kiribati dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Saint Kitts and Nevis',
      official: 'Federation of Saint Christopher and Nevis',
      native: {
        eng: {
          official: 'Federation of Saint Christopher and Nevis',
          common: 'Saint Kitts and Nevis',
        },
      },
    },
    cca2: 'KN',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'South Korea',
      official: 'Republic of Korea',
      native: { kor: { official: '대한민국', common: '한국' } },
    },
    cca2: 'KR',
    currencies: { KRW: { name: 'South Korean won', symbol: '₩' } },
  },
  {
    name: {
      common: 'Kosovo',
      official: 'Republic of Kosovo',
      native: {
        sqi: { official: 'Republika e Kosovës', common: 'Kosova' },
        srp: { official: 'Република Косово', common: 'Косово' },
      },
    },
    cca2: 'XK',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Kuwait',
      official: 'State of Kuwait',
      native: { ara: { official: 'دولة الكويت', common: 'الكويت' } },
    },
    cca2: 'KW',
    currencies: { KWD: { name: 'Kuwaiti dinar', symbol: 'د.ك' } },
  },
  {
    name: {
      common: 'Laos',
      official: "Lao People's Democratic Republic",
      native: {
        lao: { official: 'ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ', common: 'ສປປລາວ' },
      },
    },
    cca2: 'LA',
    currencies: { LAK: { name: 'Lao kip', symbol: '₭' } },
  },
  {
    name: {
      common: 'Lebanon',
      official: 'Lebanese Republic',
      native: {
        ara: { official: 'الجمهورية اللبنانية', common: 'لبنان' },
        fra: { official: 'République libanaise', common: 'Liban' },
      },
    },
    cca2: 'LB',
    currencies: { LBP: { name: 'Lebanese pound', symbol: 'ل.ل' } },
  },
  {
    name: {
      common: 'Liberia',
      official: 'Republic of Liberia',
      native: { eng: { official: 'Republic of Liberia', common: 'Liberia' } },
    },
    cca2: 'LR',
    currencies: { LRD: { name: 'Liberian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Libya',
      official: 'State of Libya',
      native: { ara: { official: 'الدولة ليبيا', common: '‏ليبيا' } },
    },
    cca2: 'LY',
    currencies: { LYD: { name: 'Libyan dinar', symbol: 'ل.د' } },
  },
  {
    name: {
      common: 'Saint Lucia',
      official: 'Saint Lucia',
      native: { eng: { official: 'Saint Lucia', common: 'Saint Lucia' } },
    },
    cca2: 'LC',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Liechtenstein',
      official: 'Principality of Liechtenstein',
      native: {
        deu: { official: 'Fürstentum Liechtenstein', common: 'Liechtenstein' },
      },
    },
    cca2: 'LI',
    currencies: { CHF: { name: 'Swiss franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Sri Lanka',
      official: 'Democratic Socialist Republic of Sri Lanka',
      native: {
        sin: {
          official: 'ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය',
          common: 'ශ්‍රී ලංකාව',
        },
        tam: { official: 'இலங்கை சனநாயக சோசலிசக் குடியரசு', common: 'இலங்கை' },
      },
    },
    cca2: 'LK',
    currencies: { LKR: { name: 'Sri Lankan rupee', symbol: 'Rs  රු' } },
  },
  {
    name: {
      common: 'Lesotho',
      official: 'Kingdom of Lesotho',
      native: {
        eng: { official: 'Kingdom of Lesotho', common: 'Lesotho' },
        sot: { official: 'Kingdom of Lesotho', common: 'Lesotho' },
      },
    },
    cca2: 'LS',
    currencies: {
      LSL: { name: 'Lesotho loti', symbol: 'L' },
      ZAR: { name: 'South African rand', symbol: 'R' },
    },
  },
  {
    name: {
      common: 'Lithuania',
      official: 'Republic of Lithuania',
      native: { lit: { official: 'Lietuvos Respublikos', common: 'Lietuva' } },
    },
    cca2: 'LT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Luxembourg',
      official: 'Grand Duchy of Luxembourg',
      native: {
        deu: { official: 'Großherzogtum Luxemburg', common: 'Luxemburg' },
        fra: { official: 'Grand-Duché de Luxembourg', common: 'Luxembourg' },
        ltz: { official: 'Groussherzogtum Lëtzebuerg', common: 'Lëtzebuerg' },
      },
    },
    cca2: 'LU',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Latvia',
      official: 'Republic of Latvia',
      native: { lav: { official: 'Latvijas Republikas', common: 'Latvija' } },
    },
    cca2: 'LV',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Macau',
      official:
        "Macao Special Administrative Region of the People's Republic of China",
      native: {
        por: {
          official:
            'Região Administrativa Especial de Macau da República Popular da China',
          common: 'Macau',
        },
        zho: { official: '中华人民共和国澳门特别行政区', common: '澳门' },
      },
    },
    cca2: 'MO',
    currencies: { MOP: { name: 'Macanese pataca', symbol: 'P' } },
  },
  {
    name: {
      common: 'Saint Martin',
      official: 'Saint Martin',
      native: { fra: { official: 'Saint-Martin', common: 'Saint-Martin' } },
    },
    cca2: 'MF',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Morocco',
      official: 'Kingdom of Morocco',
      native: {
        ara: { official: 'المملكة المغربية', common: 'المغرب' },
        ber: { official: 'ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ', common: 'ⵍⵎⴰⵖⵔⵉⴱ' },
      },
    },
    cca2: 'MA',
    currencies: { MAD: { name: 'Moroccan dirham', symbol: 'د.م.' } },
  },
  {
    name: {
      common: 'Monaco',
      official: 'Principality of Monaco',
      native: { fra: { official: 'Principauté de Monaco', common: 'Monaco' } },
    },
    cca2: 'MC',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Moldova',
      official: 'Republic of Moldova',
      native: { ron: { official: 'Republica Moldova', common: 'Moldova' } },
    },
    cca2: 'MD',
    currencies: { MDL: { name: 'Moldovan leu', symbol: 'L' } },
  },
  {
    name: {
      common: 'Madagascar',
      official: 'Republic of Madagascar',
      native: {
        fra: { official: 'République de Madagascar', common: 'Madagascar' },
        mlg: { official: "Repoblikan'i Madagasikara", common: 'Madagasikara' },
      },
    },
    cca2: 'MG',
    currencies: { MGA: { name: 'Malagasy ariary', symbol: 'Ar' } },
  },
  {
    name: {
      common: 'Maldives',
      official: 'Republic of the Maldives',
      native: {
        div: {
          official: 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ',
          common: 'ދިވެހިރާއްޖޭގެ',
        },
      },
    },
    cca2: 'MV',
    currencies: { MVR: { name: 'Maldivian rufiyaa', symbol: '.ރ' } },
  },
  {
    name: {
      common: 'Mexico',
      official: 'United Mexican States',
      native: {
        spa: { official: 'Estados Unidos Mexicanos', common: 'México' },
      },
    },
    cca2: 'MX',
    currencies: { MXN: { name: 'Mexican peso', symbol: '$' } },
  },
  {
    name: {
      common: 'Marshall Islands',
      official: 'Republic of the Marshall Islands',
      native: {
        eng: {
          official: 'Republic of the Marshall Islands',
          common: 'Marshall Islands',
        },
        mah: { official: 'Republic of the Marshall Islands', common: 'M̧ajeļ' },
      },
    },
    cca2: 'MH',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'North Macedonia',
      official: 'Republic of North Macedonia',
      native: {
        mkd: { official: 'Република Северна Македонија', common: 'Македонија' },
      },
    },
    cca2: 'MK',
    currencies: { MKD: { name: 'denar', symbol: 'den' } },
  },
  {
    name: {
      common: 'Mali',
      official: 'Republic of Mali',
      native: { fra: { official: 'République du Mali', common: 'Mali' } },
    },
    cca2: 'ML',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Malta',
      official: 'Republic of Malta',
      native: {
        eng: { official: 'Republic of Malta', common: 'Malta' },
        mlt: { official: "Repubblika ta ' Malta", common: 'Malta' },
      },
    },
    cca2: 'MT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Myanmar',
      official: 'Republic of the Union of Myanmar',
      native: {
        mya: {
          official: 'ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်',
          common: 'မြန်မာ',
        },
      },
    },
    cca2: 'MM',
    currencies: { MMK: { name: 'Burmese kyat', symbol: 'Ks' } },
  },
  {
    name: {
      common: 'Montenegro',
      official: 'Montenegro',
      native: { srp: { official: 'Црна Гора', common: 'Црна Гора' } },
    },
    cca2: 'ME',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Mongolia',
      official: 'Mongolia',
      native: { mon: { official: 'Монгол улс', common: 'Монгол улс' } },
    },
    cca2: 'MN',
    currencies: { MNT: { name: 'Mongolian tögrög', symbol: '₮' } },
  },
  {
    name: {
      common: 'Northern Mariana Islands',
      official: 'Commonwealth of the Northern Mariana Islands',
      native: {
        cal: {
          official: 'Commonwealth of the Northern Mariana Islands',
          common: 'Northern Mariana Islands',
        },
        cha: {
          official: 'Sankattan Siha Na Islas Mariånas',
          common: 'Na Islas Mariånas',
        },
        eng: {
          official: 'Commonwealth of the Northern Mariana Islands',
          common: 'Northern Mariana Islands',
        },
      },
    },
    cca2: 'MP',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Mozambique',
      official: 'Republic of Mozambique',
      native: {
        por: { official: 'República de Moçambique', common: 'Moçambique' },
      },
    },
    cca2: 'MZ',
    currencies: { MZN: { name: 'Mozambican metical', symbol: 'MT' } },
  },
  {
    name: {
      common: 'Mauritania',
      official: 'Islamic Republic of Mauritania',
      native: {
        ara: {
          official: 'الجمهورية الإسلامية الموريتانية',
          common: 'موريتانيا',
        },
      },
    },
    cca2: 'MR',
    currencies: { MRU: { name: 'Mauritanian ouguiya', symbol: 'UM' } },
  },
  {
    name: {
      common: 'Montserrat',
      official: 'Montserrat',
      native: { eng: { official: 'Montserrat', common: 'Montserrat' } },
    },
    cca2: 'MS',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Martinique',
      official: 'Martinique',
      native: { fra: { official: 'Martinique', common: 'Martinique' } },
    },
    cca2: 'MQ',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Mauritius',
      official: 'Republic of Mauritius',
      native: {
        eng: { official: 'Republic of Mauritius', common: 'Mauritius' },
        fra: { official: 'République de Maurice', common: 'Maurice' },
        mfe: { official: 'Republik Moris', common: 'Moris' },
      },
    },
    cca2: 'MU',
    currencies: { MUR: { name: 'Mauritian rupee', symbol: '₨' } },
  },
  {
    name: {
      common: 'Malawi',
      official: 'Republic of Malawi',
      native: {
        eng: { official: 'Republic of Malawi', common: 'Malawi' },
        nya: {
          official: 'Chalo cha Malawi, Dziko la Malaŵi',
          common: 'Malaŵi',
        },
      },
    },
    cca2: 'MW',
    currencies: { MWK: { name: 'Malawian kwacha', symbol: 'MK' } },
  },
  {
    name: {
      common: 'Malaysia',
      official: 'Malaysia',
      native: {
        eng: { official: 'Malaysia', common: 'Malaysia' },
        msa: { official: 'مليسيا', common: 'مليسيا' },
      },
    },
    cca2: 'MY',
    currencies: { MYR: { name: 'Malaysian ringgit', symbol: 'RM' } },
  },
  {
    name: {
      common: 'Mayotte',
      official: 'Department of Mayotte',
      native: {
        fra: { official: 'Département de Mayotte', common: 'Mayotte' },
      },
    },
    cca2: 'YT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Namibia',
      official: 'Republic of Namibia',
      native: {
        afr: { official: 'Republiek van Namibië', common: 'Namibië' },
        deu: { official: 'Republik Namibia', common: 'Namibia' },
        eng: { official: 'Republic of Namibia', common: 'Namibia' },
        her: { official: 'Republic of Namibia', common: 'Namibia' },
        hgm: { official: 'Republic of Namibia', common: 'Namibia' },
        kwn: { official: 'Republic of Namibia', common: 'Namibia' },
        loz: { official: 'Republic of Namibia', common: 'Namibia' },
        ndo: { official: 'Republic of Namibia', common: 'Namibia' },
        tsn: { official: 'Lefatshe la Namibia', common: 'Namibia' },
      },
    },
    cca2: 'NA',
    currencies: {
      NAD: { name: 'Namibian dollar', symbol: '$' },
      ZAR: { name: 'South African rand', symbol: 'R' },
    },
  },
  {
    name: {
      common: 'New Caledonia',
      official: 'New Caledonia',
      native: {
        fra: { official: 'Nouvelle-Calédonie', common: 'Nouvelle-Calédonie' },
      },
    },
    cca2: 'NC',
    currencies: { XPF: { name: 'CFP franc', symbol: '₣' } },
  },
  {
    name: {
      common: 'Niger',
      official: 'Republic of Niger',
      native: { fra: { official: 'République du Niger', common: 'Niger' } },
    },
    cca2: 'NE',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Norfolk Island',
      official: 'Territory of Norfolk Island',
      native: {
        eng: {
          official: 'Territory of Norfolk Island',
          common: 'Norfolk Island',
        },
        pih: { official: "Teratri of Norf'k Ailen", common: "Norf'k Ailen" },
      },
    },
    cca2: 'NF',
    currencies: { AUD: { name: 'Australian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Nigeria',
      official: 'Federal Republic of Nigeria',
      native: {
        eng: { official: 'Federal Republic of Nigeria', common: 'Nigeria' },
      },
    },
    cca2: 'NG',
    currencies: { NGN: { name: 'Nigerian naira', symbol: '₦' } },
  },
  {
    name: {
      common: 'Nicaragua',
      official: 'Republic of Nicaragua',
      native: {
        spa: { official: 'República de Nicaragua', common: 'Nicaragua' },
      },
    },
    cca2: 'NI',
    currencies: { NIO: { name: 'Nicaraguan córdoba', symbol: 'C$' } },
  },
  {
    name: {
      common: 'Niue',
      official: 'Niue',
      native: {
        eng: { official: 'Niue', common: 'Niue' },
        niu: { official: 'Niuē', common: 'Niuē' },
      },
    },
    cca2: 'NU',
    currencies: { NZD: { name: 'New Zealand dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Netherlands',
      official: 'Kingdom of the Netherlands',
      native: {
        nld: { official: 'Koninkrijk der Nederlanden', common: 'Nederland' },
      },
    },
    cca2: 'NL',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Norway',
      official: 'Kingdom of Norway',
      native: {
        nno: { official: 'Kongeriket Noreg', common: 'Noreg' },
        nob: { official: 'Kongeriket Norge', common: 'Norge' },
        smi: { official: 'Norgga gonagasriika', common: 'Norgga' },
      },
    },
    cca2: 'NO',
    currencies: { NOK: { name: 'Norwegian krone', symbol: 'kr' } },
  },
  {
    name: {
      common: 'Nepal',
      official: 'Federal Democratic Republic of Nepal',
      native: {
        nep: { official: 'नेपाल संघीय लोकतान्त्रिक गणतन्त्र', common: 'नेपाल' },
      },
    },
    cca2: 'NP',
    currencies: { NPR: { name: 'Nepalese rupee', symbol: '₨' } },
  },
  {
    name: {
      common: 'Nauru',
      official: 'Republic of Nauru',
      native: {
        eng: { official: 'Republic of Nauru', common: 'Nauru' },
        nau: { official: 'Republic of Nauru', common: 'Nauru' },
      },
    },
    cca2: 'NR',
    currencies: { AUD: { name: 'Australian dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'New Zealand',
      official: 'New Zealand',
      native: {
        eng: { official: 'New Zealand', common: 'New Zealand' },
        mri: { official: 'Aotearoa', common: 'Aotearoa' },
        nzs: { official: 'New Zealand', common: 'New Zealand' },
      },
    },
    cca2: 'NZ',
    currencies: { NZD: { name: 'New Zealand dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Oman',
      official: 'Sultanate of Oman',
      native: { ara: { official: 'سلطنة عمان', common: 'عمان' } },
    },
    cca2: 'OM',
    currencies: { OMR: { name: 'Omani rial', symbol: 'ر.ع.' } },
  },
  {
    name: {
      common: 'Pakistan',
      official: 'Islamic Republic of Pakistan',
      native: {
        eng: { official: 'Islamic Republic of Pakistan', common: 'Pakistan' },
        urd: { official: 'اسلامی جمہوریۂ پاكستان', common: 'پاكستان' },
      },
    },
    cca2: 'PK',
    currencies: { PKR: { name: 'Pakistani rupee', symbol: '₨' } },
  },
  {
    name: {
      common: 'Panama',
      official: 'Republic of Panama',
      native: { spa: { official: 'República de Panamá', common: 'Panamá' } },
    },
    cca2: 'PA',
    currencies: {
      PAB: { name: 'Panamanian balboa', symbol: 'B/.' },
      USD: { name: 'United States dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Pitcairn Islands',
      official: 'Pitcairn Group of Islands',
      native: {
        eng: {
          official: 'Pitcairn Group of Islands',
          common: 'Pitcairn Islands',
        },
      },
    },
    cca2: 'PN',
    currencies: { NZD: { name: 'New Zealand dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Peru',
      official: 'Republic of Peru',
      native: {
        aym: { official: 'Piruw Suyu', common: 'Piruw' },
        que: { official: 'Piruw Ripuwlika', common: 'Piruw' },
        spa: { official: 'República del Perú', common: 'Perú' },
      },
    },
    cca2: 'PE',
    currencies: { PEN: { name: 'Peruvian sol', symbol: 'S/.' } },
  },
  {
    name: {
      common: 'Philippines',
      official: 'Republic of the Philippines',
      native: {
        eng: { official: 'Republic of the Philippines', common: 'Philippines' },
        fil: { official: 'Republic of the Philippines', common: 'Pilipinas' },
      },
    },
    cca2: 'PH',
    currencies: { PHP: { name: 'Philippine peso', symbol: '₱' } },
  },
  {
    name: {
      common: 'Palau',
      official: 'Republic of Palau',
      native: {
        eng: { official: 'Republic of Palau', common: 'Palau' },
        pau: { official: 'Beluu er a Belau', common: 'Belau' },
      },
    },
    cca2: 'PW',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Papua New Guinea',
      official: 'Independent State of Papua New Guinea',
      native: {
        eng: {
          official: 'Independent State of Papua New Guinea',
          common: 'Papua New Guinea',
        },
        hmo: {
          official: 'Independen Stet bilong Papua Niugini',
          common: 'Papua Niu Gini',
        },
        tpi: {
          official: 'Independen Stet bilong Papua Niugini',
          common: 'Papua Niugini',
        },
      },
    },
    cca2: 'PG',
    currencies: { PGK: { name: 'Papua New Guinean kina', symbol: 'K' } },
  },
  {
    name: {
      common: 'Poland',
      official: 'Republic of Poland',
      native: { pol: { official: 'Rzeczpospolita Polska', common: 'Polska' } },
    },
    cca2: 'PL',
    currencies: { PLN: { name: 'Polish złoty', symbol: 'zł' } },
  },
  {
    name: {
      common: 'Puerto Rico',
      official: 'Commonwealth of Puerto Rico',
      native: {
        eng: { official: 'Commonwealth of Puerto Rico', common: 'Puerto Rico' },
        spa: {
          official: 'Estado Libre Asociado de Puerto Rico',
          common: 'Puerto Rico',
        },
      },
    },
    cca2: 'PR',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'North Korea',
      official: "Democratic People's Republic of Korea",
      native: { kor: { official: '조선민주주의인민공화국', common: '조선' } },
    },
    cca2: 'KP',
    currencies: { KPW: { name: 'North Korean won', symbol: '₩' } },
  },
  {
    name: {
      common: 'Portugal',
      official: 'Portuguese Republic',
      native: { por: { official: 'República português', common: 'Portugal' } },
    },
    cca2: 'PT',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Paraguay',
      official: 'Republic of Paraguay',
      native: {
        grn: { official: 'Tetã Paraguái', common: 'Paraguái' },
        spa: { official: 'República de Paraguay', common: 'Paraguay' },
      },
    },
    cca2: 'PY',
    currencies: { PYG: { name: 'Paraguayan guaraní', symbol: '₲' } },
  },
  {
    name: {
      common: 'Palestine',
      official: 'State of Palestine',
      native: { ara: { official: 'دولة فلسطين', common: 'فلسطين' } },
    },
    cca2: 'PS',
    currencies: {
      EGP: { name: 'Egyptian pound', symbol: 'E£' },
      ILS: { name: 'Israeli new shekel', symbol: '₪' },
      JOD: { name: 'Jordanian dinar', symbol: 'JD' },
    },
  },
  {
    name: {
      common: 'French Polynesia',
      official: 'French Polynesia',
      native: {
        fra: { official: 'Polynésie française', common: 'Polynésie française' },
      },
    },
    cca2: 'PF',
    currencies: { XPF: { name: 'CFP franc', symbol: '₣' } },
  },
  {
    name: {
      common: 'Qatar',
      official: 'State of Qatar',
      native: { ara: { official: 'دولة قطر', common: 'قطر' } },
    },
    cca2: 'QA',
    currencies: { QAR: { name: 'Qatari riyal', symbol: 'ر.ق' } },
  },
  {
    name: {
      common: 'Réunion',
      official: 'Réunion Island',
      native: { fra: { official: 'Ile de la Réunion', common: 'La Réunion' } },
    },
    cca2: 'RE',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Romania',
      official: 'Romania',
      native: { ron: { official: 'România', common: 'România' } },
    },
    cca2: 'RO',
    currencies: { RON: { name: 'Romanian leu', symbol: 'lei' } },
  },
  {
    name: {
      common: 'Russia',
      official: 'Russian Federation',
      native: { rus: { official: 'Российская Федерация', common: 'Россия' } },
    },
    cca2: 'RU',
    currencies: { RUB: { name: 'Russian ruble', symbol: '₽' } },
  },
  {
    name: {
      common: 'Rwanda',
      official: 'Republic of Rwanda',
      native: {
        eng: { official: 'Republic of Rwanda', common: 'Rwanda' },
        fra: { official: 'République rwandaise', common: 'Rwanda' },
        kin: { official: "Repubulika y'u Rwanda", common: 'Rwanda' },
      },
    },
    cca2: 'RW',
    currencies: { RWF: { name: 'Rwandan franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Saudi Arabia',
      official: 'Kingdom of Saudi Arabia',
      native: {
        ara: {
          official: 'المملكة العربية السعودية',
          common: 'العربية السعودية',
        },
      },
    },
    cca2: 'SA',
    currencies: { SAR: { name: 'Saudi riyal', symbol: 'ر.س' } },
  },
  {
    name: {
      common: 'Sudan',
      official: 'Republic of the Sudan',
      native: {
        ara: { official: 'جمهورية السودان', common: 'السودان' },
        eng: { official: 'Republic of the Sudan', common: 'Sudan' },
      },
    },
    cca2: 'SD',
    currencies: { SDG: { name: 'Sudanese pound', symbol: '' } },
  },
  {
    name: {
      common: 'Senegal',
      official: 'Republic of Senegal',
      native: { fra: { official: 'République du Sénégal', common: 'Sénégal' } },
    },
    cca2: 'SN',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Singapore',
      official: 'Republic of Singapore',
      native: {
        zho: { official: '新加坡共和国', common: '新加坡' },
        eng: { official: 'Republic of Singapore', common: 'Singapore' },
        msa: { official: 'Republik Singapura', common: 'Singapura' },
        tam: { official: 'சிங்கப்பூர் குடியரசு', common: 'சிங்கப்பூர்' },
      },
    },
    cca2: 'SG',
    currencies: { SGD: { name: 'Singapore dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'South Georgia',
      official: 'South Georgia and the South Sandwich Islands',
      native: {
        eng: {
          official: 'South Georgia and the South Sandwich Islands',
          common: 'South Georgia',
        },
      },
    },
    cca2: 'GS',
    currencies: { SHP: { name: 'Saint Helena pound', symbol: '£' } },
  },
  {
    name: {
      common: 'Svalbard and Jan Mayen',
      official: 'Svalbard og Jan Mayen',
      native: {
        nor: {
          official: 'Svalbard og Jan Mayen',
          common: 'Svalbard og Jan Mayen',
        },
      },
    },
    cca2: 'SJ',
    currencies: { NOK: { name: 'krone', symbol: 'kr' } },
  },
  {
    name: {
      common: 'Solomon Islands',
      official: 'Solomon Islands',
      native: {
        eng: { official: 'Solomon Islands', common: 'Solomon Islands' },
      },
    },
    cca2: 'SB',
    currencies: { SBD: { name: 'Solomon Islands dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Sierra Leone',
      official: 'Republic of Sierra Leone',
      native: {
        eng: { official: 'Republic of Sierra Leone', common: 'Sierra Leone' },
      },
    },
    cca2: 'SL',
    currencies: { SLL: { name: 'Sierra Leonean leone', symbol: 'Le' } },
  },
  {
    name: {
      common: 'El Salvador',
      official: 'Republic of El Salvador',
      native: {
        spa: { official: 'República de El Salvador', common: 'El Salvador' },
      },
    },
    cca2: 'SV',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'San Marino',
      official: 'Most Serene Republic of San Marino',
      native: {
        ita: {
          official: 'Serenissima Repubblica di San Marino',
          common: 'San Marino',
        },
      },
    },
    cca2: 'SM',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Somalia',
      official: 'Federal Republic of Somalia',
      native: {
        ara: { official: 'جمهورية الصومال‎‎', common: 'الصومال‎‎' },
        som: {
          official: 'Jamhuuriyadda Federaalka Soomaaliya',
          common: 'Soomaaliya',
        },
      },
    },
    cca2: 'SO',
    currencies: { SOS: { name: 'Somali shilling', symbol: 'Sh' } },
  },
  {
    name: {
      common: 'Saint Pierre and Miquelon',
      official: 'Saint Pierre and Miquelon',
      native: {
        fra: {
          official: 'Collectivité territoriale de Saint-Pierre-et-Miquelon',
          common: 'Saint-Pierre-et-Miquelon',
        },
      },
    },
    cca2: 'PM',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Serbia',
      official: 'Republic of Serbia',
      native: { srp: { official: 'Република Србија', common: 'Србија' } },
    },
    cca2: 'RS',
    currencies: { RSD: { name: 'Serbian dinar', symbol: 'дин.' } },
  },
  {
    name: {
      common: 'South Sudan',
      official: 'Republic of South Sudan',
      native: {
        eng: { official: 'Republic of South Sudan', common: 'South Sudan' },
      },
    },
    cca2: 'SS',
    currencies: { SSP: { name: 'South Sudanese pound', symbol: '£' } },
  },
  {
    name: {
      common: 'São Tomé and Príncipe',
      official: 'Democratic Republic of São Tomé and Príncipe',
      native: {
        por: {
          official: 'República Democrática do São Tomé e Príncipe',
          common: 'São Tomé e Príncipe',
        },
      },
    },
    cca2: 'ST',
    currencies: { STN: { name: 'São Tomé and Príncipe dobra', symbol: 'Db' } },
  },
  {
    name: {
      common: 'Suriname',
      official: 'Republic of Suriname',
      native: { nld: { official: 'Republiek Suriname', common: 'Suriname' } },
    },
    cca2: 'SR',
    currencies: { SRD: { name: 'Surinamese dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Slovakia',
      official: 'Slovak Republic',
      native: { slk: { official: 'Slovenská republika', common: 'Slovensko' } },
    },
    cca2: 'SK',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Slovenia',
      official: 'Republic of Slovenia',
      native: { slv: { official: 'Republika Slovenija', common: 'Slovenija' } },
    },
    cca2: 'SI',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Sweden',
      official: 'Kingdom of Sweden',
      native: { swe: { official: 'Konungariket Sverige', common: 'Sverige' } },
    },
    cca2: 'SE',
    currencies: { SEK: { name: 'Swedish krona', symbol: 'kr' } },
  },
  {
    name: {
      common: 'Eswatini',
      official: 'Kingdom of Eswatini',
      native: {
        eng: { official: 'Kingdom of Eswatini', common: 'Eswatini' },
        ssw: { official: 'Umbuso weSwatini', common: 'eSwatini' },
      },
    },
    cca2: 'SZ',
    currencies: { SZL: { name: 'Swazi lilangeni', symbol: 'L' } },
  },
  {
    name: {
      common: 'Sint Maarten',
      official: 'Sint Maarten',
      native: {
        eng: { official: 'Sint Maarten', common: 'Sint Maarten' },
        fra: { official: 'Saint-Martin', common: 'Saint-Martin' },
        nld: { official: 'Sint Maarten', common: 'Sint Maarten' },
      },
    },
    cca2: 'SX',
    currencies: { ANG: { name: 'Netherlands Antillean guilder', symbol: 'ƒ' } },
  },
  {
    name: {
      common: 'Seychelles',
      official: 'Republic of Seychelles',
      native: {
        crs: { official: 'Repiblik Sesel', common: 'Sesel' },
        eng: { official: 'Republic of Seychelles', common: 'Seychelles' },
        fra: { official: 'République des Seychelles', common: 'Seychelles' },
      },
    },
    cca2: 'SC',
    currencies: { SCR: { name: 'Seychellois rupee', symbol: '₨' } },
  },
  {
    name: {
      common: 'Syria',
      official: 'Syrian Arab Republic',
      native: {
        ara: { official: 'الجمهورية العربية السورية', common: 'سوريا' },
      },
    },
    cca2: 'SY',
    currencies: { SYP: { name: 'Syrian pound', symbol: '£' } },
  },
  {
    name: {
      common: 'Turks and Caicos Islands',
      official: 'Turks and Caicos Islands',
      native: {
        eng: {
          official: 'Turks and Caicos Islands',
          common: 'Turks and Caicos Islands',
        },
      },
    },
    cca2: 'TC',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Chad',
      official: 'Republic of Chad',
      native: {
        ara: { official: 'جمهورية تشاد', common: 'تشاد‎' },
        fra: { official: 'République du Tchad', common: 'Tchad' },
      },
    },
    cca2: 'TD',
    currencies: { XAF: { name: 'Central African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Togo',
      official: 'Togolese Republic',
      native: { fra: { official: 'République togolaise', common: 'Togo' } },
    },
    cca2: 'TG',
    currencies: { XOF: { name: 'West African CFA franc', symbol: 'Fr' } },
  },
  {
    name: {
      common: 'Thailand',
      official: 'Kingdom of Thailand',
      native: { tha: { official: 'ราชอาณาจักรไทย', common: 'ประเทศไทย' } },
    },
    cca2: 'TH',
    currencies: { THB: { name: 'Thai baht', symbol: '฿' } },
  },
  {
    name: {
      common: 'Tajikistan',
      official: 'Republic of Tajikistan',
      native: {
        rus: { official: 'Республика Таджикистан', common: 'Таджикистан' },
        tgk: { official: 'Ҷумҳурии Тоҷикистон', common: 'Тоҷикистон' },
      },
    },
    cca2: 'TJ',
    currencies: { TJS: { name: 'Tajikistani somoni', symbol: 'ЅМ' } },
  },
  {
    name: {
      common: 'Tokelau',
      official: 'Tokelau',
      native: {
        eng: { official: 'Tokelau', common: 'Tokelau' },
        smo: { official: 'Tokelau', common: 'Tokelau' },
        tkl: { official: 'Tokelau', common: 'Tokelau' },
      },
    },
    cca2: 'TK',
    currencies: { NZD: { name: 'New Zealand dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Turkmenistan',
      official: 'Turkmenistan',
      native: {
        rus: { official: 'Туркменистан', common: 'Туркмения' },
        tuk: { official: 'Türkmenistan', common: 'Türkmenistan' },
      },
    },
    cca2: 'TM',
    currencies: { TMT: { name: 'Turkmenistan manat', symbol: 'm' } },
  },
  {
    name: {
      common: 'Timor-Leste',
      official: 'Democratic Republic of Timor-Leste',
      native: {
        por: {
          official: 'República Democrática de Timor-Leste',
          common: 'Timor-Leste',
        },
        tet: {
          official: 'Repúblika Demokrátika Timór-Leste',
          common: 'Timór-Leste',
        },
      },
    },
    cca2: 'TL',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Tonga',
      official: 'Kingdom of Tonga',
      native: {
        eng: { official: 'Kingdom of Tonga', common: 'Tonga' },
        ton: { official: 'Kingdom of Tonga', common: 'Tonga' },
      },
    },
    cca2: 'TO',
    currencies: { TOP: { name: 'Tongan paʻanga', symbol: 'T$' } },
  },
  {
    name: {
      common: 'Trinidad and Tobago',
      official: 'Republic of Trinidad and Tobago',
      native: {
        eng: {
          official: 'Republic of Trinidad and Tobago',
          common: 'Trinidad and Tobago',
        },
      },
    },
    cca2: 'TT',
    currencies: { TTD: { name: 'Trinidad and Tobago dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Tunisia',
      official: 'Tunisian Republic',
      native: { ara: { official: 'الجمهورية التونسية', common: 'تونس' } },
    },
    cca2: 'TN',
    currencies: { TND: { name: 'Tunisian dinar', symbol: 'د.ت' } },
  },
  {
    name: {
      common: 'Turkey',
      official: 'Republic of Turkey',
      native: { tur: { official: 'Türkiye Cumhuriyeti', common: 'Türkiye' } },
    },
    cca2: 'TR',
    currencies: { TRY: { name: 'Turkish lira', symbol: '₺' } },
  },
  {
    name: {
      common: 'Tuvalu',
      official: 'Tuvalu',
      native: {
        eng: { official: 'Tuvalu', common: 'Tuvalu' },
        tvl: { official: 'Tuvalu', common: 'Tuvalu' },
      },
    },
    cca2: 'TV',
    currencies: {
      AUD: { name: 'Australian dollar', symbol: '$' },
      TVD: { name: 'Tuvaluan dollar', symbol: '$' },
    },
  },
  {
    name: {
      common: 'Taiwan',
      official: 'Republic of China (Taiwan)',
      native: { zho: { official: '中華民國', common: '台灣' } },
    },
    cca2: 'TW',
    currencies: { TWD: { name: 'New Taiwan dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Tanzania',
      official: 'United Republic of Tanzania',
      native: {
        eng: { official: 'United Republic of Tanzania', common: 'Tanzania' },
        swa: {
          official: 'Jamhuri ya Muungano wa Tanzania',
          common: 'Tanzania',
        },
      },
    },
    cca2: 'TZ',
    currencies: { TZS: { name: 'Tanzanian shilling', symbol: 'Sh' } },
  },
  {
    name: {
      common: 'Uganda',
      official: 'Republic of Uganda',
      native: {
        eng: { official: 'Republic of Uganda', common: 'Uganda' },
        swa: { official: 'Republic of Uganda', common: 'Uganda' },
      },
    },
    cca2: 'UG',
    currencies: { UGX: { name: 'Ugandan shilling', symbol: 'Sh' } },
  },
  {
    name: {
      common: 'Ukraine',
      official: 'Ukraine',
      native: { ukr: { official: 'Україна', common: 'Україна' } },
    },
    cca2: 'UA',
    currencies: { UAH: { name: 'Ukrainian hryvnia', symbol: '₴' } },
  },
  {
    name: {
      common: 'United States Minor Outlying Islands',
      official: 'United States Minor Outlying Islands',
      native: {
        eng: {
          official: 'United States Minor Outlying Islands',
          common: 'United States Minor Outlying Islands',
        },
      },
    },
    cca2: 'UM',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Uruguay',
      official: 'Oriental Republic of Uruguay',
      native: {
        spa: { official: 'República Oriental del Uruguay', common: 'Uruguay' },
      },
    },
    cca2: 'UY',
    currencies: { UYU: { name: 'Uruguayan peso', symbol: '$' } },
  },
  {
    name: {
      common: 'United States',
      official: 'United States of America',
      native: {
        eng: { official: 'United States of America', common: 'United States' },
      },
    },
    cca2: 'US',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Uzbekistan',
      official: 'Republic of Uzbekistan',
      native: {
        rus: { official: 'Республика Узбекистан', common: 'Узбекистан' },
        uzb: { official: "O'zbekiston Respublikasi", common: 'O‘zbekiston' },
      },
    },
    cca2: 'UZ',
    currencies: { UZS: { name: 'Uzbekistani soʻm', symbol: "so'm" } },
  },
  {
    name: {
      common: 'Vatican City',
      official: 'Vatican City State',
      native: {
        ita: { official: 'Stato della Città del Vaticano', common: 'Vaticano' },
        lat: { official: 'Status Civitatis Vaticanæ', common: 'Vaticanæ' },
      },
    },
    cca2: 'VA',
    currencies: { EUR: { name: 'Euro', symbol: '€' } },
  },
  {
    name: {
      common: 'Saint Vincent and the Grenadines',
      official: 'Saint Vincent and the Grenadines',
      native: {
        eng: {
          official: 'Saint Vincent and the Grenadines',
          common: 'Saint Vincent and the Grenadines',
        },
      },
    },
    cca2: 'VC',
    currencies: { XCD: { name: 'Eastern Caribbean dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Venezuela',
      official: 'Bolivarian Republic of Venezuela',
      native: {
        spa: {
          official: 'República Bolivariana de Venezuela',
          common: 'Venezuela',
        },
      },
    },
    cca2: 'VE',
    currencies: {
      VES: { name: 'Venezuelan bolívar soberano', symbol: 'Bs.S.' },
    },
  },
  {
    name: {
      common: 'British Virgin Islands',
      official: 'Virgin Islands',
      native: {
        eng: { official: 'Virgin Islands', common: 'British Virgin Islands' },
      },
    },
    cca2: 'VG',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'United States Virgin Islands',
      official: 'Virgin Islands of the United States',
      native: {
        eng: {
          official: 'Virgin Islands of the United States',
          common: 'United States Virgin Islands',
        },
      },
    },
    cca2: 'VI',
    currencies: { USD: { name: 'United States dollar', symbol: '$' } },
  },
  {
    name: {
      common: 'Vietnam',
      official: 'Socialist Republic of Vietnam',
      native: {
        vie: {
          official: 'Cộng hòa xã hội chủ nghĩa Việt Nam',
          common: 'Việt Nam',
        },
      },
    },
    cca2: 'VN',
    currencies: { VND: { name: 'Vietnamese đồng', symbol: '₫' } },
  },
  {
    name: {
      common: 'Vanuatu',
      official: 'Republic of Vanuatu',
      native: {
        bis: { official: 'Ripablik blong Vanuatu', common: 'Vanuatu' },
        eng: { official: 'Republic of Vanuatu', common: 'Vanuatu' },
        fra: { official: 'République de Vanuatu', common: 'Vanuatu' },
      },
    },
    cca2: 'VU',
    currencies: { VUV: { name: 'Vanuatu vatu', symbol: 'Vt' } },
  },
  {
    name: {
      common: 'Wallis and Futuna',
      official: 'Territory of the Wallis and Futuna Islands',
      native: {
        fra: {
          official: 'Territoire des îles Wallis et Futuna',
          common: 'Wallis et Futuna',
        },
      },
    },
    cca2: 'WF',
    currencies: { XPF: { name: 'CFP franc', symbol: '₣' } },
  },
  {
    name: {
      common: 'Samoa',
      official: 'Independent State of Samoa',
      native: {
        eng: { official: 'Independent State of Samoa', common: 'Samoa' },
        smo: { official: 'Malo Saʻoloto Tutoʻatasi o Sāmoa', common: 'Sāmoa' },
      },
    },
    cca2: 'WS',
    currencies: { WST: { name: 'Samoan tālā', symbol: 'T' } },
  },
  {
    name: {
      common: 'Yemen',
      official: 'Republic of Yemen',
      native: { ara: { official: 'الجمهورية اليمنية', common: 'اليَمَن' } },
    },
    cca2: 'YE',
    currencies: { YER: { name: 'Yemeni rial', symbol: '﷼' } },
  },
  {
    name: {
      common: 'South Africa',
      official: 'Republic of South Africa',
      native: {
        afr: { official: 'Republiek van Suid-Afrika', common: 'South Africa' },
        eng: { official: 'Republic of South Africa', common: 'South Africa' },
        nbl: {
          official: 'IRiphabliki yeSewula Afrika',
          common: 'Sewula Afrika',
        },
        nso: {
          official: 'Rephaboliki ya Afrika-Borwa ',
          common: 'Afrika-Borwa',
        },
        sot: {
          official: 'Rephaboliki ya Afrika Borwa',
          common: 'Afrika Borwa',
        },
        ssw: {
          official: 'IRiphabhulikhi yeNingizimu Afrika',
          common: 'Ningizimu Afrika',
        },
        tsn: {
          official: 'Rephaboliki ya Aforika Borwa',
          common: 'Aforika Borwa',
        },
        tso: {
          official: 'Riphabliki ra Afrika Dzonga',
          common: 'Afrika Dzonga',
        },
        ven: {
          official: 'Riphabuḽiki ya Afurika Tshipembe',
          common: 'Afurika Tshipembe',
        },
        xho: {
          official: 'IRiphabliki yaseMzantsi Afrika',
          common: 'Mzantsi Afrika',
        },
        zul: {
          official: 'IRiphabliki yaseNingizimu Afrika',
          common: 'Ningizimu Afrika',
        },
      },
    },
    cca2: 'ZA',
    currencies: { ZAR: { name: 'South African rand', symbol: 'R' } },
  },
  {
    name: {
      common: 'Zambia',
      official: 'Republic of Zambia',
      native: { eng: { official: 'Republic of Zambia', common: 'Zambia' } },
    },
    cca2: 'ZM',
    currencies: { ZMW: { name: 'Zambian kwacha', symbol: 'ZK' } },
  },
  {
    name: {
      common: 'Zimbabwe',
      official: 'Republic of Zimbabwe',
      native: {
        bwg: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        eng: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        kck: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        khi: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        ndc: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        nde: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        nya: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        sna: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        sot: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        toi: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        tsn: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        tso: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        ven: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        xho: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
        zib: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      },
    },
    cca2: 'ZW',
    currencies: {
      BWP: { name: 'Botswana pula', symbol: 'P' },
      CNY: { name: 'Chinese yuan', symbol: '¥' },
      EUR: { name: 'Euro', symbol: '€' },
      GBP: { name: 'British pound', symbol: '£' },
      INR: { name: 'Indian rupee', symbol: '₹' },
      JPY: { name: 'Japanese yen', symbol: '¥' },
      USD: { name: 'United States dollar', symbol: '$' },
      ZAR: { name: 'South African rand', symbol: 'Rs' },
      ZWB: { name: 'Zimbabwean bonds', symbol: '$' },
    },
  },
];
