import React from 'react';
import {
  Button,
  Badge,
  A,
  randomEffect,
  randomBgGrandientDarkStyle,
} from '../Util';
import { db } from './GameOfFortuneDB';
import Char from './GameOfFortuneChar';
import Keyboard from './GameOfFortuneKeyboard';

function MessageBox(props) {
  const { value, onNext } = props;
  const effect = `animated ${randomEffect()} faster`;
  const style = {
    //background: `linear-gradient(64deg,Fuchsia,Crimson)`,
    background: randomBgGrandientDarkStyle(),
    color: 'white',
  };
  return (
    <div
      className={`text-center bg-light p-3 rounded shadow mb-3 ${effect}`}
      style={style}
    >
      <h5 className="text-shadow">{value}</h5>
      <Button light outline lg shadow onClick={onNext} className="mb-2">
        <span className="mr-3">Next</span>
        {'\u2192'}
      </Button>
      <div>
        <small>
          Press <kbd>enter</kbd> or <kbd>return</kbd>
        </small>
      </div>
    </div>
  );
}

export default function GameOfFortune(props) {
  const [data] = React.useState(db());
  const [guesses, setGuesses] = React.useState([]);
  const [msg, setMsg] = React.useState('');
  const [effect, setEffect] = React.useState(randomEffect());

  const { list, size } = data;
  //const [qIndex,setQIndex] = React.useState(Math.floor(Math.random()*size))
  const [qIndex, setQIndex] = React.useState(0);
  const { clue, q, count } = list[qIndex];
  const Q = q.toUpperCase();

  const guess = (c) => {
    if (guesses.join('').indexOf(c) < 0) setGuesses([...guesses, c]);
  };

  const win = () => {
    setMsg("Congratulations! You've got it");
  };

  const next = () => {
    setMsg('');
    setGuesses([]);
    //const qIndex = Math.floor(Math.random()*size)
    const step = Math.floor(Math.random() * 5) + 1;
    let nextQIndex = qIndex + step;
    if (nextQIndex >= size) {
      nextQIndex -= size;
    }
    setQIndex(nextQIndex);
    setEffect(randomEffect());
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    const { key } = e;
    if (/^([A-Za-z]{1})$/.test(key)) {
      guess(key.toUpperCase());
    } else if (key === 'Enter') {
      if (msg) next();
    } else if (key === '/') {
      guess('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
    }
  };

  React.useEffect(() => {
    if (guesses.length > 0) {
      const guessesStr = guesses.join('');
      let n = 0;
      for (let i = 0; i < Q.length; i++) {
        const c = Q.charAt(i);
        if (guessesStr.indexOf(c) >= 0) {
          n++;
        }
      }
      if (n === count) win();
    }
  }, [guesses, Q, count]);

  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <div className="container mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f3b2}'} Game of Fortune</h1>
        <p className="lead">Word Guessing game built in React</p>
        <A blank href="https://codepen.io/mjunaidi/pen/LoaEyp">
          See the code
        </A>
      </div>
      <hr />

      {msg && <MessageBox value={msg} onNext={next} />}

      <div className="text-center mb-3">
        <Badge dark pill shadow className="mb-2">
          CLUE
        </Badge>
        <h5>{clue}</h5>
      </div>

      <div className="mb-3">
        <div
          className="row"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {new Array(Q.length).fill(0).map((e, i) => {
            const c = Q.charAt(i);
            const reveal = guesses.join('').indexOf(c) >= 0;
            if (c === ' ') {
              return <div key={i} className="col-12"></div>;
            }
            return <Char key={i} value={c} reveal={reveal} />;
          })}
        </div>
      </div>

      {guesses.length > 0 && (
        <div className={`animated ${effect} faster mb-3 text-center`}>
          <div className="mb-2">
            <Badge dark pill shdaow>
              GUESS
            </Badge>
          </div>
          {guesses.map((e, i) => (
            <span key={i} className={`animated ${effect} faster m-1`}>
              {e}
            </span>
          ))}
        </div>
      )}

      <div className="mb-3">
        <Keyboard onClick={guess} list={guesses} />
      </div>
    </div>
  );
}
