import React from 'react';
import {
  lipsum,
  Button,
  PreCode,
  Details,
  Callout,
  randomBgGrandientDarkStyle,
  animated,
  randomEffect,
} from '../Util';

export default function Lipsum(props) {
  const [effect] = React.useState(animated(randomEffect()));
  const [l, setL] = React.useState(256);
  const [length, setLength] = React.useState(l);
  const [value, setValue] = React.useState('');

  const handleClick = (e) => {
    e.preventDefault();
    setValue(lipsum(length));
  };

  const changeLength = (e) => {
    e.preventDefault();
    setL(e.target.value);
  };

  React.useEffect(() => {
    if (typeof l === 'string' && l.length > 0) {
      const length = parseInt(l);
      if (typeof length === 'number') {
        setLength(length);
      }
    }
  }, [l]);

  const [sample] = React.useState(lipsum());
  const [sample5] = React.useState(lipsum(5));
  const [sample5dot] = React.useState(lipsum(5, true));

  const countWord = (s) => {
    if (typeof s === 'string' && s.length > 0) {
      return s.split(' ').length;
    }
    return 0;
  };

  const countLength = (s) => {
    if (typeof s === 'string' && s.length > 0) {
      return s.length;
    }
    return 0;
  };

  return (
    <div className="container-lg mb-5">
      <h1 className={`text-center ${effect}`}>{'\u{1f4ac}'} Lipsum</h1>
      <p className={`lead text-center ${effect}`}>
        Generate random text from "Lorem Ipsum"
      </p>

      <div
        className={`mb-5 p-3 shadow rounded text-white ${effect}`}
        style={{ background: randomBgGrandientDarkStyle() }}
      >
        <form onSubmit={handleClick}>
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <Button
                light
                outline
                shadow
                block
                lg
                className="h3"
                onClick={handleClick}
              >
                Generate &raquo;
              </Button>
              <div className="form-group">
                <label className="label-sm">Number of words</label>
                <input
                  className="form-control shadow-inset"
                  value={l}
                  type="number"
                  onChange={changeLength}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="form-group mb-0">
                <textarea
                  className="form-control"
                  value={value}
                  rows={4}
                  readOnly
                />
                <small className="">
                  <span className="mr-3">{countWord(value)} words</span>
                  <span className="float-right">
                    {countLength(value)} characters
                  </span>
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="mb-5">
        <Details lead="The Code" className="h3">
          <PreCode>{`
function lipsum(n=10,dot=false) {
  if (typeof(n)!=='number'||(typeof(n)==='number'&&n<1)) n=10
  const words = ['lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit', 'vivamus', 'et', 'accumsan', 'augue', 'duis', 'eget', 'nunc', 'id', 'sodales', 'finibus', 'vestibulum', 'sagittis', 'magna', 'nec', 'rutrum', 'volutpat', 'risus', 'tincidunt', 'justo', 'non', 'gravida', 'tortor', 'enim', 'in', 'urna', 'ut', 'vel', 'metus', 'pellentesque', 'porttitor', 'vitae', 'nisi', 'nullam', 'faucibus', 'condimentum', 'quam', 'imperdiet', 'class', 'aptent', 'taciti', 'sociosqu', 'ad', 'litora', 'torquent', 'per', 'conubia', 'nostra', 'inceptos', 'himenaeos', 'interdum', 'malesuada', 'fames', 'ac', 'ante', 'primis', 'curabitur', 'nibh', 'quis', 'iaculis', 'cras', 'mollis', 'eu', 'congue', 'leo']
  const count = n
  const sentence = []
  const indexes = (new Array(count)).fill(0).map(index=>Math.floor(Math.random()*words.length))
  indexes.forEach((index,i)=>{
    const word = words[index]
    if (i===0)
      sentence.push(word.charAt(0).toUpperCase()+word.substr(1))
    else
      sentence.push(word)
  })
  if (dot) return sentence.join(' ').concat('.')
  return sentence.join(' ')
}
            `}</PreCode>
        </Details>
      </div>

      <div className="mb-5">
        <h3>Usage</h3>
        <PreCode>{`lipsum()`}</PreCode>
        <h5>Output</h5>
        <PreCode>{sample}</PreCode>
        <Callout primary shadow>
          By default, 10-word length sentence will be generated if no length
          value is set.
        </Callout>
      </div>

      <div className="mb-5">
        <h3>Set the length</h3>
        <PreCode>{`lipsum(5)`}</PreCode>
        <h5>Output</h5>
        <PreCode>{sample5}</PreCode>
      </div>

      <div className="mb-5">
        <h3>End the sentence with a dot</h3>
        <PreCode>{`lipsum(5,true)`}</PreCode>
        <h5>Output</h5>
        <PreCode>{sample5dot}</PreCode>
      </div>
    </div>
  );
}
