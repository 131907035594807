import React from 'react';
//import {Color,Copyable} from '../Util'
import { Color } from '../Util';

export default function ColorBox(props) {
  const { name, className = '' } = props;
  const classNames = ['color', className];

  const el = React.useRef();
  const [rgb, setRgb] = React.useState();
  const [hsl, setHsl] = React.useState();
  const [hex, setHex] = React.useState();
  const [lumin, setLumin] = React.useState();

  React.useEffect(() => {
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        const rgb = window.getComputedStyle(current).backgroundColor;
        setRgb(rgb);
      }
    }
  }, []);

  React.useEffect(() => {
    const c = new Color(rgb);
    //const lumin = c.luminance()
    const lumin = c.lumin();
    setLumin(lumin);
    setHex(c.toString('#'));
    setHsl(c.toString('hsl'));
  }, [rgb]);

  const style = { backgroundColor: name, color: lumin };

  return (
    <div ref={el} className={classNames.join(' ')} style={style}>
      {/*<h6><Copyable>{name}</Copyable></h6>
      <p><Copyable>{hex}</Copyable></p>
      <p><Copyable>{rgb}</Copyable></p>
      <p><Copyable>{hsl}</Copyable></p>*/}
      <h6>{name}</h6>
      <div className="small">{hex}</div>
      <div className="small">{rgb}</div>
      <div className="small">{hsl}</div>
    </div>
  );
}
