import React from 'react';
import SlayteFetcher from './SlayteFetcher';

export default class SlayteFetcherBase64 extends SlayteFetcher {
  render() {
    const base64 = btoa(JSON.stringify(this.state.json));
    return (
      <details open={this.props.open}>
        <summary>Base64</summary>
        <pre style={{ whiteSpace: 'break-spaces' }}>
          <code>{base64}</code>
        </pre>
      </details>
    );
  }
}
