import React from 'react';
import Navbar from './Navbar';
import Main from './Main';
//import Foot from './Foot';

export default function Body(props) {
  return (
    <React.Fragment>
      <Navbar />
      <Main />
      {/*<Foot />*/}
    </React.Fragment>
  );
}
