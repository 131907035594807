import React from 'react';
import { Container, P, Input, PreCode } from '../Util';

const QUADRATIC_FORMULA = `x=[-b\u00b1sqrt(b^2-4ac)]/2a`;

// 2x^2-5x-3=0, x=3 or x=−1/2
// x^2+3x-4=0, x=1 or x=−4
function quadraticFormulaSolver(value) {
  const isEquation = () => {
    if (typeof value === 'string' && value.length > 0) {
      value = value.trim();
      if (value.indexOf('=')) {
        if (value.split('=').length === 2) return true;
      }
    }
    return false;
  };

  const parse = () => {
    if (isEquation(value)) {
      const splits = value.split('=');
      const lhs = splits[0].trim();
      //const rhs = splits[1].trim()

      const operations = [];
      const terms = [];
      const term = [];

      lhs.split('').forEach((e, i) => {
        if (/^[a-zA-Z]+/.test(e)) {
          term.push(e);
        } else if (/^[0-9]+/.test(e)) {
          term.push(e);
        } else if ('^' === e) {
          term.push(e);
        } else if (['+', '-', '–', '*', '/'].indexOf(e) >= 0) {
          terms.push(term.slice(0).join(''));
          term.splice(0, term.length);
          operations.push(e);
        }
      });
      terms.push(term.slice(0).join(''));

      if (terms.length === 3 && operations.length === 2) {
        const abc = { a: [], b: [] };
        terms.forEach((e, i) => {
          if (e.indexOf('^2') >= 0) {
            const n = e.slice(0, e.indexOf('^2') - 1);
            if (n.length > 0) {
              n.split('').forEach((e) => {
                if (/^[0-9]+/.test(e)) {
                  abc.a.push(e);
                }
              });
            } else {
              abc.a = 1;
            }
          } else if (/[a-zA-Z]+/.test(e)) {
            const n = [];
            e.split('').forEach((e) => {
              if (/^[0-9]+/.test(e)) {
                n.push(e);
              }
            });
            if (n.length > 0) {
              n.forEach((e) => {
                if (/^[0-9]+/.test(e)) {
                  abc.b.push(e);
                }
              });
            } else {
              abc.b = 1;
            }
          } else if (/^[0-9]+/.test(e)) {
            abc.c = e;
          }
        });

        if (Array.isArray(abc.a)) {
          abc.a = abc.a.join('');
          if (typeof abc.a === 'string' && abc.a.length > 0) {
            abc.a = parseInt(abc.a);
          }
        }
        if (Array.isArray(abc.b)) {
          abc.b = abc.b.join('');
          if (typeof abc.b === 'string' && abc.b.length > 0) {
            abc.b = parseInt(abc.b);
          }
        }
        if (typeof abc.c === 'string' && abc.c.length > 0) {
          abc.c = parseInt(abc.c);
        }

        if (['-', '–'].indexOf(operations[0]) >= 0) {
          abc.b *= -1;
        }
        if (['-', '–'].indexOf(operations[1]) >= 0) {
          abc.c *= -1;
        }

        return abc;
      }
    }
    return { a: 0, b: 0, c: 0 };
  };

  const { a, b, c } = parse();
  const results = [];

  results.push((-b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a));
  results.push((-b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a));

  return { a, b, c, results };
}

export default function QuadraticFormula(props) {
  const [value, setValue] = React.useState(`x^2+3x–4=0`);

  return (
    <Container lg className="mb-5">
      <Container center>
        <h1>{'\u{1f4c8}'} Quadratic Formula</h1>
        <P>Solve quadratic equation with quadratic formula</P>
      </Container>

      <Container gradient dark rounded shadow className="p-3 mb-3">
        <Input
          textarea
          label="Quadratic equation (example: x^2+3x–4=0)"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          rows={2}
        />
        <PreCode>{value}</PreCode>
      </Container>

      <Container gradient rounded shadow className="p-3">
        <h5>Quadratic Formula</h5>
        <P>{QUADRATIC_FORMULA}</P>
        <PreCode>{quadraticFormulaSolver(value)}</PreCode>
      </Container>
    </Container>
  );
}
