import React from 'react'
import {A,PreCode,Badge,Callout,lipsum} from '../Util'

export default function ReactXBootstrapCallout(props) {
  return (
    <div className="container-fluid pt-5 mb-5" id="Callout">
      <div className="pt-2 text-center">
        <h1 className="mb-3">Callout</h1>
        <p className="lead"><b>Callout</b> component is highly used in Bootstrap docs, but hardly documented. For example like in <A href="https://getbootstrap.com/docs/4.3/content/images/#responsive-images" blank>here</A>, see <i>SVG images and IE 10</i>. Sort of like <A href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#blockquotes" blank>Blockquotes</A> in Markdown.</p>
        <p>It is highly useful for highlighting something important. And the color could give a hint on type or intention of the message.</p>
      </div>
      <hr/>

      <div className="mb-3">
        <details>
          <summary><span className="h3">The Code</span></summary>
          <PreCode>{`
function Callout(props) {
  const {title='',children,className='',shadow=false} = props
  const classNames = ['callout',className]
  Object.keys(props).forEach(e=>{
    if (['primary','secondary','info','success','warning','danger','light','dark'].indexOf(e)>=0) {
      classNames.push(\`callout-\${e}\`)
    }
  })
  if (shadow) {
    classNames.push('shadow')
  }
  return (
    <div className={classNames.join(' ')}>
      {
        typeof(title)==='string'&&title.length>0&&
        <h5>{title}</h5>
      }
      <p>{children}</p>
    </div>
  )
}
            `}</PreCode>
        </details>
      </div>

      <div>
        <h3>Preview</h3>
        <Callout primary shadow title="A Callout Example">Lorem ipsum dolor sit amet</Callout>
      </div>

      <div>
        <h3>Usage</h3>
        <PreCode>{`<Callout primary>...</Callout>`}</PreCode>
        <p>Simply replace <Badge primary shadow pill>primary</Badge> props with any of {
            ['secondary','info','success','warning','danger','light','dark'].map((e,i)=>{
            const el = {}
            el[e] = true
            return (<Badge key={i} {...el} shadow pill className="mr-1">{e}</Badge>)})
          } to change the hint color.</p>
      </div>

      <div>
        <h3>Using with a title</h3>
        <PreCode>{`<Callout primary title="This is a callout">...</Callout>`}</PreCode>
        <h5>Preview</h5>
        <Callout primary title="This is a callout">{lipsum()}</Callout>
      </div>

      <div>
        <h3 className="mb-3">Examples</h3>
        {
          ['primary','secondary','info','success','warning','danger','light','dark'].map((e,i)=>{
            const el = {}
            el[e] = true
            const code = `<Callout ${e} title="${e}">...</Callout>`
            return (
              <div key={i}>
                <h5>For <Badge shadow {...el} pill>{e}</Badge></h5>
                <h6>The code</h6>
                <PreCode>{code}</PreCode>
                <h6>Result</h6>
                <Callout {...el} shadow title={e}>{lipsum()}</Callout>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
