import React from 'react'
import {Container,Row,Col,Badge} from '../Util'

export default function Tablesify(props) {
  const {value={}} = props

  const fromArray = v=>{
    if (Array.isArray(v)&&v.length>0) {
      return (
        v.map((e,i)=>(
          <Row key={i}>
            <Col s="1"><Badge info pill shadow>{i+1}</Badge></Col>
            <Col>
              <Tablesify value={e} />
            </Col>
          </Row>
        ))
      )
    }
    return null
  }

  const fromObject = v=>{
    if (typeof(v)==='object'&&v!==null) {
      const keys = Object.keys(v)||[]
      return (
        <Container className="mb-3">
          <Row className="border-bottom">
            {keys.map((e,i)=><Col key={i}><Badge primary shadow>{e}</Badge></Col>)}
          </Row>
          <Row>
            {keys.map((e,i)=><Col key={i}><Tablesify value={v[e]} /></Col>)}
          </Row>
        </Container>
      )
    }
    return null
  }

  if (typeof(value)==='string') return <Badge light pill shadow>{value}</Badge>
  if (typeof(value)==='number') return <Badge dark shadow>{value}</Badge>
  if (Array.isArray(value)) return fromArray(value)
  if (typeof(value)==='object') return fromObject(value)

  return null
}
