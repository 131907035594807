import React from 'react';
import { Button, Badge, randomBgGrandientLightStyle } from '../Util';

const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export default function GameOfFortuneKeyboard(props) {
  const { onClick, list } = props;
  const click = (c) => {
    if (typeof onClick === 'function') {
      onClick(c);
    }
  };
  const style = {
    background: randomBgGrandientLightStyle(),
  };
  return (
    <div className="p-3 shadow rounded" style={style}>
      <div className="text-center">
        {ABC.split('').map((e, i) => {
          const disabled = list.join('').indexOf(e) >= 0;
          const btn = {};
          const classNames = ['m-1'];
          if (disabled) {
            btn.secondary = true;
            classNames.push('shadow-inset');
          } else {
            btn.light = true;
            btn.shadow = true;
          }
          return (
            <div key={i} className="d-inline-block">
              <Button
                {...btn}
                sm
                className={classNames.join(' ')}
                onClick={() => click(e)}
                disabled={disabled}
              >
                {e}
              </Button>
            </div>
          );
        })}
      </div>
      <div className="text-center">
        <small>
          <Badge dark className="mr-2">
            INFO
          </Badge>
          Click on the virtual keyboard or type with your physical keyboard
        </small>
      </div>
    </div>
  );
}
