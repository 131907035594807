// for compilation purpose
const Asset = {
  Image: {}
}

export const Translation = {
  "apple": "apel",
  "alligator": "buaya",
  "apron": "celemek",
  "anchor": "sauh",
  "avocado": "alpukat",
  "ball": "bola",
  "bear": "beruang",
  "bicycle": "sepeda",
  "bird": "burung",
  "book": "buku",
  "box": "kotak",
  "broccoli": "brokoli",
  "burger": "burger",
  "butterfly": "rama-rama",
  "buoy": "pelampung",
  "cabbage": "kubis",
  "camera": "kamera",
  "car": "mobil",
  "cart": "troli",
  "cheese": "keju",
  "cherry": "ceri",
  "chicken": "ayam",
  "cloud": "awan",
  "coffee": "kopi",
  "compass": "kompas",
  "corn": "jagung",
  "cow": "sapi",
  "crab": "ketam",
  "cucumber": "timun",
  "cupcake": "kek cawan",
  "chick": "anak ayam",
  "cinnamon": "kayu manis",
  "coin": "koin",
  "doctor": "dokter",
  "dog": "anjing",
  "dolphin": "lumba-lumba",
  "donut": "donat",
  "dragon": "naga",
  "duck": "itik",
  "daisy": "aster",
  "diamond": "berlian",
  "dice": "dadu",
  "dragonfly": "capung",
  "egg": "telur",
  "eggplant": "terong",
  "elephant": "gajah",
  "fire": "api",
  "frog": "katak",
  "fish": "ikan",
  "flip-flop": "sandal",
  "field": "lapangan",
  "flamingo": "flamingo",
  "fox": "rubah",
  "garlic": "bawang putih",
  "ginger": "halia",
  "grape": "anggur",
  "guitar": "gitar",
  "giraffe": "zirafah",
  "goldbar": "emas",
  "gift": "hadiah",
  "hammer": "tukul",
  "hamster": "hamster",
  "hat": "topi",
  "hibiscus": "bunga raya",
  "heart": "jantung",
  "hippo": "kuda nil",
  "icecream": "es krim",
  "island": "pulau",
  "jam": "jem",
  "jelly": "jeli",
  "jellyfish": "ubur-ubur",
  "jump": "lompat",
  "jar": "botol",
  "kettle": "ketel",
  "kite": "layang-layang",
  "key": "kunci",
  "ladder": "tangga",
  "ladle": "sendok",
  "lamp": "lampu",
  "lantern": "lentera",
  "mask": "topeng",
  "match": "korek",
  "mouse": "tikus",
  "net": "jaring",
  "nest": "sarang",
  "nut": "kacang",
  "onion": "bawang",
  "owl": "burung hantu",
  "pan": "panci",
  "panda": "panda",
  "pig": "babi",
  "pool": "kolam",
  "pear": "pir",
  "pepper": "merica",
  "pot": "periuk",
  "pumpkin": "labu",
  "pancake": "serabi",
  "park": "taman",
  "peach": "persik",
  "penguin": "penguin",
  "peppermint": "pepermin",
  "quail": "puyuh",
  "queen": "ratu",
  "quill": "pena bulu",
  "rabbit": "arnab",
  "rainbow": "pelangi",
  "reindeer": "rusa kutub",
  "ribbon": "pita",
  "rope": "tali",
  "screw": "sekrup",
  "screwdriver": "obeng",
  "shell": "kerang",
  "shoe": "sepatu",
  "shopping": "belanja",
  "sing": "nyanyi",
  "sleep": "tidur",
  "snake": "ular",
  "spatula": "sudip",
  "sponge": "sepon",
  "sprayer": "semprot",
  "squirrel": "tupai",
  "strawberry": "stroberi",
  "sun": "matahari",
  "sunflower": "bunga matahari",
  "seal": "anjing laut",
  "snowman": "orang-orangan salju",
  "star": "bintang",
  "sunglass": "kacamata hitam",
  "teapot": "teko",
  "telephone": "telepon",
  "tie": "dasi",
  "tent": "kemah",
  "tiger": "harimau",
  "tooth": "gigi",
  "tomato": "tomat",
  "tree": "pohon",
  "truck": "truk",
  "tank": "tangki",
  "toucan": "tukan",
  "towel": "tuala",
  "train": "kereta api",
  "umbrella": "payung",
  "unicorn": "unicorn",
  "vacuum": "vakum",
  "vegetable": "sayur",
  "watch": "jam",
  "watermelon": "tembikai",
  "whisk": "pengocok",
  "wrench": "kunci pas",
  "walrus": "anjing laut",
  "x-ray": "x-ray",
  "xylophone": "gambang",
  "yak": "yak",
  "yam": "ubi",
  "yarn": "benang",
  "yo-yo": "yo-yo",
  "zebra": "zebra",
  "zucchini": "zucchini"
}

export const CardSet = {
  a: [
    {value:'apple', image: Asset.Image.apple, reveal:false, active:false},
    {value:'alligator', image: Asset.Image.alligator, reveal:false, active:false},
    {value:'apron', image: Asset.Image.apron, reveal:false, active:false},
    {value:'anchor', image: Asset.Image.anchor, reveal:false, active:false},
    {value:'avocado', image: Asset.Image.avocado, reveal:false, active:false},
  ],
  b: [
    {value:'ball', image: Asset.Image.ball, reveal:false, active:false},
    {value:'bear', image: Asset.Image.bear, reveal:false, active:false},
    {value:'bicycle', image: Asset.Image.bicycle, reveal:false, active:false},
    {value:'bird', image: Asset.Image.bird, reveal:false, active:false},
    {value:'book', image: Asset.Image.book, reveal:false, active:false},
    {value:'box', image: Asset.Image.box, reveal:false, active:false},
    {value:'broccoli', image: Asset.Image.broccoli, reveal:false, active:false},
    {value:'burger', image: Asset.Image.burger, reveal:false, active:false},
    {value:'butterfly', image: Asset.Image.butterfly, reveal:false, active:false},
    {value:'buoy', speech:'boy', image: Asset.Image.buoy, reveal:false, active:false},
  ],
  c: [
    {value:'cabbage', image: Asset.Image.cabbage, reveal:false, active:false},
    {value:'camera', image: Asset.Image.camera, reveal:false, active:false},
    {value:'car', image: Asset.Image.car, reveal:false, active:false},
    {value:'cart', image: Asset.Image.cart, reveal:false, active:false},
    {value:'cheese', image: Asset.Image.cheese, reveal:false, active:false},
    {value:'cherry', image: Asset.Image.cherry, reveal:false, active:false},
    {value:'chicken', image: Asset.Image.chicken, reveal:false, active:false},
    {value:'cloud', image: Asset.Image.cloud, reveal:false, active:false},
    {value:'coffee', image: Asset.Image.coffee, reveal:false, active:false},
    {value:'compass', image: Asset.Image.compass, reveal:false, active:false},
    {value:'corn', image: Asset.Image.corn, reveal:false, active:false},
    {value:'cow', image: Asset.Image.cow, reveal:false, active:false},
    {value:'crab', image: Asset.Image.crab, reveal:false, active:false},
    {value:'cucumber', speech:'q-comber', image: Asset.Image.cucumber, reveal:false, active:false},
    {value:'cupcake', image: Asset.Image.cupcake, reveal:false, active:false},
    {value:'chick', image: Asset.Image.chick, reveal:false, active:false},
    {value:'cinnamon', image: Asset.Image.cinnamon, reveal:false, active:false},
    {value:'coin', image: Asset.Image.coin, reveal:false, active:false},
  ],
  d: [
    {value:'doctor', image: Asset.Image.doctor, reveal:false, active:false},
    {value:'dog', image: Asset.Image.dog, reveal:false, active:false},
    {value:'dolphin', image: Asset.Image.dolphin, reveal:false, active:false},
    {value:'donut', image: Asset.Image.donut, reveal:false, active:false},
    {value:'dragon', image: Asset.Image.dragon, reveal:false, active:false},
    {value:'duck', image: Asset.Image.duck, reveal:false, active:false},
    {value:'daisy', image: Asset.Image.daisy, reveal:false, active:false},
    {value:'diamond', image: Asset.Image.diamond, reveal:false, active:false},
    {value:'dice', image: Asset.Image.dice, reveal:false, active:false},
    {value:'dragonfly', image: Asset.Image.dragonfly, reveal:false, active:false},
  ],
  e: [
    {value:'egg', speech:'eighg', image: Asset.Image.egg, reveal:false, active:false},
    {value:'eggplant', speech:'eighg plant', image: Asset.Image.eggplant, reveal:false, active:false},
    {value:'elephant', image: Asset.Image.elephant, reveal:false, active:false},
  ],
  f: [
    {value:'fire', image: Asset.Image.fire, reveal:false, active:false},
    {value:'frog', image: Asset.Image.frog, reveal:false, active:false},
    {value:'fish', image: Asset.Image.fish, reveal:false, active:false},
    {value:'flip-flop', image: Asset.Image.flipflop, reveal:false, active:false},
    {value:'field', image: Asset.Image.field, reveal:false, active:false},
    {value:'flamingo', image: Asset.Image.flamingo, reveal:false, active:false},
    {value:'fox', image: Asset.Image.fox, reveal:false, active:false},
  ],
  g: [
    {value:'garlic', image: Asset.Image.garlic, reveal:false, active:false},
    {value:'ginger', image: Asset.Image.ginger, reveal:false, active:false},
    {value:'grape', image: Asset.Image.grape, reveal:false, active:false},
    {value:'guitar', image: Asset.Image.guitar, reveal:false, active:false},
    {value:'giraffe', image: Asset.Image.giraffe, reveal:false, active:false},
    {value:'goldbar', speech: 'gold bar', image: Asset.Image.goldbar, reveal:false, active:false},
    {value:'gift', image: Asset.Image.gift, reveal:false, active:false},
  ],
  h: [
    {value:'hammer', image: Asset.Image.hammer, reveal:false, active:false},
    {value:'hamster', image: Asset.Image.hamster, reveal:false, active:false},
    {value:'hat', image: Asset.Image.hat, reveal:false, active:false},
    {value:'hibiscus', speech:'hi-biscus', image: Asset.Image.hibiscus, reveal:false, active:false},
    {value:'heart', image: Asset.Image.heart, reveal:false, active:false},
    {value:'hippo', speech: 'hipp-po', image: Asset.Image.hippo, reveal:false, active:false},
  ],
  i: [
    {value:'icecream', image: Asset.Image.icecream, reveal:false, active:false},
    {value:'island', image: Asset.Image.island, reveal:false, active:false},
  ],
  j: [
    {value:'jam', speech:'jem', image: Asset.Image.jam, reveal:false, active:false},
    {value:'jelly', image: Asset.Image.jelly, reveal:false, active:false},
    {value:'jellyfish', image: Asset.Image.jellyfish, reveal:false, active:false},
    {value:'jump', image: Asset.Image.jump, reveal:false, active:false},
    {value:'jar', image: Asset.Image.jar, reveal:false, active:false},
  ],
  k: [
    {value:'kettle', image: Asset.Image.kettle, reveal:false, active:false},
    {value:'kite', image: Asset.Image.kite, reveal:false, active:false},
    {value:'key', image: Asset.Image.key, reveal:false, active:false},
  ],
  l: [
    {value:'ladder', image: Asset.Image.ladder, reveal:false, active:false},
    {value:'ladle', image: Asset.Image.ladle, reveal:false, active:false},
    {value:'lamp', image: Asset.Image.lamp, reveal:false, active:false},
    {value:'lantern', image: Asset.Image.lantern, reveal:false, active:false},
  ],
  m: [
    {value:'mask', image: Asset.Image.mask, reveal:false, active:false},
    {value:'match', image: Asset.Image.match, reveal:false, active:false},
    {value:'mouse', image: Asset.Image.mouse, reveal:false, active:false},
  ],
  n: [
    {value:'net', image: Asset.Image.net, reveal:false, active:false},
    {value:'nest', image: Asset.Image.nest, reveal:false, active:false},
    {value:'nut', image: Asset.Image.nut, reveal:false, active:false},
  ],
  o: [
    {value:'onion', image: Asset.Image.onion, reveal:false, active:false},
    {value:'owl', image: Asset.Image.owl, reveal:false, active:false},
  ],
  p: [
    {value:'pan', speech:'pann', image: Asset.Image.pan, reveal:false, active:false},
    {value:'panda', image: Asset.Image.panda, reveal:false, active:false},
    {value:'pig', image: Asset.Image.pig, reveal:false, active:false},
    {value:'pool', image: Asset.Image.pool, reveal:false, active:false},
    {value:'pear', image: Asset.Image.pear, reveal:false, active:false},
    {value:'pepper', image: Asset.Image.pepper, reveal:false, active:false},
    {value:'pot', speech:'potd', image: Asset.Image.pot, reveal:false, active:false},
    {value:'pumpkin', image: Asset.Image.pumpkin, reveal:false, active:false},
    {value:'pancake', image: Asset.Image.pancake, reveal:false, active:false},
    {value:'park', image: Asset.Image.park, reveal:false, active:false},
    {value:'peach', image: Asset.Image.peach, reveal:false, active:false},
    {value:'penguin', image: Asset.Image.penguin, reveal:false, active:false},
    {value:'peppermint', image: Asset.Image.peppermint, reveal:false, active:false},
  ],
  q: [
    {value:'quail', image: Asset.Image.quail, reveal:false, active:false},
    {value:'queen', image: Asset.Image.queen, reveal:false, active:false},
    {value:'quill', image: Asset.Image.quill, reveal:false, active:false},
  ],
  r: [
    {value:'rabbit', image: Asset.Image.rabbit, reveal:false, active:false},
    {value:'rainbow', image: Asset.Image.rainbow, reveal:false, active:false},
    {value:'reindeer', image: Asset.Image.reindeer, reveal:false, active:false},
    {value:'ribbon', image: Asset.Image.ribbon, reveal:false, active:false},
    {value:'rope', image: Asset.Image.rope, reveal:false, active:false},
  ],
  s: [
    {value:'screw', image: Asset.Image.screw, reveal:false, active:false},
    {value:'screwdriver', image: Asset.Image.screwdriver, reveal:false, active:false},
    {value:'shell', image: Asset.Image.shell, reveal:false, active:false},
    {value:'shoe', image: Asset.Image.shoe, reveal:false, active:false},
    {value:'shopping', image: Asset.Image.shopping, reveal:false, active:false},
    {value:'sing', image: Asset.Image.sing, reveal:false, active:false},
    {value:'sleep', image: Asset.Image.sleep, reveal:false, active:false},
    {value:'snake', image: Asset.Image.snake, reveal:false, active:false},
    {value:'spatula', image: Asset.Image.spatula, reveal:false, active:false},
    {value:'sponge', image: Asset.Image.sponge, reveal:false, active:false},
    {value:'sprayer', speech:'sprayyer', image: Asset.Image.sprayer, reveal:false, active:false},
    {value:'squirrel', image: Asset.Image.squirrel, reveal:false, active:false},
    {value:'strawberry', image: Asset.Image.strawberry, reveal:false, active:false},
    {value:'sun', image: Asset.Image.sun, reveal:false, active:false},
    {value:'sunflower', image: Asset.Image.sunflower, reveal:false, active:false},
    {value:'seal', image: Asset.Image.seal, reveal:false, active:false},
    {value:'snowman', image: Asset.Image.snowman, reveal:false, active:false},
    {value:'star', image: Asset.Image.star, reveal:false, active:false},
    {value:'sunglass', speech:'sun glass', image: Asset.Image.sunglass, reveal:false, active:false},
  ],
  t: [
    {value:'teapot', image: Asset.Image.teapot, reveal:false, active:false},
    {value:'telephone', image: Asset.Image.telephone, reveal:false, active:false},
    {value:'tie', speech:'tigh', image: Asset.Image.tie, reveal:false, active:false},
    {value:'tent', image: Asset.Image.tent, reveal:false, active:false},
    {value:'tiger', image: Asset.Image.tiger, reveal:false, active:false},
    {value:'tooth', image: Asset.Image.tooth, reveal:false, active:false},
    {value:'tomato', image: Asset.Image.tomato, reveal:false, active:false},
    {value:'tree', image: Asset.Image.tree, reveal:false, active:false},
    {value:'truck', image: Asset.Image.truck, reveal:false, active:false},
    {value:'tank', image: Asset.Image.tank, reveal:false, active:false},
    {value:'toucan', image: Asset.Image.toucan, reveal:false, active:false},
    {value:'towel', speech:'tower', image: Asset.Image.towel, reveal:false, active:false},
    {value:'train', speech:'treen', image: Asset.Image.train, reveal:false, active:false},
  ],
  u: [
    {value:'umbrella', image: Asset.Image.umbrella, reveal:false, active:false},
    {value:'unicorn', image: Asset.Image.unicorn, reveal:false, active:false},
  ],
  v: [
    {value:'vacuum', speech:'vacum', image: Asset.Image.vacuum, reveal:false, active:false},
    {value:'vegetable', image: Asset.Image.vegetable, reveal:false, active:false},
  ],
  w: [
    {value:'watch', image: Asset.Image.watch, reveal:false, active:false},
    {value:'watermelon', image: Asset.Image.watermelon, reveal:false, active:false},
    {value:'whisk', image: Asset.Image.whisk, reveal:false, active:false},
    {value:'wrench', image: Asset.Image.wrench, reveal:false, active:false},
    {value:'walrus', image: Asset.Image.walrus, reveal:false, active:false},
  ],
  x: [
    {value:'x-ray', image: Asset.Image.xray, reveal:false, active:false},
    {value:'xylophone', image: Asset.Image.xylophone, reveal:false, active:false},
    {value:'box', image: Asset.Image.box, reveal:false, active:false},
    {value:'fox', image: Asset.Image.fox, reveal:false, active:false},
  ],
  y: [
    {value:'yak', image: Asset.Image.yak, reveal:false, active:false},
    {value:'yam', speech:'yaam', image: Asset.Image.yam, reveal:false, active:false},
    {value:'yarn', image: Asset.Image.yarn, reveal:false, active:false},
    {value:'yo-yo', image: Asset.Image.yoyo, reveal:false, active:false},
  ],
  z: [
    {value:'zebra', image: Asset.Image.zebra, reveal:false, active:false},
    {value:'zucchini', image: Asset.Image.zucchini, reveal:false, active:false},
  ],
}
