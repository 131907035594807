import React from 'react'
import {lipsum} from '../Util'

const Constant = {
  width:312,
  height:170,
}

export default class ReactXCssAnimatedBackground extends React.Component {
  mounted=false
  state={}
  componentDidMount() {
    const weight = 1.8
    const width = parseInt(Constant.width*weight)
    const height = parseInt(Constant.height*weight)
    const list = (new Array(5)).fill(0).map((e,i)=>{
      const seed = Math.floor(Math.random()*1000)+1
      const src = `https://picsum.photos/${width}/${height}/?random&${seed}`
      const title = lipsum(3).slice(0,-1)
      return {src,title}
    })
    const selected = 0
    // the amount must be larger than the easing duration
    const auto = 2000
    this.setState({list,selected,auto,},()=>{
      this.mounted = true
      this.auto()
    })
  }
  componentWillUnmount() {
    this.mounted = false
  }
  renderList() {
    const {list,selected} = this.state
    if (Array.isArray(list)&&list.length>0) {
      return (
        <div className="row no-gutters">
          {
            list.map((e,i)=>(
              <div key={i} className={`col p-1 ${i===selected?'border border-primary':''}`}>
                <img src={e.src} alt="" className="rounded w-100" onClick={event=>{
                    event.preventDefault()
                    const {auto} = this.state
                    if (auto===0) {
                      this.choose(i)
                    }
                  }} />
              </div>
            ))
          }
        </div>
      )
    }
    return null
  }
  auto=()=>{
    const {auto} = this.state
    if (typeof(auto)==='number'&&auto>0) {
      setTimeout(this.next,auto)
    }
  }
  prev=event=>{
    const {selected,list} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        let prev = selected-1
        if (prev<0) {
          prev = list.length-1
        }
        this.choose(prev)
      }
    }
  }
  next=event=>{
    const {selected,list} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
        let next = selected+1
        if (next>=list.length) {
          next=0
        }
        this.choose(next)
      }
    }
  }
  choose=i=>{
    const {list,selected} = this.state
    if (Array.isArray(list)&&list.length>0) {
      if (typeof(i)==='number'&&i>=0&&i<list.length&&i!==selected) {
        if (this.mounted) {
          this.setState({selected:i},this.done)
        }
      }
    }
  }
  done=()=>{
    const {auto} = this.state
    if (auto) {
      this.auto()
    }
  }
  render() {
    const {list,selected,auto} = this.state
    if (Array.isArray(list)&&list.length>0) {
      const src = list[selected].src
      const style = {
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        transition: `background-image ${auto/2000}s ease-in-out`,
      }
      return (
        <div className="container-fluid ReactXCssAnimatedBackground rounded shadow" style={style}>
          <h1 className="text-center p-3 text-shadow">Animated Background</h1>
          {this.renderList()}
        </div>
      )
    }
    return <div>Loading...</div>
  }
}
