import React from 'react';
//import { useTranslation } from 'react-i18next';
import { HOME_PATHS } from '../home/HOME_PATHS';
import { Link } from 'react-router-dom';

function getPrevNext(searchPath) {
  const list = HOME_PATHS;
  const i = list.findIndex(({ path }) => path === searchPath);
  if (i < 0) {
    return { prev: null, next: null };
  }
  const prev = i > 0 ? list[i - 1] : null;
  const next = i < list.length - 1 ? list[i + 1] : null;
  return { prev, next };
}

function NavigatorButton(props) {
  //const { t } = useTranslation();
  const { icon, path, name, left = false, right = false, onClick } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <Link to={path}>
      <button
        className="btn btn-outline-dark rounded-pill px-4"
        onClick={handleClick}
      >
        {left && (
          <React.Fragment>
            <span className="">&#x2190;</span>
            <span className="mx-2">{icon}</span>
          </React.Fragment>
        )}

        {!left && !right && <span className="mr-2">&#x2190;</span>}

        <span className="">{name}</span>

        {right && (
          <React.Fragment>
            <span className="mx-2">{icon}</span>
            <span className="">&#x2192;</span>
          </React.Fragment>
        )}
      </button>
    </Link>
  );
}

export default function Navigator(props) {
  const { path, setEffect } = props;
  const { prev, next } = getPrevNext(path);

  function handlePrev() {
    setEffect('fadeInLeft');
  }

  function handleNext() {
    setEffect('fadeInRight');
  }

  return (
    <div className="row p-2">
      <div className="col-6 text-left">
        {prev && <NavigatorButton {...prev} left onClick={handlePrev} />}
      </div>
      <div className="col-6 text-right">
        {next && <NavigatorButton {...next} right onClick={handleNext} />}
      </div>
    </div>
  );
}
