import React from 'react';
import { COLOR, Color } from '../Util';

export function randomColor(n = '') {
  const randomInt = (a = 10, b = 0) => {
    return Math.floor(Math.random() * a) + b;
  };

  const keys = Object.keys(COLOR);
  const l = keys.length;
  const i = randomInt(l);
  let k = keys[i];
  if (typeof n === 'string' && n.length > 0) {
    const j = keys.indexOf(n);
    if (j >= 0) {
      k = keys[j];
    }
  }

  const colors = COLOR[k];
  return colors[randomInt(colors.length)];
}

export default function RandomColorBox(props) {
  const { className = '' } = props;
  const style = {};
  let found = false;
  let color = '';
  Object.keys(props).forEach((e) => {
    const keys = Object.keys(COLOR);
    for (let i = 0; i < keys.length; i++) {
      const colors = COLOR[keys[i]];
      for (let j = 0; j < colors.length; j++) {
        if (colors[j] === e) {
          style.backgroundColor = e;
          color = e;
          found = true;
          break;
        }
      }
      if (found) break;
    }
  });
  if (!found) {
    color = randomColor('');
    style.backgroundColor = color;
  }

  const el = React.useRef();
  const [rgb, setRgb] = React.useState();
  const [hsl, setHsl] = React.useState();
  const [hex, setHex] = React.useState();
  const [lumin, setLumin] = React.useState();

  React.useEffect(() => {
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        const rgb = window.getComputedStyle(current).backgroundColor;
        setRgb(rgb);
      }
    }
  }, []);

  React.useEffect(() => {
    const c = new Color(rgb);
    //const lumin = c.luminance()
    const lumin = c.lumin();
    setLumin(lumin);
    setHex(c.toString('#'));
    setHsl(c.toString('hsl'));
  }, [rgb]);

  style.color = lumin;

  return (
    <div
      ref={el}
      className={`ColorBox ${className}`}
      style={style}
      title={`${hex} ${hsl}`}
    >
      <h6 className="text-center text-shadow">{color}</h6>
    </div>
  );
}
