import React from 'react'
import {Container,Row,Col,Input,PreCode,Details} from '../Util'
import Tablesify from '../tablesify/Tablesify'
import sample from '../tablesify/sample.json'

function isCommon(a,b) {
  if (Array.isArray(a)&&a.length>0) {
    if (Array.isArray(b)&&b.length>0) {
      let count = 0
      for (let i=0;i<a.length;i++) {
        for (let j=0;j<b.length;j++) {
          if (a[i]===b[j]) {
            count++
            if (count>=2) return true
          }
        }
      }
    }
  }
  return false
}

/*function process1(value) {
  if (typeof(value)==='object'&&value!==null) {
    const {recipients} = value
    if (Array.isArray(recipients)&&recipients.length>0) {
      const groups = []
      for (let i=0;i<recipients.length;i++) {
        const e = recipients[i]
        const g = [e.name]
        for (let j=i+1;j<recipients.length;j++) {
          const {tags,name} = recipients[j]
          if (isCommon(e.tags,tags)) {
            g.push(name)
          }
        }
        if (g.length>1) {
          groups.push(g)
        }
      }
      return groups
    }
  }
  return value
}

function process2(value) {
  if (typeof(value)==='object'&&value!==null) {
    const {recipients} = value
    if (Array.isArray(recipients)&&recipients.length>0) {
      // get the list of tag
      const list = []
      recipients.forEach((e,i)=>{
        const {tags} = e
        tags.forEach((e,j)=>{
          if (list.indexOf(e)<0) {
            list.push(e)
          }
        })
      })
      return list
    }
  }
  return value
}*/

function process(value) {
  if (typeof(value)==='object'&&value!==null) {
    const {recipients} = value
    if (Array.isArray(recipients)&&recipients.length>0) {
      const groups = []
      for (let i=0;i<recipients.length;i++) {
        const e = recipients[i]
        for (let j=i+1;j<recipients.length;j++) {
          const {tags,name} = recipients[j]
          if (isCommon(e.tags,tags)) {
            groups.push([e.name,name])
          }
        }
      }
      return groups
    }
  }
  return value
}

function stringify(value) {
  if (Array.isArray(value)) {
    return value.map((e,i)=>{
      if (Array.isArray(e)) {
        return e.join(', ')
      }
      return null
    }).join('|')
  }
  return JSON.stringify(value)
}

export default function TablesifyDemo(props) {
  const [value,setValue] = React.useState(JSON.stringify(sample,null,2))
  //const json = process(JSON.parse(value))
  //const json = process2(JSON.parse(value))
  const json = process(JSON.parse(value))
  return (
    <Container fluid>
      <Details lead="See the Code" className="h3">
        <h5 className="mt-3">Comparing two list of arrays, checking if there are at least 2 common entries from both list and returning true</h5>
        <PreCode>{`
function isCommon(a,b) {
  if (Array.isArray(a)&&a.length>0) {
    if (Array.isArray(b)&&b.length>0) {
      let count = 0
      for (let i=0;i<a.length;i++) {
        for (let j=0;j<b.length;j++) {
          if (a[i]===b[j]) {
            count++
            if (count>=2) return true
          }
        }
      }
    }
  }
  return false
}
          `}</PreCode>
          <h5 className="mt-3">Traversing the list of participants and comparing each participant to another participant</h5>
          <ul>
            <li>If both participants has more than 2 common tags, their names will be paired into a group</li>
            <li>A list of pair of names will be returned</li>
          </ul>
          <PreCode>{`
function process(value) {
  if (typeof(value)==='object'&&value!==null) {
    const {recipients} = value
    if (Array.isArray(recipients)&&recipients.length>0) {
      const groups = []
      for (let i=0;i<recipients.length;i++) {
        const e = recipients[i]
        for (let j=i+1;j<recipients.length;j++) {
          const {tags,name} = recipients[j]
          if (isCommon(e.tags,tags)) {
            groups.push([e.name,name])
          }
        }
      }
      return groups
    }
  }
  return value
}
        `}</PreCode>
        <h5 className="mb-3">Convert the list of pair of names into a format "name1, name2|name1, name2|..."</h5>
        <PreCode>{`
function stringify(value) {
  if (Array.isArray(value)) {
    return value.map((e,i)=>{
      if (Array.isArray(e)) {
        return e.join(', ')
      }
      return null
    }).join('|')
  }
  return JSON.stringify(value)
}
        `}</PreCode>
        <h5>Usage</h5>
        <PreCode>{`
// supply "process" with the object from the json file
// producing an output in format "name1, name2|name1, name2|..."
const output = stringify(process(data))
          `}</PreCode>
      </Details>
      <hr />
      <Row>
        <Col s="3">
          <h3>Input</h3>
          <Input value={value} textarea onChange={e=>setValue(e.target.value)} rows={10} />
        </Col>
        <Col s="9">
          <Details lead="Input"><PreCode>{json}</PreCode></Details>
          <hr/>
          <h3>Output</h3>
          <PreCode>{stringify(json)}</PreCode>
          <Tablesify value={json} />
          <hr />
          <Tablesify value={JSON.parse(value)} />
        </Col>
      </Row>
    </Container>
  )
}
