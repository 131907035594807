import React from 'react';
import {
  Container,
  Row,
  Col,
  Badge,
  Input,
  Details,
  Button,
  PreCode,
} from '../Util';
import { Translation, CardSet } from '../data/ABCCardSet';

const SAMPLE = `${JSON.stringify(Translation, null, 2)}`;

function reverseMap(o) {
  if (typeof o === 'object' && o !== null) {
    const map = {};
    Object.keys(o).forEach((e, i) => {
      if (typeof e === 'string' && e.length > 0) {
        const v = o[e];
        if (typeof v === 'string' && v.length > 0) {
          map[v] = e;
        }
      }
    });
    return map;
  }
  return {};
}

export default function ObjectTranslator(props) {
  const [value, setValue] = React.useState(SAMPLE);
  const [out, setOut] = React.useState('');

  const handleChange = (e) => setValue(e.target.value);

  const process = () => {
    if (typeof value === 'string' && value.length > 0) {
      const o = JSON.parse(value);
      if (typeof o === 'object' && o !== null) {
        const map = reverseMap(o);
        const keys = Object.keys(map).sort();

        const out = {};
        Object.keys(CardSet).forEach((e) => {
          out[e] = [];
        });

        keys.forEach((e) => {
          if (typeof e === 'string' && e.length > 0) {
            const v = map[e];
            if (typeof v === 'string' && v.length > 0) {
              const k = e.charAt(0);
              out[k].push(
                `{value:'${e}', image: Asset.Image.${v}, reveal:false, active:false}`,
              );
            }
          }
        });

        setOut(out);
      }
    }
  };

  React.useEffect(() => {
    process();
    // eslint-disable-next-line
  }, [value]);

  const el = React.useRef();
  const copy = (e) => {
    e.preventDefault();
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        current.select();
        //current.setSelectionRange(0,value.length)
        document.execCommand('copy');
      }
    }
  };

  const lineCount = value.split('\n').length;
  const rows = Math.max(Math.min(lineCount, 14), 7);

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f488}'} Object Translator</h1>
        <p className="lead">
          Processing text into another form of structured data
        </p>
      </div>
      <hr />

      <Row>
        <Col s="6">
          <Container gradient dark rounded shadow className="p-3">
            <small className="float-right">
              Length <Badge info>{value.length}</Badge>
              <br /># of Line <Badge info>{lineCount}</Badge>
            </small>
            <h3>Input</h3>
            <Input textarea rows={rows} onChange={handleChange} value={value} />
          </Container>
        </Col>
        <Col s="6">
          {value && (
            <Container gradient rounded shadow className="mb-3 p-3">
              <Details lead="See Input">
                <PreCode className="none mt-3">{value}</PreCode>
              </Details>
            </Container>
          )}

          <Container gradient dark rounded shadow className="p-3 mb-3">
            <h5>Output</h5>
            <Button light shadow sm block onClick={copy} className="mb-2">
              {'\u{1f4cb}'} Copy to Clipboard
            </Button>
            <textarea
              ref={el}
              className="form-control"
              value={JSON.stringify(out)}
              rows={1}
              readOnly
            />
            <Badge dark pill>
              read-only
            </Badge>
          </Container>

          <PreCode className="font-sm">{out}</PreCode>
        </Col>
      </Row>
    </div>
  );
}
