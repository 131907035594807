import React from 'react'
import {isPrime,Badge,Button,randomInt} from '../Util'

export default function PrimeTester(props) {
  const {n} = props
  const [value,setValue] = React.useState(typeof(n)==='number'?n:13)

  const handleChange = e=>{
    e.preventDefault()
    setValue(e.target.value)
  }

  function Checker(props) {
    const {value} = props
    if (typeof(value)==='string') {
      if (value.indexOf('.')>=0) {
        return <Badge warning pill shadow>Not an integer</Badge>
      }
      if (value.length<=0) {
        return <Badge warning pill shadow>Empty</Badge>
      }
      const n = parseInt(value)
      if (typeof(n)==='number') {
        return <Checker value={n} />
      }
    } else if (typeof(value)==='number'&&!isNaN(value)) {
      return isPrime(value)?<Badge success pill shadow>Prime</Badge>:<Badge dark pill>Not Prime</Badge>
    }
    return <Badge danger shadow>Not even a number</Badge>
  }

  return (
    <div className="row no-gutters">
      <div className="col-6 pr-3">
        <label className="lead">Enter a number</label>
        <div className="input-group">
          <input type="number" className="form-control shadow-inset" value={value} onChange={handleChange} />
          <div className="input-group-append">
            <Button light outline sm onClick={e=>setValue(randomInt(1024))}>{'\u{1f3b2}'} Randomize</Button>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <label className="lead">Is <Badge light shadow>{value}</Badge> a prime?</label>
          <p className="h4 text-center">
            <Checker value={value} />
          </p>
        </div>
      </div>
    </div>
  )
}
