import moment from 'moment';
import 'moment-timezone';
import { TIMEZONES } from './TIMEZONES';
import { TIMEZONE_i18n } from './TIMEZONE_i18n';

function translate(s) {
  if (TIMEZONE_i18n !== void 0 && TIMEZONE_i18n[s]) {
    return TIMEZONE_i18n[s];
  }

  return s;
}

function shortify(s) {
  try {
    const i = s.indexOf(':');
    if (i >= 0) {
      s = s.slice(0, i);
    }

    const j = s.indexOf('0');
    if (j >= 0) {
      s = s.slice(0, j).concat(s.slice(j + 1));
    }
    return s;
  } catch (error) {
    return '';
  }
}

export const TIMEZONE_OPTIONS = moment.tz
  .names()
  .filter((tz) => {
    return TIMEZONES.includes(tz);
  })
  .reduce((memo, tz) => {
    memo.push({
      name: tz,
      offset: moment.tz(tz).utcOffset(),
    });

    return memo;
  }, [])
  .sort((a, b) => {
    return a.offset - b.offset;
  })
  /*.reduce((memo, tz) => {
    const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';

    return memo.concat(
      `<option value="${tz.name}">(GMT${timezone}) ${_t(tz.name)}</option>`,
    );
  }, '');*/
  .map((tz) => {
    const { offset, name: value } = tz;
    const name = translate(value);
    const offsetLabel = moment.tz(value).format('Z');
    const offsetShortLabel = offset ? shortify(offsetLabel) : '';
    const timezoneLabel = offset ? offsetLabel : '';
    const timezoneShortLabel = offset ? offsetShortLabel : '';
    const label = `(UTC${timezoneLabel}) ${translate(tz.name)}`;
    const shortLabel = `(UTC${
      timezoneShortLabel ? ` ${timezoneShortLabel}` : ''
    }) ${name}`;
    return {
      label,
      shortLabel,
      name,
      value,
      offset,
      offsetLabel,
      offsetShortLabel,
    };
  });
