import React from 'react'
import ReactXCssAnimation from '../reactxcss/ReactXCssAnimation'
import ReactXCssImageGallery from '../reactxcss/ReactXCssImageGallery'
import ReactXCssAnimatedBackground from '../reactxcss/ReactXCssAnimatedBackground'
import {A} from '../Util'

const list = [
  {name: 'Image Gallery', Component: ReactXCssImageGallery},
  {name: 'CSS3 Animation', Component: ReactXCssAnimation},
  {name: 'Animated Background', Component: ReactXCssAnimatedBackground},
]

export default function ReactXCss(props) {
  return (
    <div className="container-fluid mb-5">
      <h1 className="mb-5 text-center text-shadow"><span className="mr-5">{'\u269b'}</span>React &times; CSS3</h1>

      <div className="row">
        <div className="col-2">
          <div className="list-group sidebar w-100">
            <h6 className="lead text-center mb-3 pt-3">Jump to</h6>
            {
              list.map((e,i)=>{
                const {name} = e
                return (
                  <A key={i} shadow className="list-group-item list-group-item-action border-0" href={`#${name}`}>{name}</A>
                )
              })
            }
          </div>
        </div>
        <div className="col-10">
          {
            list.map((e,i)=>{
              const {name,Component} = e
              return (
                <div key={i} id={name} className="pt-5">
                  <Component />
                </div>
              )
            })
          }
        </div>
      </div>

    </div>
  )
}
