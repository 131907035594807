import React from 'react'
import {Row,Col,PreCode,Input,Checkbox,Button,P,randomEffect} from '../Util'

export const TYPES = ['string','number','Date','Object','Array']
export const InputType = {
  string: ['text','textarea'],
  number: ['number'],
  Date: ['date'],
  Object: [],
  Array: [],
}

export default function TemplateEditor(props) {
  const [effect] = React.useState({
    add: randomEffect(),
    rem: 'fadeInLeft',
  })
  const [mode,setMode] = React.useState()
  const type = TYPES[0]
  const inputType = InputType[type][0]
  const [value,setValue] = React.useState({name:'',list:[{name:'',type,inputType}]})
  const {name,list} = value

  const add = (i)=>{
    const type = TYPES[0]
    const inputType = InputType[type][0]
    const entry = {name:'',type,inputType}
    if (typeof(i)==='number'&&i>=0&&i<list.length) {
      setValue({name,list:[...list.slice(0,i+1),entry,...list.slice(i+1)]})
    } else {
      setValue({name,list:[...list,entry]})
    }
  }

  const order = (i,n)=>{
    if (typeof(i)==='number'&&i>=0&&i<list.length) {
      const j = i+n
      if (j>i&&j<list.length) {
        setValue({name,list:[...list.slice(0,i),list[j],list[i],...list.slice(j+1)]})
      } else if (j<i&&j>=0) {
        setValue({name,list:[...list.slice(0,j),list[i],list[j],...list.slice(i+1)]})
      }
    }
  }

  const remove = (i)=>{
    if (typeof(i)==='number'&&i>=0&&i<list.length) {
      setValue({name,list:[...list.slice(0,i),...list.slice(i+1)]})
    }
  }

  const setProp = (i,key,v)=>{
    const {list} = value
    list[i][key] = v
    if (key==='type') {
      const inputTypes = InputType[v]
      if (Array.isArray(inputTypes)&&inputTypes.length>0) {
        if (inputTypes.indexOf(list[i].inputType)<0) {
          list[i].inputType = inputTypes[0]
        }
      } else {
        list[i].inputType = ''
      }
    }
    setValue({name,list})
  }

  const toggleModeRemove = e=>{
    if (e.target.checked) setMode('remove')
    else setMode('')
  }

  return (
    <div className="TemplateEditor">
      <h3 className="text-center">Template Editor</h3>

      <Row>
        <Col s="9">
          <Input label="Template Name" value={name} onChange={e=>setValue({...value,name:e.target.value})}  className="mb-5" />

          <Row>
            <Col s="6">
              <h5>Properties</h5>
            </Col>
            <Col s="3" center>
              <Checkbox label="Remove" value={mode==='remove'} onChange={toggleModeRemove} />
            </Col>
            <Col s="3">
              <Button light sm shadow className="float-right nowrap" onClick={add}>+ Add</Button>
            </Col>
          </Row>

          {list.length<=0&&<P center muted>{'\u2756'} Empty {'\u2756'}</P>}

          {
            list.map((e,i)=>{
              const {name,type,inputType} = e
              return (
                <Row key={i} noGutters className={`animated ${effect.add} faster`}>
                  {mode==='remove'&&<Col s="1" center className={`animated ${effect.rem} faster`}>
                    <label className="mb-1">Remove</label>
                    <div>
                      <Button danger sm shadow className="btn-remove font-weight-bold" onClick={e=>remove(i)}>{'\u2715'}</Button>
                    </div>
                  </Col>}
                  <Col s="1" center>
                    <label>Order</label>
                    <div className="btn-order-group">
                      <Button outline primary sm shadow className="btn-order font-weight-bold" onClick={e=>order(i,-1)}>{'\u25b2'}</Button><br/>
                      <Button outline primary sm shadow className="btn-order font-weight-bold" onClick={e=>order(i,+1)}>{'\u25bc'}</Button>
                    </div>
                  </Col>
                  <Col>
                    <Input label="Name" value={name} onChange={e=>setProp(i,'name',e.target.value)} />
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Type</label>
                      <select className="form-control" value={type} onChange={e=>setProp(i,'type',e.target.value)}>
                        {
                          TYPES.map((e,j)=>(
                            <option key={j}>{e}</option>
                          ))
                        }
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Input Type</label>
                      <select className="form-control" value={inputType} onChange={e=>setProp(i,'inputType',e.target.value)}>
                        {
                          InputType[type].map((e,j)=>(
                            <option key={j}>{e}</option>
                          ))
                        }
                      </select>
                    </div>
                  </Col>
                  <Col s="1" center>
                    <label className="mb-1">Add</label>
                    <div>
                      <Button success sm shadow className="btn-remove font-weight-bold" onClick={e=>add(i)}>{'\uff0b'}</Button>
                    </div>
                  </Col>
                </Row>
              )
            })
          }
        </Col>
        <Col s="3">
          <PreCode sm>{value}</PreCode>
        </Col>
      </Row>

    </div>
  )
}
