import React from 'react';
import { randomBgGrandientDarkStyle } from '../Util';

export default function Rgb2Hex(props) {
  const [r, setR] = React.useState(171);
  const [g, setG] = React.useState(79);
  const [b, setB] = React.useState(152);

  let backgroundColor = '#000000';

  if (typeof r === 'number' && typeof g === 'number' && typeof b === 'number') {
    backgroundColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  React.useEffect(() => {
    if (typeof r === 'string') {
      setR(parseInt(r));
    }
  }, [r]);

  React.useEffect(() => {
    if (typeof g === 'string') {
      setG(parseInt(g));
    }
  }, [g]);

  React.useEffect(() => {
    if (typeof b === 'string') {
      setB(parseInt(b));
    }
  }, [b]);

  /*React.useEffect(()=>{
    if (typeof(r)==='number'&&typeof(g)==='number'&&typeof(b)==='number') {
      backgroundColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`
    }
  },[r,g,b])*/

  return (
    <div className="Rgb2Hex container-lg mb-5">
      <h1 className="text-center">{'\u{1f501}'} RGB to Hex Converter</h1>
      <p className="lead text-center">Convert RGB to Hexadecimal string</p>
      <div
        className="row p-5 shadow rounded text-white"
        style={{ background: randomBgGrandientDarkStyle() }}
      >
        <div className="col-6">
          <h4>RGB</h4>
          <label>R</label>
          <input
            type="number"
            className="form-control shadow-inset"
            value={r}
            onChange={(e) => setR(e.target.value)}
          />
          <label>G</label>
          <input
            type="number"
            className="form-control shadow-inset"
            value={g}
            onChange={(e) => setG(e.target.value)}
          />
          <label>B</label>
          <input
            type="number"
            className="form-control shadow-inset"
            value={b}
            onChange={(e) => setB(e.target.value)}
          />
        </div>
        <div className="col-6">
          <h4>Hex</h4>
          <div className="lead text-monospace text-shine mb-3">
            {backgroundColor}
          </div>
          <h4>Preview</h4>
          <div
            style={{
              backgroundColor,
              width: 75,
              height: 75,
            }}
            className="shadow rounded"
          ></div>
        </div>
      </div>
    </div>
  );
}
