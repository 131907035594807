import React from 'react';
import { Container } from '../Util';

function hex2dec(s) {
  if (typeof s === 'string' && s.length > 0) {
    const n = parseInt(s, 16);
    if (typeof n === 'number') return n;
  }
  return '';
}

/*function dec2hex(n) {
  if (typeof(n)==='number') {
    const s = n.toString(16)
    if (typeof(s)==='string'&&s.length>0) return s
  }
  return ''
}*/

export default function Hex2Dec(props) {
  const [value, setValue] = React.useState('1f680');

  const n = hex2dec(value);

  return (
    <div className="container-lg mb-5">
      <Container gradient dark rounded shadow className="p-3">
        <h3 className="text-center">Hex2Dec</h3>
        <p className="text-center lead">
          Converting hexadecimal (base 16) value to decimal number (base 10)
        </p>
        <div className="row">
          <div className="col">
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-xs-2">
            <h3>{'\u2192'}</h3>
          </div>
          <div className="col">
            <h3>{n}</h3>
          </div>
        </div>
      </Container>
    </div>
  );
}
