import React from 'react';
import { Button, Badge, Details, PreCode, P, A } from '../Util';
//import {flagsource} from '../asset/flagsource'
import unicode from '../asset/unicode-flag.json';

//const categories = ['american','asia','africa','europe','australia',]

export function extractFrom(src) {
  const list = [];
  if (typeof src === 'string' && src.length > 0) {
    const div = document.createElement('div');
    div.innerHTML = src;

    const trs = div.getElementsByTagName('tr');

    for (let tr of trs) {
      const id = tr.getAttribute('id');
      if (typeof id === 'string' && id.length > 0) {
        const splits = id.split('-');
        if (Array.isArray(splits) && splits.length > 1) {
          const code = [parseInt(splits[1], 16), parseInt(splits[2], 16)];
          const value = `U+${splits[1]} U+${splits[2]}`;
          const category = 'flag';

          const tds = tr.getElementsByTagName('td');

          let iso = '';
          let name = '';

          let i = 0;
          for (let td of tds) {
            if (i === 0 && td.getAttribute('class') === 'code')
              iso = td.innerHTML.trim();
            if (i === 3) name = td.innerHTML.trim();
            i++;
          }

          list.push({ value, iso, code, name, category });
        }
      }
    }
  }
  return list;
}

export default function UnicodeFlag(props) {
  const [list, setList] = React.useState([]);
  const [count, setCount] = React.useState({});
  const [category /*setCategory*/] = React.useState();
  const [q, setQ] = React.useState('');
  const [fav, setFav] = React.useState([]);

  React.useEffect(() => {
    if (list.length <= 0) {
      //setList(extractFrom(flagsource))
      setList(unicode);
    } else {
      const count = {};
      list.forEach((e) => {
        const { category } = e;
        const n = count[category];
        if (typeof n === 'number') {
          count[category]++;
        } else {
          count[category] = 1;
        }
      });
      setCount(count);
    }
  }, [list]);

  const filterByCategory = (list, category) => {
    if (typeof category === 'string' && category.length > 0) {
      if (Array.isArray(list) && list.length > 0) {
        return list.filter((e) => {
          return e.category === category;
        });
      }
    }
    return list;
  };

  const filterByQuery = (list, q) => {
    if (typeof q === 'string' && q.length > 0) {
      if (Array.isArray(list) && list.length > 0) {
        return list.filter((e) => {
          const { iso, name } = e;
          const qt = q.trim();
          if (new RegExp(qt, 'i').test(name)) return true;
          if (new RegExp(qt, 'i').test(iso)) return true;
          return false;
        });
      }
    }
    return list;
  };

  const toggleFav = (e) => {
    if (typeof e === 'object' && e !== null) {
      const { code } = e;
      const i = fav.findIndex((e) => e.code === code);
      if (i >= 0) {
        setFav([...fav.slice(0, i), ...fav.slice(i + 1)]);
      } else {
        setFav([...fav, e]);
      }
    }
  };

  const isFav = (e) => {
    if (typeof e === 'object' && e !== null) {
      const { code } = e;
      return fav.findIndex((e) => e.code === code) >= 0;
    }
    return false;
  };

  const filtered = filterByQuery(filterByCategory(list, category), q);

  return (
    <div className="container-lg mb-5">
      <h1 className="text-center">{'\u{1f6e1}'} Unicode Country Flags</h1>

      <P center>
        Each flag is made of 2 abstract unicode characters;{' '}
        <A blank href="https://en.wikipedia.org/wiki/Regional_Indicator_Symbol">
          Regional Indicator Symbol
        </A>
      </P>

      {/*<div className="p-3 text-center">
        <Button primary outline={typeof(category)==='string'&&category.length>0} shadow className="text-capitalize m-1" onClick={e=>setCategory(null)}>All <Badge light pill>{list.length}</Badge></Button>

        {categories.map((e,i)=>{
          return (
            <Button key={i} primary outline={category!==e} shadow className="text-capitalize m-1" onClick={()=>setCategory(e)}>{e} <Badge light pill>{count[e]}</Badge></Button>
          )
        })}
      </div>*/}

      {category && (
        <div className="text-center text-capitalize h3">
          {category}{' '}
          <Badge primary pill shadow>
            {count[category]}
          </Badge>
        </div>
      )}

      <div className="p-3">
        <input
          type="search"
          placeholder="Search..."
          value={q}
          onChange={(e) => setQ(e.target.value)}
          className="form-control"
        />
      </div>

      {/*<div><PreCode>{fav}</PreCode></div>*/}

      <div className="">
        <Details lead={`code (${filtered.length})`}>
          <textarea
            value={JSON.stringify(filtered, null, 2)}
            className="form-control mb-2"
            readOnly
          />
          <PreCode>{filtered}</PreCode>
        </Details>
      </div>

      <div className="mb-2">
        <Details lead={`favorite (${fav.length})`}>
          <Button warning shadow onClick={(e) => setFav([])} className="mb-2">
            clear
          </Button>

          <div className="row justify-content-center">
            {fav.map((e, i) => {
              const { value, code, iso, name } = e;
              return (
                <div key={i} className="col-xs-2">
                  <Button
                    onClick={() => toggleFav(e)}
                    className="m-1"
                    style={{
                      margin: 0,
                      padding: 0,
                      border: 'none',
                    }}
                  >
                    <div className="p-2 bg-light rounded shadow">
                      <div className="row">
                        <div className="col-3">
                          {isFav(e) && (
                            <span
                              className=""
                              style={{
                                color: 'limegreen',
                                position: 'absolute',
                                fontSize: 'xx-large',
                                fontWeight: 'bold',
                                top: -15,
                                left: 15,
                              }}
                            >
                              {'\u2611'}
                            </span>
                          )}
                        </div>
                        <div className="col-6">
                          <div className="h1 text-center">
                            {String.fromCodePoint(...code)}
                          </div>
                        </div>
                        <div className="col-3"></div>
                      </div>
                      <div className="text-capitalize text-center">{iso}</div>
                      <div className="text-capitalize text-center">{name}</div>
                      <div className="text-capitalize text-center">{value}</div>
                      {/*<div className="text-capitalize text-center">{category}</div>*/}
                    </div>
                  </Button>
                </div>
              );
            })}
          </div>

          <textarea
            value={JSON.stringify(fav, null, 2)}
            className="form-control mb-2"
            readOnly
          />
          <PreCode>{fav}</PreCode>
        </Details>
      </div>

      <div className="row justify-content-center">
        {filtered.map((e, i) => {
          const { value, code, iso, name } = e;
          return (
            <div key={i} className="col-xs-2">
              <Button
                onClick={() => toggleFav(e)}
                className="m-1"
                style={{
                  margin: 0,
                  padding: 0,
                  border: 'none',
                }}
              >
                <div className="p-2 bg-light rounded shadow">
                  <div className="row">
                    <div className="col-3">
                      {isFav(e) && (
                        <span
                          className=""
                          style={{
                            color: 'limegreen',
                            position: 'absolute',
                            fontSize: 'xx-large',
                            fontWeight: 'bold',
                            top: -15,
                            left: 15,
                          }}
                        >
                          {'\u2611'}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      <div className="h1 text-center">
                        {String.fromCodePoint(...code)}
                      </div>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <div className="text-capitalize text-center">{iso}</div>
                  <div className="text-capitalize text-center">{name}</div>
                  <div className="text-capitalize text-center">{value}</div>
                  {/*<div className="text-capitalize text-center">{category}</div>*/}
                </div>
              </Button>
            </div>
          );
        })}
      </div>

      {/*<PreCode>{unicode}</PreCode>*/}
    </div>
  );
}
