import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSwitch } from '../util/';

export default function DynamicFormInputsEntryArray(props) {
  const { t } = useTranslation();
  const { k, v, value, onChange } = props;

  const [isRemoveMode, setIsRemoveMode] = React.useState();

  function handleChange(k, v) {
    onChange({ ...value, [k]: v });
  }

  return (
    <div className="border p-2 mb-3">
      <div>
        <label>{k}</label>
        <button
          type="button"
          className="btn btn-light btn-sm px-3 border"
          data-tootik={t(`Add`)}
          onClick={(e) => handleChange(k, ['', ...v])}
        >
          +
        </button>
        <div className="float-right">
          <InputSwitch
            className=""
            label={t('Remove')}
            value={isRemoveMode}
            onChange={setIsRemoveMode}
          />
        </div>
      </div>
      <div>
        {v.map((vv, i) => (
          <div key={i} className="input-group">
            <input
              type="text"
              value={vv}
              onChange={(e) =>
                handleChange(k, [
                  ...v.slice(0, i),
                  e.target.value,
                  ...v.slice(i + 1),
                ])
              }
              className="form-control mb-2"
            />
            <div className="input-group-append">
              {!isRemoveMode ? (
                <button
                  type="button"
                  className="btn btn-light btn-sm px-3 border"
                  data-tootik={t(`Add another`)}
                  onClick={(e) =>
                    handleChange(k, [
                      ...v.slice(0, i + 1),
                      '',
                      ...v.slice(i + 1),
                    ])
                  }
                >
                  +
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-danger btn-sm px-3"
                  data-tootik={t(`Remove`)}
                  onClick={(e) =>
                    handleChange(k, [...v.slice(0, i), ...v.slice(i + 1)])
                  }
                >
                  -
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
