import React from 'react';
import { useTranslation } from 'react-i18next';
import DynamicFormInputs from './DynamicFormInputs';
//import TextareaAutosize from 'react-textarea-autosize';

const DATA = {
  name: 'John Donohue',
  address: {
    city: 'Cyberjaya',
    country: 'Malaysia',
  },
  languages: ['JavaScript', 'Python', 'Swift', 'Kotlin', 'Dart'],
};

export default function DynamicForm(props) {
  const { t } = useTranslation();

  //const [json, setJson] = React.useState(JSON.stringify(DATA, null, 2));
  const [value, setValue] = React.useState(DATA);
  const [submitted, setSubmitted] = React.useState();

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(value);
  }

  return (
    <div className="container-lg mb-5">
      <div className="text-center">
        <h1>{t('Dynamic Form')}</h1>
        <p className="lead">{t('Render a form based on the JSON object')}</p>
      </div>

      <div
        className={`position-fixed w-100 animate__animated animate__faster animate__${
          submitted ? 'fadeInRight' : 'fadeOutRight'
        }`}
        style={{ left: 0, zIndex: 99 }}
      >
        <div className="container bg-info text-white p-3 mb-3 shadow-lg rounded-lg">
          <div className="float-right">
            <button
              className="btn btn-outline-light btn-lg"
              onClick={(e) => setSubmitted()}
            >
              {t('Close')} &times;
            </button>
          </div>
          <h5 className="text-center">{t('Submitted')}</h5>
          <pre className="p-3 text-white">
            <code>{JSON.stringify(submitted, null, 2)}</code>
          </pre>
        </div>
      </div>

      <div className="p-3 bg-white rounded-lg shadow-sm">
        <div className="row">
          <div className="col-6 border-right">
            <pre>
              <code>{JSON.stringify(value, null, 2)}</code>
            </pre>
            <hr />
            {/*<pre>
              <code>{JSON.stringify(Object.entries(value), null, 2)}</code>
            </pre>*/}
            {/* TODO: make JSON data can be changed */}
            {/*<div className="form-group">
              <label>{t('JSON')}</label>
              <TextareaAutosize
                value={json}
                onChange={(e) => setJson(e.target.value)}
                className=""
                placeholder="JSON Object string"
                className="form-control"
              />
            </div>*/}
          </div>
          <div className="col-6">
            <form onSubmit={handleSubmit}>
              <DynamicFormInputs value={value} onChange={setValue} />

              <div>
                <button type="submit" className="btn btn-primary">
                  {t('Submit')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
