import React from 'react';
import { useTranslation } from 'react-i18next';
import Constant from '../Constant';

const { HOME } = Constant;
const { logo, title, description } = HOME;

export default function HomeHero(props) {
  const { t } = useTranslation();
  return (
    <div
      className="HomeHero container place-items-center text-center text-white py-5 animate__animated"
      style={{ height: 360 }}
    >
      <div className="py-5">
        <h1 className="display-1 animate__animated animate__faster animate__flipInX animate__delay-1s">
          {logo}
        </h1>
        <h1 className="animate__animated animate__flipInX">{t(title)}</h1>
        <p className="lead animate__animated animate__faster animate__lightSpeedInRight animate__delay-2s">
          {t(description)}
        </p>
      </div>
    </div>
  );
}
