import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  toPng,
  /*toJpeg, */ toBlob /*toPixelData, toSvg*/,
} from 'html-to-image';
import { saveAs } from 'file-saver';

export default function HTMLtoImage(props) {
  const { t } = useTranslation();

  const previewEl = React.useRef();
  const [errorMsg, setErrorMsg] = React.useState();

  const [greetings, setGreetings] = React.useState('Hello!');
  const [message, setMessage] = React.useState(`Is it me you're looking for?`);
  const [from, setFrom] = React.useState('~Lionel Richie~');

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const preview = params.get('preview');

    if (preview === '') {
      getImage();
      //capturePreview();
    }
  }, []);

  function capturePreview() {
    /*toPng(previewEl.current)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        //document.body.appendChild(img);

        //window.open('').document.write(img.outerHTML);
        //window.open(dataUrl, '_blank');
      })
      .catch((error) => {
        setErrorMsg(`${error}`);
      });*/

    toBlob(previewEl.current)
      .then((blob) => {
        const timestamp = new Date().toISOString().replace(/:/g, '_');
        saveAs(blob, `screencapture-${timestamp}.png`);
      })
      .catch((error) => {
        setErrorMsg(`${error}`);
      });
  }

  function shareOverWhatsapp() {
    toPng(previewEl.current)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;

        const { href } = window.location;

        const metaOgImage = document.createElement('meta');
        metaOgImage.setAttribute('property', 'og:image');
        metaOgImage.setAttribute('content', `${href}?preview`);

        document.head.appendChild(metaOgImage);

        const url = `https://api.whatsapp.com/send?text=${href}?preview`;
        window.open(url, '_blank');
      })
      .catch((error) => {
        setErrorMsg(`${error}`);
      });
  }

  function getImage() {
    toPng(previewEl.current)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;

        document.write(img.outerHTML);
      })
      .catch((error) => {
        setErrorMsg(`${error}`);
      });
  }

  return (
    <div className="container-lg mb-5">
      <h3 className="text-secondary text-center animate__animated animate__fadeIn animate__faster animate__delay-1s p-4">
        HTML to image
      </h3>

      <div className="mb-4">
        <div className="p-4 bg-white rounded shadow">
          <h4>{t('Update message')}</h4>
          <div className="form-group">
            <label className="label-sm">{t('Greetings')}</label>
            <input
              className="form-control"
              value={greetings}
              onChange={(e) => setGreetings(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="label-sm">{t('Message')}</label>
            <textarea
              className="form-control"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="label-sm">{t('From')}</label>
            <input
              className="form-control"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div className="float-right">
          <button
            className="btn btn-outline-primary btn-sm shadow m-1"
            onClick={capturePreview}
          >
            {t('Save preview as PNG image')}
          </button>

          {
            <button
              className="btn btn-outline-success btn-sm shadow m-1"
              onClick={shareOverWhatsapp}
            >
              {t('Share over Whatsapp')}
            </button>
          }

          <button
            className="btn btn-outline-secondary btn-sm shadow m-1"
            onClick={getImage}
          >
            {t('Get Image')}
          </button>
        </div>

        <h3>{t('Preview')}</h3>
      </div>

      {errorMsg && (
        <div className="text-center text-danger">{{ errorMsg }}</div>
      )}

      <div
        ref={previewEl}
        className="rounded text-white text-center d-flex align-content-center align-items-center justify-content-center flex-wrap w-full"
        style={{
          height: '100vh',
          background: 'linear-gradient(108deg, midnightblue, cyan)',
          margin: '0px auto',
        }}
      >
        <div>
          <h1>{greetings}</h1>
          <h3 className="py-4">{message}</h3>
          <h4>{from}</h4>
        </div>
      </div>
    </div>
  );
}
