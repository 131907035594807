import React from 'react';

export function capFirstChar(s) {
  if (typeof s === 'string' && s.length > 0) {
    return s.charAt(0).toUpperCase().concat(s.slice(1));
  }
  return '';
}

export function isUrl(s) {
  if (typeof s === 'string' && s.length > 0) {
    return s.startsWith('http') && s.indexOf('://') > 0;
  }
  return false;
}

export function formatNumber(n) {
  if (typeof n === 'number') {
    return n.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  return n;
}

export function isNumber(s) {
  if (typeof s === 'string' && s.length > 0) {
    if (s.match(/^-{0,1}\d+$/)) {
      const n = parseFloat(s);
      if (typeof n === 'number' && !isNaN(n)) return true;
    } else if (s.match(/^\d+\.\d+$/)) {
      const n = parseInt(s);
      if (typeof n === 'number' && !isNaN(n)) return true;
    }
    /*if (s.indexOf('.')>=0) {
      const n = parseFloat(s)
      if (typeof(n)==='number'&&!isNaN(n)) return true
    }
    const n = parseInt(s)
    if (typeof(n)==='number'&&!isNaN(n)) return true*/
  }
  if (typeof s === 'number') return true;
  return false;
}

export function numberFrom(s) {
  if (typeof s === 'string' && s.length > 0) {
    if (s.match(/^-{0,1}\d+$/)) {
      const n = parseFloat(s);
      if (typeof n === 'number' && !isNaN(n)) return n;
    } else if (s.match(/^\d+\.\d+$/)) {
      const n = parseInt(s);
      if (typeof n === 'number' && !isNaN(n)) return n;
    }
    /*if (s.indexOf('.')>=0) {
      const n = parseFloat(s)
      if (typeof(n)==='number'&&!isNaN(n)) return n
    }
    const n = parseInt(s)
    if (typeof(n)==='number'&&!isNaN(n)) return n*/
  }
  if (typeof s === 'number') return s;
  return false;
}

export function randomInt(a = 10, b = 0) {
  return Math.floor(Math.random() * a) + b;
}

// randomInt alias
export function randInt(a, b) {
  return randomInt(a, b);
}

export function randomChar(upper = false) {
  const abc = 'abcdefghijklmnopqrstuvwxyz';
  const c = abc[Math.floor(Math.random() * abc.length)];
  return upper ? c.toUpperCase() : c;
}

export function randomWord(l = 10) {
  return new Array(l)
    .fill(0)
    .map((e) => randomChar())
    .join('');
}

// colors with luminance black grouped by basic colors
export const LightColor = {
  Pink: ['Pink', 'LightPink'],
  Yellow: [
    'Yellow',
    'LightYellow',
    'LemonChiffon',
    'LightGoldenrodYellow',
    'PapayaWhip',
    'Moccasin',
    'PeachPuff',
    'PaleGoldenrod',
    'Khaki',
    'DarkKhaki',
    'Gold',
  ],
  Brown: [
    'Cornsilk',
    'BlanchedAlmond',
    'Bisque',
    'NavajoWhite',
    'Wheat',
    'Burlywood',
  ],
  Green: ['Chartreuse', 'GreenYellow', 'LightGreen', 'PaleGreen'],
  Cyan: ['LightCyan', 'PaleTurquoise', 'Aquamarine'],
  Blue: [
    'LightSteelBlue',
    'PowderBlue',
    'LightBlue',
    'SkyBlue',
    'LightSkyBlue',
  ],
  Purple: ['Lavender', 'Thistle'],
  White: [
    'White',
    'Snow',
    'Honeydew',
    'MintCream',
    'Azure',
    'AliceBlue',
    'GhostWhite',
    'WhiteSmoke',
    'Seashell',
    'Beige',
    'OldLace',
    'FloralWhite',
    'Ivory',
    'AntiqueWhite',
    'Linen',
    'LavenderBlush',
    'MistyRose',
  ],
  Gray: ['Gainsboro', 'LightGray', 'Silver'],
};

export function randColor(props) {
  const keys = Object.keys(LightColor);
  const index = randomInt(keys.length);
  const list = LightColor[keys[index]];
  return list[randomInt(list.length)];
}

// colors with luminance white grouped by basic colors
const DarkColor = {
  Pink: [/*'HotPink',*/ 'DeepPink', 'PaleVioletRed', 'MediumVioletRed'],
  Red: [
    /*'LightSalmon','Salmon','DarkSalmon','LightCoral',*/ 'IndianRed',
    'Crimson',
    'Firebrick',
    'DarkRed',
    'Red',
  ],
  Orange: ['OrangeRed', 'Tomato', 'Coral', 'DarkOrange', 'Orange'],
  Yellow: ['DarkKhaki'],
  Brown: [
    /*'Tan',*/ 'RosyBrown',
    'SandyBrown',
    'Goldenrod',
    'DarkGoldenrod',
    'Peru',
    'Chocolate',
    'SaddleBrown',
    'Sienna',
    'Brown',
    'Maroon',
  ],
  Green: [
    'DarkOliveGreen',
    'Olive',
    'OliveDrab',
    /*'YellowGreen','LimeGreen','Lime','LawnGreen','SpringGreen','MediumSpringGreen','DarkSeaGreen','MediumAquamarine','MediumSeaGreen',*/ 'SeaGreen',
    'ForestGreen',
    'Green',
    'DarkGreen',
  ],
  Cyan: [
    /*'Aqua','Cyan','Turquoise','MediumTurquoise','DarkTurquoise',*/ 'LightSeaGreen',
    'CadetBlue',
    'DarkCyan',
    'Teal',
  ],
  Blue: [
    /*'DeepSkyBlue',*/ 'DodgerBlue',
    'CornflowerBlue',
    'SteelBlue',
    'RoyalBlue',
    'Blue',
    'MediumBlue',
    'DarkBlue',
    'Navy',
    'MidnightBlue',
  ],
  Purple: [
    /*'Plum','Violet',*/ 'Orchid',
    'Fuchsia',
    'Magenta',
    'MediumOrchid',
    'MediumPurple',
    'BlueViolet',
    'DarkViolet',
    'DarkOrchid',
    'DarkMagenta',
    'Purple',
    'Indigo',
    'DarkSlateBlue',
    'SlateBlue',
    'MediumSlateBlue',
  ],
  Gray: [
    /*'DarkGray',*/ 'Gray',
    'DimGray',
    'LightSlateGray',
    'SlateGray',
    'DarkSlateGray',
    'Black',
  ],
};

export function randColor2(props) {
  const keys = Object.keys(DarkColor);
  const index = randomInt(keys.length);
  const list = DarkColor[keys[index]];
  return list[randomInt(list.length)];
}

export function randomBgGrandientLightStyle() {
  const a = randColor();
  const b = randColor();
  const n = randomInt(360);
  return `linear-gradient(${n}deg,${a},${b})`;
}

export function randomBgGrandientDarkStyle() {
  const a = randColor2();
  const b = randColor2();
  const n = randomInt(360);
  return `linear-gradient(${n}deg,${a},${b})`;
}

export const Effect = {
  all: [
    'bounce',
    'flash',
    'pulse',
    'rubberBand',
    'shake',
    'headShake',
    'swing',
    'tada',
    'wobble',
    'jello',
    'bounceIn',
    'bounceInDown',
    'bounceInLeft',
    'bounceInRight',
    'bounceInUp',
    'bounceOut',
    'bounceOutDown',
    'bounceOutLeft',
    'bounceOutRight',
    'bounceOutUp',
    'fadeIn',
    'fadeInDown',
    'fadeInDownBig',
    'fadeInLeft',
    'fadeInLeftBig',
    'fadeInRight',
    'fadeInRightBig',
    'fadeInUp',
    'fadeInUpBig',
    'fadeOut',
    'fadeOutDown',
    'fadeOutDownBig',
    'fadeOutLeft',
    'fadeOutLeftBig',
    'fadeOutRight',
    'fadeOutRightBig',
    'fadeOutUp',
    'fadeOutUpBig',
    'flipInX',
    'flipInY',
    'flipOutX',
    'flipOutY',
    'lightSpeedIn',
    'lightSpeedOut',
    'rotateIn',
    'rotateInDownLeft',
    'rotateInDownRight',
    'rotateInUpLeft',
    'rotateInUpRight',
    'rotateOut',
    'rotateOutDownLeft',
    'rotateOutDownRight',
    'rotateOutUpLeft',
    'rotateOutUpRight',
    'hinge',
    'jackInTheBox',
    'rollIn',
    'rollOut',
    'zoomIn',
    'zoomInDown',
    'zoomInLeft',
    'zoomInRight',
    'zoomInUp',
    'zoomOut',
    'zoomOutDown',
    'zoomOutLeft',
    'zoomOutRight',
    'zoomOutUp',
    'slideInDown',
    'slideInLeft',
    'slideInRight',
    'slideInUp',
    'slideOutDown',
    'slideOutLeft',
    'slideOutRight',
    'slideOutUp',
    'heartBeat',
  ],
  attentionSeekers: [
    'bounce',
    'flash',
    'pulse',
    'shake',
    'headShake',
    'swing',
    'tada',
    'wobble',
    'jello',
    'heartBeat',
  ],
};

Effect.in = Effect.all.filter((e) => e.indexOf('In') >= 0);
Effect.out = Effect.all.filter((e) => e.indexOf('Out') >= 0).concat('hinge');

export function randomEffect() {
  //const list = Effect.attentionSeekers
  const list = Effect.in;
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}

export function randomAttentionSeeker() {
  const list = Effect.attentionSeekers;
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}

export function animated(effect, speed) {
  if (Effect.all.indexOf(effect) >= 0) {
    if (['slow', 'slower', 'fast', 'faster'].indexOf(speed) >= 0) {
      return `animated ${effect} ${speed}`;
    }
    return `animated ${effect}`;
  }
  return animated(randomEffect(), 'faster');
}

export function generateId(prefix = '_', l = 6) {
  return `${prefix}${Date.now()}${new Array(l)
    .fill(0)
    .map((e) => randomInt())
    .join('')}`;
}

export function whatos() {
  let os = 'NA';
  if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1)
    os = 'Windows 10';
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1)
    os = 'Windows 8';
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1)
    os = 'Windows 7';
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
    os = 'Windows Vista';
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1)
    os = 'Windows XP';
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1)
    os = 'Windows 2000';
  if (window.navigator.userAgent.indexOf('Mac') !== -1) os = 'Mac/iOS';
  if (window.navigator.userAgent.indexOf('X11') !== -1) os = 'UNIX';
  if (window.navigator.userAgent.indexOf('Linux') !== -1) os = 'Linux';
  return os;
}

export const COLOR = {
  Pink: [
    'Pink',
    'LightPink',
    'HotPink',
    'DeepPink',
    'PaleVioletRed',
    'MediumVioletRed',
  ],
  Red: [
    'LightSalmon',
    'Salmon',
    'DarkSalmon',
    'LightCoral',
    'IndianRed',
    'Crimson',
    'Firebrick',
    'DarkRed',
    'Red',
  ],
  Orange: ['OrangeRed', 'Tomato', 'Coral', 'DarkOrange', 'Orange'],
  Yellow: [
    'Yellow',
    'LightYellow',
    'LemonChiffon',
    'LightGoldenrodYellow',
    'PapayaWhip',
    'Moccasin',
    'PeachPuff',
    'PaleGoldenrod',
    'Khaki',
    'DarkKhaki',
    'Gold',
  ],
  Brown: [
    'Cornsilk',
    'BlanchedAlmond',
    'Bisque',
    'NavajoWhite',
    'Wheat',
    'Burlywood',
    'Tan',
    'RosyBrown',
    'SandyBrown',
    'Goldenrod',
    'DarkGoldenrod',
    'Peru',
    'Chocolate',
    'SaddleBrown',
    'Sienna',
    'Brown',
    'Maroon',
  ],
  Green: [
    'DarkOliveGreen',
    'Olive',
    'OliveDrab',
    'YellowGreen',
    'LimeGreen',
    'Lime',
    'LawnGreen',
    'Chartreuse',
    'GreenYellow',
    'SpringGreen',
    'MediumSpringGreen',
    'LightGreen',
    'PaleGreen',
    'DarkSeaGreen',
    'MediumAquamarine',
    'MediumSeaGreen',
    'SeaGreen',
    'ForestGreen',
    'Green',
    'DarkGreen',
  ],
  Cyan: [
    'Aqua',
    'Cyan',
    'LightCyan',
    'PaleTurquoise',
    'Aquamarine',
    'Turquoise',
    'MediumTurquoise',
    'DarkTurquoise',
    'LightSeaGreen',
    'CadetBlue',
    'DarkCyan',
    'Teal',
  ],
  Blue: [
    'LightSteelBlue',
    'PowderBlue',
    'LightBlue',
    'SkyBlue',
    'LightSkyBlue',
    'DeepSkyBlue',
    'DodgerBlue',
    'CornflowerBlue',
    'SteelBlue',
    'RoyalBlue',
    'Blue',
    'MediumBlue',
    'DarkBlue',
    'Navy',
    'MidnightBlue',
  ],
  Purple: [
    'Lavender',
    'Thistle',
    'Plum',
    'Violet',
    'Orchid',
    'Fuchsia',
    'Magenta',
    'MediumOrchid',
    'MediumPurple',
    'BlueViolet',
    'DarkViolet',
    'DarkOrchid',
    'DarkMagenta',
    'Purple',
    'Indigo',
    'DarkSlateBlue',
    'SlateBlue',
    'MediumSlateBlue',
  ],
  White: [
    'White',
    'Snow',
    'Honeydew',
    'MintCream',
    'Azure',
    'AliceBlue',
    'GhostWhite',
    'WhiteSmoke',
    'Seashell',
    'Beige',
    'OldLace',
    'FloralWhite',
    'Ivory',
    'AntiqueWhite',
    'Linen',
    'LavenderBlush',
    'MistyRose',
  ],
  Gray: [
    'Gainsboro',
    'LightGray',
    'Silver',
    'DarkGray',
    'Gray',
    'DimGray',
    'LightSlateGray',
    'SlateGray',
    'DarkSlateGray',
    'Black',
  ],
};

export function isColor(c) {
  if (typeof c === 'string' && c.length > 0) {
    const keys = Object.keys(COLOR);
    for (let i = 0; i < keys.length; i++) {
      const list = COLOR[keys[i]];
      for (let j = 0; j < list.length; j++) {
        if (c.toLowerCase() === list[j].toLowerCase()) return true;
      }
    }
  }
  return false;
}

export class Color {
  static black = new Color('#000000');
  static white = new Color('#ffffff');
  constructor(value) {
    if (typeof value === 'string' && value.length > 0) {
      this.fromString(value);
    } else {
      // rgb value is between 0-255, a (alpha) is between 0.0-1.0
      this.r = 0;
      this.g = 0;
      this.b = 0;
      this.a = 1;
    }
  }
  inverse() {
    const { r, g, b } = this;
    const max = 255;
    const rgb = [max - r, max - g, max - b];
    return new Color(`rgb(${rgb.join(',')})`);
  }
  luminance() {
    const { r, g, b } = this;
    const L = r * 0.299 + g * 0.587 + b * 0.114;
    if (L > 186) return Color.black;
    return Color.white;
  }
  lumin() {
    //const {h,s,l} = this.toHsl()
    //return s>50?Color.black:Color.white
    return this.luminance();
  }
  fromString(s) {
    if (typeof s === 'string' && s.length > 0) {
      if (s.charAt(0) === '#') {
        const rgb = s.slice(1);
        this.r = parseInt(rgb.slice(0, 2), 16);
        this.g = parseInt(rgb.slice(2, 4), 16);
        this.b = parseInt(rgb.slice(4, 6), 16);
        if (typeof this.a !== 'number') {
          this.a = 1;
        }
      } else if (s.startsWith('rgba')) {
        const rgba = s
          .trim()
          .slice(5, s.length - 1)
          .split(',');
        const rgb = rgba.slice(0, rgba.length - 1).map((e) => parseInt(e));
        this.r = rgb[0];
        this.g = rgb[1];
        this.b = rgb[2];
        this.a = parseFloat(rgba.slice(rgba.length - 1));
      } else if (s.startsWith('rgb')) {
        const rgb = s
          .trim()
          .slice(4, s.length - 1)
          .split(',')
          .map((e) => parseInt(e));
        this.r = rgb[0];
        this.g = rgb[1];
        this.b = rgb[2];
        if (typeof this.a !== 'number') {
          this.a = 1;
        }
      }
    }
    return this;
  }
  toHsl() {
    const r = parseFloat(this.r) / 255;
    const g = parseFloat(this.g) / 255;
    const b = parseFloat(this.b) / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const diff = max - min;
    const add = max + min;
    const hue =
      min === max
        ? 0
        : r === max
        ? ((60 * (g - b)) / diff + 360) % 360
        : g === max
        ? (60 * (b - r)) / diff + 120
        : (60 * (r - g)) / diff + 240;
    const lum = 0.5 * add;
    const sat =
      lum === 0
        ? 0
        : lum === 1
        ? 1
        : lum <= 0.5
        ? diff / add
        : diff / (2 - add);
    const h = Math.round(hue);
    const s = Math.round(sat * 100);
    const l = Math.round(lum * 100);
    return { h, s, l };
  }
  hsl() {
    const { h, s, l } = this.toHsl();
    return `hsl(${h}, ${s}%, ${l}%)`;
  }
  hsla() {
    const { h, s, l } = this.toHsl();
    const a = parseFloat(this.a) || 1;
    return `hsla(${h}, ${s}%, ${l}%, ${a})`;
  }
  toString(p) {
    const { r, g, b, a } = this;
    if (p === 'hex' || p === '#') {
      const toHex = (n) => {
        const hex = n.toString(16);
        if (hex.length === 1) return `0${hex}`;
        return hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }
    if (p === 'rgb') {
      return `rgb(${r},${g},${b})`;
    }
    if (p === 'hsl') {
      return this.hsl();
    }
    if (p === 'hsla') {
      return this.hsla();
    }
    return `rgba(${r},${g},${b},${a})`;
  }
}

export function randomColorGroup() {
  const keys = Object.keys(COLOR);
  return keys[randomInt(keys.length)];
}

export function randomColor(n = '') {
  const randomInt = (a = 10, b = 0) => {
    return Math.floor(Math.random() * a) + b;
  };

  const keys = Object.keys(COLOR);
  const l = keys.length;
  const i = randomInt(l);
  let k = keys[i];
  if (typeof n === 'string' && n.length > 0) {
    const j = keys.indexOf(n);
    if (j >= 0) {
      k = keys[j];
    }
  }

  const colors = COLOR[k];
  return colors[randomInt(colors.length)];
}

export function trace(value, size) {
  if (typeof size === 'number' && size > 0) {
    console.log(JSON.stringify(value, null, size));
  } else {
    console.log(value);
  }
}

export function todate() {
  return new Date().toISOString().split('T')[0];
}

export function lipsum(n = 10, dot = false) {
  if (typeof n !== 'number' || (typeof n === 'number' && n < 1)) n = 10;
  const words = [
    'lorem',
    'ipsum',
    'dolor',
    'sit',
    'amet',
    'consectetur',
    'adipiscing',
    'elit',
    'vivamus',
    'et',
    'accumsan',
    'augue',
    'duis',
    'eget',
    'nunc',
    'id',
    'sodales',
    'finibus',
    'vestibulum',
    'sagittis',
    'magna',
    'nec',
    'rutrum',
    'volutpat',
    'risus',
    'tincidunt',
    'justo',
    'non',
    'gravida',
    'tortor',
    'enim',
    'in',
    'urna',
    'ut',
    'vel',
    'metus',
    'pellentesque',
    'porttitor',
    'vitae',
    'nisi',
    'nullam',
    'faucibus',
    'condimentum',
    'quam',
    'imperdiet',
    'class',
    'aptent',
    'taciti',
    'sociosqu',
    'ad',
    'litora',
    'torquent',
    'per',
    'conubia',
    'nostra',
    'inceptos',
    'himenaeos',
    'interdum',
    'malesuada',
    'fames',
    'ac',
    'ante',
    'primis',
    'curabitur',
    'nibh',
    'quis',
    'iaculis',
    'cras',
    'mollis',
    'eu',
    'congue',
    'leo',
  ];
  const count = n; //Math.floor(Math.random()*n)+1
  const sentence = [];
  const indexes = new Array(count)
    .fill(0)
    .map((index) => Math.floor(Math.random() * words.length));
  indexes.forEach((index, i) => {
    const word = words[index];
    if (i === 0) sentence.push(word.charAt(0).toUpperCase() + word.substr(1));
    else sentence.push(word);
  });
  if (dot) return sentence.join(' ').concat('.');
  return sentence.join(' ');
}

export function PreCode(props) {
  const { children, value, size = 2, className = '', sm = false } = props;
  const style = {};
  if (sm) style.fontSize = 'x-small';
  if (typeof value === 'string') {
    return (
      <div className={className}>
        <pre style={style}>
          <code>{value.trim()}</code>
        </pre>
      </div>
    );
  }
  if (
    typeof value === 'object' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return (
      <div className={className}>
        <pre style={style}>
          <code>{JSON.stringify(value, null, size)}</code>
        </pre>
      </div>
    );
  }
  if (typeof children === 'string') {
    return (
      <div className={className}>
        <pre style={style}>
          <code>{children.trim()}</code>
        </pre>
      </div>
    );
  }
  return (
    <div className={className}>
      <pre style={style}>
        <code>{JSON.stringify(children, null, size)}</code>
      </pre>
    </div>
  );
}

export function isPrime(n) {
  if (typeof n === 'number') {
    for (let i = 2; i <= Math.sqrt(n); i++) {
      if (n % i === 0) {
        return false;
      }
    }
    return n > 1;
  }
  return false;
}

/* Container */
export function Container(props) {
  const {
    className = '',
    style = {},
    sm = false,
    md = false,
    lg = false,
    xl = false,
    fluid = false,
    gradient = false,
    dark = false,
    shadow = false,
    rounded = false,
    deg,
    top = false,
    bottom = false,
    left = false,
    right = false,
    center = false,
    children,
  } = props;
  const classNames = [className];

  if (fluid) classNames.push('container-fluid');
  else if (xl) classNames.push('container-xl');
  else if (lg) classNames.push('container-lg');
  else if (md) classNames.push('container-md');
  else if (sm) classNames.push('container-sm');
  else classNames.push('container');

  if (shadow) classNames.push('shadow');
  if (rounded) classNames.push('rounded');
  if (center) classNames.push('text-center');
  if (gradient) {
    const colors = [];
    if (dark) {
      const keys = Object.keys(props);
      for (let i = 0; i < keys.length; i++) {
        const e = keys[i];
        if (isColor(e)) colors.push(e);
      }
      if (colors.length === 0) {
        colors.push(randColor2());
        colors.push(randColor2());
      } else if (colors.length === 1) {
        colors.push('black');
      }
      classNames.push('text-white');
    } else {
      const keys = Object.keys(props);
      for (let i = 0; i < keys.length; i++) {
        const e = keys[i];
        if (isColor(e)) colors.push(e);
      }
      if (colors.length === 0) {
        colors.push(randColor());
        colors.push(randColor());
      } else if (colors.length === 1) {
        colors.push('white');
      }
    }
    if (colors.length > 1) {
      const a = colors[0];
      const b = colors[1];
      if (typeof deg === 'number') {
        style.background = `linear-gradient(${deg}deg,${a},${b})`;
      } else {
        let dir = '';
        if (top) dir = 'to top';
        if (bottom) dir = 'to bottom';
        if (left) dir = 'to left';
        if (right) dir = 'to right';
        if (dir) {
          style.background = `linear-gradient(${dir},${a},${b})`;
        } else {
          const d = Math.floor(Math.random() * 360);
          style.background = `linear-gradient(${d}deg,${a},${b})`;
        }
      }
    }
  }
  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

/* Grid */
export function Row(props) {
  const { children, noGutters = false, className = '' } = props;
  const classNames = ['row', className];
  if (noGutters) classNames.push('no-gutters');
  return <div className={classNames.join(' ')}>{children}</div>;
}

export function Col(props) {
  const { s, children, center = false, className = '' } = props;
  const classNames = [className];
  const size = parseInt(s);
  if (size >= 1 && size <= 12) classNames.push(`col-${size}`);
  else classNames.push('col');
  if (center) classNames.push('text-center');
  return <div className={classNames.join(' ')}>{children}</div>;
}

/* Form */
export function Input(props) {
  const {
    label,
    value,
    onChange,
    search = false,
    number = false,
    color = false,
    date = false,
    datetime = false,
    textarea = false,
    placeholder,
    rows = 3,
    className = '',
  } = props;
  let type = 'text';
  if (search) type = 'search';
  if (number) type = 'number';
  if (color) type = 'color';
  if (date) type = 'date';
  if (datetime) type = 'datetime-local';
  const readOnly = value && typeof onChange !== 'function';
  if (textarea) {
    return (
      <div className={`form-group ${className}`}>
        {label && <label>{label}</label>}
        <textarea
          value={value}
          placeholder={placeholder ? placeholder : label}
          onChange={onChange}
          className="form-control"
          rows={rows}
          readOnly={readOnly}
        />
      </div>
    );
  }
  return (
    <div className={`form-group ${className}`}>
      {label && <label>{label}</label>}
      <input
        className="form-control"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder ? placeholder : label}
        readOnly={readOnly}
      />
    </div>
  );
}

export function Checkbox(props) {
  const { label = '', value, className = '', onChange } = props;
  const id = generateId('Checkbox');
  return (
    <div className={`form-group form-check ${className}`}>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        value={value}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export function Button(props) {
  const {
    children,
    className = '',
    outline = false,
    sm = false,
    lg = false,
    block = false,
    disabled = false,
    shadow = false,
    label = '',
    title = '',
    button = true,
    submit = false,
    reset = false,
    style = {},
    onClick,
    up = false,
    left = false,
    right = false,
    down = false,
  } = props;
  const classNames = ['btn', className];
  Object.keys(props).forEach((e) => {
    if (
      [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'light',
        'dark',
        'link',
      ].indexOf(e) >= 0
    ) {
      classNames.push(`btn-${outline ? 'outline-' : ''}${e}`);
    }
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(e) >= 0)
      classNames.push(e);
  });
  if (sm) classNames.push('btn-sm');
  if (lg) classNames.push('btn-lg');
  if (block) classNames.push('btn-block');
  if (shadow) classNames.push('shadow');
  let type = 'button';
  if (submit) type = 'submit';
  else if (reset) type = 'reset';
  let pos = '';
  if (typeof title === 'string' && title.length > 0) {
    if (up) pos = 'up';
    else if (left) pos = 'left';
    else if (right) pos = 'right';
    else if (down) pos = 'down';
    else pos = 'up';
  } else {
    return (
      <button
        className={classNames.join(' ')}
        onClick={onClick}
        disabled={disabled}
        type={type}
        title={title}
        style={style}
      >
        {label || children}
      </button>
    );
  }
  return (
    <button
      className={classNames.join(' ')}
      onClick={onClick}
      disabled={disabled}
      type={type}
      title={title}
      style={style}
      data-balloon={title}
      data-balloon-pos={pos}
    >
      {label || children}
    </button>
  );
}

export function Badge(props) {
  const { children, className = '', pill, title = '', shadow = false } = props;
  const classNames = ['badge', className];
  if (pill) {
    classNames.push('badge-pill');
  }
  Object.keys(props).forEach((e) => {
    if (
      [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'light',
        'dark',
      ].indexOf(e) >= 0
    ) {
      classNames.push(`badge-${e}`);
    }
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(e) >= 0) {
      classNames.push(e);
    }
  });
  if (shadow) classNames.push('shadow');
  return (
    <span className={classNames.join(' ')} title={title}>
      {children}
    </span>
  );
}

export function A(props) {
  const {
    href = '',
    label = '',
    className = '',
    children,
    color,
    target = '',
    title = '',
    shadow = false,
    outline = false,
    sm = false,
    lg = false,
    blank = false,
    style = {},
  } = props;
  const id = `link${Date.now()}${new Array(6)
    .fill(0)
    .map((e) => Math.floor(Math.random() * 10))
    .join('')}`;
  const classNames = [id, className];
  if (typeof color === 'string' && color.length > 0) style.color = color;
  if (shadow) classNames.push('shadow');
  let isBtn = false;
  Object.keys(props).forEach((e) => {
    if (
      [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'light',
        'dark',
      ].indexOf(e) >= 0
    ) {
      if (outline) classNames.push(`btn btn-outline-${e}`);
      else classNames.push(`btn btn-${e}`);
      isBtn = true;
    }
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(e) >= 0)
      classNames.push(e);
  });
  if (isBtn) {
    if (sm) classNames.push('btn-sm');
    if (lg) classNames.push('btn-lg');
  }
  return (
    <>
      <style>{`#${id}{position:relative;text-decoration:none;}#${id}:hover{text-decoration:none;}#${id}:before{content:"";position:absolute;width:100%;height:.2rem;bottom:0;left:0;background-color:currentColor;visibility:hidden;transform:scaleX(0);transition:all 0.3s ease-in-out 0s;-webkit-transform:scaleX(0);-webkit-transition:all 0.3s ease-in-out 0s;}#${id}:hover:before{visibility:visible;-webkit-transform:scaleX(1);transform:scaleX(1);}`}</style>
      <a
        id={id}
        href={href}
        className={classNames.join(' ')}
        style={style}
        target={target || blank ? '_blank' : ''}
        rel={target === '_blank' || blank ? 'noopener noreferrer' : ''}
        title={title ? title : !target && blank ? 'Open in New Tab' : ''}
      >
        {label || children}
      </a>
    </>
  );
}

export function P(props) {
  const {
    value,
    children,
    lead = false,
    shadow = false,
    center = false,
    right = false,
    justify = false,
    truncate = false,
    textBreak = false,
    lowercase = false,
    uppercase = false,
    capitalize = false,
    bold = false,
    bolder = false,
    normal = false,
    light = false,
    lighter = false,
    italic = false,
    monospace = false,
    muted = false,
    none = false,
    className = '',
    style = {},
  } = props;
  const classNames = [className];
  if (lead) classNames.push('lead');
  if (center) classNames.push('text-center');
  if (right) classNames.push('text-right');
  if (justify) classNames.push('text-justify');
  if (truncate) classNames.push('text-truncate');
  if (textBreak) classNames.push('text-break');
  if (lowercase) classNames.push('text-lowercase');
  if (uppercase) classNames.push('text-uppercase');
  if (capitalize) classNames.push('text-capitalize');
  if (bold) classNames.push('font-weight-bold');
  if (bolder) classNames.push('font-weight-bolder');
  if (normal) classNames.push('font-weight-normal');
  if (light) classNames.push('font-weight-light');
  if (lighter) classNames.push('font-weight-lighter');
  if (italic) classNames.push('font-italic');
  if (monospace) classNames.push('text-monospace');
  if (muted) classNames.push('text-muted');
  if (none) classNames.push('text-decoration-none');

  if (shadow) style.textShadow = `0 .25rem .75rem DarkSlateGray`;
  return (
    <p className={classNames.join(' ')} style={style}>
      {value || children}
    </p>
  );
}

export function Callout(props) {
  const { title = '', children, className = '', shadow = false } = props;
  const classNames = ['callout', className];
  Object.keys(props).forEach((e) => {
    if (
      [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'light',
        'dark',
      ].indexOf(e) >= 0
    ) {
      classNames.push(`callout-${e}`);
    }
  });
  if (shadow) {
    classNames.push('shadow');
  }
  return (
    <div className={classNames.join(' ')}>
      {typeof title === 'string' && title.length > 0 && <h5>{title}</h5>}
      <p>{children}</p>
    </div>
  );
}

export function Details(props) {
  const {
    className = '',
    lead = '',
    summary = '',
    open = false,
    children,
  } = props;
  return (
    <details open={open}>
      <summary>
        <span className={className}>{lead || summary}</span>
      </summary>
      {children}
    </details>
  );
}

export function Copyable(props) {
  const { children, className = '' } = props;
  const [value, setValue] = React.useState('');
  const [copied, setCopied] = React.useState(false);

  const el = React.useRef();
  const input = React.useRef();

  const copy = () => {
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        const { textContent } = current;
        if (textContent.length > 0) {
          const s = textContent.slice(0, -1);
          setValue(s);
        }
      }
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    copy();
  };

  React.useEffect(() => {
    if (typeof value === 'string' && value.length > 0) {
      if (typeof input === 'object' && input !== null) {
        const { current } = input;
        if (typeof current === 'object' && current !== null) {
          current.select();
          document.execCommand('copy');
          setValue('');
          setCopied(true);
        }
      }
    }
  }, [value]);

  const [left, setLeft] = React.useState(0);
  const [top, setTop] = React.useState(0);
  const [width, setWidth] = React.useState(10);
  const [active, setActive] = React.useState(false);

  const setXY = () => {
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        const left = current.offsetLeft;
        const top = current.offsetTop;
        const width = current.offsetWidth;

        setLeft(left);
        setTop(top - 20);
        setWidth(width);
      }
    }
  };

  React.useLayoutEffect(() => {
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        setXY();
        current.onmouseover = () => setActive(true);
        current.onmouseout = () => setActive(false);
      }
    }
  }, [el]);

  React.useEffect(() => {
    if (active) {
      setXY();
    } else {
      setCopied(false);
    }
  }, [active]);

  const style = { position: 'absolute', left, top, width };

  return (
    <span
      ref={el}
      className={`copyable ${className} ${active ? 'active' : ''}`}
    >
      {children}
      <button
        title="Copy"
        className={`btn btn-sm shadow ${active ? '' : 'd-none'} ${
          copied ? 'btn-secondary' : 'btn-primary'
        }`}
        onClick={handleClick}
        style={style}
      >
        {'\u25f3'}
        {copied && <span className="ml-2">Copied</span>}
      </button>
      <input
        ref={input}
        type="text"
        value={value}
        className={`${active ? '' : 'd-none'}`}
        readOnly
      />
    </span>
  );
}

export function Alert(props) {
  const {
    type = 'primary',
    value,
    className = '',
    shadow = false,
    children,
    onClick,
  } = props;
  const classNames = ['alert', className];
  if (typeof type === 'string' && type.length > 0)
    classNames.push(`alert-${type}`);
  if (shadow) classNames.push('shadow');
  function renderCloseButton() {
    if (typeof onClick === 'function') {
      return (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={onClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      );
    }
    return null;
  }
  if (typeof value === 'string' && value.length > 0) {
    return (
      <div className={classNames.join(' ')} role="alert">
        {value}
        {renderCloseButton()}
      </div>
    );
  }
  if (children) {
    return (
      <div className={classNames.join(' ')} role="alert">
        {children}
        {renderCloseButton()}
      </div>
    );
  }
  return null;
}

export function useWidth() {
  const [width, setWidth] = React.useState(
    window.innerWidth || document.documentElement.clientWidth,
  );
  React.useEffect(() => {
    function handleResize(e) {
      setWidth(e.target.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return width;
}
