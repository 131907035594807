import React from 'react';
import { P, A } from '../Util';

const Symbol = {
  x: '\u2573',
  o: '\u25ef',
};

function Box(props) {
  const {
    w = 50,
    h = 50,
    rounded = true,
    shadow = true,
    className = '',
    style = {},
    children,
  } = props;
  style.width = w;
  style.height = h;
  const classNames = [className];
  if (rounded) classNames.push('rounded');
  if (shadow) classNames.push('shadow');
  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

function Mark(props) {
  const { value = '', color, onClick, style = {} } = props;
  if (typeof color === 'string' && color.length > 0) {
    style.color = color;
  }
  const disabled = value !== '';
  return (
    <button
      className="w-100 h-100 border-none rounded font-weight-bold h2"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {value}
    </button>
  );
}

function Stat(props) {
  return (
    <div className="row">
      {Object.keys(props).map((k, i) => {
        const v = props[k];
        return (
          <div key={i} className="col nowrap">
            <div className="text-center p-1">
              <div className="h4">{v}</div>
              <div className="small text-uppercase">{k}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

const DEG = randInt(360);
const BG = [randColor(), randColor()];

export default function TicTacToe() {
  const [board, setBoard] = React.useState([
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ]);
  const [turn, setTurn] = React.useState(true);
  const [done, setDone] = React.useState(false);
  const [msg, setMsg] = React.useState('Play');
  const [stat, setStat] = React.useState({
    [Symbol.x]: 0,
    [Symbol.o]: 0,
    draw: 0,
  });

  function restart() {
    setBoard([
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
    ]);
    setTurn(true);
    setMsg('Play');
    setDone(false);
  }

  function mark(i, j) {
    if (done) return;
    const s = turn ? Symbol.x : Symbol.o;
    setBoard([
      ...board.slice(0, i),
      [...board[i].slice(0, j), s, ...board[i].slice(j + 1)],
      ...board.slice(i + 1),
    ]);
  }

  function check() {
    const s = turn ? Symbol.x : Symbol.o;
    const marked = [];
    board.forEach((rows, r) => {
      rows.forEach((e, c) => {
        if (e === s) marked.push({ y: r, x: c });
      });
    });

    if (marked.length === 0) return;

    const winning = isWinning(marked);

    if (winning) {
      setDone(true);
      setMsg(`${s} wins`);
      setStat({ ...stat, [s]: stat[s] + 1 });
    } else if (marked.length === 5) {
      setDone(true);
      setMsg("It's a draw");
      setStat({ ...stat, draw: stat.draw + 1 });
    } else {
      setTurn(!turn);
    }
  }

  function auto() {
    // get unmarked blocks
    const choices = [];
    board.forEach((rows, r) => {
      rows.forEach((e, c) => {
        if (e === '') choices.push({ r, c });
      });
    });

    // check which com should mark;
    // 1) check any could straightly win, or
    // 2) to prevent lose, or
    // 3) random
    let cIndex;
    for (let i in choices) {
      const entry = choices[i];

      // deep clone blocks for testing
      const copy = JSON.parse(JSON.stringify(board));
      copy[entry.r][entry.c] = Symbol.o;

      const cMarkedTmp = [];
      copy.forEach((rows, r) => {
        rows.forEach((val, c) => {
          if (val === Symbol.o) cMarkedTmp.push({ y: r, x: c });
        });
      });
      if (isWinning(cMarkedTmp)) {
        cIndex = i;
        break;
      }
    }

    // or prevent p1 from winning
    if (!cIndex) {
      for (let i in choices) {
        const entry = choices[i];

        // deep clone blocks for testing
        const copy = JSON.parse(JSON.stringify(board));
        copy[entry.r][entry.c] = Symbol.x;

        const cMarkedTmp = [];
        copy.forEach((rows, r) => {
          rows.forEach((val, c) => {
            if (val === Symbol.x) cMarkedTmp.push({ y: r, x: c });
          });
        });
        if (isWinning(cMarkedTmp)) {
          cIndex = i;
          break;
        }
      }
    }

    // or else set randomly
    if (!cIndex) cIndex = Math.floor(Math.random() * choices.length);

    // com marks
    // TODO: add machine learning to increase com playing level
    if (Array.isArray(choices) && choices.length > 0) {
      //board[choices[cIndex].r][choices[cIndex].c] = Symbol.o
      if (cIndex >= 0 && cIndex < choices.length) {
        const { r, c } = choices[cIndex];
        mark(r, c);
      }
    }
  }

  React.useEffect(() => {
    check();
    // eslint-disable-next-line
  }, [board]);

  React.useEffect(() => {
    if (!turn) auto();
    // eslint-disable-next-line
  }, [turn]);

  return (
    <div className="container">
      <FlexContainer bg={BG} deg={DEG}>
        <div className="p-3">
          <div className="text-center mb-3">
            <h2 className="text-shadow">{'\u{1f3b2}'} Tic Tac Toe</h2>
            <P className="text-white text-shadow">Tic Tac Toe in React Hooks</P>
            <A
              blank
              href="https://codepen.io/mjunaidi/pen/LYPvYjq"
              className=""
            >
              See the code
            </A>
          </div>

          <hr />

          <div className="h3 text-center mb-3">{msg}</div>
          <div className="mb-3">
            <Stat {...stat} />
          </div>
          <div className="mb-5">
            {board.map((e, i) => {
              return (
                <div key={i} className="row no-gutters">
                  {e.map((f, j) => {
                    const color = f === Symbol.x ? 'red' : 'blue';
                    return (
                      <div key={j} className="col-xs-4">
                        <Box className="m-2 bg-white">
                          <Mark
                            value={f}
                            onClick={() => mark(i, j)}
                            color={color}
                          />
                        </Box>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {done && (
            <div className="text-center">
              <button
                className="btn btn-primary btn-lg shadow"
                onClick={restart}
              >
                Restart
              </button>
            </div>
          )}
        </div>
      </FlexContainer>
    </div>
  );
}

function FlexContainer(props) {
  const { children, bg, deg = randInt(360), style = {} } = props;
  if (Array.isArray(bg) && bg.length > 0) {
    style.background = `linear-gradient(${deg}deg,${bg.join(',')})`;
  }
  return (
    <div
      className="d-flex align-content-center align-items-center justify-content-center flex-wrap w-100 v-100 vh-75 mb-5 rounded shadow-lg"
      style={style}
    >
      {children}
    </div>
  );
}

/* game utils */
function combinations(list, n) {
  if (n > list.length) return [];

  if (n === list.length) return [list];

  const combs = [];
  if (n === 1) {
    for (let i = 0; i < list.length; i++) {
      combs.push([list[i]]);
    }
    return combs;
  }

  let h;
  let t;
  for (let i = 0; i < list.length - n + 1; i++) {
    h = list.slice(i, i + 1);
    t = combinations(list.slice(i + 1), n - 1);
    for (let j = 0; j < t.length; j++) {
      combs.push(h.concat(t[j]));
    }
  }
  return combs;
}

function isInline(p) {
  return (
    [
      p[0].x * (p[1].y - p[2].y) +
        p[1].x * (p[2].y - p[0].y) +
        p[2].x * (p[0].y - p[1].y),
    ] /
      2 ===
    0
  );
}

function isWinning(marked) {
  const combs = combinations(marked, 3);
  const win = combs.some((c) => {
    return isInline(c);
  });
  return win;
}

/* utils */
function randInt(n = 10) {
  return Math.floor(Math.random() * n);
}
function randColor() {
  return `#${[...Array(3).keys()]
    .map((e) => (randInt(245) + 10).toString(16))
    .join('')}`;
}
