import React from 'react';
//import { useTranslation } from 'react-i18next';
import DynamicFormInputsEntry from './DynamicFormInputsEntry';

export default function DynamicFormInputs(props) {
  //const { t } = useTranslation();
  const { value, onChange } = props;

  if (typeof value === 'object' && value !== null) {
    return Object.entries(value).map(([k, v]) => (
      <DynamicFormInputsEntry
        key={k}
        k={k}
        v={v}
        value={value}
        onChange={onChange}
      />
    ));
  }

  return null;
}
