import React from 'react';
import { Container } from '../Util';
import TemplateEditor from '../json-editor/TemplateEditor';
import ContentEditor from '../json-editor/ContentEditor';
import '../json-editor/json-editor.css';

export default function JsonEditor(props) {
  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h3>{'\u{1f4dd}'} JSON Editor</h3>
        <p className="lead">JSON Editor written in React</p>
      </div>
      <hr />

      <Container gradient rounded shadow className="p-3 mb-3">
        <TemplateEditor />
      </Container>

      <Container gradient rounded shadow className="p-3">
        <ContentEditor />
      </Container>
    </div>
  );
}
