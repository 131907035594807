import React from 'react';
import { Switch, Route } from 'react-router-dom';
//import Home from './Home';
import Secret from './Secret';
//import About from './About';
import Home from '../home/Home';
import About from '../about/About';
import { HOME_PATHS } from '../home/HOME_PATHS';
import Navigator from './Navigator';
import NotFound from './NotFound';
import Slayte from '../slayte/Slayte';

export default function Main(props) {
  const [effect, setEffect] = React.useState('fadeInRight');

  return (
    <main role="main">
      {/*<Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
        <Breadcrumb.Item>App</Breadcrumb.Item>
      </Breadcrumb>*/}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/143">
          <Secret />
        </Route>

        <Route exact path="/slayte">
          <Slayte />
        </Route>

        <Route
          path="/unicode-explorer/:a"
          component={require('../page/UnicodeExplorer').default}
        />
        <Route
          path="/unicode/:q"
          component={require('../page/UnicodeLibrary').default}
        />
        <Route
          path="/gradient/:a/:b"
          component={require('../page/GradientColorPair').default}
        />

        {HOME_PATHS.map(({ path, component: C }) => (
          <Route key={path} exact path={path}>
            <div
              className={`animate__animated animate__faster animate__${effect}`}
            >
              <Navigator path={path} setEffect={setEffect} />
              <C />
            </div>
          </Route>
        ))}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
}
