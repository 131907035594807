import React from 'react';
import SlayteImage from './SlayteImage';

export default function SlayteWrapImages(props) {
  const { list = [], maxImageWidth } = props;
  return (
    <div className="SlayteWrapImages">
      {list.map((src, i) => (
        <SlayteImage src={src} width={maxImageWidth} />
      ))}
    </div>
  );
}
