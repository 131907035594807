import React from 'react';
import { randomBgGrandientDarkStyle, randomEffect } from '../Util';
import AnalogClock from '../tool/AnalogClock';

const World = {
  Australia: [
    { gmt: 11, name: 'Kingston' },
    { gmt: 10, name: 'Canberra ACT' },
    { gmt: 9.5, name: 'Adelaide' },
    { gmt: 8, name: 'Perth' },
    { gmt: 7, name: 'Christmas Island' },
  ],
  NewZealand: [{ gmt: 12, name: 'Wellington' }],
  Indonesia: [
    { gmt: 9, name: 'Jayapura' },
    { gmt: 8, name: 'Bali' },
    { gmt: 7, name: 'Jakarta' },
  ],
  China: [{ gmt: 8, name: 'China' }],
  Singapore: [{ gmt: 8, name: 'Singapore' }],
  Malaysia: [{ gmt: 8, name: 'Kuala Lumpur' }],
  India: [{ gmt: 5.5, name: 'India' }],
  France: [{ gmt: 2, name: 'France' }],
  UnitedKingdom: [{ gmt: 1, name: 'United Kingdom' }],
  Brazil: [
    { gmt: -2, name: 'Ilha de Fernando de Noronha' },
    { gmt: -3, name: 'Brasillia - Federal District' },
    { gmt: -3, name: 'Belém' },
    { gmt: -4, name: 'Manaus - State of Amazonas' },
    { gmt: -5, name: 'Rio Branco' },
  ],
  Canada: [
    { gmt: -2.5, name: 'Newfoundland' },
    { gmt: -3, name: 'Halifax, NS' },
    { gmt: -4, name: 'Ottawa, ON,' },
    { gmt: -5, name: 'Winnipeg' },
    { gmt: -6, name: 'Regina' },
    { gmt: -6, name: 'Edmonton' },
    { gmt: -7, name: 'Vancouver' },
  ],
  USA: [
    { gmt: -4, name: 'Washington, DC' },
    { gmt: -5, name: 'Chicago' },
    { gmt: -6, name: 'Denver' },
    { gmt: -7, name: 'Phoenix' },
    { gmt: -7, name: 'Los Angeles' },
    { gmt: -8, name: 'Anchorage' },
    { gmt: -10, name: 'Honolulu' },
  ],
};

// ref: https://apps.timwhitlock.info/emoji/tables/iso3166
const Flag = {
  Australia: '\u{1f1e6}\u{1f1fa}',
  NewZealand: '\u{1f1f3}\u{1f1ff}',
  Indonesia: '\u{1f1ee}\u{1f1e9}',
  China: '\u{1f1e8}\u{1f1f3}',
  Singapore: '\u{1f1f8}\u{1f1ec}',
  Malaysia: '\u{1f1f2}\u{1f1fe}',
  India: '\u{1f1ee}\u{1f1f3}',
  France: '\u{1f1eb}\u{1f1f7}',
  UnitedKingdom: '\u{1f1ec}\u{1f1e7}',
  Brazil: '\u{1f1e7}\u{1f1f7}',
  Canada: '\u{1f1e8}\u{1f1e6}',
  USA: '\u{1f1fa}\u{1f1f8}',
};

function toGMT(n) {
  if (typeof n === 'number') {
    if (n === 0) return 'GMT';
    if (n > 0) return `GMT+${n}`;
    return `GMT${n}`;
  }
  return 'GMT';
}

export default function ReactClock(props) {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  //const timezone = timezoneOffset>=0?`+${timezoneOffset}`:timezoneOffset
  const [effect] = React.useState(randomEffect());
  return (
    <div className="container-lg text-center">
      <h1>
        <span className="mr-3">{'\u23f0'}</span> React Analog Clock
      </h1>
      <p className="lead">React &times; SVG</p>

      <div
        className={`p-3 shadow rounded text-white mb-5 animated ${effect}`}
        style={{ background: randomBgGrandientDarkStyle() }}
      >
        <div className="row">
          <div className="col-sm-2 col-md-4"></div>
          <div className="col-sm-8 col-md-4">
            <div className={`animated ${effect} slow`}>
              <AnalogClock />
            </div>
            <h5>
              Local Time <small>(GMT{timezoneOffset})</small>
            </h5>
          </div>
          <div className="col-sm-2 col-md-4"></div>
        </div>
      </div>

      <h1 className="mb-3">World Clock</h1>

      {Object.keys(World).map((e, i) => {
        const list = World[e];
        return (
          <div
            key={i}
            className={`mb-5 p-3 shadow rounded text-white animated ${effect}`}
            style={{ background: randomBgGrandientDarkStyle() }}
          >
            <h3 className="mb-3">
              <span className="mr-3">{Flag[e]}</span>
              {e}
            </h3>
            <div className="row">
              {list.map((e, i) => {
                const { gmt, name, region } = e;
                return (
                  <div
                    key={i}
                    className="col-sm-3"
                    style={{ margin: '0 auto' }}
                  >
                    <AnalogClock timezone={gmt} />
                    <h5>
                      {name}{' '}
                      <small>
                        {region} ({toGMT(gmt)})
                      </small>
                    </h5>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
