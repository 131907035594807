import React from 'react';

export function Tabs(props) {
  const { children, pills = false } = props;

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (!Array.isArray(children)) {
    return <div>{children}</div>;
  }

  return (
    <div>
      <ul className={`nav nav-${pills ? 'pills' : 'tabs'}`}>
        {children.map((child, i) => (
          <li key={i} className={`nav-item`}>
            <button
              className={`nav-link ${selectedIndex === i ? 'active' : ''}`}
              onClick={(e) => setSelectedIndex(i)}
              style={{ outline: 0 }}
            >
              {child.props.name || `Tab #${i + 1}`}
            </button>
          </li>
        ))}
      </ul>

      {children.map((child, i) => (
        <div
          key={i}
          className={`p-3 ${selectedIndex === i ? 'd-block' : 'd-none'}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
}
