import React from 'react';
import SlayteFetcher from './SlayteFetcher';

export default class SlayteFetcherJSON extends SlayteFetcher {
  render() {
    return (
      <details open={this.props.open}>
        <summary>JSON</summary>
        <pre>
          <code>
            {JSON.stringify(this.state.json, null, this.props.depth || 0)}
          </code>
        </pre>
      </details>
    );
  }
}
