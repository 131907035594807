import React from 'react';
import * as he from 'he';
import { PreCode } from '../Util';

const HTML_VALUE = '<html><body><h1>TITLE</h1><p>LEAD</p></body></html>';

function htmlEncode(value) {
  return he.encode(value);
}

export default function HtmlEncoder(props) {
  const [value, setValue] = React.useState(
    localStorage.getItem('htmlEncoder__value') || HTML_VALUE,
  );

  React.useEffect(() => {
    localStorage.setItem('htmlEncoder__value', value);
  }, [value]);

  return (
    <div className="container-lg my-3">
      <h2 className="text-center mb-4">{'\u{1f4dd}'} Online HTML Encoder</h2>

      <div className="container bg-light p-3 rounded-lg shadow">
        <p className="lead text-center mb-3">Encode HTML string </p>

        <div className="mb-3">
          <div className="row no-gutters mb-3">
            <div className="col-6">
              <label>Enter HTML string</label>
              <textarea
                placeholder="Enter un-encoded HTML string to be encoded"
                className="form-control shadow-inset bg-light rounded-lg"
                //autoFocus={true}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{ height: 200 }}
              />
            </div>
            <div className="col-1 place-items-center">&#x2192;</div>
            <div className="col-5">
              <label>HTML Encoded</label>
              <PreCode>{htmlEncode(value)}</PreCode>
            </div>
          </div>

          <hr />

          <div>
            <h6>HTML Preview</h6>
            <iframe
              title="HTML Preview"
              srcDoc={value}
              width="100%"
              className="border rounded-lg shadow-sm bg-white"
              style={{ border: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
