import React from 'react';
import { randInt, P, Button, PreCode, Badge, todate } from '../Util';

function ColorSelector(props) {
  const { label = '', value, setValue, className = '' } = props;
  const style = {
    width: '5rem',
    height: '5rem',
    background: value,
    borderRadius: '.25rem',
    margin: '0 auto',
  };
  return (
    <div className={`form-group ${className}`}>
      {label && <label className="mr-3">{label}</label>}
      <div>
        <input
          type="color"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ margin: '0 auto' }}
          className="shadow mb-2"
        />
      </div>
      <div className="mb-2">{value}</div>
      {value && <div style={style} className="animated fadeIn shadow"></div>}
    </div>
  );
}

function randomColor() {
  return `#${new Array(3)
    .fill(0)
    .map((e) => {
      const h = randInt(255).toString(16);
      return h.length < 2 ? `0${h}` : h;
    })
    .join('')}`;
}

function ColorBox(props) {
  const { label = '', className = '', value = randomColor(), onClick } = props;
  const style = {
    minWidth: '5rem',
    minHeight: '5rem',
    background: value,
    borderRadius: '.25rem',
    margin: '0 auto',
  };
  if (typeof onClick === 'function') style.cursor = 'pointer';
  return (
    <div style={style} className={className} onClick={onClick}>
      <h1 className="text-white text-shadow mt-3">{label}</h1>
    </div>
  );
}

function nth(i) {
  if (typeof i === 'number' && i > 0) {
    const c = `${i}`.slice(-1);
    if (c === '1') return `${i}st`;
    if (c === '2') return `${i}nd`;
    if (c === '3') return `${i}rd`;
    return `${i}th`;
  }
  return '';
}

function Demo(props) {
  const [color, setColor] = React.useState();
  const [r, setR] = React.useState(randomColor());
  const [list, setList] = React.useState(
    new Array(6).fill(0).map((e) => randomColor()),
  );
  const [index, setIndex] = React.useState();

  return (
    <div className="container-fluid text-center">
      <h3>Basic</h3>

      <ColorSelector label="Choose a color" value={color} setValue={setColor} />

      <hr />

      <h3>Set a default value</h3>

      <Button
        light
        shadow
        sm
        onClick={(e) => setR(randomColor())}
        className="mb-3"
      >
        {'\u{1f3b2}'} Randomize
      </Button>
      <ColorSelector value={r} setValue={setR} />

      <hr />

      <h3>Updating list of colors</h3>
      <P>Choose a color to update</P>

      <div className="row mb-3">
        {list.map((e, i) => (
          <ColorBox
            key={i}
            value={e}
            className="col shadow"
            onClick={(e) => setIndex(i)}
            label={i + 1}
          />
        ))}
      </div>

      {typeof index === 'number' && index >= 0 && index < list.length && (
        <div className="animated fadeIn">
          <h5>Change the {nth(index + 1)} color</h5>
          <ColorSelector
            value={list[index]}
            setValue={(e) => {
              setList([...list.slice(0, index), e, ...list.slice(index + 1)]);
            }}
          />
          <Button light sm shadow onClick={(e) => setIndex(null)}>
            done
          </Button>
        </div>
      )}

      <div className="text-left mt-5">
        <PreCode>{list}</PreCode>
      </div>
    </div>
  );
}

export default function ColorSelectorApp(props) {
  return (
    <div className="container-lg">
      <div className="pt-2 text-center">
        <Badge dark pill shadow className="mr-2">
          React JS
        </Badge>
        <Badge info pill shadow className="mr-2">
          Bootstrap 4
        </Badge>
        <Badge light pill shadow>
          {todate()}
        </Badge>
        <h1>ColorSelector</h1>
        <P>Showing a preview box on select</P>
      </div>
      <hr />
      <Demo />
    </div>
  );
}
