import React from 'react';
import './slayte.css';
import SlayteImage from './SlayteImage';
import SlayteWrapImages from './SlayteWrapImages';
import SlayteFetcher from './SlayteFetcher';
import SlayteFetcherJSON from './SlayteFetcherJSON';
import SlayteFetcherBase64 from './SlayteFetcherBase64';
import { randInt } from '../Util';
import { Tabs, TabsPanel } from '../util/';

const IMAGES = [...Array(50).keys()].map((i) => {
  const w = randInt(250, 150);
  const h = randInt(250, 150);
  return `https://picsum.photos/${w}/${h}`;
});

const DATA_URL = '/manifest.json';

const URLS = [
  DATA_URL,
  '/data/glossary.json',
  '/data/menu.json',
  '/data/svg-menu.json',
  '/data/web-app.json',
  '/data/widget.json',
];

export default function Slayte(props) {
  const [url, setUrl] = React.useState(DATA_URL);
  const [depth, setDepth] = React.useState(2);

  return (
    <div className="container-lg py-4">
      <h1 className="mb-4">Slayte Interview Challenge</h1>

      <Tabs>
        <TabsPanel name="SlayteImage">
          <SlayteImage src={'https://picsum.photos/200/300'} />
          <SlayteImage src={'https://picsum.photos/200/300'} width={100} />
          <SlayteImage src={'https://picsum.photos/200/300'} height={200} />
          <SlayteImage
            src={'https://picsum.photos/200/300'}
            width={1024}
            height={1024}
          />
        </TabsPanel>
        <TabsPanel name="SlayteWrapImages">
          <SlayteWrapImages list={IMAGES} maxImageWidth={100} />
        </TabsPanel>
        <TabsPanel name="JSON Viewer">
          <div>
            <p>
              Build a base class that fetches a json resource at a remote
              location. Extend this base class with two classes, one that
              renders the response as stringified text with a prop `depth`
              (`JSON.stringify(object, null, depth`), the other that renders it
              as a base64 hash.
            </p>
            <div>
              {URLS.map((url) => (
                <button
                  key={url}
                  className="btn btn-light border shadow m-1"
                  onClick={(e) => setUrl(url)}
                >
                  {url}
                </button>
              ))}
            </div>
            <div className="py-3">
              URL<span className="mx-3">:</span>
              {url}
            </div>
            <div>
              <label htmlFor="depth">Depth</label>
              <input
                name="depth"
                type="range"
                className="mx-3"
                value={depth}
                onChange={(e) => setDepth(parseInt(e.target.value))}
              />
              {depth}
            </div>
            <SlayteFetcher url={url} />
            <SlayteFetcherJSON url={url} open depth={depth} />
            <SlayteFetcherBase64 url={url} open />
          </div>
        </TabsPanel>
      </Tabs>
    </div>
  );
}
