import React from 'react';
import COUNTRY_FLAGS from '../asset/unicode-flag.json';

function findFlagUnicodesByCountryCode(countryCode) {
  return COUNTRY_FLAGS.find(({ iso, code }) =>
    iso === countryCode ? code : null,
  );
}

export default function CountryFlag(props) {
  const { countryCode, className = '' } = props;
  const countryFlag = findFlagUnicodesByCountryCode(countryCode);
  if (!countryFlag) {
    return null;
  }
  return (
    <span className={className}>
      {String.fromCodePoint(...countryFlag.code)}
    </span>
  );
}
