import React from 'react';
import Lottie from './Lottie';
import AnimationData from './data';

/*const PATHS = [
  '/lottie/LoginBW.json',
  '/lottie/LoginColored.json',
  '/lottie/BloggingBW.json',
  '/lottie/BloggingColored.json',
  '/lottie/BodyMovin.json',
];*/

export default function LottieDemo(props) {
  return (
    <div className="container-lg mb-5">
      <h1 className="text-center">Lottie Demo</h1>

      <div className="py-8">
        <p className="lead text-center">
          <span className="mr-4">Animation from</span>
          <a
            href="https://www.pixeltrue.com/free-illustrations"
            target="_blank"
            rel="noreferrer noopener"
          >
            pixeltrue
          </a>
          <span className="mx-4">|</span>
          <a
            href="https://aescripts.com/bodymovin"
            target="_blank"
            rel="noreferrer noopener"
          >
            BodyMovin
          </a>
        </p>

        <p className="text-center">
          <span className="mr-4">Powered by</span>
          <a
            href="https://github.com/airbnb/lottie-web"
            target="_blank"
            rel="noreferrer noopener"
          >
            Lottie Web by Airbnb
          </a>
        </p>
      </div>

      <div className="row">
        {AnimationData.map((animationData, i) => (
          <div key={i} className="col-6">
            <div className="rounded-lg shadow-sm border mb-4">
              <Lottie animationData={animationData} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
