import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tick } from '../util/index';

import ANIMATION_DATA from '../lottie/data/57669-ketupat-bg-transparent.json';
import Lottie from '../lottie/Lottie';

const TICK_STYLE = {
  width: 36,
  height: 39,
  verticalAlign: 'bottom',
  marginBottom: 0,
};

const RAYA_2021_DATE = new Date(2021, 4, 12, 19, 20);

export default function Raya2021(props) {
  const [now, setNow] = React.useState(new Date());
  const [tick, setTick] = React.useState(true);
  const [value, setValue] = React.useState(99);
  const [targetDate] = React.useState(RAYA_2021_DATE);

  const { t } = useTranslation();

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      const now = new Date();
      setNow(now);
      setTick(!tick);

      setValue(value - 1);
    }, 1000); // use 1000 for 1-sec tick, smaller for smoother ticking
    return () => window.clearInterval(timer);
    // eslint-disable-next-line
  }, [tick]);

  const diff = targetDate - now;

  const days = parseInt(diff / (24 * 3.6e6));
  const hours = parseInt((diff - days * 24 * 3.6e6) / 3.6e6);
  const minutes = parseInt((diff - hours * 3.6e6 - days * 24 * 3.6e6) / 60000);
  const seconds = parseInt(
    (diff - hours * 3.6e6 - minutes * 60000 - days * 24 * 3.6e6) / 1000,
  );

  return (
    <div className="container-lg mb-5">
      <h3 className="text-secondary text-center animate__animated animate__fadeIn animate__faster animate__delay-1s">
        Countdown to Raya 2021
      </h3>

      <div className="text-center">
        <div>
          <h4 className="mb-1 animate__animated animate__fadeIn animate__faster animate__delay-1250ms">
            <small>
              Malam Raya di <b>Klang, Selangor</b>
            </small>
          </h4>
          <p className="mt-0 animate__animated animate__fadeIn animate__faster animate__delay-1500ms">
            <small>dan sewaktu dengannya</small>
          </p>
        </div>

        <p className="animate__animated animate__fadeInDown animate__faster animate__delay-1750ms">
          <span>{moment(targetDate).format('D MMMM Y h:mm A')}</span>
        </p>

        <div
          className="d-flex flex-row justify-content-center bg-dark text-white mb-3 mx-auto shadow animate__animated animate__flipInX animate__faster animate__delay-2500ms"
          style={{ maxWidth: 320 }}
        >
          <div className="p-1 text-center bg-dark rounded">
            <div className="d-inline-block text-center mx-3">
              <Tick value={days} className="h1 text-shine" style={TICK_STYLE} />
              <small>{t('days')}</small>
            </div>
          </div>
          <div className="p-1 text-center bg-dark">
            <div className="d-inline-block text-center mx-3">
              <Tick
                value={hours}
                className="h1 text-shine"
                style={TICK_STYLE}
              />
              <small>{t('hours')}</small>
            </div>
          </div>
          <div className="p-1 text-center bg-dark">
            <div className="d-inline-block text-center mx-3">
              <Tick
                value={minutes}
                className="h1 text-shine"
                style={TICK_STYLE}
              />
              <small>{t('minutes')}</small>
            </div>
          </div>
          <div className="p-1 text-center bg-dark rounded">
            <div className="d-inline-block text-center mx-3">
              <Tick
                value={seconds}
                className="h1 text-shine"
                style={TICK_STYLE}
              />
              <small>{t('seconds')}</small>
            </div>
          </div>
        </div>

        <div
          className="mx-auto mb-3 animate__animated animate__bounceInUp animate__slow animate__delay-2500ms"
          style={{ maxWidth: 240 }}
        >
          <Lottie animationData={ANIMATION_DATA} />
        </div>

        <div className="animate__animated animate__fadeIn animate__faster animate__delay-4s">
          <p className="mb-1">
            <small>
              {t('Check iftar and imsak time for Malaysia and Singapore')}
            </small>
          </p>
          <a
            href="https://puasa.mjunaidi.com"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-light p-1 pr-3 shadow-sm rounded-pill animate__animated animate__bounceIn animate__faster animate__delay-4250ms"
          >
            <img
              src="/img/app-icon/iftar.png"
              alt=""
              style={{ width: 32, height: 32 }}
              className="mr-2"
            />
            <span
              data-tootik={t(`Open in new tab`)}
              className="font-weight-bold"
            >
              Ramadhan Time Table 2021
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
