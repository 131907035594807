import React from 'react';

export default class SlayteFetcher extends React.Component {
  state = {};

  async componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    try {
      const response = await fetch(this.props.url);
      const json = await response.json();
      this.setState({ json });
    } catch (error) {
      console.warn(error);
    }
  }

  render() {
    return null;
  }
}
