import React from 'react';
import { Button, Color, Details, animated, randomEffect } from '../Util';

const randInt = (n = 10, m = 0) => {
  return Math.floor(Math.random() * n) + m;
};

const randColor = () => {
  const rgb = new Array(3).fill(0).map((e) => randInt(256));
  return '#'.concat(
    rgb
      .map((e) => {
        const hex = e.toString(16);
        if (hex.length === 1) {
          return `0${hex}`;
        }
        return hex;
      })
      .join(''),
  );
};

const formats = ['', '#', 'rgba', 'rgb', 'hsl', 'hsla'];

class ColorBox extends React.Component {
  state = {};
  componenetDidMount() {
    this.setState({ format: '' });
  }
  onChange = (event) => {
    const value = event.target.value;
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };
  fromValue() {
    const { value } = this.props;
    if (value instanceof Color) {
      return value.toString();
    }
    return value;
  }
  toggleFormat = (event) => {
    this.setState((prevState, props) => {
      const { format } = prevState;
      const index = formats.findIndex((e) => format === e);
      if (index + 1 >= formats.length) {
        return { format: formats[0] };
      }
      return { format: formats[index + 1] };
    });
  };
  randomize = () => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      const color = randColor();
      onChange(color);
    }
  };
  render() {
    const { value, className, effect } = this.props;
    if (value instanceof Color) {
      const { format } = this.state;
      const backgroundColor = value.toString();
      const lumin = value.luminance();
      const color = lumin.toString();
      const textShadow = `${lumin.luminance().toString('#')} 1px 0 5px`;
      const inversed = value.inverse();
      return (
        <div
          className={`box ${className || ''} ${animated(effect)}`}
          style={{ backgroundColor, color, textShadow }}
        >
          <span className="mr-3">
            <input
              title="Change this color!"
              type="color"
              value={value.toString('#')}
              onChange={this.onChange}
            />
          </span>
          <span className="mr-3">{value.toString(format)}</span>
          <div>
            <Button
              right
              title="Change format"
              className="btn btn-link"
              onClick={this.toggleFormat}
            >
              {'\u261d'}
            </Button>
          </div>
          <div>
            <button
              title="Random color"
              className="btn btn-secondary shadow"
              style={{
                backgroundColor: inversed.toString(),
                color: inversed.luminance().toString(),
              }}
              onClick={this.randomize}
            >
              {'\u21bb'}
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default class ColorDemo extends React.Component {
  state = {};
  componentDidMount() {
    const color = new Color(randColor());
    const list = [
      color,
      Color.white,
      Color.white.inverse(),
      Color.black,
      Color.black.inverse(),
      new Color(
        `rgba(${new Array(3)
          .fill(0)
          .map((e) => randInt())
          .join(',')},1.0)`,
      ),
    ];
    const effect = randomEffect();
    this.setState({ color, list, effect });
  }
  handleChange(value, index, inv = false) {
    this.setState((prevState, props) => {
      if (typeof value === 'string' && value.length > 0) {
        const { list } = this.state;
        if (typeof index === 'number' && index >= 0 && index < list.length) {
          const e = list[index];
          if (e.toString('#') !== value) {
            const color = new Color(value);
            list[index] = inv ? color.inverse() : color;
            return { list };
          }
        }
      }
      return null;
    });
  }
  renderList() {
    const { list, effect } = this.state;
    if (Array.isArray(list) && list.length > 0) {
      return list.map((e, i) => (
        <div key={i} className="col-md-4 col-sm-6 mb-3">
          <ColorBox
            key={i}
            value={e}
            effect={effect}
            className="p-3 rounded shadow text-center mb-3"
            onChange={(value) => {
              this.handleChange(value, i);
            }}
          />
        </div>
      ));
    }
    return null;
  }
  render() {
    return (
      <div className="container-lg mb-5">
        <h1 className="text-center">Color class in JavaScript</h1>
        <p className="lead text-center">Built-in with inverse & luminance</p>
        <p className="lead text-center">
          Luminance is demonstrated with the font color. It is calculated based
          on the background color. If the background color is dark, then its
          luminance is white, and if the background color is light, then its
          luminance is black. The goal is to allow us to change the background
          into any color, and the font color will automatically change to black
          or white, still be readable.
        </p>
        <p className="lead text-center">
          The inversed color is the button color
        </p>
        <div className="row">{this.renderList()}</div>
        <Details lead="See the Code" className="h3">
          <iframe
            height="464"
            style={{ width: '100%' }}
            scrolling="no"
            title="ReactJS: class Color (built-in with inverse, luminance)"
            src="//codepen.io/mjunaidi/embed/ZVQxRx/?height=464&theme-id=0&default-tab=js"
            frameBorder="no"
            allowtransparency="true"
            allowFullScreen
          >
            See the Pen{' '}
            <a href="https://codepen.io/mjunaidi/pen/ZVQxRx/">
              ReactJS: class Color (built-in with inverse, luminance)
            </a>{' '}
            by M Junaidi (<a href="https://codepen.io/mjunaidi">@mjunaidi</a>)
            on <a href="https://codepen.io">CodePen</a>.
          </iframe>
        </Details>
      </div>
    );
  }
}
