import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorPaletteEntry from './ColorPaletteEntry';
import { COLOR_PALETTE } from './COLOR_PALETTE';

const PALETTES = Object.keys(COLOR_PALETTE);

export default function ColorPalette(props) {
  const { t } = useTranslation();
  return (
    <div className="container-lg">
      <h1 className="text-center mb-4">{t('Color Palette')}</h1>

      {PALETTES.map((name) => (
        <ColorPaletteEntry key={name} name={name} {...COLOR_PALETTE[name]} />
      ))}
    </div>
  );
}
