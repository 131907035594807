import React from 'react';
import {
  Container,
  Row,
  Col,
  Badge,
  Input,
  Details,
  Button,
  PreCode,
} from '../Util';

// reference: https://www.billboard.com/articles/news/list/8459289/best-songs-of-2018-top-50
const sample = `
1. Cardi B feat. Bad Bunny & J Balvin, "I Like It"
1. Drake, "Nice for What"
1. Ariana Grande, "No Tears Left to Cry"
1. Childish Gambino, "This Is America"
1. Kendrick Lamar & SZA, "All the Stars"
1. Troye Sivan, "My My My!"
1. Zedd, Maren Morris & Grey, "The Middle"
1. Janelle Monáe, "Make Me Feel"
1. King Princess, "1950"
1. Calvin Harris & Dua Lipa, "One Kiss"
1. Kacey Musgraves, "High Horse"
1. Kim Petras, "Heart to Break"
1. Bruno Mars & Cardi B, "Finesse"
1. Ella Mai, "Boo'd Up"
1. Drake, "God's Plan"
1. Migos, "Stir Fry"
1. Kali Uchis feat. Tyler the Creator & Bootsy Collins, "After the Storm"
1. Charlie Puth feat. Kehlani, "Done for Me"
1. Camila Cabello, "Never Be the Same"
1. James Bay, "Pink Lemonade"
1. BTS, "Fake Love"
1. Shawn Mendes, "Lost in Japan"
1. Nicky Jam & J Balvin, "X"
1. Rae Sremmurd & Juicy J, "Powerglide"
1. Azealia Banks, "Anna Wintour"
1. Marshmello & Anne-Marie, "Friends"
1. John Mayer, "New Light"
1. Cardi B, "Be Careful"
1. Christine and the Queens feat. Dâm-Funk, "Girlfriend"
1. Bazzi, "Mine"
1. 6lack, Khalid & Ty Dolla $ign, "OTW"
1. Kendrick Lamar, Jay Rock, Future & James Blake, "King's Dead"
1. Parquet Courts, "Wide Awake"
1. Ashley McBryde, "A Little Dive Bar in Dahlonega"
1. Nicki Minaj, "Chun-Li"
1. Hayley Kiyoko, "Curious"
1. Jessie Reyez, "Body Count"
1. Florence + the Machine, "Hunger"
1. Leon Bridges, "Bad Bad News"
1. Khalid & Normani, "Love Lies"
1. A$AP Rocky feat. Moby, "A$AP Forever"
1. The Weeknd, "Call Out My Name"
1. Arctic Monkeys, "Four Out of Five"
1. Casper Magico, Nio Garcia, Darell, Nicky Jam, Ozuna & Bad Bunny, "Te Boté"
1. Jordan Davis, "Singles You Up"
1. BlocBoy JB feat. Drake, "Look Alive"
1. Snail Mail, "Pristine"
1. SAINt JHN, "I Heard You Got Too Litt Last Night"
1. Jade Bird, "Lottery"
1. Justin Timberlake feat. Chris Stapleton, "Say Something"
`;

function abcCountOf(s) {
  if (typeof s === 'string' && s.length > 0) {
    let n = 0;
    const abc = 'abcdefghijklmnopqrstuvwxyz';
    const ABC = abc.toUpperCase();
    const alphabets = `${abc}${ABC}`;
    for (let i = 0; i < s.length; i++) {
      if (alphabets.indexOf(s.charAt(i)) >= 0) n++;
    }
    return n;
  }
  return 0;
}

export default function TextProcessor(props) {
  const [value, setValue] = React.useState(sample.trim());
  const [out, setOut] = React.useState('');

  const handleChange = (e) => setValue(e.target.value);

  const process = () => {
    const list = value
      .split('\n')
      .map((e, i) => {
        const s = e.slice(3);
        const artist = s.slice(0, s.indexOf(', "'));
        const name = s.slice(s.indexOf('"') + 1, s.lastIndexOf('"'));
        const count = abcCountOf(name);
        return {
          i,
          artist,
          name,
          count,
        };
      })
      .filter((e) => e.count > 0);
    setOut({ size: list.length, list });
  };

  React.useEffect(() => {
    process();
    // eslint-disable-next-line
  }, [value]);

  const el = React.useRef();
  const copy = (e) => {
    e.preventDefault();
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        current.select();
        //current.setSelectionRange(0,value.length)
        document.execCommand('copy');
      }
    }
  };

  const lineCount = value.split('\n').length;
  const rows = Math.max(Math.min(lineCount, 14), 7);

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f488}'} Text Processor</h1>
        <p className="lead">
          Processing text into another form of structured data
        </p>
      </div>
      <hr />

      <Row>
        <Col s="6">
          <Container gradient dark rounded shadow className="p-3">
            <small className="float-right">
              Length <Badge info>{value.length}</Badge>
              <br /># of Line <Badge info>{lineCount}</Badge>
            </small>
            <h3>Input</h3>
            <Input textarea rows={rows} onChange={handleChange} value={value} />
          </Container>
        </Col>
        <Col s="6">
          {value && (
            <Container gradient rounded shadow className="mb-3 p-3">
              <Details lead="See Input">
                <PreCode className="none mt-3">{value}</PreCode>
              </Details>
            </Container>
          )}

          <Container gradient dark rounded shadow className="p-3 mb-3">
            <h5>Output</h5>
            <Button light shadow sm block onClick={copy} className="mb-2">
              {'\u{1f4cb}'} Copy to Clipboard
            </Button>
            <textarea
              ref={el}
              className="form-control"
              value={JSON.stringify(out)}
              rows={1}
              readOnly
            />
            <Badge dark pill>
              read-only
            </Badge>
          </Container>

          <PreCode className="font-sm">{out}</PreCode>
        </Col>
      </Row>
    </div>
  );
}
