import React from 'react';
import { COLOR, Badge, Button, randomEffect } from '../Util';
import RandomColorBox, { randomColor } from './RandomColorBox';

export default function RandomColor(props) {
  const [effect, setEffect] = React.useState(randomEffect());
  const [loading, setLoading] = React.useState(false);

  const reload = (e) => {
    e.preventDefault();
    setEffect(randomEffect());
    setLoading(true);
  };

  React.useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 0);
    }
  }, [loading]);

  const [group, setGroup] = React.useState('');

  /*const selectGroup=e=>{
    const {value} = e.target
    if (typeof(value)==='string') {
      setGroup(value)
    }
  }*/

  return (
    <div className="container-lg">
      <div className="pt-2 text-center">
        <h1>Random Colors</h1>
        <p className="lead">Random web colors</p>
        <Button primary lg shadow className="mr-3 mb-3" onClick={reload}>
          {/*'\u27F3'*/} &#x27F3; Reload
        </Button>

        {/*
        <div className="text-center">
          <div className="form-group">
            <label>Color group <Badge light pill shadow>{group}</Badge></label>
            <select className="form-control shadow" value={group} onChange={selectGroup}>
              <option value="">All</option>
              {
                Object.keys(COLOR).map((e,i)=>(
                  <option key={i}>{e}</option>
                ))
              }
            </select>
          </div>
        </div>
        */}

        <div className="mb-4">
          <div>
            <label>
              Color group{' '}
              <Badge light pill shadow>
                {group}
              </Badge>
            </label>
          </div>
          <Button
            sm
            shadow
            className={`${group === '' ? 'btn-dark' : 'btn-light'} m-1`}
            onClick={(e) => setGroup('')}
          >
            All
          </Button>
          {Object.keys(COLOR).map((e, i) => {
            //const style = {}
            const handleClick = (evt) => {
              evt.preventDefault();
              setGroup(e);
            };
            const el = {};
            if (group === e) el.dark = true;
            else el.light = true;
            return (
              <Button
                key={i}
                {...el}
                sm
                shadow
                className="m-1"
                onClick={handleClick}
              >
                {e}
              </Button>
            );
          })}
        </div>
      </div>

      <div>
        {!loading && (
          <div className="text-center">
            {new Array(100).fill(0).map((e, i) => {
              const color = randomColor(group);
              const el = {};
              el[color] = true;
              return (
                <RandomColorBox
                  key={i}
                  {...el}
                  className={`d-inline-block p-3 shadow animated ${effect} faster`}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
