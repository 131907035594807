import React from 'react';
import { Link } from 'react-router-dom';

export default function HomeListEntry(props) {
  const { icon, name, description, path } = props;
  return (
    <Link to={path}>
      <div className="HomeListEntry rounded-lg shadow h-100 shadow-hover">
        <div className="row no-gutters">
          <div className="col-2">{icon}</div>
          <div className="col-10 font-weight-bold">{name}</div>
        </div>
        <p className="mb-0 pt-2">{description}</p>
      </div>
    </Link>
  );
}
