import React from 'react';
import {
  lipsum,
  Button,
  Details,
  P,
  randomBgGrandientDarkStyle,
  randomEffect,
} from '../Util';

export default function Todo(props) {
  const [effect] = React.useState(randomEffect());

  const [list, setList] = React.useState(
    new Array(3).fill(0).map((e) => lipsum(5)),
  );
  const [value, setValue] = React.useState('');

  const add = (e) => {
    e.preventDefault();
    if (typeof value === 'string' && value.length > 0) {
      setList([...list, value]);
      setValue('');
    }
  };

  const remove = (i) => {
    if (typeof i === 'number' && i >= 0 && i < list.length) {
      setList([...list.slice(0, i), ...list.slice(i + 1)]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const [style, setStyle] = React.useState({
    background: randomBgGrandientDarkStyle(),
  });

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h3>{'\u{1f4dd}'} Super Simple TODO List with React Hooks</h3>
        <p className="lead">React Hooks implementation of TODO list</p>
        <a
          target="_blank"
          href="https://codepen.io/mjunaidi/pen/JqLKgq"
          className="lead"
          title="Open in New Tab"
          data-balloon="Open in New Tab"
          data-balloon-pos="up"
          rel="noopener noreferrer"
        >
          See the Code
        </a>
      </div>
      <hr />

      <div
        className="todo container pb-5 shadow-lg text-white rounded mb-5"
        style={style}
      >
        <Button
          lg
          className="float-right"
          title="Randomize Background Color"
          style={{
            outline: 'none',
            boxShadow: 'none',
            margin: 0,
            padding: '.375rem 0',
          }}
          onClick={(e) =>
            setStyle({ background: randomBgGrandientDarkStyle() })
          }
        >
          {/*'\u{1f3b2}'*/}
          {'\u{1f3a8}'}
          {/*'\u{1f5bc}'*/}
        </Button>

        <h3 className="text-center text-shine pt-5 mb-5">TODO</h3>
        {list.length === 0 && (
          <h5 className="text-center text-warning mb-3">Empty</h5>
        )}
        <ol className="mb-3">
          {list.map((e, i) => {
            return (
              <li key={i} className={`mb-3 animated ${effect} faster`}>
                <div className="row">
                  <div className="col-9 lead">{e}</div>
                  <div className="col-3 text-center">
                    <Button
                      danger
                      sm
                      shadow
                      onClick={(e) => remove(i)}
                      className="btn-remove font-weight-bold"
                    >
                      {'\u2715'}
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
        <Details lead="Preview">
          <P>{value}</P>
        </Details>
        <form onSubmit={add}>
          <div className="input-group mb-3">
            <input
              type="text"
              value={value}
              className="form-control shadow-inset"
              onChange={handleChange}
            />
            <div className="input-group-append">
              <Button light outline submit shadow>
                + Add
              </Button>
            </div>
          </div>
          <P>
            <small className="text-center form-text">
              Simply press <kbd>enter</kbd> or <kbd>return</kbd> on done to add.
            </small>
          </P>
        </form>
      </div>

      <Details lead="See the Code" className="lead">
        {/*<p className="codepen" data-height="265" data-theme-id="0" data-default-tab="js,result" data-user="mjunaidi" data-slug-hash="JqLKgq" style={{
          height: 265, boxSizing: 'border-box', display: 'flex', alignIitems: 'center', justifyContent: 'center', border: '2px solid', margin: '1em 0', padding: '1em',
        }} data-pen-title="ReactJS: Super Simple TODO List with React Hooks">
          <span>See the Pen <a href="https://codepen.io/mjunaidi/pen/JqLKgq/">
          ReactJS: Super Simple TODO List with React Hooks</a> by M Junaidi (<a href="https://codepen.io/mjunaidi">@mjunaidi</a>)
          on <a href="https://codepen.io">CodePen</a>.</span>
        </p>
        <script async src="https://static.codepen.io/assets/embed/ei.js"></script>*/}
        {/*<iframe height="265" style={{width:'100%'}} scrolling="no" title="ReactJS: Super Simple TODO List with React Hooks" src="//codepen.io/mjunaidi/embed/JqLKgq/?height=265&theme-id=0&default-tab=js,result" frameborder="no" allowtransparency="true" allowfullscreen="true">
          See the Pen <a href='https://codepen.io/mjunaidi/pen/JqLKgq/'>ReactJS: Super Simple TODO List with React Hooks</a> by M Junaidi
          (<a href='https://codepen.io/mjunaidi'>@mjunaidi</a>) on <a href='https://codepen.io'>CodePen</a>.
        </iframe>*/}
        <iframe
          height="512"
          style={{ width: '100%' }}
          scrolling="no"
          title="ReactJS: Super Simple TODO List with React Hooks"
          src="//codepen.io/mjunaidi/embed/JqLKgq/?height=512&theme-id=0&default-tab=js"
          frameBorder="no"
          allowtransparency="true"
          allowFullScreen={true}
        >
          See the Pen{' '}
          <a href="https://codepen.io/mjunaidi/pen/JqLKgq/">
            ReactJS: Super Simple TODO List with React Hooks
          </a>{' '}
          by M Junaidi (<a href="https://codepen.io/mjunaidi">@mjunaidi</a>) on{' '}
          <a href="https://codepen.io">CodePen</a>.
        </iframe>
      </Details>
    </div>
  );
}
