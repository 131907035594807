import React from 'react';
import {
  randColor2,
  randInt,
  PreCode,
  lipsum,
  Badge,
  todate,
  Button,
} from '../Util';

const FONTS = [
  'Zapfino',
  'Noteworthy',
  'Bodoni Ornaments',
  'Chalkduster',
  'Snell Roundhand',
  'Futura',
  'Heiti SC',
];

function Glow(props) {
  const {
    children,
    color = 'white',
    size = 2,
    font = FONTS[0],
    a = randColor2(),
    b = randColor2(),
    viewCss = false,
  } = props;
  const id = `e-${new Array(6)
    .fill(0)
    .map((e) => randInt(10))
    .join('')}`;
  const name = `${id}-e`;
  const fontSize = `${size}rem`;
  const unit = size / 8;
  const from = new Array(7)
    .fill(0)
    .map((e, i) => `0 0 ${unit * (i + 1)}rem ${i < 2 ? color : a}`)
    .join(',');
  const to = new Array(7)
    .fill(0)
    .map((e, i) => `0 0 ${unit * (i + 2)}rem ${i < 1 ? color : b}`)
    .join(',');
  const style = `
#${id} {
  font-size: ${fontSize};
  font-family: ${font};
  color: ${color};
  text-align: center;
  -webkit-animation: ${name} 1s ease-in-out infinite alternate;
  -moz-animation: ${name} 1s ease-in-out infinite alternate;
  animation: ${name} 1s ease-in-out infinite alternate;
}

@-webkit-keyframes ${name} {
  from {
    text-shadow: ${from};
  }
  to {
    text-shadow: ${to};
  }
}
`;
  const css = style
    .replace(new RegExp(name, 'ig'), 'glow')
    .replace(new RegExp(`#${id}`, 'ig'), '.glow');
  return (
    <>
      <style>{style}</style>
      <div id={id}>{children}</div>
      {viewCss && (
        <div className="mt-5">
          <h5 className="text-center">CSS</h5>
          <PreCode>{css}</PreCode>
        </div>
      )}
    </>
  );
}

export default function GlowingText(props) {
  const [value, setValue] = React.useState(lipsum(5));
  const [load, setLoad] = React.useState(true);
  const [a, setA] = React.useState(randColor2());
  const [b, setB] = React.useState(randColor2());
  const [size, setSize] = React.useState(2);
  const [font, setFont] = React.useState(FONTS[0]);

  React.useEffect(() => {
    if (!load) setLoad(true);
  }, [load]);

  const resize = (n = 1) => {
    const s = size + n;
    if (s >= 0 && s <= 10) setSize(s);
  };

  const randomizeColors = (e) => {
    setA(randColor2());
    setB(randColor2());
  };

  return (
    <div className="container-lg">
      <div className="pt-2 text-center">
        <Badge dark pill shadow className="mr-2">
          React JS
        </Badge>
        <Badge danger pill shadow className="mr-2">
          CSS
        </Badge>
        <Badge info pill shadow className="mr-2">
          Bootstrap 4
        </Badge>
        <Badge light pill shadow>
          {todate()}
        </Badge>
        <h4>Text with Glowing Effect</h4>
        <p>
          The glowing effect is made up of two colors. The effect size is
          growing proportionately by the font size.
        </p>
      </div>

      <div className="row pl-2 pr-2">
        <Button
          light
          shadow
          sm
          onClick={randomizeColors}
          className="col m-1"
          title="Randomize colors"
        >
          {'\u{1f3b2}'} Colors
        </Button>

        <Button
          light
          shadow
          sm
          onClick={(e) => resize(0.25)}
          disabled={size >= 9.75}
          className="col m-1"
          title="Increase font size"
        >
          {'\u2191'} Bigger
        </Button>

        <Button
          light
          shadow
          sm
          onClick={(e) => resize(-0.25)}
          disabled={size <= 0.25}
          className="col m-1"
          title="Decrease font size"
        >
          {'\u2193'} Smaller
        </Button>

        <select
          value={font}
          onChange={(e) => setFont(e.target.value)}
          className="col custom-select custom-select-sm shadow m-1"
          title="Change font"
          data-balloon="Change font"
          data-balloon-pos="up"
        >
          {FONTS.map((e, i) => (
            <option key={i}>{e}</option>
          ))}
        </select>
      </div>

      <div className="form-group text-center mb-5 pb-3">
        <label>change text</label>
        <div className="input-group">
          <input
            className="form-control shadow"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="input-group-append">
            <Button
              light
              shadow
              sm
              onClick={(e) => setValue(lipsum(5))}
              title="Randomize text"
            >
              {'\u{1f3b2}'} Randomize
            </Button>
          </div>
        </div>
      </div>

      <Glow viewCss size={size} font={font} a={a} b={b}>
        {value}
      </Glow>
    </div>
  );
}
