import React from 'react';

export default function CurrencyListCurrencies(props) {
  const { currencies } = props;

  if (typeof currencies === 'object' && currencies !== null) {
    const currencyCodes = Object.keys(currencies);
    return currencyCodes.map((currencyCode) => {
      const { name, symbol } = currencies[currencyCode];
      return (
        <div key={currencyCode} className="mr-3 d-inline-block">
          <small className="font-weight-bold mr-1">{currencyCode}</small>
          <span className="badge badge-success px-2 mr-1">{symbol}</span>
          <small>{name}</small>
        </div>
      );
    });
  }

  return null;
}
