import React from 'react';
import { useTranslation } from 'react-i18next';

export default function BootstrapIcons(props) {
  const { t } = useTranslation();
  return (
    <div className="container-lg">
      <div className="text-center mb-4">
        <h1>{t('Bootstrap Icons')}</h1>
        <a
          href="https://icons.getbootstrap.com"
          target="_blank"
          rel="noreferrer noopener"
          className="small"
        >
          {t('Reference')}
        </a>
      </div>

      <div className="p-4 bg-white rounded shadow-sm mb-4">
        <h3>{t('Install')}</h3>

        <pre className="p-3 rounded bg-secondary text-white">
          <code>{`yarn add bootstrap-icons`}</code>
        </pre>
      </div>

      <div className="p-4 bg-white rounded shadow-sm mb-4">
        <h3>{t('Usage')}</h3>

        <div className="row mb-4">
          <div className="col-8">
            <h5>{t('Embedded')}</h5>
            <pre
              className="p-3 bg-dark text-light rounded"
              style={{ whiteSpace: 'break-spaces' }}
            >
              <code>{`<svg class="bi bi-chevron-right" width="32" height="32" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"/></svg>
`}</code>
            </pre>
          </div>
          <div className="col-4 text-center">
            <svg
              class="bi bi-chevron-right"
              width="32"
              height="32"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
