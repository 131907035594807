import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
//import { lipsum } from '../Util';

const STYLE = `
#TextareaAutosize textarea {
  -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  box-shadow: none!important;
  border: none!important;
  background: inherit;
  padding: 0 !important;
  margin: 0 !important;
  resize: none;
  text-align: inherit !important;
  font-weight: inherit !important;
  vertical-align: bottom;
}
`;

//const VALUE =
//  'Gravida iaculis porttitor finibus vitae id himenaeos sociosqu magna taciti';

export default function AutoResizeTextarea(props) {
  const [title, setTitle] = React.useState('Auto Resize HTML Textarea');
  const [value, setValue] = React.useState();
  //const [value, setValue] = React.useState(lipsum());
  //const [value, setValue] = React.useState(VALUE);

  function handleKeyDown(e) {
    // 13: enter, 27: esc
    switch (e.keyCode) {
      case 13:
      case 27:
        e.target.blur();
        break;
      default:
    }
  }

  return (
    <div id="TextareaAutosize" className="container-lg my-3">
      <style>{STYLE}</style>

      <h2 className="text-center mb-4">
        <div className="row">
          <div className="col-3 text-right">
            <span className="mr-2">{'\u{1f4dd}'}</span>
          </div>
          <div className="col-9 text-left">
            <TextareaAutosize
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className=""
              placeholder="Click to edit"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </h2>

      <p className="text-center lead">
        <span className="badge badge-primary badge-pill">textarea</span> is
        styled the same for visual comparison purpose
      </p>

      <p className="text-center lead">
        Lose focus on <kbd>Enter</kbd> or <kbd>Esc</kbd> is pressed
      </p>

      <div className="container bg-light p-3 rounded-lg shadow">
        <div className="form-row">
          <div className="col-6">
            <h6 className="text-center">Editable textarea is directly below</h6>
          </div>
          <div className="col-6 text-right">
            <small className="text-secondary">
              * String displayed on the right and below the textarea are for
              size comparison purpose
            </small>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-6">
            <div className="bg-white">
              <TextareaAutosize
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className=""
                placeholder="Click to edit"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="bg-white">{value}</div>
          </div>
          <div className="col-6">
            <div className="bg-white">{value}</div>
          </div>

          <div className="col-6"></div>
          <div className="col-12">
            <hr />
          </div>

          <div className="col-6">
            <h6 className="text-center">Editable textarea is directly below</h6>
          </div>
          <div className="col-6 text-right">
            <small className="text-secondary">
              * To demonstrate the style such as text alignment and font weight
              can be inherited
            </small>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-6">
            <div className="bg-white text-center font-weight-bold">
              <TextareaAutosize
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className=""
                placeholder="Click to edit"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="bg-white text-center font-weight-bold">{value}</div>
          </div>
          <div className="col-6">
            <div className="bg-white text-center font-weight-bold">{value}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
