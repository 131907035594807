import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Constant from '../Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfo } from '@fortawesome/free-solid-svg-icons';

const NAVS = [
  { path: '/', name: 'Home', icon: <FontAwesomeIcon icon={faHome} /> },
  //{ path: '/about', name: 'About' },
];

const RIGHT_NAVS = [
  {
    path: '/about',
    name: 'About',
    icon: <FontAwesomeIcon icon={faInfo} />,
  },
];

export default function Navbar(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [scrollY, setScrollY] = React.useState();
  const [selectedKey, setSelectedKey] = React.useState(pathname);

  const [isCollapse, setIsCollapse] = React.useState(false);

  React.useEffect(() => {
    const eventListener = (event) => setScrollY(window.scrollY);
    window.addEventListener('scroll', eventListener);
    return () => {
      window.removeEventListener('scroll', eventListener);
    };
  }, []);

  React.useEffect(() => {
    setSelectedKey(pathname);
  }, [pathname]);

  function toggleCollapse() {
    setIsCollapse(!isCollapse);
  }

  function handleClick() {
    toggleCollapse();
  }

  return (
    <nav
      className={`navbar navbar-expand-md navbar-dark fixed-top bg-dark ${
        scrollY > 25 ? 'shadow' : ''
      }`}
    >
      <Link to="/" className="navbar-brand">
        <span className="badge badge-light rounded shadow mr-2 font-weight-bold">
          {Constant.APP_LOGO}
        </span>{' '}
        <span className="font-weight-bold">{Constant.APP_NAME}</span>
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={toggleCollapse}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${isCollapse ? 'show' : ''}`}
        id="navbarCollapse"
      >
        <ul className="navbar-nav mr-auto">
          {NAVS.map(({ path, icon, name }) => (
            <li
              key={path}
              className={`nav-item ${selectedKey === path ? 'active' : ''}`}
            >
              <Link to={path} className="nav-link" onClick={handleClick}>
                {icon}
                <span className="sr-only">{t(name)}</span>
              </Link>
            </li>
          ))}
        </ul>

        <ul className="navbar-nav">
          {RIGHT_NAVS.map(({ path, icon, name }) => (
            <li
              key={path}
              className={`nav-item ${selectedKey === path ? 'active' : ''}`}
            >
              <Link to={path} className="nav-link" onClick={handleClick}>
                {icon}
                <span className="sr-only">{t(name)}</span>
              </Link>
            </li>
          ))}
        </ul>

        {/*<form className="form-inline mt-2 mt-md-0">
          <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
          <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form>*/}
      </div>
    </nav>
  );
}
