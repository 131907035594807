import React from 'react';
import { Container } from '../Util';

function dec2hex(n) {
  if (typeof n === 'string' && n.length > 0) return dec2hex(parseInt(n));
  if (typeof n === 'number') {
    const s = n.toString(16);
    if (typeof s === 'string' && s.length > 0) return s;
  }
  return '';
}

export default function Dec2Hex(props) {
  const [value, setValue] = React.useState(128640);

  const n = dec2hex(value);

  return (
    <div className="container-lg mb-5">
      <Container gradient dark rounded shadow className="p-3">
        <h3 className="text-center">Dec2Hex</h3>
        <p className="text-center lead">
          Converting decimal (base 10) value to hexadecimal number (base 16)
        </p>
        <div className="row">
          <div className="col">
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-xs-2">
            <h3>{'\u2192'}</h3>
          </div>
          <div className="col">
            <h3>{n}</h3>
          </div>
        </div>
      </Container>
    </div>
  );
}
