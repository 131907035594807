import React from 'react';
//import { useTranslation } from 'react-i18next';
//import Constant from '../Constant';
import Particles from 'react-particles-js';
import HomeHero from './HomeHero';
import HomeList from './HomeList';

//const { HOME } = Constant;
//const { logo, title, description } = HOME;

const STYLE = `
#root > .navbar {
  background: linear-gradient(180deg, rgba(0,0,0,.25), rgba(255,255,255,0)) !important;
}
`;

export default function Home(props) {
  //const { t } = useTranslation();
  return (
    <React.Fragment>
      <style>{STYLE}</style>

      <div
        id="Home"
        className="animate__animated animate__fadeIn animate__faster"
      >
        <div
          className="position-absolute w-100"
          style={{ top: 0, left: 0, zIndex: -1 }}
        >
          <Particles
            params={{
              particles: {
                number: {
                  value: 256,
                  density: {
                    enable: true,
                    value_area: 2048,
                  },
                },
              },
            }}
          />
        </div>

        <HomeHero />

        <div className="container-lg">
          <HomeList />
        </div>
      </div>
    </React.Fragment>
  );
}
