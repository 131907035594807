import React from 'react';
import { Button, Details, PreCode, Badge, P, todate } from '../Util';

function firstOf(l) {
  if (Array.isArray(l) && l.length > 0) {
    return l[0];
  }
  return null;
}

// Dan Brown's The Da Vinci Code.
const PASSAGE = `Only 15 feet away, outside the sealed gate, the mountainous silhouette of his attacker stared through the iron bars. He was broad and tall, with ghost-pale skin and thinning white hair. His irises were pink with dark red pupils. The albino drew a pistol from his coat and aimed the barrel through the bars, directly at the curator. "You should not have run." His accent was not easy to place. "Now tell me where it is."

"I told you already," the curator stammered, kneeling defenseless on the floor of the gallery. "I have no idea what you are talking about!"

"You are lying." The man stared at him, perfectly immobile except for the glint in his ghostly eyes. "You and your brethren possess something that is not yours."`;

function Voices(props) {
  const { list, selected, onChange } = props;

  const [lang, setLang] = React.useState();

  if (Array.isArray(list) && list.length > 0) {
    const labels = ['Name', 'Lang', '', ''];

    const Lang = {};

    list.forEach((e) => {
      const { lang } = e;
      if (typeof lang === 'string' && lang.length > 0) {
        const l = lang.split('-')[0];
        if (typeof l === 'string' && l.length > 0) {
          if (Lang[l]) {
            const langs = Lang[l];
            if (Array.isArray(langs)) {
              if (langs.indexOf(lang) < 0) {
                Lang[l].push(lang);
              }
            }
          } else {
            Lang[l] = [lang];
          }
        }
      }
    });

    const choose = (e) => {
      if (typeof onChange === 'function') {
        onChange(e);
      }
    };

    const filter = () => {
      if (typeof lang === 'string' && lang.length > 0) {
        const l = list.filter((e) => e.lang.indexOf(lang) >= 0);
        return l;
      }
      return list;
    };

    const isSelected = (e) => {
      if (selected === e) return true;
      if (typeof selected === 'object' && selected !== null) {
        if (typeof e === 'object' && e !== null) {
          if (selected.name === e.name) return true;
          if (selected.voiceURI === e.voiceURI) return true;
        }
      }
      return false;
    };

    return (
      <div className="">
        <div className="mb-3">
          <div className="mb-1">
            <label className="font-weight-bold mr-2">Filter by lang</label>
            {lang && (
              <Badge danger pill shadow>
                <span className="ml-1 mr-1">{lang}</span>
                <Button
                  sm
                  onClick={(e) => setLang(null)}
                  className="text-white p-0 m-0"
                >
                  {'\u00d7'}
                </Button>
              </Badge>
            )}
          </div>
          {Object.keys(Lang).map((e, i) => {
            const el = {};

            if (e === lang) el.primary = true;
            else el.light = true;

            const count = Lang[e].length;

            return (
              <Button
                key={i}
                {...el}
                shadow
                sm
                className="mr-1 mb-1"
                onClick={() => setLang(e)}
              >
                <span className="mr-1">{e}</span>
                <Badge success pill>
                  {count}
                </Badge>
              </Button>
            );
          })}
        </div>

        <div className="bg-white shadow rounded">
          <table className="table table-striped table-hover shadow rounded">
            <thead className="thead-dark">
              <tr>
                {labels.map((e, i) => (
                  <th
                    key={i}
                    className="col text-center font-weight-bold"
                    scope="col"
                  >
                    {e}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filter().map((e, i) => {
                const { lang, localService, name /*voiceURI*/ } = e;
                return (
                  <tr
                    key={i}
                    className={i === selected ? 'bg-primary text-white' : ''}
                  >
                    <th className="p-1 text-center" scope="row">
                      {name}
                    </th>
                    <td className="p-1 text-center">{lang}</td>
                    <td className="p-1 text-center">{localService}</td>
                    {/*<td className="p-1 text-center">{voiceURI}</td>*/}
                    <td className="p-1 text-center">
                      {isSelected(e) ? (
                        <Button sm disabled className="ml-3 mr-3">
                          {'\u2713'}
                        </Button>
                      ) : (
                        <Button
                          primary
                          outline
                          shadow
                          sm
                          onClick={() => choose(e)}
                        >
                          Choose
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return null;
}

function Text2Speech(props) {
  //const [value,setValue] = React.useState('Hello, World!')
  //const [value,setValue] = React.useState(lipsum())
  const [value, setValue] = React.useState(PASSAGE);

  const [voices, setVoices] = React.useState(
    props.voices || window.speechSynthesis.getVoices(),
  );
  const [voice, setVoice] = React.useState(firstOf(props.voices));
  const [log, setLog] = React.useState('');

  React.useEffect(() => {
    if (Array.isArray(voices) && voices.length > 0) {
      setVoice(voices[0]);
      // TMP
      //setVoice(voices.find(e=>e.name==='Victoria'))
      //setVoice(voices.find(e=>e.name==='Google UK English Female'))
    }
  }, [voices]);

  const stop = () => {
    window.speechSynthesis.cancel();
  };

  const say = (init = false) => {
    if (init && Array.isArray(voices) && voices.length <= 0) {
      // TMP: filter by en
      /*const vs = window.speechSynthesis.getVoices().filter(e=>{
        const {lang} = e
        return (new RegExp('en','gi')).test(lang)
      })
      setVoices(vs)*/
      setVoices(window.speechSynthesis.getVoices());
    }
    const speech = new SpeechSynthesisUtterance(value);
    speech.voice = voice;
    speech.rate = 1;
    speech.pitch = 1;

    // stop current speech if any
    stop();
    window.speechSynthesis.speak(speech);

    const { rate, pitch } = speech;
    setLog({ rate, pitch });
  };

  const changeVoice = (e) => {
    const i = e.target.value;
    if (Array.isArray(voices) && voices.length > 0) {
      if (i >= 0 && i < voices.length) {
        const voice = voices[i];
        setVoice(voice);
      }
    }
  };

  const selectVoice = (e) => {
    if (typeof e === 'object' && e !== null) {
      setVoice(e);
      const { lang, name, localService, voiceURI } = e;
      setLog({ default: e.default, lang, name, localService, voiceURI });
    }
  };

  const selected = () => {
    if (Array.isArray(voices) && voices.length > 0) {
      if (typeof voice === 'object' && voice !== null) {
        const { name } = voice;
        const i = voices.findIndex((e) => {
          return e.name === name;
        });
        if (i >= 0) return i;
      }
    }
    return 0;
  };

  const whose = () => {
    if (typeof voice === 'object' && voice !== null) {
      const { name, lang } = voice;
      return `${name} \u2022 ${lang}`;
    }
    return '';
  };

  return (
    <div className="container-fluid">
      <div className="mb-3">
        <Details lead="Show log">
          <PreCode>{log}</PreCode>
        </Details>
      </div>

      <div className="row">
        <div className="col">
          <select
            className="form-control shadow mb-3"
            value={selected()}
            onChange={changeVoice}
          >
            {voices.map((e, i) => {
              const { lang, name } = e;
              return (
                <option key={i} value={i}>
                  {name} {'\u2022'} {lang}
                </option>
              );
            })}
          </select>
          <Voices list={voices} selected={voice} onChange={selectVoice} />
        </div>

        <div className="col">
          <div className="mb-3 text-right">
            {speechSynthesis.speaking && (
              <Button
                danger
                lg
                shadow
                onClick={(e) => speechSynthesis.cancel()}
                className="mr-3"
              >
                {'\u25a0'} Stop
              </Button>
            )}
            {
              <Button light lg shadow className="" onClick={say}>
                {'\u{1f5e3}'} Say
              </Button>
            }
          </div>
          <div className="form-group">
            <label>{whose()}</label>
            <textarea
              className="form-control"
              rows="3"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Text2SpeechApp(props) {
  const [voices, setVoices] = React.useState(
    window.speechSynthesis.getVoices(),
  );

  React.useState(() => {
    if (Array.isArray(voices) && voices.length <= 0) {
      setTimeout(() => {
        setVoices(window.speechSynthesis.getVoices());
      }, 1000);
    }
  }, [voices]);

  const count = (l) => {
    if (Array.isArray(l)) {
      return l.length;
    }
    return 0;
  };

  return (
    <div className="container-lg">
      <div className="pt-2 text-center">
        <Badge dark pill shadow className="mr-2">
          React JS
        </Badge>
        <Badge info pill shadow className="mr-2">
          Bootstrap 4
        </Badge>
        <Badge light pill shadow>
          {todate()}
        </Badge>
        <h1>Text-to-Speech</h1>
        <P>Using SpeechSynthesisUtterance</P>
      </div>
      <hr />
      {count(voices) > 0 && <Text2Speech voices={voices} />}
    </div>
  );
}
