const SampleData = {
  "CalendarEvents.csv": `Start Date ,Start Time,End Date,End Time,Event Title ,All Day Event,No End Time,Event Description,Contact ,Contact Email,Contact Phone,Location,Category,Mandatory,Registration,Maximum,Last Date To Register
  9/5/2011,3:00:00 PM,9/5/2011,,Social Studies Dept. Meeting,N,Y,Department meeting,Chris Gallagher,cgallagher@schoolwires.com,814-555-5179,High School,2,N,N,25,9/2/2011
  9/5/2011,6:00:00 PM,9/5/2011,8:00:00 PM,Curriculum Meeting,N,N,Curriculum Meeting,Chris Gallagher,cgallagher@schoolwires.com,814-555-5179,High School,2,N,N,25,9/2/2011
`,
  "TechCrunchcontinentalUSA.csv": `permalink,company,numEmps,category,city,state,fundedDate,raisedAmt,raisedCurrency,round
lifelock,LifeLock,,web,Tempe,AZ,1-May-07,6850000,USD,b
lifelock,LifeLock,,web,Tempe,AZ,1-Oct-06,6000000,USD,a
lifelock,LifeLock,,web,Tempe,AZ,1-Jan-08,25000000,USD,c
mycityfaces,MyCityFaces,7,web,Scottsdale,AZ,1-Jan-08,50000,USD,seed
flypaper,Flypaper,,web,Phoenix,AZ,1-Feb-08,3000000,USD,a
infusionsoft,Infusionsoft,105,software,Gilbert,AZ,1-Oct-07,9000000,USD,a
gauto,gAuto,4,web,Scottsdale,AZ,1-Jan-08,250000,USD,seed
chosenlist-com,ChosenList.com,5,web,Scottsdale,AZ,1-Oct-06,140000,USD,seed
chosenlist-com,ChosenList.com,5,web,Scottsdale,AZ,25-Jan-08,233750,USD,angel
digg,Digg,60,web,San Francisco,CA,1-Dec-06,8500000,USD,b
digg,Digg,60,web,San Francisco,CA,1-Oct-05,2800000,USD,a
facebook,Facebook,450,web,Palo Alto,CA,1-Sep-04,500000,USD,angel
facebook,Facebook,450,web,Palo Alto,CA,1-May-05,12700000,USD,a
facebook,Facebook,450,web,Palo Alto,CA,1-Apr-06,27500000,USD,b
facebook,Facebook,450,web,Palo Alto,CA,1-Oct-07,300000000,USD,c
facebook,Facebook,450,web,Palo Alto,CA,1-Mar-08,40000000,USD,c
facebook,Facebook,450,web,Palo Alto,CA,15-Jan-08,15000000,USD,c
facebook,Facebook,450,web,Palo Alto,CA,1-May-08,100000000,USD,debt_round
photobucket,Photobucket,60,web,Palo Alto,CA,1-May-06,10500000,USD,b
photobucket,Photobucket,60,web,Palo Alto,CA,1-Mar-05,3000000,USD,a
omnidrive,Omnidrive,,web,Palo Alto,CA,1-Dec-06,800000,USD,angel
geni,Geni,18,web,West Hollywood,CA,1-Jan-07,1500000,USD,a
geni,Geni,18,web,West Hollywood,CA,1-Mar-07,10000000,USD,b
twitter,Twitter,17,web,San Francisco,CA,1-Jul-07,5400000,USD,b
twitter,Twitter,17,web,San Francisco,CA,1-May-08,15000000,USD,c
stumbleupon,StumbleUpon,,web,San Francisco,CA,1-Dec-05,1500000,USD,seed
gizmoz,Gizmoz,,web,Menlo Park,CA,1-May-07,6300000,USD,a
gizmoz,Gizmoz,,web,Menlo Park,CA,16-Mar-08,6500000,USD,b
scribd,Scribd,14,web,San Francisco,CA,1-Jun-06,12000,USD,seed
scribd,Scribd,14,web,San Francisco,CA,1-Jan-07,40000,USD,angel
scribd,Scribd,14,web,San Francisco,CA,1-Jun-07,3710000,USD,a
slacker,Slacker,,web,San Diego,CA,1-Jun-07,40000000,USD,b
slacker,Slacker,,web,San Diego,CA,1-Jun-07,13500000,USD,a
lala,Lala,,web,Palo Alto,CA,1-May-07,9000000,USD,a
plaxo,Plaxo,50,web,Mountain View,CA,1-Nov-02,3800000,USD,a
plaxo,Plaxo,50,web,Mountain View,CA,1-Jul-03,8500000,USD,b
plaxo,Plaxo,50,web,Mountain View,CA,1-Apr-04,7000000,USD,c
plaxo,Plaxo,50,web,Mountain View,CA,1-Feb-07,9000000,USD,d
powerset,Powerset,60,web,San Francisco,CA,1-Jun-07,12500000,USD,a
technorati,Technorati,25,web,San Francisco,CA,1-Jun-06,10520000,USD,c
technorati,Technorati,25,web,San Francisco,CA,1-Sep-04,6500000,USD,b
technorati,Technorati,25,web,San Francisco,CA,13-Jun-08,7500000,USD,d
technorati,Technorati,25,web,San Francisco,CA,10-May-07,1000000,USD,c
mahalo,Mahalo,40,web,Santa Monica,CA,1-Jan-06,5000000,USD,a
mahalo,Mahalo,40,web,Santa Monica,CA,1-Jan-07,16000000,USD,b
kyte,Kyte,40,web,San Francisco,CA,1-Jul-07,2250000,USD,a
kyte,Kyte,40,web,San Francisco,CA,1-Dec-07,15000000,USD,b
kyte,Kyte,40,web,San Francisco,CA,10-Mar-08,6100000,USD,b
veoh,Veoh,,web,San Diego,CA,1-Apr-06,12500000,USD,b
veoh,Veoh,,web,San Diego,CA,1-Aug-07,25000000,USD,c
veoh,Veoh,,web,San Diego,CA,1-Jul-05,2250000,USD,a
veoh,Veoh,,web,San Diego,CA,3-Jun-08,30000000,USD,d
jingle-networks,Jingle Networks,,web,Menlo Park,CA,1-Oct-06,30000000,USD,c
jingle-networks,Jingle Networks,,web,Menlo Park,CA,1-Dec-05,5000000,USD,a
jingle-networks,Jingle Networks,,web,Menlo Park,CA,1-Apr-06,26000000,USD,b
jingle-networks,Jingle Networks,,web,Menlo Park,CA,1-Oct-05,400000,USD,angel
jingle-networks,Jingle Networks,,web,Menlo Park,CA,1-Jan-08,13000000,USD,c
ning,Ning,41,web,Palo Alto,CA,1-Jul-07,44000000,USD,c
ning,Ning,41,web,Palo Alto,CA,1-Apr-08,60000000,USD,d
jotspot,JotSpot,,web,Palo Alto,CA,1-Aug-04,5200000,USD,a
mercora,Mercora,,web,Sunnyvale,CA,1-Jan-05,5000000,USD,a
wesabe,Wesabe,,web,San Francisco,CA,1-Feb-07,700000,USD,seed
wesabe,Wesabe,,web,San Francisco,CA,1-Jun-07,4000000,USD,a
jangl,Jangl,22,web,Pleasanton,CA,1-Jul-06,7000000,USD,b
hyphen8,Hyphen 8,4,web,San Francisco,CA,1-Oct-07,100000,USD,angel
prosper,Prosper,,web,San Francisco,CA,1-Jun-07,20000000,USD,c
prosper,Prosper,,web,San Francisco,CA,1-Apr-05,7500000,USD,a
prosper,Prosper,,web,San Francisco,CA,1-Feb-06,12500000,USD,b
google,Google,20000,web,Mountain View,CA,1-Jun-99,25000000,USD,a
jajah,Jajah,80,web,Mountain View,CA,1-May-07,20000000,USD,c
jajah,Jajah,80,web,Mountain View,CA,1-Mar-06,3000000,USD,a
jajah,Jajah,80,web,Mountain View,CA,1-Apr-06,5000000,USD,b
youtube,YouTube,,web,San Bruno,CA,1-Nov-05,3500000,USD,a
youtube,YouTube,,web,San Bruno,CA,1-Apr-06,8000000,USD,b
ustream,Ustream,,web,Mountain View,CA,1-Dec-07,2000000,USD,angel
ustream,Ustream,,web,Mountain View,CA,10-Apr-08,11100000,USD,a
gizmoproject,GizmoProject,,web,San Diego,CA,1-Feb-06,6000000,USD,a
adap-tv,Adap.tv,15,web,San Mateo,CA,1-Jul-07,10000000,USD,a
topix,Topix,,web,Palo Alto,CA,1-Nov-06,15000000,USD,b
revision3,Revision3,,web,San Francisco,CA,1-Sep-06,1000000,USD,a
revision3,Revision3,,web,San Francisco,CA,1-Jun-07,8000000,USD,b
aggregateknowledge,Aggregate Knowledge,,web,San Mateo,CA,1-Jun-06,5000000,USD,a
aggregateknowledge,Aggregate Knowledge,,web,San Mateo,CA,1-Apr-07,20000000,USD,b
sugarinc,Sugar Inc,,web,San Francisco,CA,1-Oct-06,5000000,USD,a
sugarinc,Sugar Inc,,web,San Francisco,CA,1-Jun-07,10000000,USD,b
zing,Zing,,web,Mountain View,CA,1-Jan-07,13000000,USD,a
criticalmetrics,CriticalMetrics,4,web,,CA,1-Jan-07,100000,USD,angel
spock,Spock,30,web,Redwood City,CA,1-Dec-06,7000000,USD,a
wize,Wize,,web,,CA,1-Jan-07,4000000,USD,a
sodahead,SodaHead,,web,,CA,1-Jan-07,4250000,USD,a
sodahead,SodaHead,,web,,CA,25-Jun-08,8400000,USD,b
casttv,CastTV,0,web,San Francisco,CA,1-Apr-07,3100000,USD,a
buzznet,BuzzNet,,web,Hollywood,CA,1-May-07,6000000,USD,a
buzznet,BuzzNet,,web,Hollywood,CA,7-Apr-08,25000000,USD,b
funny-or-die,Funny Or Die,,web,Palo Alto,CA,1-Dec-07,15000000,USD,b
sphere,Sphere,11,web,San Francisco,CA,1-May-06,3000000,USD,b
sphere,Sphere,11,web,San Francisco,CA,1-Apr-05,500000,USD,a
meevee,MeeVee,,web,Burlingame,CA,1-Feb-06,6500000,USD,b
meevee,MeeVee,,web,Burlingame,CA,1-Aug-06,8000000,USD,c
meevee,MeeVee,,web,Burlingame,CA,1-Feb-05,7000000,USD,a
meevee,MeeVee,,web,Burlingame,CA,1-Sep-07,3500000,USD,d
mashery,Mashery,0,web,San Francisco,CA,26-Jun-08,2000000,USD,c
yelp,Yelp,,web,San Francisco,CA,1-Oct-06,10000000,USD,c
yelp,Yelp,,web,San Francisco,CA,1-Oct-05,5000000,USD,b
yelp,Yelp,,web,San Francisco,CA,1-Jul-04,1000000,USD,a
yelp,Yelp,,web,San Francisco,CA,26-Feb-08,15000000,USD,d
spotplex,Spotplex,3,web,Santa Clara,CA,1-Jan-07,450000,USD,angel
coghead,Coghead,21,web,Redwood City,CA,1-Mar-06,3200000,USD,a
coghead,Coghead,21,web,Redwood City,CA,1-Mar-07,8000000,USD,b
zooomr,Zooomr,,web,San Francisco,CA,1-Feb-06,50000,USD,angel
sidestep,SideStep,75,web,Santa Clara,CA,1-Feb-07,15000000,USD,c
sidestep,SideStep,75,web,Santa Clara,CA,1-Jan-04,8000000,USD,b
sidestep,SideStep,75,web,Santa Clara,CA,1-Dec-99,2200000,USD,a
sidestep,SideStep,75,web,Santa Clara,CA,1-Oct-00,6800000,USD,a
rockyou,RockYou,,web,San Mateo,CA,1-Jan-07,1500000,USD,a
rockyou,RockYou,,web,San Mateo,CA,1-Mar-07,15000000,USD,b
rockyou,RockYou,,web,San Mateo,CA,9-Jun-08,35000000,USD,c
rockyou,RockYou,,web,San Mateo,CA,27-May-08,1000000,USD,unattributed
pageflakes,Pageflakes,20,web,San Francisco,CA,1-May-06,1300000,USD,a
swivel,Swivel,,web,San Francisco,CA,1-Sep-06,1000000,USD,a
swivel,Swivel,,web,San Francisco,CA,1-Apr-07,1000000,USD,a
slide,Slide,64,web,San Francisco,CA,1-Jul-05,8000000,USD,b
slide,Slide,64,web,San Francisco,CA,1-Jan-08,50000000,USD,d
bebo,Bebo,,web,San Francisco,CA,1-May-06,15000000,USD,a
freebase,freebase,,web,San Francisco,CA,1-Mar-06,15000000,USD,a
freebase,freebase,,web,San Francisco,CA,1-Jan-08,42500000,USD,b
metawebtechnologies,Metaweb Technologies,,web,San Francisco,CA,15-Jan-08,42000000,USD,b
glammedia,Glam Media,,web,Brisbane,CA,1-Jul-04,1100000,USD,a
glammedia,Glam Media,,web,Brisbane,CA,1-Jul-05,10000000,USD,b
glammedia,Glam Media,,web,Brisbane,CA,1-Dec-06,18500000,USD,c
glammedia,Glam Media,,web,Brisbane,CA,1-Feb-08,65000000,USD,d
glammedia,Glam Media,,web,Brisbane,CA,1-Feb-08,20000000,USD,d
thefind,TheFind,,web,Mountain View,CA,1-Feb-05,7000000,USD,a
thefind,TheFind,,web,Mountain View,CA,1-Oct-06,4000000,USD,b
thefind,TheFind,,web,Mountain View,CA,1-Jul-07,15000000,USD,c
zazzle,Zazzle,,web,Redwood City,CA,1-Jul-05,16000000,USD,a
zazzle,Zazzle,,web,Redwood City,CA,1-Oct-07,30000000,USD,b
dogster,Dogster,,web,San Francisco,CA,1-Sep-06,1000000,USD,angel
pandora,Pandora,80,web,Oakland,CA,1-Jan-04,7800000,USD,b
pandora,Pandora,80,web,Oakland,CA,1-Oct-05,12000000,USD,c
pandora,Pandora,80,web,Oakland,CA,2-Mar-00,1500000,USD,a
cafepress,Cafepress,300,web,Foster City,CA,1-Mar-00,1200000,USD,a
cafepress,Cafepress,300,web,Foster City,CA,1-May-01,300000,USD,a
cafepress,Cafepress,300,web,Foster City,CA,1-Feb-05,14000000,USD,b
pbwiki,pbwiki,15,web,,CA,11-Sep-06,350000,USD,a
pbwiki,pbwiki,15,web,,CA,1-Feb-07,2100000,USD,b
adbrite,AdBrite,,web,San Francisco,CA,1-Sep-04,4000000,USD,a
adbrite,AdBrite,,web,San Francisco,CA,1-Feb-06,8000000,USD,b
adbrite,AdBrite,,web,San Francisco,CA,1-Nov-07,23000000,USD,c
loomia,Loomia,,web,San Francisco,CA,1-Jun-05,1000000,USD,seed
loomia,Loomia,,web,San Francisco,CA,1-Apr-08,5000000,USD,a
meebo,Meebo,40,web,Mountain View,CA,1-Dec-05,3500000,USD,a
meebo,Meebo,40,web,Mountain View,CA,1-Jan-07,9000000,USD,b
meebo,Meebo,40,web,Mountain View,CA,30-Apr-08,25000000,USD,c
eventbrite,Eventbrite,13,web,San Francisco,CA,1-Nov-06,100000,USD,angel
linkedin,LinkedIn,,web,Mountain View,CA,1-Nov-03,4700000,USD,a
linkedin,LinkedIn,,web,Mountain View,CA,1-Oct-04,10000000,USD,b
linkedin,LinkedIn,,web,Mountain View,CA,1-Jan-07,12800000,USD,c
linkedin,LinkedIn,,web,Mountain View,CA,17-Jun-08,53000000,USD,d
flickim,FlickIM,,web,Berkeley,CA,1-Mar-07,1600000,USD,a
terabitz,Terabitz,12,web,Palo Alto,CA,1-Feb-07,10000000,USD,a
healthline,Healthline,,web,San Francisco,CA,1-Jan-06,14000000,USD,a
healthline,Healthline,,web,San Francisco,CA,1-Jul-07,21000000,USD,b
box-net,Box.net,19,web,Palo Alto,CA,1-Oct-06,1500000,USD,a
box-net,Box.net,19,web,Palo Alto,CA,23-Jan-08,6000000,USD,b
conduit,Conduit,,web,Redwood Shores,CA,1-Jul-06,1800000,USD,a
conduit,Conduit,,web,Redwood Shores,CA,1-Jan-08,8000000,USD,b
edgeio,Edgeio,,web,Palo Alto,CA,1-Jul-07,5000000,USD,a
spotrunner,Spot Runner,,web,Los Angeles,CA,1-Jan-06,10000000,USD,a
spotrunner,Spot Runner,,web,Los Angeles,CA,1-Oct-06,40000000,USD,b
spotrunner,Spot Runner,,web,Los Angeles,CA,7-May-08,51000000,USD,c
kaboodle,Kaboodle,,web,Santa Clara,CA,1-Mar-05,1500000,USD,a
kaboodle,Kaboodle,,web,Santa Clara,CA,1-Mar-06,2000000,USD,b
kaboodle,Kaboodle,,web,Santa Clara,CA,1-Mar-07,1500000,USD,c
giga-omni-media,Giga Omni Media,18,web,San Francisco,CA,1-Jun-06,325000,USD,a
visiblepath,Visible Path,,web,Foster City,CA,1-Mar-06,17000000,USD,b
visiblepath,Visible Path,,web,Foster City,CA,1-Mar-06,7700000,USD,a
wink,Wink,,web,Mountain View,CA,1-Jan-05,6200000,USD,a
seesmic,seesmic,,web,San Francisco,CA,1-Nov-07,6000000,USD,a
seesmic,seesmic,,web,San Francisco,CA,20-Jun-08,6000000,USD,b
zvents,Zvents,,web,San Mateo,CA,7-Nov-06,7000000,USD,a
zvents,Zvents,,web,San Mateo,CA,5-Oct-05,200000,USD,seed
eventful,Eventful,35,web,San Diego,CA,1-Sep-06,7500000,USD,b
eventful,Eventful,35,web,San Diego,CA,1-Mar-05,2100000,USD,a
oodle,Oodle,,web,San Mateo,CA,1-May-06,5000000,USD,a
oodle,Oodle,,web,San Mateo,CA,1-Jul-07,11000000,USD,b
odesk,oDesk,,web,Menlo Park,CA,1-Apr-06,6000000,USD,a
odesk,oDesk,,web,Menlo Park,CA,1-Sep-06,8000000,USD,b
odesk,oDesk,,web,Menlo Park,CA,1-Jun-08,15000000,USD,c
simplyhired,SimplyHired,,web,Mountain View,CA,1-Mar-06,13500000,USD,b
simplyhired,SimplyHired,,web,Mountain View,CA,5-Aug-05,3000000,USD,a
ooma,ooma,47,web,Palo Alto,CA,1-Jan-05,8000000,USD,a
ooma,ooma,47,web,Palo Alto,CA,1-Dec-06,18000000,USD,b
goingon,GoingOn,3,web,Woodside,CA,1-Aug-05,1000000,USD,seed
flixster,Flixster,17,web,San Francisco,CA,1-Feb-07,2000000,USD,a
flixster,Flixster,17,web,San Francisco,CA,4-Apr-08,5000000,USD,b
piczo,Piczo,,web,San Francisco,CA,1-Jan-07,11000000,USD,c
socialtext,Socialtext,,web,Palo Alto,CA,1-Jun-04,300000,USD,angel
socialtext,Socialtext,,web,Palo Alto,CA,1-Jun-05,3100000,USD,b
socialtext,Socialtext,,web,Palo Alto,CA,1-Nov-07,9500000,USD,c
powerreviews,PowerReview,,web,Millbrae,CA,1-Dec-05,6250000,USD,a
powerreviews,PowerReview,,web,Millbrae,CA,1-Sep-07,15000000,USD,b
hi5,hi5,100,web,San Francisco,CA,1-Jul-07,20000000,USD,a
hi5,hi5,100,web,San Francisco,CA,1-Dec-07,15000000,USD,debt_round
tagged,Tagged,,web,San Francisco,CA,1-Sep-05,1500000,USD,angel
tagged,Tagged,,web,San Francisco,CA,1-Dec-05,7000000,USD,a
tagged,Tagged,,web,San Francisco,CA,1-Jul-07,15000000,USD,b
jaxtr,Jaxtr,,web,Menlo Park,CA,1-Jul-07,1500000,USD,angel
jaxtr,Jaxtr,,web,Menlo Park,CA,1-Aug-07,10000000,USD,a
jaxtr,Jaxtr,,web,Menlo Park,CA,23-Jun-08,10000000,USD,b
m,Me,,web,San Jose,CA,1-May-07,1500000,USD,seed
intronetworks,introNetworks,9,web,Santa Barbara,CA,1-May-07,2700000,USD,a
leveragesoftware,Leverage Software,21,web,San Francisco,CA,14-May-05,6000000,USD,a
lithiumtechnologies,Lithium Technologies,42,web,Emeryville,CA,17-Apr-07,9000000,USD,a
lithiumtechnologies,Lithium Technologies,42,web,Emeryville,CA,1-Jun-08,12000000,USD,b
genius,Genius,,web,San Mateo,CA,1-Mar-06,5100000,USD,a
genius,Genius,,web,San Mateo,CA,1-Jan-07,10000000,USD,b
genius,Genius,,web,San Mateo,CA,5-Feb-08,19000000,USD,c
respectance,Respectance,,web,San Francisco,CA,1-Jun-07,250000,USD,seed
respectance,Respectance,,web,San Francisco,CA,1-Jul-07,1500000,USD,a
curse,Curse,,web,San Francisco,CA,1-Jul-07,5000000,USD,a
licketyship,LicketyShip,,web,San Francisco,CA,1-Dec-07,1500000,USD,angel
grockit,Grockit,8,web,San Francisco,CA,1-Jun-07,2300000,USD,a
grockit,Grockit,8,web,San Francisco,CA,30-May-08,8000000,USD,b
peerme,PeerMe,,web,Mountain View,CA,1-Jan-05,5000000,USD,a
kiptronic,Kiptronic,20,web,San Francisco,CA,1-Dec-06,4000000,USD,a
kiptronic,Kiptronic,20,web,San Francisco,CA,1-Nov-05,300000,USD,angel
kiptronic,Kiptronic,20,web,San Francisco,CA,5-Jun-08,3000000,USD,b
vuze,Vuze,,web,Palo Alto,CA,1-Dec-06,12000000,USD,b
vuze,Vuze,,web,Palo Alto,CA,1-Dec-07,20000000,USD,c
phonezoo,Phonezoo,,web,Sunnyvale,CA,1-Jun-06,560000,USD,a
phonezoo,Phonezoo,,web,Sunnyvale,CA,1-Feb-07,1500000,USD,a
droplettechnology,Droplet Technology,,web,Menlo Park,CA,1-Oct-06,3500000,USD,a
podtech,PodTech,,web,Palo Alto,CA,1-Mar-06,5500000,USD,a
podtech,PodTech,,web,Palo Alto,CA,1-Jun-07,2000000,USD,a
crackle,Crackle,,web,Mill Valley,CA,1-Dec-05,1750000,USD,a
reddit,Reddit,,web,San Francisco,CA,1-Jun-05,100000,USD,seed
wikia,Wikia,,web,San Mateo,CA,1-Mar-06,4000000,USD,a
wikia,Wikia,,web,San Mateo,CA,1-Dec-06,10000000,USD,b
retrevo,Retrevo,,web,Sunnyvale,CA,1-Dec-06,3200000,USD,a
retrevo,Retrevo,,web,Sunnyvale,CA,1-Feb-06,700000,USD,seed
retrevo,Retrevo,,web,Sunnyvale,CA,20-Mar-08,8000000,USD,b
buxfer,Buxfer,2,web,Mountain View,CA,1-Jan-07,15000,USD,seed
buxfer,Buxfer,2,web,Mountain View,CA,1-Apr-07,300000,USD,angel
yousendit,YouSendIt,0,web,Campbell,CA,24-Apr-07,10000000,USD,b
yousendit,YouSendIt,0,web,Campbell,CA,1-Aug-05,10000000,USD,a
yousendit,YouSendIt,0,web,Campbell,CA,15-Jul-08,14000000,USD,c
tangler,Tangler,,web,Mountain View,CA,1-Jul-06,1500000,USD,angel
obopay,obopay,,web,Redwood City,CA,1-Feb-06,10000000,USD,a
obopay,obopay,,web,Redwood City,CA,1-Sep-06,7000000,USD,b
obopay,obopay,,web,Redwood City,CA,1-Jul-07,29000000,USD,c
obopay,obopay,,web,Redwood City,CA,21-Apr-08,20000000,USD,d
paypal,PayPal,,web,San Jose,CA,1-Apr-00,100000000,USD,c
paypal,PayPal,,web,San Jose,CA,1-Feb-01,90000000,USD,d
paypal,PayPal,,web,San Jose,CA,1-Jul-99,4000000,USD,b
talkplus,TalkPlus,,web,San Mateo,CA,1-Oct-06,5500000,USD,a
vudu,Vudu,50,hardware,Santa Clara,CA,1-Jun-05,21000000,USD,a
insiderpages,Insider Pages,,web,Redwood City,CA,1-Mar-06,8500000,USD,a
snapfish,Snapfish,,web,San Francisco,CA,1-Nov-99,7500000,USD,a
snapfish,Snapfish,,web,San Francisco,CA,1-May-00,36000000,USD,b
affinitycircles,Affinity Circles,22,web,Mountain View,CA,1-May-06,4100000,USD,c
affinitycircles,Affinity Circles,22,web,Mountain View,CA,1-Jun-05,1000000,USD,b
affinitycircles,Affinity Circles,22,web,Mountain View,CA,1-Dec-04,440000,USD,a
imvu,IMVU,,web,Palo Alto,CA,1-Feb-06,1000000,USD,angel
nirvanix,Nirvanix,,web,San Diego,CA,19-Dec-07,18000000,USD,a
fuzzwich,Habit Industries,4,web,Palo Alto,CA,1-Jun-07,15000,USD,seed
gaia,Gaia,,web,San Jose,CA,1-Jun-06,8930000,USD,a
gaia,Gaia,,web,San Jose,CA,1-Mar-07,12000000,USD,b
gaia,Gaia,,web,San Jose,CA,14-Jul-08,11000000,USD,c
greatcall,GreatCall,,web,Del Mar,CA,1-Aug-07,36600000,USD,a
revver,Revver,,web,Los Angeles,CA,1-Nov-05,4000000,USD,a
revver,Revver,,web,Los Angeles,CA,1-Apr-06,8700000,USD,b
metacafe,Metacafe,,web,Palo Alto,CA,1-Jul-06,15000000,USD,b
metacafe,Metacafe,,web,Palo Alto,CA,1-Aug-07,30000000,USD,c
flock,Flock,40,web,Redwood City,CA,1-Nov-05,3300000,USD,b
flock,Flock,40,web,Redwood City,CA,1-Jun-06,10000000,USD,c
flock,Flock,40,web,Redwood City,CA,21-May-08,15000000,USD,d
vmixmedia,VMIX Media,42,web,San Diego,CA,1-Mar-06,5000000,USD,a
vmixmedia,VMIX Media,42,web,San Diego,CA,1-Oct-07,16500000,USD,b
kontera,Kontera,,web,San Francisco,CA,1-Aug-07,10300000,USD,b
kontera,Kontera,,web,San Francisco,CA,1-Jul-06,7000000,USD,a
tokbox,Tokbox,,web,San Francisco,CA,1-Oct-07,4000000,USD,a
tokbox,Tokbox,,web,San Francisco,CA,6-Aug-08,10000000,USD,b
six-apart,Six Apart,150,web,San Francisco,CA,1-Oct-04,10000000,USD,b
six-apart,Six Apart,150,web,San Francisco,CA,1-Mar-06,12000000,USD,c
six-apart,Six Apart,150,web,San Francisco,CA,23-Apr-03,600000,USD,a
weebly,Weebly,,web,San Francisco,CA,1-May-07,650000,USD,seed
synthasite,Synthasite,26,web,San Francisco,CA,1-Nov-07,5000000,USD,a
bittorrent,BitTorrent,29,web,San Francisco,CA,1-Sep-05,8750000,USD,a
bittorrent,BitTorrent,29,web,San Francisco,CA,1-Dec-06,25000000,USD,b
kongregate,Kongregate,16,web,San Francisco,CA,1-Mar-07,1000000,USD,a
kongregate,Kongregate,16,web,San Francisco,CA,1-Aug-07,5000000,USD,b
kongregate,Kongregate,16,web,San Francisco,CA,1-Apr-08,3000000,USD,b
dancejam,DanceJam,0,web,San Francisco,CA,1-May-07,1000000,USD,angel
dancejam,DanceJam,0,web,San Francisco,CA,1-Feb-08,3500000,USD,a
fathomonline,Fathom Online,,web,San Francisco,CA,1-Jul-04,6000000,USD,a
zivity,Zivity,16,web,San Francisco,CA,1-Aug-07,1000000,USD,seed
zivity,Zivity,16,web,San Francisco,CA,1-Mar-08,7000000,USD,b
bluelithium,BlueLithium,135,web,San Jose,CA,1-Feb-05,11500000,USD,a
expresso,eXpresso,,web,Menlo Park,CA,1-Oct-07,2000000,USD,a
doostang,Doostang,,web,Palo Alto,CA,1-Sep-07,3500000,USD,a
docstoc,Docstoc,,web,Beverly Hills,CA,1-Nov-07,750000,USD,a
docstoc,Docstoc,,web,Beverly Hills,CA,28-Apr-08,3250000,USD,b
mevio,Mevio,,web,San Francisco,CA,1-Jul-05,8900000,USD,a
mevio,Mevio,,web,San Francisco,CA,1-Sep-06,15000000,USD,b
mevio,Mevio,,web,San Francisco,CA,9-Jul-08,15000000,USD,c
purevideo,PureVideo Networks,,web,Los Angeles,CA,1-Dec-05,5600000,USD,a
purevideo,PureVideo Networks,,web,Los Angeles,CA,1-Sep-07,2850000,USD,b
filmloop,FilmLoop,,web,Menlo Park,CA,1-Feb-05,5600000,USD,a
bitpass,BitPass,,web,Mountain View,CA,1-Jul-03,1500000,USD,a
bitpass,BitPass,,web,Mountain View,CA,1-Sep-04,11800000,USD,b
art-com,Art.com,500,web,Emeryville,CA,1-Feb-05,30000000,USD,a
atomshockwave,Atom Shockwave,,web,San Francisco,CA,1-Mar-01,22900000,USD,a
cinemanow,CinemaNow,,web,Marina Del Rey,CA,1-Jul-04,11000000,USD,a
digitalchocolate,Digital Chocolate,,web,San Mateo,CA,1-Dec-03,8400000,USD,a
digitalchocolate,Digital Chocolate,,web,San Mateo,CA,1-Dec-03,13000000,USD,b
eharmony,eHarmony,,web,Pasadena,CA,1-Jun-00,3000000,USD,a
eharmony,eHarmony,,web,Pasadena,CA,1-Nov-04,110000000,USD,b
friendster,Friendster,465,web,San Francisco,CA,1-Dec-02,2400000,USD,a
friendster,Friendster,465,web,San Francisco,CA,1-Oct-03,13000000,USD,b
friendster,Friendster,465,web,San Francisco,CA,1-Aug-06,10000000,USD,c
friendster,Friendster,465,web,San Francisco,CA,5-Aug-08,20000000,USD,d
greystripe,Greystripe,,web,San Francisco,CA,1-May-07,8900000,USD,b
slingmedia,Sling Media,,web,Foster City,CA,1-Nov-04,10500000,USD,a
slingmedia,Sling Media,,web,Foster City,CA,1-Jul-05,4000000,USD,a
trulia,Trulia,,web,San Francisco,CA,1-Dec-05,5700000,USD,b
trulia,Trulia,,web,San Francisco,CA,1-May-07,10000000,USD,c
trulia,Trulia,,web,San Francisco,CA,1-Sep-05,2100000,USD,a
trulia,Trulia,,web,San Francisco,CA,10-Jul-08,15000000,USD,d
lendingclub,Lending Club,,web,Sunnyvale,CA,1-May-07,2000000,USD,angel
lendingclub,Lending Club,,web,Sunnyvale,CA,1-Aug-07,10260000,USD,a
tubemogul,tubemogul,15,web,Emeryville,CA,7-Feb-08,1500000,USD,a
gemini,Gemini,,web,San Mateo,CA,1-Mar-07,20000000,USD,a
gemini,Gemini,,web,San Mateo,CA,1-May-07,5000000,USD,b
multiverse,Multiverse,,web,Mountain View,CA,1-May-07,4175000,USD,a
multiverse,Multiverse,,web,Mountain View,CA,17-Apr-07,850000,USD,angel
limelife,LimeLife,,mobile,Menlo Park,CA,28-Mar-06,10000000,USD,b
limelife,LimeLife,,mobile,Menlo Park,CA,1-Aug-05,5000000,USD,a
limelife,LimeLife,,mobile,Menlo Park,CA,24-May-07,3900000,USD,b
opentable,OpenTable,,web,San Francisco,CA,1-May-99,2000000,USD,a
opentable,OpenTable,,web,San Francisco,CA,1-Jan-00,10000000,USD,b
opentable,OpenTable,,web,San Francisco,CA,1-Oct-00,36000000,USD,c
rooftopcomedy,RooftopComedy,20,web,San Francisco,CA,1-May-07,2500000,USD,a
federatedmedia,Federated Media,,web,Sausalito,CA,1-Aug-07,4500000,USD,b
federatedmedia,Federated Media,,web,Sausalito,CA,1-Apr-08,50000000,USD,c
deliveryagent,Delivery Agent,,web,San Francisco,CA,1-May-07,18500000,USD,c
deliveryagent,Delivery Agent,,web,San Francisco,CA,17-May-06,11000000,USD,b
deliveryagent,Delivery Agent,,web,San Francisco,CA,29-Mar-05,5500000,USD,a
reunion,Reunion,100,web,Los Angeles,CA,1-Apr-07,25000000,USD,a
mixercast,Mixercast,,web,San Mateo,CA,1-May-05,2600000,USD,a
mixercast,Mixercast,,web,San Mateo,CA,1-Jan-08,6000000,USD,b
mtraks,mTraks,,web,San Diego,CA,1-May-07,550000,USD,angel
xora,Xora,,web,Mountain View,CA,1-May-01,7000000,USD,a
xora,Xora,,web,Mountain View,CA,1-Feb-03,4000000,USD,b
xora,Xora,,web,Mountain View,CA,1-Jun-07,4000000,USD,debt_round
ooyala,Ooyala,,web,Mountain View,CA,1-Jan-08,8500000,USD,b
saynow,SayNow,14,web,Palo Alto,CA,1-Sep-07,7500000,USD,a
crunchyroll,Crunchyroll,,web,San Francsico,CA,1-Feb-08,4050000,USD,a
circleup,CircleUp,19,web,Newport Beach,CA,1-Jun-07,3000000,USD,a
pixsense,PixSense,,web,Santa Clara,CA,1-Dec-06,5400000,USD,a
pixsense,PixSense,,web,Santa Clara,CA,1-Jun-07,2000000,USD,b
danal,Danal,,web,San Jose,CA,1-Jun-07,9500000,USD,a
hulu,hulu,,web,Los Angeles,CA,1-Aug-07,100000000,USD,a
ourstory,OurStory,,web,Mountain View,CA,1-Jan-06,6300000,USD,a
specificmedia,Specificmedia,,web,Ir vine,CA,1-Jun-06,10000000,USD,a
specificmedia,Specificmedia,,web,Ir vine,CA,1-Nov-07,100000000,USD,b
admob,AdMob,,web,San Mateo,CA,1-Mar-07,15000000,USD,b
mysql,MySQL,,web,Cupertino,CA,1-Feb-06,18500000,USD,c
mysql,MySQL,,web,Cupertino,CA,1-Jun-03,19500000,USD,b
attributor,Attributor,0,web,Redwood City,CA,28-Jan-06,2000000,USD,a
attributor,Attributor,0,web,Redwood City,CA,2-Apr-08,12000000,USD,c
attributor,Attributor,0,web,Redwood City,CA,18-Dec-06,8000000,USD,b
socialmedia,Social Media,15,web,Palo Alto,CA,1-Sep-07,500000,USD,a
socialmedia,Social Media,15,web,Palo Alto,CA,1-Oct-07,3500000,USD,b
demandmedia,Demand Media,,web,Santa Monica,CA,1-May-06,120000000,USD,a
demandmedia,Demand Media,,web,Santa Monica,CA,1-Sep-06,100000000,USD,b
demandmedia,Demand Media,,web,Santa Monica,CA,1-Sep-07,100000000,USD,c
demandmedia,Demand Media,,web,Santa Monica,CA,24-Mar-08,35000000,USD,d
tripit,TripIt,8,web,San Francisco,CA,1-Apr-07,1000000,USD,seed
pubmatic,PubMatic,35,web,Palo Alto,CA,1-Jan-08,7000000,USD,a
mint,Mint,22,web,Mountain View,CA,1-Oct-07,4700000,USD,a
mint,Mint,22,web,Mountain View,CA,1-Oct-06,325000,USD,seed
mint,Mint,22,web,Mountain View,CA,5-Mar-08,12000000,USD,b
app2you,app2you,4,web,La Jolla,CA,20-Nov-07,255000,USD,angel
app2you,app2you,4,web,La Jolla,CA,1-Oct-06,177000,USD,angel
yourstreet,YourStreet,6,web,San Francisco,CA,1-Oct-06,250000,USD,angel
tagworld,Tagworld,,web,Santa Monica,CA,1-Mar-06,7500000,USD,a
tagworld,Tagworld,,web,Santa Monica,CA,1-Dec-06,40000000,USD,b
rotohog,RotoHog,,web,Inglewood,CA,1-Aug-07,6000000,USD,a
thisnext,ThisNext,0,web,Santa Monica,CA,1-Jan-08,5000000,USD,b
thisnext,ThisNext,0,web,Santa Monica,CA,1-Jan-06,2500000,USD,a
chesspark,ChessPark,,web,West Palm Beach,CA,1-Jul-07,1000000,USD,angel
break,Break,,web,Beverly Hills,CA,1-Jul-07,21400000,USD,a
musestorm,MuseStorm,,web,Sunnyvale,CA,1-Jul-07,1000000,USD,a
mediamachines,Media Machines,,web,San Francisco,CA,1-Aug-07,9400000,USD,a
mig33,Ciihui,,web,Burlingame,CA,1-May-07,10000000,USD,a
mig33,Ciihui,,web,Burlingame,CA,1-Jan-08,13500000,USD,b
aliph,Aliph,,web,San Francisco,CA,1-Jul-07,5000000,USD,a
mego,mEgo,15,web,Los Angeles,CA,1-May-06,1100000,USD,angel
mego,mEgo,15,web,Los Angeles,CA,1-Jul-07,2000000,USD,angel
realius,Realius,3,web,Berkeley,CA,1-May-07,500000,USD,angel
xobni,Xobni,0,software,San Francisco,CA,1-Jun-06,12000,USD,seed
xobni,Xobni,0,software,San Francisco,CA,1-Nov-06,80000,USD,angel
xobni,Xobni,0,software,San Francisco,CA,1-Mar-07,4260000,USD,a
spoke,Spoke,0,web,San Mateo,CA,18-Nov-03,11700000,USD,c
spoke,Spoke,0,web,San Mateo,CA,24-Apr-03,9200000,USD,b
fixya,FixYa,30,web,San Mateo,CA,1-Jan-07,2000000,USD,a
fixya,FixYa,30,web,San Mateo,CA,18-Mar-08,6000000,USD,b
mozilla,Mozilla,150,web,Mountain View,CA,15-Jul-03,2000000,USD,unattributed
brightqube,BrightQube,0,web,Carlsbad,CA,1-May-07,200000,USD,angel
buzzdash,BuzzDash,0,web,Marina del Rey,CA,1-Sep-06,1200000,USD,angel
urbanmapping,Urban Mapping,9,web,San Francisco,CA,1-May-06,400000,USD,seed
consortemedia,Consorte Media,0,web,San Francisco,CA,1-Dec-07,7000000,USD,b
ffwd,ffwd,,web,San Francisco,CA,1-Aug-07,1700000,USD,a
satisfaction,Get Satisfaction,,web,San Francisco,CA,1-Sep-07,1300000,USD,a
ringcentral,RingCentral,,web,Redwood City,CA,1-Sep-07,12000000,USD,a
ringcentral,RingCentral,,web,Redwood City,CA,1-Feb-08,12000000,USD,b
3jam,3Jam,,web,Menlo Park,CA,1-Jul-07,4000000,USD,a
playspan,PlaySpan,,web,Santa Clara,CA,1-Sep-07,6500000,USD,a
grouply,Grouply,6,web,Redwood City,CA,1-Jun-07,935000,USD,angel
grouply,Grouply,6,web,Redwood City,CA,1-Jan-08,365000,USD,angel
grouply,Grouply,6,web,Redwood City,CA,14-Jan-08,1300000,USD,a
graspr,Graspr,,web,Sunnyvale,CA,10-Jul-08,2500000,USD,a
zannel,Zannel,,web,San Francisco,CA,2-Jun-08,10000000,USD,b
deca-tv,deca.tv,,web,Santa Monica,CA,1-Sep-07,5000000,USD,a
zimbra,Zimbra,,web,San Mateo,CA,1-Apr-06,14500000,USD,c
friendfeed,FriendFeed,,web,Mountain View,CA,26-Feb-08,5000000,USD,a
rupture,Rupture,15,web,San Francisco,CA,1-Jul-07,3000000,USD,angel
jibjab,JibJab,,web,Santa Monica,CA,1-Oct-07,3000000,USD,b
rubiconproject,Rubicon Project,,web,Los Angeles,CA,1-Oct-07,4000000,USD,a
rubiconproject,Rubicon Project,,web,Los Angeles,CA,1-Oct-07,2000000,USD,debt_round
rubiconproject,Rubicon Project,,web,Los Angeles,CA,28-Jan-08,15000000,USD,b
snaplayout,SnapLayout,,web,San Francisco,CA,1-May-07,650000,USD,a
confabb,Confabb,,web,,CA,1-Aug-07,200000,USD,angel
uptake,UpTake,,web,Palo Alto,CA,18-Dec-07,4000000,USD,a
23andme,23andMe,30,web,Mountain View,CA,1-May-07,9000000,USD,a
yume,YuMe,,web,Redwood City,CA,1-Oct-07,9000000,USD,b
mochimedia,Mochi Media,,web,San Francisco,CA,12-Mar-08,4000000,USD,a
mochimedia,Mochi Media,,web,San Francisco,CA,18-Jun-08,10000000,USD,b
blurb,Blurb,,web,San Francisco,CA,1-Oct-06,12000000,USD,b
weatherbill,WeatherBill,,web,San Francisco,CA,1-Oct-07,12600000,USD,a
automattic,Automattic,20,web,San Francisco,CA,1-Jul-05,1100000,USD,a
automattic,Automattic,20,web,San Francisco,CA,1-Jan-08,29500000,USD,b
twine,Radar Networks,,web,San Francisco,CA,1-Apr-06,5000000,USD,a
twine,Radar Networks,,web,San Francisco,CA,1-Feb-08,13000000,USD,b
veodia,Veodia,15,web,San Mateo,CA,12-May-08,8300000,USD,a
echosign,EchoSign,,web,Palo Alto,CA,1-Oct-07,6000000,USD,a
rollbase,Rollbase,0,web,Mountain View,CA,1-Aug-07,400000,USD,angel
predictify,Predictify,6,web,Redwood City,CA,25-Mar-08,4300000,USD,a
trialpay,TrialPay,50,web,San Francisco,CA,1-Feb-08,12700000,USD,b
socializr,Socializr,,web,San Francisco,CA,1-Sep-07,1500000,USD,a
eurekster,Eurekster,0,web,,CA,1-Dec-04,1350000,USD,a
eurekster,Eurekster,0,web,,CA,13-Mar-07,5500000,USD,b
gydget,Gydget,,web,San Francisco,CA,1-Aug-06,1000000,USD,a
pickspal,PicksPal,,web,Mountain View,CA,1-Oct-07,3000000,USD,c
gigya,Gigya,,web,Palo Alto,CA,1-Feb-07,3000000,USD,a
gigya,Gigya,,web,Palo Alto,CA,9-Mar-08,9500000,USD,b
songbird,Songbird,,web,San Francisco,CA,1-Dec-06,8000000,USD,seed
guardiananalytics,Guardian Analytics,,web,Los Altos,CA,1-Oct-07,4500000,USD,b
fora-tv,Fora.TV,,web,San Francisco,CA,1-Oct-07,4000000,USD,a
disqus,Disqus,3,web,San Francisco,CA,18-Mar-08,500000,USD,a
sezwho,SezWho,9,web,Los Altos,CA,1-Oct-07,1000000,USD,a
yieldbuild,YieldBuild,,web,San Francisco,CA,3-Mar-08,6000000,USD,b
akimbo,Akimbo,,web,San Mateo,CA,1-Jun-06,15500000,USD,c
akimbo,Akimbo,,web,San Mateo,CA,1-Jul-04,12000000,USD,b
akimbo,Akimbo,,web,San Mateo,CA,1-Jun-03,4200000,USD,seed
akimbo,Akimbo,,web,San Mateo,CA,29-Feb-08,8000000,USD,d
laszlosystems,Laszlo Systems,60,software,San Mateo,CA,6-Mar-08,14600000,USD,c
laszlosystems,Laszlo Systems,60,software,San Mateo,CA,15-Apr-05,6250000,USD,b
brightroll,BrightRoll,,web,San Francisco,CA,1-Oct-07,5000000,USD,b
merchantcircle,MerchantCircle,10,web,Los Altos,CA,1-Sep-07,10000000,USD,b
minekey,Minekey,,web,Sunnyvale,CA,1-Aug-07,3000000,USD,a
bookingangel,Booking Angel,,web,Hollywood,CA,1-Aug-07,100000,USD,angel
loopt,Loopt,,web,Mountain View,CA,1-Jun-05,6000,USD,seed
loopt,Loopt,,web,Mountain View,CA,23-Jul-07,8250000,USD,b
fix8,Fix8,,web,Sherman Oaks,CA,1-Oct-07,3000000,USD,a
fix8,Fix8,,web,Sherman Oaks,CA,3-Apr-08,2000000,USD,b
mybuys,MyBuys,,web,Redwood Shores,CA,21-Feb-07,2800000,USD,a
mybuys,MyBuys,,web,Redwood Shores,CA,8-Oct-07,10000000,USD,b
blackarrow,BlackArrow,35,web,San Mateo,CA,1-Oct-07,12000000,USD,b
blackarrow,BlackArrow,35,web,San Mateo,CA,1-Nov-06,14750000,USD,a
peerflix,Peerflix,,web,Palo Alto,CA,1-Oct-05,8000000,USD,b
peerflix,Peerflix,,web,Palo Alto,CA,1-Mar-05,2000000,USD,a
fatdoor,fatdoor,,web,Palo Alto,CA,1-Nov-07,5500000,USD,a
fatdoor,fatdoor,,web,Palo Alto,CA,1-Feb-07,1000000,USD,angel
fatdoor,fatdoor,,web,Palo Alto,CA,1-May-07,500000,USD,debt_round
verimatrix,Verimatrix,100,web,San Diego,CA,1-Jun-06,8000000,USD,b
verimatrix,Verimatrix,100,web,San Diego,CA,1-Oct-07,5000000,USD,c
billeo,Billeo,,web,Santa Clara,CA,1-Nov-07,7000000,USD,b
billeo,Billeo,,web,Santa Clara,CA,1-Apr-06,4000000,USD,a
caring-com,Caring.com,,web,San Mateo,CA,1-Sep-07,6000000,USD,a
ask-com,Ask.com,475,web,Oakland,CA,1-Mar-99,25000000,USD,a
projectplaylist,Project Playlist,,web,Palo Alto,CA,1-Sep-07,3000000,USD,a
blowtorch,Blowtorch,,web,Sausalito,CA,1-Nov-07,50000000,USD,a
gamelayers,GameLayers,,web,San Francisco,CA,1-Oct-07,500000,USD,seed
mogreet,MoGreet,,web,Venice,CA,1-Aug-07,1200000,USD,a
mogreet,MoGreet,,web,Venice,CA,20-Jun-08,2500000,USD,b
yourtrumanshow,YourTrumanShow,,web,San Francisco,CA,1-Dec-06,1300000,USD,angel
apprema,Apprema,,web,Sunnyvale,CA,1-Nov-07,800000,USD,debt_round
trustedid,TrustedID,100,web,Redwood City,CA,1-Jan-07,5000000,USD,a
trustedid,TrustedID,100,web,Redwood City,CA,18-Oct-07,10000000,USD,b
seeqpod,Seeqpod,25,web,Emeryville,CA,1-Apr-08,7000000,USD,angel
quantenna,Quantenna,,web,Sunnyvale,CA,1-Nov-07,12700000,USD,b
qwaq,Qwaq,,web,Palo Alto,CA,1-Nov-07,7000000,USD,b
uber,uber,24,web,Beverly Hills,CA,26-May-08,7600000,USD,b
cashview,CashView,,web,Palo Alto,CA,1-Sep-07,6500000,USD,b
crossloop,CrossLoop,8,web,Monterey,CA,1-Dec-07,3000000,USD,a
dapper,Dapper,20,web,San Francisco,CA,1-Dec-07,3000000,USD,a
anchorintelligence,Anchor Intelligence,,web,Mountain View,CA,1-Jan-07,2000000,USD,a
anchorintelligence,Anchor Intelligence,,web,Mountain View,CA,1-Sep-07,4000000,USD,b
edgecast,EdgeCast,30,web,Los Angeles,CA,1-Dec-07,6000000,USD,b
edgecast,EdgeCast,30,web,Los Angeles,CA,1-Jun-07,4000000,USD,a
kosmix,Kosmix,,web,Mountain View,CA,1-Dec-07,10000000,USD,c
goodreads,GoodReads,,web,Santa Monica,CA,1-Nov-07,750000,USD,angel
ribbit,Ribbit,28,web,Mountain View,CA,1-Dec-07,10000000,USD,b
ribbit,Ribbit,28,web,Mountain View,CA,1-Oct-06,3000000,USD,a
jobvite,Jobvite,,web,San Francisco,CA,1-Dec-07,7200000,USD,a
juicewireless,Juice Wireless,,web,Los Angeles,CA,1-Apr-05,500000,USD,a
juicewireless,Juice Wireless,,web,Los Angeles,CA,1-Jan-06,1500000,USD,a
juicewireless,Juice Wireless,,web,Los Angeles,CA,1-Jul-06,4000000,USD,a
juicewireless,Juice Wireless,,web,Los Angeles,CA,1-Mar-07,3300000,USD,b
juicewireless,Juice Wireless,,web,Los Angeles,CA,1-Dec-07,6000000,USD,c
qik,Qik,22,web,Foster City,CA,9-Apr-08,3000000,USD,b
playfirst,PlayFirst,,web,San Francisco,CA,1-Dec-07,16500000,USD,c
playfirst,PlayFirst,,web,San Francisco,CA,1-Jan-06,5000000,USD,b
mobissimo,Mobissimo,15,web,San Francisco,CA,1-Apr-04,1000000,USD,seed
chumby,Chumby,20,hardware,San Diego,CA,1-Dec-06,5000000,USD,a
chumby,Chumby,20,hardware,San Diego,CA,31-Mar-08,12500000,USD,b
ugobe,UGOBE,,web,Emeryville,CA,1-Oct-06,8000000,USD,b
ausra,Ausra,4,web,Palo Alto,CA,1-Sep-07,40000000,USD,a
causes,Causes,,web,Berkeley,CA,1-Jan-07,2350000,USD,a
causes,Causes,,web,Berkeley,CA,1-Mar-08,5000000,USD,b
nanosolar,Nanosolar,,web,San Jose,CA,1-May-05,20000000,USD,b
nanosolar,Nanosolar,,web,San Jose,CA,1-Jun-06,75000000,USD,c
shozu,Shozu,,web,San Francisco,CA,1-May-05,12000000,USD,b
shozu,Shozu,,web,San Francisco,CA,29-Jan-08,12000000,USD,c
tesla-motors,Tesla Motors,270,hardware,San Carlos,CA,1-May-06,40000000,USD,c
tesla-motors,Tesla Motors,270,hardware,San Carlos,CA,1-May-07,45000000,USD,d
tesla-motors,Tesla Motors,270,hardware,San Carlos,CA,1-Apr-04,7500000,USD,a
tesla-motors,Tesla Motors,270,hardware,San Carlos,CA,1-Feb-05,13000000,USD,b
tesla-motors,Tesla Motors,270,hardware,San Carlos,CA,8-Feb-08,40000000,USD,e
bunchball,Bunchball,,web,Redwood City,CA,1-Oct-06,2000000,USD,a
bunchball,Bunchball,,web,Redwood City,CA,13-Apr-08,4000000,USD,b
pinger,Pinger,,web,San Jose,CA,1-Dec-06,8000000,USD,b
hooja,Hooja,,web,Palo Alto,CA,1-Dec-07,1250000,USD,seed
travelmuse,TravelMuse,11,web,Los Altos,CA,1-May-07,3000000,USD,a
cooking-com,Cooking.com,,web,Santa Monica,CA,1-Jan-08,7000000,USD,debt_round
cooking-com,Cooking.com,,web,Santa Monica,CA,1-May-00,35000000,USD,e
cooking-com,Cooking.com,,web,Santa Monica,CA,1-Apr-99,16000000,USD,b
cooking-com,Cooking.com,,web,Santa Monica,CA,1-Oct-99,30000000,USD,c
meraki,Meraki,,web,Mountain View,CA,1-Feb-07,5000000,USD,a
meraki,Meraki,,web,Mountain View,CA,1-Jan-08,20000000,USD,b
sugarcrm,SugarCRM,,web,Cupertino,CA,1-Jan-08,14500000,USD,d
sugarcrm,SugarCRM,,web,Cupertino,CA,1-Oct-05,18770000,USD,c
sugarcrm,SugarCRM,,web,Cupertino,CA,1-Aug-04,2000000,USD,a
sugarcrm,SugarCRM,,web,Cupertino,CA,1-Feb-05,5750000,USD,b
puddingmedia,Pudding Media,11,software,San Jose,CA,1-Jan-08,8000000,USD,a
4homemedia,4HomeMedia,10,web,Sunnyvale,CA,1-Jan-07,2850000,USD,a
pageonce,Pageonce,,web,Palo Alto,CA,1-Jan-08,1500000,USD,a
bluepulse,bluepulse,0,web,San Mateo,CA,1-Apr-07,6000000,USD,a
mogad,Mogad,2,web,San Francisco,CA,1-Aug-07,500000,USD,a
devicevm,DeviceVM,,web,San Jose,CA,1-Aug-06,10000000,USD,a
devicevm,DeviceVM,,web,San Jose,CA,1-Oct-07,10000000,USD,b
outspark,Outspark,,web,San Francisco,CA,11-Apr-07,4000000,USD,a
outspark,Outspark,,web,San Francisco,CA,1-Jan-08,11000000,USD,b
engineyard,Engine Yard,60,web,San Francisco,CA,1-Jan-08,3500000,USD,a
engineyard,Engine Yard,60,web,San Francisco,CA,13-Jul-08,15000000,USD,b
plymedia,PLYmedia,,web,Palo Alto,CA,1-Oct-06,2500000,USD,a
plymedia,PLYmedia,,web,Palo Alto,CA,1-Jul-08,6000000,USD,b
fabrik,fabrik,175,web,San Mateo,CA,1-Sep-05,4100000,USD,a
fabrik,fabrik,175,web,San Mateo,CA,1-Jun-06,8000000,USD,b
fabrik,fabrik,175,web,San Mateo,CA,1-Feb-07,14300000,USD,c
fabrik,fabrik,175,web,San Mateo,CA,1-May-07,24900000,USD,d
widgetbox,Widgetbox,,web,San Francisco,CA,1-Jun-06,1500000,USD,angel
widgetbox,Widgetbox,,web,San Francisco,CA,1-Jan-08,8000000,USD,b
widgetbox,Widgetbox,,web,San Francisco,CA,1-Jun-07,5000000,USD,a
razorgator,RazorGator,,web,Los Angeles,CA,1-Mar-05,26000000,USD,a
razorgator,RazorGator,,web,Los Angeles,CA,1-Mar-06,22800000,USD,b
oversee,OverSee,150,web,Los Angeles,CA,1-Jan-07,60000000,USD,debt_round
oversee,OverSee,150,web,Los Angeles,CA,1-Jan-08,150000000,USD,a
zynga,Zynga,90,other,San Francisco,CA,1-Jan-08,10000000,USD,a
zynga,Zynga,90,other,San Francisco,CA,22-Jul-08,29000000,USD,b
smaato,Smaato,,web,San Mateo,CA,1-Jan-08,3500000,USD,a
credit-karma,Credit Karma,4,web,San Francisco,CA,1-May-07,750000,USD,angel
greenplum,Greenplum,,web,San Mateo,CA,1-Jan-08,27000000,USD,c
greenplum,Greenplum,,web,San Mateo,CA,1-Feb-07,15000000,USD,b
greenplum,Greenplum,,web,San Mateo,CA,1-Mar-06,15000000,USD,a
greenplum,Greenplum,,web,San Mateo,CA,1-Feb-07,4000000,USD,debt_round
amobee,Amobee,,web,Redwood City,CA,1-Nov-06,5000000,USD,a
amobee,Amobee,,web,Redwood City,CA,1-Jan-07,15000000,USD,b
webmynd,WebMynd,,web,San Francisco,CA,1-Jul-08,250000,USD,angel
currenttv,Current Media,,web,San Francisco,CA,29-Jul-05,15000000,USD,a
heroku,Heroku,3,web,San Francisco,CA,1-Jan-08,20000,USD,seed
heroku,Heroku,3,web,San Francisco,CA,8-May-08,3000000,USD,a
lookery,Lookery,9,web,San Francisco,CA,7-Feb-08,900000,USD,seed
mill-river-labs,Mill River Labs,5,web,Mountain View,CA,1-Jan-08,900000,USD,angel
grouptivity,Grouptivity,10,web,San Mateo,CA,1-May-06,2000000,USD,angel
aductions,Aductions,5,web,San Jose,CA,5-Jul-07,100000,USD,seed
venturebeat,VentureBeat,,web,,CA,11-Feb-08,320000,USD,seed
collarity,Collarity,30,web,Palo Alto,CA,1-Feb-08,7800000,USD,b
rocketon,RocketOn,,web,South San Francisco,CA,1-Feb-08,5800000,USD,b
what-they-like,What They Like,10,web,San Francisco,CA,29-Aug-07,990000,USD,a
gumgum,GumGum,5,web,Los Angeles,CA,1-Dec-07,225000,USD,seed
gumgum,GumGum,5,web,Los Angeles,CA,21-Jul-08,1000000,USD,a
snap-technologies,Snap Technologies,32,web,Pasadena,CA,1-Jul-05,10000000,USD,a
twofish,TwoFish,,web,Redwood City,CA,1-Jun-07,5000000,USD,a
three-rings,Three Rings,30,web,San Francisco,CA,3-Mar-08,3500000,USD,a
smalltown,Smalltown,8,web,San Mateo,CA,1-Nov-05,4000000,USD,a
sparkplay-media,Sparkplay Media,,web,Mill Valley,CA,1-Feb-08,4250000,USD,a
mog,MOG,,web,Berkeley,CA,1-Mar-07,1800000,USD,angel
mog,MOG,,web,Berkeley,CA,29-Apr-08,2800000,USD,a
social-gaming-network,Social Gaming Network,,web,Palo Alto,CA,13-May-08,15000000,USD,a
danger,Danger,,software,Palo Alto,CA,1-Oct-01,36000000,USD,b
danger,Danger,,software,Palo Alto,CA,1-Feb-03,35000000,USD,d
danger,Danger,,software,Palo Alto,CA,1-Jul-04,37000000,USD,d
coverity,Coverity,,software,San Francisco,CA,1-Feb-08,22000000,USD,a
genietown,GenieTown,8,web,Palo Alto,CA,1-Oct-07,2000000,USD,a
redux,Redux,11,web,Berkeley,CA,1-Mar-07,1650000,USD,seed
redux,Redux,11,web,Berkeley,CA,7-Apr-08,6500000,USD,a
evernote,Evernote,,software,Sunnyvale,CA,1-Mar-06,6000000,USD,angel
evernote,Evernote,,software,Sunnyvale,CA,1-Sep-07,3000000,USD,angel
numobiq,Numobiq,,mobile,Pleasanton,CA,8-Feb-08,4500000,USD,a
goldspot-media,GoldSpot Media,,mobile,Sunnyvale,CA,23-Jan-08,3000000,USD,a
mobixell,Mobixell,,mobile,Cupertino,CA,8-Jul-08,6000000,USD,a
ad-infuse,Ad Infuse,,mobile,San Francisco,CA,23-Jan-08,12000000,USD,b
ad-infuse,Ad Infuse,,mobile,San Francisco,CA,1-Jun-06,5000000,USD,a
sendme,SendMe,,mobile,San Francisco,CA,1-Dec-06,6000000,USD,b
sendme,SendMe,,mobile,San Francisco,CA,18-Mar-08,15000000,USD,c
tiny-pictures,Tiny Pictures,15,mobile,San Francisco,CA,1-Aug-07,4000000,USD,a
tiny-pictures,Tiny Pictures,15,mobile,San Francisco,CA,1-Feb-08,7200000,USD,b
flurry,flurry,,mobile,San Francisco,CA,8-Mar-07,3500000,USD,a
sharpcast,Sharpcast,,,Palo Alto,CA,8-Mar-06,13500000,USD,a
sharpcast,Sharpcast,,,Palo Alto,CA,8-Mar-06,3000000,USD,b
teneros,Teneros,,software,Mountain View,CA,1-Jul-04,7000000,USD,a
teneros,Teneros,,software,Mountain View,CA,1-Mar-05,17500000,USD,b
teneros,Teneros,,software,Mountain View,CA,1-Apr-06,20000000,USD,c
teneros,Teneros,,software,Mountain View,CA,1-Jan-08,40000000,USD,d
photocrank,PhotoCrank,3,web,Palo Alto,CA,1-Apr-07,250000,USD,seed
yodlee,Yodlee,,web,Redwood City,CA,4-Jun-08,35000000,USD,unattributed
sliderocket,SlideRocket,5,web,San Francisco,CA,31-Dec-07,2000000,USD,a
surf-canyon,Surf Canyon,3,software,Oakland,CA,31-Jul-07,250000,USD,seed
surf-canyon,Surf Canyon,3,software,Oakland,CA,8-May-08,600000,USD,seed
central-desktop,Central Desktop,,web,Pasadena,CA,16-Apr-08,7000000,USD,a
opendns,OpenDNS,15,web,San Francisco,CA,1-Jun-06,2000000,USD,a
coveo,Coveo,,web,Palo Alto,CA,6-Mar-08,2500000,USD,a
vizu,Vizu,,web,San Francisco,CA,20-Feb-06,1000000,USD,a
vizu,Vizu,,web,San Francisco,CA,31-Jan-07,2900000,USD,b
taltopia,Taltopia,,web,Los Angeles,CA,8-Mar-08,800000,USD,angel
kapow-technologies,Kapow Technologies,,web,Palo Alto,CA,6-Mar-08,11600000,USD,c
kapow-technologies,Kapow Technologies,,web,Palo Alto,CA,1-Feb-05,5100000,EUR,b
programmermeetdesigner-com,ProgrammerMeetDesigner.com,4,web,Los Angeles,CA,12-Dec-07,500000,USD,seed
liveops,LiveOps,,web,Santa Clara,CA,1-Apr-04,22000000,USD,b
liveops,LiveOps,,web,Santa Clara,CA,13-Feb-07,28000000,USD,c
clickpass,Clickpass,,web,San Francisco,CA,1-Jun-07,20000,USD,seed
searchme,SearchMe,52,web,Mountain View,CA,1-Jul-05,400000,USD,a
searchme,SearchMe,52,web,Mountain View,CA,1-Jan-06,3600000,USD,b
searchme,SearchMe,52,web,Mountain View,CA,1-Jun-07,12000000,USD,c
searchme,SearchMe,52,web,Mountain View,CA,1-Oct-07,15000000,USD,d
searchme,SearchMe,52,web,Mountain View,CA,1-May-08,12600000,USD,e
accountnow,AccountNow,,web,San Ramon,CA,29-Jun-07,12750000,USD,c
dailystrength,DailyStrength,14,web,Los Angeles,CA,18-May-07,4000000,USD,a
popularmedia,PopularMedia,,web,San Francisco,CA,1-Mar-07,4250000,USD,unattributed
popularmedia,PopularMedia,,web,San Francisco,CA,28-Jul-08,8000000,USD,c
clarizen,Clarizen,5,web,San Mateo,CA,1-May-08,9000000,USD,b
sellpoint,SellPoint,,web,San Ramon,CA,27-Feb-08,7000000,USD,a
livedeal,LiveDeal,,web,Santa Clara,CA,26-Oct-05,4900000,USD,a
neoedge-networks,NeoEdge Networks,,web,Mountain View,CA,8-Jun-07,3000000,USD,b
zuora,Zuora,30,web,Redwood City,CA,13-Mar-08,6500000,USD,a
jivox,Jivox,,web,San Mateo,CA,10-Mar-08,2700000,USD,seed
jivox,Jivox,,web,San Mateo,CA,16-Jun-08,10700000,USD,a
elastra,Elastra,,,San Francisco,CA,15-Aug-07,2600000,USD,a
kwiry,kwiry,3,mobile,San Francisco,CA,20-Mar-07,1000000,USD,a
supplyframe,SupplyFrame,25,web,Pasadena,CA,21-Jun-07,7000000,USD,b
inmage-systems,InMage Systems,,software,Santa Clara,CA,10-Jul-07,10000000,USD,b
authenticlick,Authenticlick,20,web,Los Angeles,CA,1-Feb-06,5000000,USD,a
interneer,Interneer,6,web,Agoura Hills,CA,6-Jun-07,2000000,USD,angel
interneer,Interneer,6,web,Agoura Hills,CA,11-Mar-07,1200000,USD,a
handipoints,Handipoints,,web,Oakland,CA,2-May-07,250000,USD,seed
handipoints,Handipoints,,web,Oakland,CA,2-May-08,550000,USD,angel
adconion-media-group,Adconion Media Group,150,web,Santa Monica,CA,24-Feb-08,80000000,USD,c
zend-technologies,Zend Technologies,,,Cupertino,CA,28-Aug-06,20000000,USD,d
zend-technologies,Zend Technologies,,,Cupertino,CA,19-Jul-04,8000000,USD,c
slideshare,SlideShare,10,web,San Francisco,CA,7-May-08,3000000,USD,a
intent,Intent,6,web,Santa Monica,CA,1-Feb-08,250000,USD,angel
wikimedia-foundation,Wikimedia Foundation,,web,San Francisco,CA,25-Mar-08,3000000,USD,unattributed
clearwell-systems,Clearwell Systems,75,software,Mountain View,CA,22-Aug-07,17000000,USD,c
become,Become,,web,Mountain View,CA,25-Mar-08,17500000,USD,c
become,Become,,web,Mountain View,CA,17-Jul-08,8000000,USD,d
bubble-motion,Bubble Motion,,mobile,Mountain View,CA,26-Mar-08,14000000,USD,b
bubble-motion,Bubble Motion,,mobile,Mountain View,CA,11-Sep-06,10000000,USD,a
perfect-market,Perfect Market,,web,Pasadena,CA,9-Jul-08,15600000,USD,a
glassdoor,Glassdoor,12,web,Sausalito,CA,27-Mar-08,3000000,USD,b
support-space,Support Space,,web,Redwood City,CA,24-Oct-06,4250000,USD,a
xoopit,Xoopit,,web,San Francisco,CA,1-Dec-06,1500000,USD,angel
xoopit,Xoopit,,web,San Francisco,CA,2-Apr-08,5000000,USD,a
reality-digital,Reality Digital,,software,San Francisco,CA,1-Nov-05,2000000,USD,a
reality-digital,Reality Digital,,software,San Francisco,CA,31-Mar-08,6300000,USD,b
cloud9-analytics,Cloud9 Analytics,,web,San Mateo,CA,25-Jul-07,5000000,USD,a
netcipia,Netcipia,3,web,Palo Alto,CA,30-Aug-07,200000,USD,angel
icontrol,iControl,,web,Palo Alto,CA,2-Apr-08,15500000,USD,b
icontrol,iControl,,web,Palo Alto,CA,26-Apr-06,5000000,USD,a
frengo,Frengo,,web,San Mateo,CA,1-Dec-06,2300000,USD,a
frengo,Frengo,,web,San Mateo,CA,3-May-07,5700000,USD,b
mblox,mBlox,,mobile,Sunnyvale,CA,23-Feb-06,25000000,USD,d
mblox,mBlox,,mobile,Sunnyvale,CA,9-Mar-05,7500000,USD,c
mblox,mBlox,,mobile,Sunnyvale,CA,9-Jul-04,10000000,USD,b
mblox,mBlox,,mobile,Sunnyvale,CA,28-Jan-08,22000000,USD,e
esolar,eSolar,10,hardware,Pasadena,CA,17-Jan-08,10000000,USD,a
esolar,eSolar,10,hardware,Pasadena,CA,21-Apr-08,130000000,USD,b
marin-software,Marin Software,,software,San Francisco,CA,5-Oct-06,2000000,USD,a
marin-software,Marin Software,,software,San Francisco,CA,9-Apr-08,7250000,USD,b
sibeam,SiBEAM,,hardware,Sunnyvale,CA,1-Dec-04,1250000,USD,seed
sibeam,SiBEAM,,hardware,Sunnyvale,CA,1-May-05,15000000,USD,a
sibeam,SiBEAM,,hardware,Sunnyvale,CA,1-Aug-06,21000000,USD,b
sibeam,SiBEAM,,hardware,Sunnyvale,CA,7-Apr-08,40000000,USD,c
lumosity,Lumos Labs,,web,San Francisco,CA,11-Jun-07,400000,USD,angel
lumosity,Lumos Labs,,web,San Francisco,CA,3-Jun-08,3000000,USD,a
irise,iRise,150,software,El Segundo,CA,1-Mar-08,20000000,USD,unattributed
richrelevance,richrelevance,,web,San Francisco,CA,8-Apr-08,4200000,USD,b
labmeeting,Labmeeting,,web,,CA,1-May-08,500000,USD,seed
shopflick,Shopflick,10,web,Los Angeles,CA,15-Mar-08,1000000,USD,angel
shopflick,Shopflick,10,web,Los Angeles,CA,1-Jul-08,7000000,USD,a
turnhere,TurnHere,35,web,Emeryville,CA,15-Feb-08,7500000,USD,a
turnhere,TurnHere,35,web,Emeryville,CA,1-Nov-06,1100000,USD,seed
coupa,Coupa,,web,Foster City,CA,13-Mar-07,1500000,USD,a
coupa,Coupa,,web,Foster City,CA,9-Apr-08,6000000,USD,b
squaretrade,SquareTrade,,hardware,San Francisco,CA,1-Oct-99,400000,USD,seed
squaretrade,SquareTrade,,hardware,San Francisco,CA,10-Apr-08,9000000,USD,b
v-enable,V-Enable,,mobile,San Diego,CA,28-Feb-06,6000000,USD,c
v-enable,V-Enable,,mobile,San Diego,CA,21-Sep-03,3750000,USD,b
aeria,Aeria,,web,Santa Clara,CA,1-Oct-07,288000,USD,seed
heysan,Heysan,6,mobile,San Francisco,CA,24-Jan-07,20000,USD,seed
memeo,Memeo,,software,Aliso Viejo,CA,7-Jan-08,8100000,USD,b
imageshack,imageshack,,web,Los Gatos,CA,1-May-07,10000000,USD,a
pluggedin,PluggedIn,,,Santa Monica,CA,15-Apr-08,2000000,USD,unattributed
cellspin,CellSpin,12,mobile,San Jose,CA,1-Dec-06,1100000,USD,angel
remixation,Remixation,5,web,San Francisco,CA,1-Jul-07,1000000,USD,a
the-auteurs,The Auteurs,9,web,Palo Alto,CA,1-Aug-07,750000,USD,a
modern-feed,Modern Feed,,web,Los Angeles,CA,1-Apr-07,3000000,USD,seed
grou-ps,GROU.PS,,web,San Francisco,CA,1-Jun-08,1100000,USD,a
triggit,Triggit,5,web,San Francisco,CA,1-Mar-07,350000,USD,seed
triggit,Triggit,5,web,San Francisco,CA,1-Jul-07,500000,USD,angel
serious-business,Serious Business,6,web,San Francisco,CA,25-Apr-08,4000000,USD,unattributed
presdo,Presdo,1,web,Mountain View,CA,1-Dec-07,35000,USD,seed
nile-guide,Nile Guide,,web,San Francisco,CA,5-Jun-08,8000000,USD,b
adify,Adify,10,web,San Bruno,CA,1-Aug-06,8000000,USD,a
adify,Adify,10,web,San Bruno,CA,18-Apr-07,19000000,USD,b
invensense,Invensense,,hardware,Sunnyvale,CA,28-Apr-08,19000000,USD,c
centerd,Center'd,,web,Menlo Park,CA,1-Feb-07,1000000,USD,angel
centerd,Center'd,,web,Menlo Park,CA,1-Oct-07,5500000,USD,a
centerd,Center'd,,web,Menlo Park,CA,1-May-07,500000,USD,debt_round
new-relic,New Relic,,web,,CA,1-Apr-08,3500000,USD,a
gridstone-research,Gridstone Research,,software,San Mateo,CA,4-Apr-08,10000000,USD,b
fusionops,FusionOps,10,software,Sunnyvale,CA,1-Mar-05,4000000,USD,a
marketlive,MarketLive,,software,Foster City,CA,5-May-08,20000000,USD,e
snaplogic,SnapLogic,,software,San Mateo,CA,22-May-07,2500000,USD,a
boardwalktech,Boardwalktech,20,software,Palo Alto,CA,1-Oct-06,500000,USD,angel
rosum,Rosum,,hardware,Mountain View,CA,15-Apr-08,15000000,USD,b
citizenhawk,CitizenHawk,,,Aliso Viejo,CA,12-May-08,3000000,USD,unattributed
dilithium-networks,Dilithium Networks,,web,Petaluma,CA,23-Apr-03,10000000,USD,b
dilithium-networks,Dilithium Networks,,web,Petaluma,CA,14-Mar-05,18000000,USD,c
dilithium-networks,Dilithium Networks,,web,Petaluma,CA,3-Jul-07,33000000,USD,d
passenger,Passenger,,,Los Angeles,CA,5-Nov-07,8300000,USD,b
passenger,Passenger,,,Los Angeles,CA,14-Mar-08,12200000,USD,c
experience-project,Experience Project,,web,San Francisco,CA,13-May-08,3000000,USD,a
mozes,Mozes,,web,Palo Alto,CA,22-Feb-07,5000000,USD,a
mozes,Mozes,,web,Palo Alto,CA,1-May-08,11500000,USD,b
rvita,rVita,6,software,Santa Clara,CA,1-Oct-07,1000000,USD,angel
mefeedia,Mefeedia,6,web,Burbank,CA,19-Mar-08,250000,USD,angel
wavemaker-software,Wavemaker Software,,software,San Francisco,CA,15-Apr-08,4500000,USD,a
virtuallogix,VirtualLogix,,software,Sunnyvale,CA,11-Jul-07,16000000,USD,b
fonemesh,Fonemesh,7,software,San Francisco,CA,1-Jun-08,100000,USD,angel
fonemesh,Fonemesh,7,software,San Francisco,CA,1-May-08,150000,USD,seed
cognition-technologies,Cognition Technologies,20,software,Culver City,CA,15-Jul-08,2700000,USD,unattributed
sometrics,Sometrics,,web,Los Angeles,CA,14-May-08,1000000,USD,a
socialvibe,SocialVibe,,web,Los Angeles,CA,1-Dec-07,4200000,USD,a
deviantart,deviantART,,web,Hollywood,CA,21-Jun-07,3500000,USD,a
mythings,MyThings,,web,Menlo Park,CA,1-Apr-06,8000000,USD,a
mywaves,mywaves,35,mobile,Sunnyvale,CA,8-Dec-06,6000000,USD,a
litescape,litescape,,web,Redwood Shores,CA,6-Aug-07,14000000,USD,b
nextbio,nextbio,,,Cupertino,CA,6-Jun-07,7000000,USD,b
parascale,Parascale,,software,Cupertino,CA,23-Jun-08,11370000,USD,a
row44,Row44,,hardware,Westlake Village,CA,20-May-08,21000000,USD,a
jellycloud,JellyCloud,,web,Redwood City,CA,16-May-08,6600000,USD,a
aster-data-systems,Aster Data Systems,50,software,Redwood City,CA,1-Nov-05,1000000,USD,angel
aster-data-systems,Aster Data Systems,50,software,Redwood City,CA,1-May-07,5000000,USD,a
pixim,Pixim,,hardware,Mountain View,CA,7-Mar-07,15000000,USD,b
pixim,Pixim,,hardware,Mountain View,CA,9-Jun-05,12000000,USD,a
pixim,Pixim,,hardware,Mountain View,CA,12-Jun-07,5100000,USD,b
funambol,Funambol,,mobile,Redwood City,CA,8-Aug-05,5000000,USD,a
funambol,Funambol,,mobile,Redwood City,CA,1-Dec-06,5500000,USD,a
funambol,Funambol,,mobile,Redwood City,CA,17-Jun-08,12500000,USD,b
funambol,Funambol,,mobile,Redwood City,CA,1-Jun-08,2000000,USD,debt_round
moblyng,Moblyng,,web,Menlo Park,CA,21-May-08,5700000,USD,b
moblyng,Moblyng,,web,Menlo Park,CA,28-Mar-07,1530000,USD,a
zecter,Zecter,3,web,Mountain View,CA,1-Jun-07,15000,USD,seed
votigo,Votigo,,web,Emeryville,CA,31-Dec-07,1265000,USD,a
zinio,Zinio,,web,San Francisco,CA,12-Sep-05,7000000,USD,a
dreamfactory,Dreamfactory,,web,Mountain View,CA,8-May-06,5800000,USD,a
greennote,GreenNote,15,web,Redwood City,CA,1-Oct-07,4200000,USD,a
skyfire,Skyfire,,software,Mountain View,CA,1-Jun-07,4800000,USD,a
skyfire,Skyfire,,software,Mountain View,CA,28-May-08,13000000,USD,b
edufire,eduFire,,web,Santa Monica,CA,9-Apr-08,400000,USD,angel
b-hive-networks,B-hive Networks,,software,San Mateo,CA,25-Aug-06,7000000,USD,a
vmware,VMware,5000,,Palo Alto,CA,30-Jul-07,150000000,USD,unattributed
katalyst-media,Katalyst Media,11,,Los Angeles,CA,28-Jan-08,10000000,USD,a
neurovigil,NeuroVigil,5,biotech,La Jolla,CA,30-May-08,250000,USD,seed
sylantro,Sylantro,,web,Campbell,CA,1-Apr-06,11000000,USD,e
sylantro,Sylantro,,web,Campbell,CA,1-Nov-03,4500000,USD,d
sylantro,Sylantro,,web,Campbell,CA,1-Oct-00,55000000,USD,c
teleflip,TeleFlip,,mobile,Santa Monica,CA,1-Jan-08,4900000,USD,b
vidshadow,Vidshadow,16,web,Placentia,CA,15-Feb-07,2000000,USD,a
jigsaw,Jigsaw,,web,San Mateo,CA,1-Dec-03,750000,USD,a
jigsaw,Jigsaw,,web,San Mateo,CA,1-Sep-04,5200000,USD,b
jigsaw,Jigsaw,,web,San Mateo,CA,1-Mar-06,12000000,USD,c
ozmo-devices,Ozmo Devices,,hardware,Palo Alto,CA,30-Mar-06,12550000,USD,a
cooliris,Cooliris,,web,Menlo Park,CA,1-Jul-07,3000000,USD,a
gamook,Gamook,,web,Menlo Park,CA,16-Mar-08,1500000,USD,a
vindicia,Vindicia,,web,Redwood City,CA,31-Mar-08,5600000,USD,c
dizzywood,Dizzywood,,web,,CA,7-Feb-08,1000000,USD,a
limbo,Limbo,37,web,Burlingame,CA,25-Apr-07,8000000,USD,b
startforce,StartForce,21,web,San Francisco,CA,1-Sep-07,1000000,USD,a
coolearth,coolearth,9,cleantech,Livermore,CA,20-Feb-08,21000000,USD,a
coolearth,coolearth,9,cleantech,Livermore,CA,1-Jun-07,1000000,USD,angel
yield-software,Yield Software,,web,San Mateo,CA,4-Jun-08,6000000,USD,b
tapulous,Tapulous,9,web,Palo Alto,CA,1-Jul-08,1800000,USD,angel
codefast,Codefast,,software,San Jose,CA,15-Mar-05,6500000,USD,a
hyperic,Hyperic,,web,San Francisco,CA,10-May-06,3800000,USD,a
ipling-2,iPling :)),7,mobile,San Francisco,CA,1-Mar-08,300000,USD,angel
intacct,Intacct,100,software,San Jose,CA,29-Apr-08,15000000,USD,unattributed
intacct,Intacct,100,software,San Jose,CA,27-Jun-07,14000000,USD,unattributed
vivaty,Vivaty,25,web,Menlo Park,CA,1-Aug-07,9400000,USD,a
aurora-biofuels,Aurora Biofuels,,cleantech,Alameda,CA,10-Jun-08,20000000,USD,b
eeye,eEye,,,Irvine,CA,1-Jun-04,15000000,USD,d
eeye,eEye,,,Irvine,CA,1-Dec-02,9000000,USD,c
allbusiness-com,AllBusiness.com,50,other,San Francisco,CA,1-Jul-04,10000000,USD,b
allbusiness-com,AllBusiness.com,50,other,San Francisco,CA,1-Feb-06,12400000,USD,c
insideview,InsideView,40,software,San Francisco,CA,1-Jun-07,7400000,USD,a
clickability,Clickability,,software,San Francisco,CA,14-Jul-08,3500000,USD,debt_round
keibi-technologies,Keibi Technologies,30,software,San Francisco,CA,1-May-07,5000000,USD,b
robodynamics,RoboDynamics,5,hardware,Santa Monica,CA,1-Sep-03,100000,USD,seed
robodynamics,RoboDynamics,5,hardware,Santa Monica,CA,1-May-06,500000,USD,angel
imagespan,ImageSpan,,web,Sausalito,CA,23-Jun-08,11000000,USD,b
solarflare,Solarflare,,hardware,Irvine,CA,18-Jun-08,26000000,USD,unattributed
clupedia,Clupedia,,web,Santa Ana,CA,22-May-07,1300000,USD,a
zigabid,Zigabid,10,other,La Canada,CA,1-Dec-06,500000,USD,seed
zigabid,Zigabid,10,other,La Canada,CA,1-Feb-08,500000,USD,angel
inthrma,InThrMa,3,software,Oakland,CA,1-Oct-07,10000,USD,seed
webaroo,Webaroo,,,Santa Clara,CA,22-Dec-06,10000000,USD,b
webaroo,Webaroo,,,Santa Clara,CA,5-Jul-08,10000000,USD,c
fluid-entertainment,Fluid Entertainment,,other,Mill Valley,CA,12-Mar-08,3200000,USD,a
unisfair,Unisfair,,other,Menlo Park,CA,21-Jan-08,10000000,USD,b
on24,ON24,,web,San Francisco,CA,11-Jul-08,8000000,USD,unattributed
trueanthem,trueAnthem,10,other,Newport Beach,CA,28-Jul-08,2000000,USD,angel
colizer,Colizer,,web,San Diego,CA,1-Apr-04,120000,USD,a
coremetrics,Coremetrics,,software,San Mateo,CA,9-Mar-06,31000000,USD,d
coremetrics,Coremetrics,,software,San Mateo,CA,4-Apr-08,60000000,USD,e
minted,Minted,,,San Francisco,CA,18-Jul-08,2500000,USD,seed
appirio,Appirio,,software,San Mateo,CA,13-Mar-08,1100000,USD,a
appirio,Appirio,,software,San Mateo,CA,21-Jul-08,10000000,USD,b
samfind,samfind,3,web,Los Angeles,CA,1-Jan-06,20000,USD,seed
eye-fi,Eye-Fi,,hardware,Mountain View,CA,11-Jun-07,5500000,USD,a
responselogix,ResponseLogix,,web,Sunnyvale,CA,23-Jan-08,8000000,USD,a
qumu,Qumu,,software,Emeryville,CA,21-Jul-08,10700000,USD,c
carbonflow,CarbonFlow,,cleantech,San Francsico,CA,23-Jul-08,2900000,USD,a
allvoices,Allvoices,8,web,San Francisco,CA,31-Jul-07,4500000,USD,a
moxsie,Moxsie,,web,Palo Alto,CA,27-Jun-08,1000000,USD,a
service-now-com,Service-now.com,80,web,Solana Beach,CA,5-Jul-05,2500000,USD,a
service-now-com,Service-now.com,80,web,Solana Beach,CA,5-Dec-06,5000000,USD,b
anvato,Anvato,,web,Mountain View,CA,29-Jul-08,550000,USD,seed
vantage-media,Vantage Media,150,web,El Segundo,CA,28-Feb-07,70000000,USD,a
entone-technologies,Entone Technologies,80,hardware,San Mateo,CA,1-Aug-08,14500000,USD,b
entone-technologies,Entone Technologies,80,hardware,San Mateo,CA,2-May-03,9500000,USD,a
750-industries,750 Industries,7,web,San Francisco,CA,1-Aug-08,1000000,USD,a
plastic-logic,Plastic Logic,,hardware,Mountain View,CA,20-Apr-02,13700000,USD,a
plastic-logic,Plastic Logic,,hardware,Mountain View,CA,5-Jan-05,8000000,USD,b
plastic-logic,Plastic Logic,,hardware,Mountain View,CA,30-Nov-05,24000000,USD,c
plastic-logic,Plastic Logic,,hardware,Mountain View,CA,6-Jan-07,100000000,USD,d
plastic-logic,Plastic Logic,,hardware,Mountain View,CA,4-Aug-08,50000000,USD,e
paymo,Paymo,,mobile,San Francisco,CA,1-Aug-08,5000000,USD,seed
brilliant-telecom,Brilliant Telecom,,,Campbell,CA,4-Aug-08,11000000,USD,a
skygrid,SkyGrid,,web,Sunnyvale,CA,6-Aug-08,11000000,USD,b
intensedebate,Intense Debate,4,web,Boulder,CO,1-May-07,15000,USD,seed
associatedcontent,Associated Content,,web,Denver,CO,1-Jan-06,5400000,USD,a
associatedcontent,Associated Content,,web,Denver,CO,1-Jul-07,10000000,USD,b
madkast,madKast,,web,Boulder,CO,1-Mar-07,15000,USD,seed
madkast,madKast,,web,Boulder,CO,1-Oct-07,300000,USD,a
eventvue,EventVue,,web,Boulder,CO,1-Aug-07,15000,USD,seed
eventvue,EventVue,,web,Boulder,CO,1-Sep-07,250000,USD,a
socialthing,Socialthing!,6,web,Boulder,CO,18-May-07,15000,USD,seed
socialthing,Socialthing!,6,web,Boulder,CO,1-Oct-07,500000,USD,debt_round
jsquaredmedia,J Squared Media,,web,Boulder,CO,1-Aug-07,15000,USD,seed
searchtophone,Search to Phone,,web,Boulder,CO,1-Aug-07,15000,USD,seed
filtrbox,Filtrbox,,web,Boulder,CO,1-Aug-07,15000,USD,seed
filtrbox,Filtrbox,,web,Boulder,CO,28-Feb-08,500000,USD,seed
brightkite,Brightkite,,web,Denver,CO,1-Aug-07,15000,USD,seed
brightkite,Brightkite,,web,Denver,CO,1-Mar-08,1000000,USD,angel
newsgator,NewsGator,,web,Denver,CO,23-Jun-04,1400000,USD,a
newsgator,NewsGator,,web,Denver,CO,1-Dec-07,12000000,USD,c
newsgator,NewsGator,,web,Denver,CO,1-Dec-04,8600000,USD,b
me-dium,Me.dium,,web,Boulder,CO,1-Jun-07,15000000,USD,b
buzzwire,Buzzwire,,web,Denver,CO,1-Dec-07,8000000,USD,b
hivelive,HiveLive,,web,Boulder,CO,1-Feb-08,2200000,USD,angel
hivelive,HiveLive,,web,Boulder,CO,27-Feb-08,5600000,USD,a
xaware,XAware,,web,Colorado Springs,CO,1-Jan-08,7400000,USD,b
xaware,XAware,,web,Colorado Springs,CO,1-Nov-02,2100000,USD,a
lijit,Lijit,,web,Boulder,CO,1-Jan-07,900000,USD,a
lijit,Lijit,,web,Boulder,CO,1-Jun-07,3300000,USD,b
hubbuzz,hubbuzz,8,web,Centennial,CO,1-Mar-07,750000,USD,angel
gnip,Gnip,6,web,Boulder,CO,1-Mar-08,1100000,USD,a
collective-intellect,Collective Intellect,,,Boulder,CO,1-Feb-06,2600000,USD,a
collective-intellect,Collective Intellect,,,Boulder,CO,15-Apr-08,6600000,USD,b
rally-software,Rally Software,,software,Boulder,CO,4-Jun-08,16850000,USD,c
rally-software,Rally Software,,software,Boulder,CO,14-Jun-06,8000000,USD,b
symplified,Symplified,,software,Boulder,CO,9-Jun-08,6000000,USD,a
indeed,Indeed,,web,Stamford,CT,1-Aug-05,5000000,USD,a
geezeo,Geezeo,,web,Hartford,CT,24-Apr-08,1200000,USD,unattributed
entrecard,Entrecard,1,web,Hamden,CT,1-Jan-07,30000,USD,seed
entrecard,Entrecard,1,web,Hamden,CT,1-Nov-07,40000,USD,seed
americantowns-com,AmericanTowns.com,10,web,Fairfield,CT,1-Jul-06,1100000,USD,a
americantowns-com,AmericanTowns.com,10,web,Fairfield,CT,1-Sep-07,3300000,USD,b
kayak,Kayak,58,web,Norwalk,CT,1-May-06,11500000,USD,c
kayak,Kayak,58,web,Norwalk,CT,1-Dec-04,7000000,USD,b
kayak,Kayak,58,web,Norwalk,CT,1-Dec-07,196000000,USD,d
gocrosscampus,GoCrossCampus,25,web,New Haven,CT,1-Sep-07,375000,USD,seed
health-plan-one,Health Plan One,,web,Shelton,CT,21-Apr-08,6500000,USD,a
your-survival,Your Survival,10,web,Westport,CT,1-Nov-07,350000,USD,angel
media-lantern,Media Lantern,9,web,New London,CT,30-Mar-08,250000,USD,seed
gridpoint,GridPoint,,cleantech,Washington,DC,1-Sep-07,32000000,USD,d
gridpoint,GridPoint,,cleantech,Washington,DC,1-May-06,16000000,USD,b
gridpoint,GridPoint,,cleantech,Washington,DC,1-Sep-06,21000000,USD,c
gridpoint,GridPoint,,cleantech,Washington,DC,28-Mar-08,15000000,USD,d
hotpads-com,HotPads,11,web,Washington,DC,1-Mar-07,2300000,USD,a
launchbox,LaunchBox,,web,Washinton,DC,1-Feb-08,250000,USD,a
cogent,Cogent,,web,Washington,DC,12-Feb-08,510000,USD,seed
swapdrive,SwapDrive,,web,Washington,DC,1-May-00,2650000,USD,a
swapdrive,SwapDrive,,web,Washington,DC,1-May-01,2000000,USD,a
searchles,Searchles,,web,Washington,DC,1-Jul-08,2000000,USD,angel
payperpost,PayPerPost,,web,Orlando,FL,1-Jun-07,7000000,USD,b
payperpost,PayPerPost,,web,Orlando,FL,1-Oct-06,3000000,USD,a
affinityinternet,Affinity Internet,,web,Fort Lauderdale,FL,1-Oct-99,60000000,USD,a
affinityinternet,Affinity Internet,,web,Fort Lauderdale,FL,1-Jan-02,25000000,USD,a
multiply,Multiply,,web,Boca Raton,FL,1-Nov-05,2000000,USD,a
multiply,Multiply,,web,Boca Raton,FL,1-Jul-06,3000000,USD,a
multiply,Multiply,,web,Boca Raton,FL,1-Aug-07,16600000,USD,b
revolutionmoney,Revolution Money,,web,Largo,FL,1-Sep-07,50000000,USD,b
batanga,Batanga,,web,Miami,FL,1-Aug-07,30000000,USD,c
batanga,Batanga,,web,Miami,FL,1-Apr-06,5000000,USD,b
divorce360,divorce360,6,web,North Palm Beach,FL,1-Sep-07,2500000,USD,a
infinitybox,Infinity Box,3,web,Tampa,FL,1-Jan-06,18000,USD,seed
infinitybox,Infinity Box,3,web,Tampa,FL,1-Apr-06,100000,USD,angel
moli,MOLI,,web,West Palm Beach,FL,1-Jan-08,29600000,USD,b
global-roaming,Global Roaming,29,mobile,Miami,FL,1-Feb-07,7500000,USD,a
global-roaming,Global Roaming,29,mobile,Miami,FL,1-Feb-08,23000000,USD,b
slingpage,Slingpage,8,software,Estero,FL,1-Dec-07,2250000,USD,angel
wrapmail,WrapMail,10,web,Fort Lauderdale,FL,6-Jan-07,1100000,USD,seed
ejamming,eJamming,,web,Boca Raton,FL,2-Jun-08,150000,USD,unattributed
lehigh-technologies,Lehigh Technologies,,cleantech,Naples,FL,23-Jun-08,34500000,USD,unattributed
tournease,TournEase,10,web,Lakeland,FL,1-Apr-06,3000000,USD,angel
tournease,TournEase,10,web,Lakeland,FL,1-Mar-08,500000,USD,angel
vitrue,Vitrue,,web,Atlanta,GA,1-Oct-07,10000000,USD,b
vitrue,Vitrue,,web,Atlanta,GA,1-May-06,2200000,USD,seed
screamingsports,Screaming Sports,,web,Atlanta,GA,1-Jul-07,1250000,USD,a
berecruited,beRecruited,,web,Atlanta,GA,1-Nov-07,1200000,USD,a
mfg,MFG,,web,Atlanta,GA,1-Sep-05,14000000,USD,a
mfg,MFG,,web,Atlanta,GA,1-Jan-07,4000000,USD,c
mfg,MFG,,web,Atlanta,GA,1-Jan-08,26000000,USD,d
scintella-solutions,Scintella Solutions,2,consulting,Atlanta,GA,30-Apr-08,10000,USD,seed
scribestorm,ScribeStorm,12,web,Fairfield,IA,24-Apr-06,225000,USD,angel
balihoo,Balihoo,,mobile,Boise,ID,1-Jul-07,1500000,USD,a
balihoo,Balihoo,,mobile,Boise,ID,1-Jan-08,4000000,USD,b
info,Info,,web,Chicago,IL,1-Nov-04,8400000,USD,a
feedburner,FeedBurner,,web,Chicago,IL,1-Jun-04,1000000,USD,a
feedburner,FeedBurner,,web,Chicago,IL,1-Apr-05,7000000,USD,b
viewpoints,Viewpoints,,web,Chicago,IL,1-Jun-07,5000000,USD,a
grubhub,GrubHub,,web,Chicago,IL,1-Nov-07,1100000,USD,a
ticketsnow,TicketsNow,,web,Rolling Meadows,IL,1-Jan-07,34000000,USD,a
the-point,The Point,,web,Chicago,IL,1-Nov-07,2500000,USD,angel
the-point,The Point,,web,Chicago,IL,1-Feb-08,4800000,USD,a
inkling,Inkling,2,web,Chicago,IL,1-Jan-07,20000,USD,seed
crowdspring,crowdSPRING,6,web,Chicago,IL,23-May-08,3000000,USD,angel
ifbyphone,Ifbyphone,16,web,Skokie,IL,1-Jul-07,1500000,USD,a
fave-media,Fave Media,18,web,Chicago,IL,17-Jan-08,1600000,USD,a
accertify,Accertify,,web,Schaumburg,IL,3-Jun-08,4000000,USD,a
firefly-energy,Firefly Energy,,cleantech,Peoria,IL,14-Oct-04,4000000,USD,a
firefly-energy,Firefly Energy,,cleantech,Peoria,IL,10-Jun-08,15000000,USD,c
firefly-energy,Firefly Energy,,cleantech,Peoria,IL,13-Nov-06,10000000,USD,b
savo,SAVO,68,software,Chicago,IL,1-Sep-05,10000000,USD,a
chacha,ChaCha,75,web,Carmel,IN,1-Jan-07,6000000,USD,a
chacha,ChaCha,75,web,Carmel,IN,1-Nov-07,10000000,USD,b
betterworld,Better World Books,,web,Mishawaka,IN,7-Apr-08,4500000,USD,a
compendium-blogware,Compendium Blogware,25,software,Indianapolis,IN,1-May-07,1100000,USD,angel
compendium-blogware,Compendium Blogware,25,software,Indianapolis,IN,1-May-08,1600000,USD,angel
instagarage,Instagarage,4,web,New Orleans,LA,10-May-07,25000,USD,seed
iskoot,iSkoot,,web,Cambridge,MA,27-Feb-07,7000000,USD,b
iskoot,iSkoot,,web,Cambridge,MA,29-Nov-06,6200000,USD,a
gotuitmedia,Gotuit Media,,web,Woburn,MA,1-Dec-04,10000000,USD,b
gotuitmedia,Gotuit Media,,web,Woburn,MA,1-Dec-02,6000000,USD,a
carbonite,Carbonite,68,web,Boston,MA,1-Mar-06,2500000,USD,a
carbonite,Carbonite,68,web,Boston,MA,1-Dec-06,3500000,USD,a
carbonite,Carbonite,68,web,Boston,MA,1-May-07,15000000,USD,b
carbonite,Carbonite,68,web,Boston,MA,28-Dec-07,5000000,USD,b
scanscout,ScanScout,40,web,Boston,MA,1-Mar-07,7000000,USD,a
scanscout,ScanScout,40,web,Boston,MA,1-Apr-07,2000000,USD,angel
going,Going,,web,Boston,MA,1-Jul-07,5000000,USD,a
brightcove,Brightcove,,web,Cambridge,MA,1-Mar-05,5500000,USD,a
brightcove,Brightcove,,web,Cambridge,MA,1-Nov-05,16200000,USD,b
brightcove,Brightcove,,web,Cambridge,MA,1-Jan-07,59500000,USD,c
brightcove,Brightcove,,web,Cambridge,MA,1-Sep-06,5000000,USD,b
brightcove,Brightcove,,web,Cambridge,MA,1-May-08,4900000,USD,d
permissiontv,PermissionTV,,web,Waltham,MA,1-Jun-07,9000000,USD,c
bountii,Bountii,,web,Boston,MA,1-Jun-07,15000,USD,seed
conduitlabs,Conduit Labs,,web,Cambridge,MA,1-Aug-07,5500000,USD,a
zoominfo,ZoomInfo,80,web,Waltham,MA,1-Jul-04,7000000,USD,a
zoominfo,ZoomInfo,80,web,Waltham,MA,1-Jul-04,7000000,USD,a
vlingo,Vlingo,,web,Cambridge,MA,2-Apr-08,20000000,USD,b
vtap,Vtap,,web,Andover,MA,1-May-07,14000000,USD,b
mocospace,MocoSpace,25,mobile,Boston,MA,1-Jan-07,3000000,USD,a
quattro-wireless,Quattro Wireless,,web,Waltham,MA,1-May-07,6000000,USD,a
quattro-wireless,Quattro Wireless,,web,Waltham,MA,5-Sep-07,12300000,USD,b
care-com,Care.com,,web,Waltham,MA,1-May-07,3100000,USD,a
onforce,OnForce,,web,Lexington,MA,1-Sep-07,6750000,USD,a
onforce,OnForce,,web,Lexington,MA,12-Jan-06,15000000,USD,a
ratepoint,RatePoint,,web,Needham,MA,1-Jun-07,6500000,USD,a
updown,UpDown,5,web,Cambridge,MA,29-Jan-08,750000,USD,angel
eons,Eons,,web,Boston,MA,1-Mar-07,22000000,USD,b
eons,Eons,,web,Boston,MA,1-Apr-06,10000000,USD,a
gamerdna,GamerDNA,,web,Cambridge,MA,18-Apr-08,3000000,USD,a
mixandmeet,Mix & Meet,2,web,Cambridge,MA,29-Mar-08,400000,USD,angel
sermo,Sermo,,web,Cambridge,MA,1-Sep-07,25000000,USD,c
sermo,Sermo,,web,Cambridge,MA,1-Jan-07,9500000,USD,b
sermo,Sermo,,web,Cambridge,MA,1-Sep-06,3000000,USD,a
worklight,WorkLight,30,web,Newton,MA,1-Sep-06,5100000,USD,a
worklight,WorkLight,30,web,Newton,MA,30-Apr-08,12000000,USD,b
matchmine,MatchMine,,web,Needham,MA,1-Sep-07,10000000,USD,a
condodomain,CondoDomain,4,other,Boston,MA,1-Jun-05,300000,USD,angel
utest,uTest,10,web,Ashland,MA,1-Oct-07,2300000,USD,a
compete,Compete,,web,Boston,MA,1-Aug-07,10000000,USD,c
compete,Compete,,web,Boston,MA,6-Oct-03,13000000,USD,b
azuki,Azuki Systems,30,web,Acton,MA,1-Sep-07,6000000,USD,a
acinion,Acinion,,web,Acton,MA,1-Dec-06,5000000,USD,a
acinion,Acinion,,web,Acton,MA,1-Oct-07,16000000,USD,b
xkoto,xkoto,45,software,Waltham,MA,1-Nov-07,7500000,USD,b
hubspot,HubSpot,30,web,Cambridge,MA,1-Sep-07,5000000,USD,a
hubspot,HubSpot,30,web,Cambridge,MA,16-May-08,12000000,USD,b
hubspot,HubSpot,30,web,Cambridge,MA,1-May-06,500000,USD,seed
utterz,Utterz,3,mobile,Maynard,MA,1-Sep-07,4000000,USD,a
intronis,Intronis,,web,Boston,MA,1-Oct-07,5000000,USD,a
acquia,Acquia,11,web,Andover,MA,1-Dec-07,7000000,USD,a
a123systems,A123Systems,,web,Watertown,MA,1-Oct-07,30000000,USD,d
a123systems,A123Systems,,web,Watertown,MA,1-Jan-07,40000000,USD,c
a123systems,A123Systems,,web,Watertown,MA,1-Feb-06,30000000,USD,b
bostonpower,Boston Power,55,web,Westborough,MA,1-Nov-06,8000000,USD,a
bostonpower,Boston Power,55,web,Westborough,MA,1-Jan-08,45000000,USD,c
bostonpower,Boston Power,55,web,Westborough,MA,1-Jan-07,15600000,USD,b
visiblemeasures,Visible Measures,,web,Boston,MA,1-Mar-07,5000000,USD,a
visiblemeasures,Visible Measures,,web,Boston,MA,1-Jan-08,13500000,USD,b
visiblemeasures,Visible Measures,,web,Boston,MA,1-Jan-06,800000,USD,seed
endeca,Endeca,500,web,Cambridge,MA,1-Jun-04,15000000,USD,c
endeca,Endeca,500,web,Cambridge,MA,1-Jan-08,15000000,USD,d
endeca,Endeca,500,web,Cambridge,MA,1-Nov-01,15000000,USD,b
endeca,Endeca,500,web,Cambridge,MA,1-Jan-01,10000000,USD,a
choicestream,ChoiceStream,70,software,Cambridge,MA,1-Jan-00,15000000,USD,seed
choicestream,ChoiceStream,70,software,Cambridge,MA,22-Feb-05,7000000,USD,a
choicestream,ChoiceStream,70,software,Cambridge,MA,1-Jun-06,13100000,USD,b
choicestream,ChoiceStream,70,software,Cambridge,MA,4-Apr-07,26500000,USD,c
youcastr,YouCastr,5,web,Cambridge,MA,1-Feb-08,100000,USD,angel
jackpot-rewards,JackPot Rewards,,web,Newton,MA,1-Feb-08,16700000,USD,a
mzinga,Mzinga,,web,Burlington,MA,3-Mar-08,32500000,USD,d
dimdim,Dimdim,,web,Burlington,MA,1-Feb-07,2400000,USD,a
dimdim,Dimdim,,web,Burlington,MA,1-Jul-08,6000000,USD,b
utoopia,utoopia,2,web,Boston,MA,1-Mar-07,100000,USD,seed
retail-convergence,Retail Convergence,,web,Boston,MA,24-Apr-08,25000000,USD,a
frame-media,Frame Media,,web,Wellesley,MA,9-Nov-07,2000000,USD,a
frame-media,Frame Media,,web,Wellesley,MA,2-May-08,3000000,USD,unattributed
turbine,Turbine,200,web,Westwood,MA,29-Apr-08,40000000,USD,c
turbine,Turbine,200,web,Westwood,MA,9-May-05,30000000,USD,b
zeer,Zeer,5,web,Boston,MA,1-Aug-07,1050000,USD,angel
simpletuition,SimpleTuition,,web,Newton,MA,14-Apr-06,4400000,USD,a
simpletuition,SimpleTuition,,web,Newton,MA,18-Dec-06,7500000,USD,b
sirtris-pharmaceuticals,Sirtris Pharmaceuticals,,biotech,Cambridge,MA,1-Dec-04,13000000,USD,a
sirtris-pharmaceuticals,Sirtris Pharmaceuticals,,biotech,Cambridge,MA,8-Mar-05,27000000,USD,b
sirtris-pharmaceuticals,Sirtris Pharmaceuticals,,biotech,Cambridge,MA,19-Apr-06,37000000,USD,c
good-data,Good Data,30,web,Cambridge,MA,23-Jul-08,2000000,USD,seed
navic-networks,Navic Networks,,web,Waltham,MA,14-Feb-00,2000000,USD,a
navic-networks,Navic Networks,,web,Waltham,MA,26-Feb-01,20000000,USD,c
navic-networks,Navic Networks,,web,Waltham,MA,7-Jun-00,20000000,USD,b
pivot,Pivot,40,software,Cambridge,MA,1-Dec-04,5000000,USD,a
pivot,Pivot,40,software,Cambridge,MA,1-Apr-06,8000000,USD,b
optaros,Optaros,200,consulting,Boston,MA,1-Jun-08,12000000,USD,c
optaros,Optaros,200,consulting,Boston,MA,20-Nov-06,13000000,USD,b
optaros,Optaros,200,consulting,Boston,MA,9-Mar-05,7000000,USD,a
astaro,Astaro,,hardware,Burlington,MA,1-May-03,6200000,USD,a
astaro,Astaro,,hardware,Burlington,MA,1-May-04,6700000,USD,b
pangea-media,Pangea Media,25,web,Watertown,MA,1-Apr-07,1000000,USD,angel
posterous,Posterous,,web,Boston,MA,1-May-08,15000,USD,seed
spire,Spire,,web,Boston,MA,8-Jul-08,9000000,USD,a
neosaej,neoSaej,,web,Burlington,MA,14-Jul-08,7000000,USD,unattributed
genarts,GenArts,,software,Cambridge,MA,17-Jul-08,22400000,USD,unattributed
ordermotion,OrderMotion,,web,Boston,MA,21-Jul-08,1400000,USD,unattributed
ham-it,HAM-IT,7,web,North Andover,MA,1-Oct-07,340000,USD,seed
freewebs,Freewebs,35,web,Silver Spring,MD,1-Aug-06,12000000,USD,a
mptrax,MPTrax,4,web,Baltimore,MD,1-Jan-07,350000,USD,angel
jackbe,JackBe,0,web,Chevy Chase,MD,1-Oct-07,9500000,USD,c
zenimax,ZeniMax,,web,Rockville,MD,1-Oct-07,300000000,USD,a
zenimax,ZeniMax,,web,Rockville,MD,30-May-08,9900000,USD,a
intelliworks,Intelliworks,24,software,Rockville,MD,14-Feb-05,6000000,USD,a
intelliworks,Intelliworks,24,software,Rockville,MD,10-Oct-06,10000000,USD,b
intelliworks,Intelliworks,24,software,Rockville,MD,7-Apr-08,4000000,USD,c
hexio,HEXIO,1,web,Kennebunk,ME,10-Jan-08,20000,USD,seed
foneshow,Foneshow,8,web,Portland,ME,1-Sep-07,1050000,USD,a
foneshow,Foneshow,8,web,Portland,ME,1-Sep-06,25000,USD,seed
zattoo,Zattoo,45,web,Ann Arbor,MI,1-Nov-07,10000000,USD,b
loudclick,LoudClick,9,web,Richfield,MN,31-Mar-08,600000,USD,angel
agilis-systems,Agilis Systems,,mobile,St. Louis,MO,16-May-08,5000000,USD,b
international-liars-poker-association,International Liars Poker Association,24,other,St. Louis,MO,1-Nov-07,1250000,USD,seed
channeladvisor,ChannelAdvisor,300,web,Morrisville,NC,1-May-07,30000000,USD,c
channeladvisor,ChannelAdvisor,300,web,Morrisville,NC,22-Jan-04,7000000,USD,a
channeladvisor,ChannelAdvisor,300,web,Morrisville,NC,28-Apr-05,18000000,USD,b
yap,Yap,,mobile,Charlotte,NC,1-Mar-07,1500000,USD,angel
yap,Yap,,mobile,Charlotte,NC,10-Jun-08,6500000,USD,a
prepchamps,PrepChamps,10,web,Durham,NC,22-Apr-08,1200000,USD,a
silkroad-technology,SilkRoad technology,,,Winston-Salem,NC,19-Feb-08,10000000,USD,b
silkroad-technology,SilkRoad technology,,,Winston-Salem,NC,15-May-08,54000000,USD,c
brightdoor-systems,BrightDoor Systems,26,web,Cary,NC,13-Apr-07,200000,USD,a
drifttoit,DriftToIt,4,web,Raleigh,NC,1-Jun-07,300000,USD,angel
rpath,rPath,45,other,Raleigh,NC,24-Jan-06,6400000,USD,a
rpath,rPath,45,other,Raleigh,NC,24-Jan-07,9100000,USD,b
rpath,rPath,45,other,Raleigh,NC,24-Jun-08,10000000,USD,c
ntractive,Ntractive,6,web,Grand Forks,ND,8-Jul-08,570000,USD,a
billmelater,Bill Me Later,,web,Omaha,NE,1-Mar-06,27400000,USD,a
adaptiveblue,AdaptiveBlue,6,web,Livingston,NJ,15-Feb-07,1500000,USD,a
phanfare,Phanfare,,web,Metuchen,NJ,1-Nov-07,2500000,USD,c
phanfare,Phanfare,,web,Metuchen,NJ,1-Jul-06,2000000,USD,b
enterprisedb,EnterpriseDB,,software,Edison,NJ,7-Sep-05,7000000,USD,a
enterprisedb,EnterpriseDB,,software,Edison,NJ,1-Aug-06,20000000,USD,b
enterprisedb,EnterpriseDB,,software,Edison,NJ,25-Mar-08,10000000,USD,c
neocleus,Neocleus,,web,Jersey City,NJ,19-Jun-08,11000000,USD,b
datapipe,Datapipe,,web,Jersey City,NJ,8-Jul-08,75000000,USD,unattributed
switch2health,Switch2Health,,web,North Brunswick,NJ,16-Jul-08,200000,USD,unattributed
voltaix,Voltaix,,hardware,N. Branch,NJ,29-Jul-08,12500000,USD,unattributed
spaboom,SpaBoom,9,web,Albuquerque,NM,15-Jul-06,700000,USD,a
spaboom,SpaBoom,9,web,Albuquerque,NM,15-Sep-07,600000,USD,b
novint,Novint,,other,Albuquerque,NM,17-Jun-08,5200000,USD,unattributed
meetmoi,MeetMoi,7,web,New York City,NY,1-Jun-07,1500000,USD,a
meetup,Meetup,1,mobile,New York,NY,1-Dec-02,2800000,USD,a
meetup,Meetup,1,mobile,New York,NY,1-Nov-03,5300000,USD,b
meetup,Meetup,1,mobile,New York,NY,23-Jul-08,7500000,USD,d
mogulus,Mogulus,20,web,New York,NY,1-May-07,1200000,USD,angel
mogulus,Mogulus,20,web,New York,NY,1-Jan-08,1500000,USD,angel
mogulus,Mogulus,20,web,New York,NY,1-Jul-08,10000000,USD,a
pando,Pando,23,software,New York,NY,1-Jan-07,11000000,USD,b
pando,Pando,23,software,New York,NY,1-Mar-08,20900000,USD,c
outside-in,Outside.in,17,web,Brooklyn,NY,1-Feb-07,900000,USD,angel
outside-in,Outside.in,17,web,Brooklyn,NY,1-Oct-07,1500000,USD,angel
outside-in,Outside.in,17,web,Brooklyn,NY,19-May-08,3000000,USD,a
selectminds,SelectMinds,55,web,New York,NY,1-Aug-07,5500000,USD,a
veotag,Veotag,,web,New York,NY,1-May-07,750000,USD,angel
roo,KIT digital,100,web,New York,NY,8-May-08,15000000,USD,b
roo,KIT digital,100,web,New York,NY,18-Apr-08,5000000,USD,a
contextweb,ContextWeb,,web,New York,NY,1-Jun-04,3000000,USD,a
contextweb,ContextWeb,,web,New York,NY,1-Jun-05,9000000,USD,b
contextweb,ContextWeb,,web,New York,NY,20-Jul-08,26000000,USD,d
datranmedia,Datran Media,,web,New York,NY,1-Mar-05,60000000,USD,a
eyeblaster,Eyeblaster,,web,New York,NY,1-Dec-03,8000000,USD,a
eyeblaster,Eyeblaster,,web,New York,NY,21-Mar-07,30000000,USD,b
covestor,Covestor,,web,New York,NY,1-Jun-07,1000000,USD,angel
covestor,Covestor,,web,New York,NY,7-Apr-08,6500000,USD,a
globalgrind,Global Grind,20,web,New York,NY,1-Aug-07,4500000,USD,b
heavy-com,Heavy.com,,web,New York,NY,1-Mar-01,3000000,USD,a
spiralfrog,SpiralFrog,15,web,New York,NY,1-Dec-07,2000000,USD,debt_round
broadbandenterprises,Broadband Enterprises,,web,New York,NY,1-Jan-08,10000000,USD,a
thumbplay,Thumbplay,,mobile,New York,NY,13-Mar-08,18000000,USD,e
waterfrontmedia,Waterfront Media,,web,"Brooklyn, New York",NY,1-Sep-07,25000000,USD,d
waterfrontmedia,Waterfront Media,,web,"Brooklyn, New York",NY,1-Mar-04,4000000,USD,a
waterfrontmedia,Waterfront Media,,web,"Brooklyn, New York",NY,1-Mar-06,6000000,USD,b
waterfrontmedia,Waterfront Media,,web,"Brooklyn, New York",NY,1-Apr-07,8000000,USD,c
tutor,Tutor,55,web,New Yorl,NY,1-May-07,13500000,USD,b
daylife,Daylife,,web,New York,NY,1-Jun-07,8300000,USD,b
teachthepeople,Teach The People,1,web,Astoria,NY,1-Jan-07,300000,USD,angel
healthcare-health-human-powered-search,OrganizedWisdom,18,web,New York,NY,1-Jun-08,2300000,USD,a
snooth,Snooth,,web,New  York,NY,1-Dec-06,300000,USD,angel
snooth,Snooth,,web,New  York,NY,1-Nov-07,1000000,USD,a
5min,5min,8,web,New York,NY,1-Apr-07,300000,USD,angel
5min,5min,8,web,New York,NY,1-Nov-07,5000000,USD,a
kaltura,Kaltura,20,web,Brooklyn,NY,1-May-07,2100000,USD,a
mimeo,Mimeo,,web,New York,NY,1-Sep-07,25000000,USD,a
rayv,RayV,,web,New York,NY,1-Oct-07,8000000,USD,b
tumblr,Tumblr,,web,New York,NY,31-Oct-07,750000,USD,a
payoneer,Payoneer,50,web,New York,NY,24-Jul-08,8000000,USD,b
exelate,eXelate,,web,New York,NY,1-Oct-07,4000000,USD,a
quigo,Quigo,,web,New York,NY,1-Mar-04,5000000,USD,a
peer39,Peer39,50,web,New York,NY,1-Feb-07,3000000,USD,a
peer39,Peer39,50,web,New York,NY,1-Sep-07,8200000,USD,b
peer39,Peer39,50,web,New York,NY,1-Mar-06,500000,USD,seed
rebelmonkey,Rebel Monkey,15,web,New York,NY,5-Feb-08,1000000,USD,a
answers,Answers Corporation,,web,New York,NY,1-Jan-99,300000,USD,a
answers,Answers Corporation,,web,New York,NY,1-Apr-99,1360000,USD,b
answers,Answers Corporation,,web,New York,NY,1-Sep-99,2750000,USD,c
answers,Answers Corporation,,web,New York,NY,1-May-00,28000000,USD,d
answers,Answers Corporation,,web,New York,NY,17-Jun-08,13000000,USD,debt_round
seetoo,SeeToo,,web,New York,NY,1-Oct-07,1000000,USD,angel
drop-io-2,drop.io,,web,Brooklyn,NY,1-Nov-07,1250000,USD,a
drop-io-2,drop.io,,web,Brooklyn,NY,10-Mar-08,2700000,USD,b
motionbox,Motionbox,,web,New York,NY,1-Sep-06,4200000,USD,a
motionbox,Motionbox,,web,New York,NY,1-Dec-07,7000000,USD,b
dgplabs,DGP Labs,,web,New York,NY,1-Nov-07,4750000,USD,a
ideeli,Ideeli,,web,New York,NY,1-Dec-07,3800000,USD,a
livegamer,Live Gamer,,web,New York,NY,1-Dec-07,24000000,USD,a
weshow,WeShow,0,web,New York,NY,1-Jun-07,5000000,USD,a
etsy,Etsy,45,web,Brooklyn,NY,1-Jun-06,315000,USD,angel
etsy,Etsy,45,web,Brooklyn,NY,1-Nov-06,1000000,USD,a
etsy,Etsy,45,web,Brooklyn,NY,1-Jul-07,3250000,USD,b
etsy,Etsy,45,web,Brooklyn,NY,1-Jan-08,27000000,USD,c
phonetag,PhoneTag,10,web,New York,NY,1-Feb-07,3500000,USD,angel
phonetag,PhoneTag,10,web,New York,NY,1-Jan-04,200000,USD,seed
phonetag,PhoneTag,10,web,New York,NY,1-Dec-07,2000000,USD,angel
tremormedia,Tremor Media,,web,New York,NY,1-Sep-06,8400000,USD,a
tremormedia,Tremor Media,,web,New York,NY,1-Jan-08,11000000,USD,b
yoonew,YooNew,,web,New York,NY,1-Aug-05,2000000,USD,angel
fifthgenerationsystems,Fifth Generation Systems,,web,Roslyn Heights,NY,1-Jan-08,5300000,USD,b
fifthgenerationsystems,Fifth Generation Systems,,web,Roslyn Heights,NY,1-Nov-06,5250000,USD,a
igaworldwide,IGA Worldwide,,web,New York,NY,1-Feb-06,12000000,USD,a
igaworldwide,IGA Worldwide,,web,New York,NY,1-Jul-07,25000000,USD,b
igaworldwide,IGA Worldwide,,web,New York,NY,1-Jan-08,5000000,USD,b
theladders,TheLadders,200,web,New York,NY,8-Nov-04,7250000,USD,a
adotube,Adotube,30,web,New York,NY,31-Jul-07,630000,USD,seed
adotube,Adotube,30,web,New York,NY,1-Apr-08,600000,USD,seed
kluster,Kluster,,web,New York,NY,1-Jan-08,1000000,USD,seed
blog-talk-radio,Blog Talk Radio,,web,New York,NY,25-Jun-08,4600000,USD,a
pingg,Pingg,7,web,New York,NY,1-Jan-07,500000,USD,seed
pingg,Pingg,7,web,New York,NY,1-Mar-07,800000,USD,angel
outbrain,Outbrain,,web,New York City,NY,1-Jan-07,1000000,USD,seed
outbrain,Outbrain,,web,New York City,NY,25-Feb-08,5000000,USD,a
mochila,Mochila,52,web,New York,NY,2-Jan-07,8000000,USD,b
olx,OLX,85,web,New York City,NY,1-Sep-07,10000000,USD,a
olx,OLX,85,web,New York City,NY,11-Apr-08,13500000,USD,b
boonty,Boonty,150,web,New York,NY,7-Jul-05,10000000,USD,b
tripology,Tripology,,web,New York,NY,1-Jan-07,1250000,USD,a
the-feedroom,The Feedroom,,web,New York,NY,9-Jul-08,12000000,USD,unattributed
next-new-networks,Next New Networks,15,web,New York,NY,1-Jan-07,8000000,USD,a
next-new-networks,Next New Networks,15,web,New York,NY,12-Mar-08,15000000,USD,b
riverwired,RiverWired,,web,New York,NY,1-Mar-08,1500000,USD,seed
fynanz,Fynanz,10,web,New York,NY,1-Oct-07,500000,USD,angel
digital-railroad,Digital Railroad,,web,New York,NY,14-Jun-05,5200000,USD,a
digital-railroad,Digital Railroad,,web,New York,NY,5-Feb-07,10000000,USD,b
silicon-alley-insider,Silicon Alley Insider,,,New York,NY,16-Jul-08,900000,USD,seed
undertone-networks,Undertone Networks,,web,New York,NY,31-Mar-08,40000000,USD,a
someecards,Someecards,,web,New York,NY,1-Apr-08,350000,USD,seed
trunkt,Trunkt,2,web,New York,NY,15-May-07,350000,USD,seed
cutcaster-2,Cutcaster,6,web,New York City,NY,1-Jan-08,150000,USD,seed
visible-world,Visible World,,software,New York,NY,14-Apr-08,25000000,USD,c
visible-world,Visible World,,software,New York,NY,17-Nov-03,8000000,USD,b
mad-mimi,Mad Mimi,3,web,,NY,29-Apr-07,200000,USD,angel
social-median,Social Median,,web,New York,NY,7-Mar-08,500000,USD,seed
scanbuy,Scanbuy,,software,New York,NY,12-Dec-06,9000000,USD,b
scanbuy,Scanbuy,,software,New York,NY,1-Nov-07,6800000,USD,b
pontiflex,Pontiflex,,web,Brooklyn,NY,30-Apr-08,2500000,USD,a
chic-tv,CHIC.TV,12,web,New York,NY,1-Jan-06,500000,USD,angel
expertflyer,ExpertFlyer,3,web,Patchogue,NY,1-Mar-04,30000,USD,seed
x-1,x+1,,web,New York,NY,1-Jun-08,16000000,USD,a
media6,Media6¡,,web,New York,NY,1-May-08,2000000,USD,debt_round
knewton,Knewton,,web,New York,NY,21-May-08,2500000,USD,a
sense-networks,Sense Networks,,mobile,New York City,NY,1-Apr-08,3000000,USD,a
targetspot,TargetSpot,20,web,New York,NY,11-Mar-08,8600000,USD,b
bountyjobs,BountyJobs,31,web,New York,NY,7-Jul-08,12000000,USD,b
vbs-tv,vbs tv,40,other,Brooklyn,NY,1-Dec-06,10000000,USD,seed
instinctiv,Instinctiv,,mobile,Ithaca,NY,1-Jun-08,750000,USD,seed
visualplant,VISUALPLANT,6,web,New York,NY,1-Mar-07,500000,USD,seed
xunlight,XunLight,,hardware,Toledo,OH,7-May-08,22000000,USD,b
strands,Strands,150,web,Corvallis,OR,1-Mar-06,6000000,USD,a
strands,Strands,150,web,Corvallis,OR,18-Jun-07,25000000,USD,b
strands,Strands,150,web,Corvallis,OR,1-Dec-07,24000000,USD,b
jivesoftware,Jive Software,,web,Portland,OR,1-Aug-07,15000000,USD,a
platial,Platial,,web,Portland,OR,1-Oct-05,800000,USD,angel
platial,Platial,,web,Portland,OR,1-Feb-07,2600000,USD,a
garagegames,GarageGames,,web,Eugene,OR,1-Sep-07,50000000,USD,a
iovation,iovation,50,web,Portland,OR,27-Mar-08,15000000,USD,a
ingrid,InGrid,,web,Berwyn,PA,1-Jul-07,13500000,USD,c
ingrid,InGrid,,web,Berwyn,PA,1-Sep-04,6600000,USD,a
ingrid,InGrid,,web,Berwyn,PA,1-Jun-06,8100000,USD,b
ingrid,InGrid,,web,Berwyn,PA,1-Jun-06,1500000,USD,debt_round
sleep-fm,Sleep.FM,4,web,Philadelphia,PA,30-Mar-08,15000,USD,seed
boomi,Boomi,0,web,Berwyn,PA,1-Jul-08,4000000,USD,a
styky,Styky,3,web,Philadelphia,PA,1-Jan-07,100000,USD,seed
totaltakeout,TotalTakeout,25,web,Allentown,PA,1-Oct-07,150000,USD,seed
redlasso,RedLasso,,web,King of Prussia,PA,1-Nov-07,6500000,CAD,a
qpondirect,QponDirect,10,web,Pittsburgh,PA,1-Mar-08,300000,USD,angel
energyweb-solutions,EnergyWeb Solutions,3,web,Allentown,PA,15-Jun-06,100000,USD,seed
aria-systems,Aria Systems,,software,Media,PA,7-Nov-07,4000000,USD,a
vivsimo,Viv’simo,,software,Pittsburgh,PA,17-Mar-08,4000000,USD,a
vivsimo,Viv’simo,,software,Pittsburgh,PA,12-Jul-03,960000,USD,seed
vivsimo,Viv’simo,,software,Pittsburgh,PA,4-Jul-02,500000,USD,seed
vivsimo,Viv’simo,,software,Pittsburgh,PA,1-Jun-00,100000,USD,seed
vivsimo,Viv’simo,,software,Pittsburgh,PA,29-Jan-01,100000,USD,seed
showclix,ShowClix,,web,Oakmont,PA,1-Oct-07,150000,USD,seed
showclix,ShowClix,,web,Oakmont,PA,1-Jun-08,250000,USD,seed
biowizard,BioWizard,8,web,Wayne,PA,3-Jan-08,1000000,USD,a
ticketleap,TicketLeap,4,web,Philadelphia,PA,22-Jul-08,2000000,USD,a
tizra,Tizra,,web,Providence,RI,1-May-07,500000,USD,seed
quickoffice,Quickoffice,,software,Plano,TN,5-Feb-07,7000000,USD,b
quickoffice,Quickoffice,,software,Plano,TN,1-Jan-06,11500000,USD,a
quickoffice,Quickoffice,,software,Plano,TN,23-May-08,3000000,USD,c
thoof,Thoof,,web,Austin,TX,1-Jun-07,1000000,USD,seed
bazaarvoice,Bazaarvoice,,web,Austin,TX,1-May-06,4000000,USD,a
bazaarvoice,Bazaarvoice,,web,Austin,TX,1-Sep-07,8800000,USD,b
bazaarvoice,Bazaarvoice,,web,Austin,TX,18-Jun-08,7100000,USD,c
smallworldlabs,Small World Labs,25,web,Austin,TX,19-Mar-08,1000000,USD,a
pluck,Pluck,,web,Austin,TX,6-Oct-04,8500000,USD,b
pluck,Pluck,,web,Austin,TX,14-Nov-06,7000000,USD,c
spiceworks,Spiceworks,,web,Austin,TX,1-Aug-07,8000000,USD,b
spendview,SpendView,3,web,Houston,TX,1-Jan-08,2000000,USD,a
shangby,Shangby,,web,Austin,TX,1-Jun-07,1000000,USD,a
onnetworks,On Networks,20,web,Austin,TX,1-Nov-06,4000000,USD,a
onnetworks,On Networks,20,web,Austin,TX,1-Nov-07,12000000,USD,b
careflash,CareFlash,0,web,Houston,TX,1-Aug-07,600000,USD,seed
heliovolt,HelioVolt,,web,Austin,TX,1-Aug-07,77000000,USD,b
challenge-games,Challenge Games,,web,Austin,TX,10-Jul-08,4500000,USD,a
woot,Woot,100,web,Carrollton,TX,1-Jan-08,4000000,USD,a
mumboe,Mumboe,17,software,Austin,TX,1-Oct-06,4500000,USD,seed
breakingpoint-systems,BreakingPoint Systems,54,hardware,Austin,TX,12-Nov-07,15000000,USD,c
famecast,FameCast,,web,Austin,TX,1-Jun-07,4500000,USD,a
click-forensics,Click Forensics,,web,Austin,TX,1-Jan-07,5000000,USD,a
click-forensics,Click Forensics,,web,Austin,TX,1-Mar-08,10000000,USD,b
godtube,GodTube,,web,Plano,TX,4-May-08,30000000,USD,b
ibiz-software,iBiz Software,50,software,Dallas,TX,20-May-08,250000,USD,angel
cosential,Cosential,10,web,Austin,TX,1-Jun-02,250000,USD,b
jad-tech-consulting,JAD Tech Consulting,6,consulting,Richardson,TX,23-Sep-93,125000,USD,seed
mozy,Mozy,26,web,American Fork,UT,1-May-05,1900000,USD,a
mozy,Mozy,26,web,American Fork,UT,1-May-05,1900000,USD,a
ancestry-com,Ancestry.com,,web,Provo,UT,1-Sep-99,33200000,USD,b
movenetworks,Move Networks,,web,American Fork,UT,1-Dec-06,11300000,USD,a
movenetworks,Move Networks,,web,American Fork,UT,1-Oct-07,34000000,USD,b
movenetworks,Move Networks,,web,American Fork,UT,14-Apr-08,46000000,USD,c
worldvitalrecords,World Vital Records,,web,Provo,UT,1-Sep-07,1200000,USD,a
bungee-labs,Bungee Labs,38,web,Orem,UT,14-Mar-08,8000000,USD,c
footnote,Footnote,31,web,Lindon,UT,1-Jan-08,8000000,USD,a
mediaforge,mediaFORGE,,web,Salt Lake City,UT,1-Jul-06,1500000,USD,a
teamstreamz,TeamStreamz,5,web,Lehi,UT,10-Jan-07,80000,USD,seed
exinda,Exinda,,software,Sandy,UT,22-May-04,6000000,USD,a
clearspring,Clearspring,,web,McLean,VA,1-May-06,2500000,USD,a
clearspring,Clearspring,,web,McLean,VA,1-Feb-07,5500000,USD,b
clearspring,Clearspring,,web,McLean,VA,20-May-08,18000000,USD,c
clearspring,Clearspring,,web,McLean,VA,27-Jul-07,10000000,USD,b
availmedia,Avail Media,,web,Reston,VA,1-May-07,17000000,USD,b
availmedia,Avail Media,,web,Reston,VA,1-Nov-07,25000000,USD,c
fortiusone,FortiusOne,,web,Arlington,VA,1-May-07,5450000,USD,b
mixx,Mixx,,web,,VA,1-Sep-07,1500000,USD,a
mixx,Mixx,,web,,VA,1-Feb-08,2000000,USD,b
jobfox,Jobfox,,web,McLean,VA,1-Jan-08,20000000,USD,c
jobfox,Jobfox,,web,McLean,VA,1-May-06,13000000,USD,b
jobfox,Jobfox,,web,McLean,VA,1-Apr-05,7000000,USD,a
healthcentral,HealthCentral,,web,Arlington,VA,1-Jan-08,50000000,USD,a
comscore,comScore,,web,Reston,VA,6-Jun-02,20000000,USD,d
comscore,comScore,,web,Reston,VA,13-Aug-01,15000000,USD,c
visualcv,VisualCV,20,web,Reston,VA,28-Jan-08,2000000,USD,a
paxfire,Paxfire,,,Sterling,VA,22-May-05,2100000,USD,a
shoutwire,ShoutWire,9,web,,VA,1-Jan-07,500000,USD,a
parature,Parature,100,software,Vienna,VA,5-Jul-06,13500000,USD,a
parature,Parature,100,software,Vienna,VA,7-May-08,16000000,USD,b
loladex,Loladex,2,web,Leesburg,VA,1-Nov-07,350000,USD,seed
appian,Appian,120,software,Vienna,VA,21-Jul-08,10000000,USD,a
fortisphere,Fortisphere,50,software,Chantilly,VA,19-Nov-07,10000000,USD,a
wetpaint,Wetpaint,35,web,Seattle,WA,1-Oct-05,5250000,USD,a
wetpaint,Wetpaint,35,web,Seattle,WA,1-Jan-07,9500000,USD,b
wetpaint,Wetpaint,35,web,Seattle,WA,1-May-05,25000000,USD,c
jobster,Jobster,,web,Seattle,WA,1-Aug-05,19500000,USD,b
jobster,Jobster,,web,Seattle,WA,1-Jul-06,18000000,USD,c
jobster,Jobster,,web,Seattle,WA,1-Jan-05,8000000,USD,a
jobster,Jobster,,web,Seattle,WA,25-Apr-08,7000000,USD,d
yapta,Yapta,,web,Seattle,WA,1-Jul-07,2300000,USD,a
yapta,Yapta,,web,Seattle,WA,1-Jul-07,700000,USD,seed
farecast,Farecast,26,web,Seattle,WA,1-Oct-04,1500000,USD,a
farecast,Farecast,26,web,Seattle,WA,1-Jul-05,7000000,USD,b
farecast,Farecast,26,web,Seattle,WA,1-Jan-07,12100000,USD,c
hautesecure,Haute Secure,,web,Seattle,WA,1-Jan-07,500000,USD,a
newsvine,Newsvine,6,web,Seattle,WA,1-Jul-05,1250000,USD,a
ilike,iLike,28,web,Seattle,WA,1-Jan-06,2500000,USD,a
ilike,iLike,28,web,Seattle,WA,1-Jan-06,13300000,USD,b
redfin,Redfin,100,web,Seattle,WA,1-Jul-07,12000000,USD,c
redfin,Redfin,100,web,Seattle,WA,1-May-06,8000000,USD,b
redfin,Redfin,100,web,Seattle,WA,1-Sep-05,770000,USD,a
actionengine,Action Engine,,web,Bellevue,WA,1-May-00,7700000,USD,a
actionengine,Action Engine,,web,Bellevue,WA,1-May-02,5000000,USD,b
actionengine,Action Engine,,web,Bellevue,WA,1-Jan-05,10000000,USD,d
actionengine,Action Engine,,web,Bellevue,WA,1-Mar-03,15500000,USD,c
actionengine,Action Engine,,web,Bellevue,WA,1-Jul-07,20000000,USD,e
wishpot,Wishpot,,web,Seattle,WA,29-Apr-08,1000000,USD,a
payscale,PayScale,,web,Seattle,WA,1-Oct-04,3200000,USD,a
payscale,PayScale,,web,Seattle,WA,1-Oct-05,7000000,USD,b
payscale,PayScale,,web,Seattle,WA,1-Jul-07,10300000,USD,c
buddytv,BuddyTV,,web,Seattle,WA,1-Jul-07,2800000,USD,a
buddytv,BuddyTV,,web,Seattle,WA,1-May-07,250000,USD,angel
buddytv,BuddyTV,,web,Seattle,WA,14-Apr-08,6000000,USD,b
judysbook,Judys Book,,web,Seattle,WA,1-Jul-04,2500000,USD,a
judysbook,Judys Book,,web,Seattle,WA,1-Nov-05,8000000,USD,b
sampa,Sampa,4,web,Redmond,WA,1-Aug-07,310000,USD,angel
sampa,Sampa,4,web,Redmond,WA,2-Apr-08,1000000,USD,angel
zango,Zango,225,web,Bellevue,WA,1-Mar-04,40000000,USD,a
cdigix,Cdigix,,web,Seattle,WA,1-Nov-05,4000000,USD,a
ripl,Ripl,,web,Seattle,WA,1-Aug-07,4500000,USD,b
eyejot,EyeJot,5,web,Seattle,WA,1-May-07,750000,USD,seed
eyejot,EyeJot,5,web,Seattle,WA,1-Jan-07,400000,USD,angel
flowplay,FlowPlay,6,web,Seattle,WA,1-May-07,500000,USD,angel
flowplay,FlowPlay,6,web,Seattle,WA,6-Feb-08,3700000,USD,a
smartsheet,SmartSheet,,web,Bellevue,WA,1-Jun-07,2690000,USD,a
visibletechnologies,Visible Technologies,60,web,Seattle,WA,1-Aug-06,3500000,USD,a
visibletechnologies,Visible Technologies,60,web,Seattle,WA,1-Sep-07,12000000,USD,b
zillow,Zillow,155,web,Seattle,WA,1-Oct-05,32000000,USD,a
zillow,Zillow,155,web,Seattle,WA,1-Jul-06,25000000,USD,b
zillow,Zillow,155,web,Seattle,WA,1-Sep-07,30000000,USD,c
seomoz,SEOMoz,,web,Seattle,WA,1-Sep-07,1250000,USD,a
docusign,DocuSign,,web,Seattle,WA,1-Apr-06,10000000,USD,b
docusign,DocuSign,,web,Seattle,WA,1-Sep-07,12400000,USD,c
adready,AdReady,33,web,Seattle,WA,1-Dec-07,10000000,USD,b
treemo,Treemo,,web,Seattle,WA,1-Oct-07,2550000,USD,a
gridnetworks,GridNetworks,,web,Seattle,WA,1-Oct-07,9500000,USD,a
pelago,Pelago,,web,Seattle,WA,1-Nov-06,7400000,USD,a
pelago,Pelago,,web,Seattle,WA,27-May-08,15000000,USD,b
blist,Blist,,web,Seattle,WA,20-Feb-08,6500000,USD,a
realself,RealSelf,,web,Seattle,WA,1-Oct-07,1000000,USD,angel
rescuetime,RescueTime,,web,Seattle,WA,14-Oct-07,20000,USD,seed
zoji,Zoji,,web,Seattle,WA,1-Oct-07,1500000,USD,angel
snapvine,Snapvine,20,web,Seattle,WA,1-Jul-06,2000000,USD,a
snapvine,Snapvine,20,web,Seattle,WA,1-Sep-07,10000000,USD,b
jott,Jott,,web,Seattle,WA,1-May-07,5400000,USD,b
earthclassmail,Earth Class Mail,,web,Seattle,WA,1-Sep-07,7400000,USD,a
earthclassmail,Earth Class Mail,,web,Seattle,WA,1-Jan-08,5900000,USD,a
smilebox,Smilebox,,web,Redmond,WA,1-Feb-06,5000000,USD,a
smilebox,Smilebox,,web,Redmond,WA,1-Dec-07,7000000,USD,b
fyreball,Fyreball,,web,Bellevue,WA,1-Dec-07,1000000,USD,angel
delve-networks,Delve Networks,,web,Seattle,WA,1-Dec-06,1650000,USD,seed
delve-networks,Delve Networks,,web,Seattle,WA,1-Aug-07,8000000,USD,a
livemocha,LiveMocha,,web,Bellevue,WA,1-Jan-08,6000000,USD,a
mercentcorporation,Mercent Corporation,50,web,Seattle,WA,1-Jan-08,6500000,USD,b
cleverset,CleverSet,,web,Seattle,WA,1-Aug-06,1400000,USD,angel
cleverset,CleverSet,,web,Seattle,WA,1-Sep-05,1200000,USD,a
cleverset,CleverSet,,web,Seattle,WA,1-Dec-07,500000,USD,angel
liquidplanner,LiquidPlanner,11,web,Bellevue,WA,1-Jan-08,1200000,USD,angel
limeade,Limeade,10,web,Bellevue,WA,1-Jan-07,1000000,USD,angel
yodio,Yodio,4,web,Bellevue,WA,1-Feb-08,850000,USD,a
tastemakers,Tastemakers,11,web,Seattle,WA,1-Feb-07,1000000,USD,a
whitepages-com,WhitePages.com,125,web,Seattle,WA,1-Aug-05,45000000,USD,a
revenuescience,RevenueScience,,web,Seattle,WA,19-Dec-05,24000000,USD,e
gotvoice,GotVoice,15,mobile,Kirkland,WA,25-Oct-06,3000000,USD,a
cardomain-network,CarDomain Network,50,web,Seattle,WA,29-Jun-07,3000000,USD,a
mpire,mpire,,web,Seattle,WA,1-Feb-07,9800000,USD,a
mpire,mpire,,web,Seattle,WA,11-Jun-08,10000000,USD,b
teachstreet,TeachStreet,8,web,Seattle,WA,1-Mar-08,2250000,USD,a
estately,Estately,,web,,WA,24-Apr-08,450000,USD,angel
infinia,Infinia,,cleantech,Kennewick,WA,22-Apr-08,57000000,USD,b
infinia,Infinia,,cleantech,Kennewick,WA,1-Jun-07,9500000,USD,a
m-metrics,M:Metrics,,web,Seattle,WA,16-Oct-05,7000000,USD,b
cozi,Cozi,26,software,Seattle,WA,11-Jul-07,3000000,USD,a
cozi,Cozi,26,software,Seattle,WA,1-Jun-08,8000000,USD,c
trusera,Trusera,15,web,Seattle,WA,1-Jun-07,2000000,USD,angel
alerts-com,Alerts.com,,web,Bellevue,WA,8-Jul-08,1200000,USD,a
myrio,Myrio,75,software,Bothell,WA,1-Jan-01,20500000,USD,unattributed
grid-networks,Grid Networks,,web,Seattle,WA,30-Oct-07,9500000,USD,a
grid-networks,Grid Networks,,web,Seattle,WA,20-May-08,10500000,USD,b`,
}
export default SampleData
