const Constant = {
  APP_LOGO: 'Ɱ',
  APP_NAME: 'M Junaidi',
  HOME: {
    logo: '💻',
    title: 'Software Development Tools & Experiments',
    description: 'Build your software faster 🏎 and have fun doing it 😉',
  },
  ABOUT: {
    description: 'Tools & Experiments in Software Development \u{1f4a1}',
    version: '1.0',
    link: 'https://www.mjunaidi.com',
    country: '\u{1f1f2}\u{1f1fe} Malaysia',
    portfolio: 'https://mjunaidi.github.io',
    email: 'mjunaidijusoh-82@yahoo.com',
    linkedin: 'https://www.linkedin.com/in/m-junaidi-963983143',
    blog: 'https://www.techcycle.me',
    codepen: 'https://codepen.io/mjunaidi',
  },
  APPS: [
    {
      icon: '/img/app-icon/math-qcalc-icon.jpg',
      name: 'Math qCalculator',
      url: 'https://itunes.apple.com/us/app/math-qcalculator/id1435296651',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Utilities'],
    },
    {
      icon: '/img/app-icon/matrix-qcalc-icon.jpg',
      name: 'Matrix qCalculator',
      url: 'https://itunes.apple.com/us/app/matrix-qcalculator/id1434505248',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Utilities'],
    },
    {
      icon: '/img/app-icon/panoramic.jpeg',
      name: 'Panoramic Wallpapers',
      url: 'https://itunes.apple.com/us/app/panoramic-wallpapers/id1392514688',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Utilities'],
    },
    {
      icon: '/img/app-icon/panoramic-premium.jpg',
      name: 'Panoramic Wallpapers (Premium)',
      url:
        'https://itunes.apple.com/us/app/panoramic-wallpapers-premium/id1407249067',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Utilities'],
    },
    {
      icon: '/img/app-icon/letsgofish.jpeg',
      name: "Let's GoFish!",
      url: 'https://itunes.apple.com/us/app/lets-gofish/id1347516992',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Game'],
    },
    {
      icon: '/img/app-icon/memofish.jpeg',
      name: 'MemoFish!',
      url: 'https://itunes.apple.com/us/app/memofish/id1350346406',
      year: 2018,
      lead: '',
      tags: ['iOS', 'Game'],
    },
    {
      icon: '/img/app-icon/moonstone.jpg',
      name: 'Get The Moonstone',
      url: 'https://itunes.apple.com/us/app/get-the-moonstone/id918752449',
      year: 2014,
      lead: '',
      tags: ['iOS', 'Game'],
    },
  ],
};

export default Constant;
