import React from 'react';
import ReactXBootstrapButton from '../reactxbootstrap/ReactXBootstrapButton';
import ReactXBootstrapBadge from '../reactxbootstrap/ReactXBootstrapBadge';
import ReactXBootstrapCallout from '../reactxbootstrap/ReactXBootstrapCallout';
import ReactXBootstrapDetails from '../reactxbootstrap/ReactXBootstrapDetails';
import { A } from '../Util';

const list = [
  { name: 'Button', Component: ReactXBootstrapButton },
  { name: 'Badge', Component: ReactXBootstrapBadge },
  { name: 'Callout', Component: ReactXBootstrapCallout },
  { name: 'Details', Component: ReactXBootstrapDetails },
];

export default function ReactXBootstrap(props) {
  return (
    <div className="ReactXBootstrap container-lg mb-5">
      <h1 className="text-center">{'\u269b'} React 16 &times; Bootstrap 4</h1>
      <p className="lead text-center"></p>

      <div className="row">
        <div className="col-2">
          <div className="list-group sidebar w-100">
            <h6 className="lead text-center mb-3 pt-3">Jump to</h6>
            {list.map((e, i) => {
              const { name } = e;
              return (
                <A
                  key={i}
                  shadow
                  className="list-group-item list-group-item-action border-0"
                  href={`#${name}`}
                >
                  {name}
                </A>
              );
            })}
          </div>
        </div>
        <div className="col-10">
          {list.map((e, i) => {
            const { Component } = e;
            return <Component key={i} />;
          })}
        </div>
      </div>
    </div>
  );
}
