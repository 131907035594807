import React from 'react';
import './App.css';
import './style/style.css';
import './style/animate.override.css';
import './style/mjunaidi.css';
import './style/media.css';
import './style/override.css';
import Body from './component/Body';

function App() {
  return <Body />;
}

export default App;
