import React from 'react';
import { Alert } from '../Util';

const TITLE = 'JSON Formatter';

function fromStr(s) {
  if (typeof s === 'string' && s.length > 0) {
    try {
      const o = JSON.parse(s);
      if (typeof o === 'object' && o !== null) {
        return o;
      }
    } catch (e) {}
  }
  return null;
}

function msgOf(o) {
  if (typeof o === 'object' && o !== null)
    return { type: 'success', value: 'OK' };
  return { type: 'warning', value: 'Invalid JSON string' };
}

export default function JsonFormatter(props) {
  const [value, setValue] = React.useState(
    '{"name":"John Donohue","location":"Cyberjaya, MY"}',
  );

  const result = fromStr(value);

  return (
    <div className="container-lg animated faster bounceInRight">
      <h1 className="text-center text-white text-shadow mb-3 animated faster slideInDown delay-1s">
        {TITLE}
      </h1>

      <div className="container-fluid p-3 bg-white rounded shadow">
        <Alert {...msgOf(result)} shadow />

        <div className="row">
          <div className="col-6">
            <label className="lead">Write your JSON string here</label>
            <textarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows="10"
              className="form-control"
            />
          </div>
          <div className="col-6">
            <textarea
              value={value}
              readOnly
              rows={1}
              className="form-control mb-2"
            />
            <pre className="bg-dark text-light p-3 rounded shadow">
              <code>{JSON.stringify(result, null, 2)}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
