import React from 'react'
import {lipsum,A,randomBgGrandientDarkStyle} from '../Util'
import 'animate.css'

const ListGroup=props=>{
  const {list,selected,onClick,className} = props
  if (Array.isArray(list)&&list.length>0) {
    return (
      <div className="list-group shadow">
        {
          list.map((e,i)=>(
            <button key={i} type="button" className={`list-group-item list-group-item-action ${className||''} ${i===selected?'active':''}`} onClick={()=>{
                if (typeof(onClick)==='function') {
                  onClick(e,i)
                }
              }}>{e.title}</button>
          ))
        }
      </div>
    )
  }
  return null
}

const Select=props=>{
  const {options,value,onChange} = props
  if (Array.isArray(options)&&options.length>0) {
    return (
      <select className="form-control" value={value} onChange={event=>{
          if (typeof(onChange)==='function') {
            const {value} = event.target
            onChange(value)
          }
        }}>
        {
          options.map((e,i)=>(
            <option key={i}>{e}</option>
          ))
        }
      </select>
    )
  }
  return null
}

const Animation = {
  in: [
    'fadeIn', 'fadeInDown', 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceInLeft', 'bounceInRight', 'lightSpeedIn', 'flipInX', 'flipInY', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight', 'rollIn', 'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp',
  ],
  out: [
    'fadeOut', 'fadeOutDown', 'fadeOutLeft', 'fadeOutRight', 'fadeOutUp', 'bounceOutLeft', 'bounceOutRight', 'lightSpeedOut', 'flipOutX', 'flipOutY', 'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight', 'hinge', 'rollOut', 'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp',
  ],
}

export default class ReactXCssAnimation extends React.Component {
  state={}
  componentDidMount() {
    const list = (new Array(3)).fill(0).map((e,i)=>{
      const id = i
      const title = lipsum(8)
      const lead = lipsum(12)
      const body = lipsum(50)
      return {id,title,lead,body}
    })
    const selected = 0
    const inAnimation = Animation.in[0]
    const outAnimation = Animation.out[0]
    const random = true
    const style = {background:randomBgGrandientDarkStyle()}
    this.setState({list,selected,inAnimation,outAnimation,random,style})
  }
  done=()=>{
    const {random} = this.state
    if (random) {
      this.setState((prevState,props)=>{
        const inAnimation = Animation.in[Math.floor(Math.random()*Animation.in.length)]
        const outAnimation = Animation.out[Math.floor(Math.random()*Animation.out.length)]
        return {
          inAnimation,
          outAnimation,
        }
      })
    }
  }
  choose=(e,i)=>{
    const {list,selected} = this.state
    if (i!==selected) {
      if (typeof(i)==='number'&&i>=0&&i<list.length) {
        const {outAnimation,inAnimation} = this.state
        this.setState({bounce:`${outAnimation} animated`},()=>{
          let timeout = 499
          if (outAnimation==='hinge') {
            timeout = 1099
          }
          setTimeout(()=>{
            this.setState({selected:i},()=>{
              this.setState({bounce:`${inAnimation} animated`},this.done)
            })
          },timeout)
        })
      }
    }
  }
  renderList() {
    const {list,selected} = this.state
    return (
      <ListGroup list={list} selected={selected} onClick={this.choose} className="text-justify" />
    )
  }
  renderSelected() {
    const {selected,list,bounce} = this.state
    if (typeof(selected)==='number'&&selected>=0&&selected<list.length) {
      const entry = list[selected]
      if (typeof(entry)==='object'&&entry!==null) {
        const {title,lead,body} = entry
        return (
          <div className={`main-content text-justify p-5 bg-white rounded shadow ${bounce||''}`}>
            <h3>{title}</h3>
            <p className="lead">{lead}</p>
            <p className="body">{body}</p>
          </div>
        )
      }
    }
    return null
  }
  setIn=v=>{
    if (typeof(v)==='string'&&v.length>0) {
      this.setState({inAnimation:v})
    }
  }
  setOut=v=>{
    if (typeof(v)==='string'&&v.length>0) {
      this.setState({outAnimation:v})
    }
  }
  toggleRandom=e=>{
    this.setState({random:e.target.checked})
  }
  renderOptions() {
    const {inAnimation,outAnimation,random} = this.state
    return (
      <div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Exit:</label> {outAnimation}
          </div>
          <div className="col">
            <label className="font-weight-bold">Entrance:</label> {inAnimation}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <Select options={Animation.out} value={outAnimation} onChange={this.setOut} />
          </div>
          <div className="col">
            <Select options={Animation.in} value={inAnimation} onChange={this.setIn} />
          </div>
        </div>
        <div>
          <div className={`form-group form-check mb-0`}>
            <input id="toggleRandom" type="checkbox" className="form-check-input" onChange={this.toggleRandom} value={random} defaultChecked />
            <label htmlFor="toggleRandom" className="form-check-label">Random on change</label>
          </div>
        </div>
      </div>
    )
  }
  renderNav() {
    const {selected,list} = this.state
    let next = selected+1
    if (Array.isArray(list)&&next>=list.length) {
      next = 0
    }
    let prev = selected-1
    if (Array.isArray(list)&&prev<0) {
      prev = list.length-1
    }
    return (
      <div className="row no-gutters mb-2">
        <div className="col-2">
          <button className="btn btn-outline-success btn-sm shadow" onClick={()=>this.choose(null,prev)}>{'\u2190'}</button>
        </div>
        <div className="col-8">
          <h4 className="text-center">Choose</h4>
        </div>
        <div className="col-2">
          <button className="btn btn-outline-success btn-sm shadow" onClick={()=>this.choose(null,next)}>{'\u2192'}</button>
        </div>
      </div>
    )
  }
  render() {
    const {style} = this.state
    return (
      <div className="container-fluid">
        <h1 className="text-center">CSS3 Animation with <A href="https://daneden.github.io/animate.css" blank>Animate.css</A></h1>

        <div className="row p-3 shadow rounded text-white" style={style}>
          <div className="col">
            {this.renderOptions()}
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            {this.renderNav()}
            {this.renderList()}
          </div>
          <div className="col-8">
            {this.renderSelected()}
          </div>
        </div>
      </div>
    )
  }
}
