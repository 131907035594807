import React from 'react';
import './clock.css';

function offsetWidthOf(el) {
  if (typeof el === 'object' && el !== null) {
    const { current } = el;
    if (typeof current === 'object' && current !== null) {
      return current.offsetWidth;
    }
  }
  return 0;
}

export default function AnalogClock(props) {
  const { timezone } = props;

  let now = new Date();

  let tz = 0;
  if (typeof timezone === 'number') {
    tz = (now.getTimezoneOffset() + timezone * 60) * 60000; // in milliseconds
    now = new Date(Date.now() + tz);
  }

  const sec = now.getSeconds();
  const ms = now.getMilliseconds();
  const min = now.getMinutes();
  const hour = (now.getHours() % 12) + min / 60;

  const secondhand = React.useRef();
  const minutehand = React.useRef();
  const hourhand = React.useRef();

  const [w, setW] = React.useState();
  const clock = React.useRef();
  if (!w && offsetWidthOf(clock) !== w) {
    setW(offsetWidthOf(clock));
  }

  let resizeTimeout;
  window.addEventListener(
    'resize',
    () => {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
          resizeTimeout = null;
          const { current } = clock;
          if (typeof current === 'object' && current !== null) {
            setW(current.offsetWidth);
          }
        }, 66);
      }
    },
    false,
  );

  const [tick, setTick] = React.useState(true);

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      secondhand.current.setAttribute(
        'transform',
        `rotate(${(sec + ms / 1000) * 6},50,50)`,
      );
      minutehand.current.setAttribute('transform', `rotate(${min * 6},50,50)`);
      hourhand.current.setAttribute(
        'transform',
        `rotate(${(hour + tz) * 30},50,50)`,
      );
      setTick(!tick);
    }, 60); // use 1000 for 1-sec tick, smaller for smoother ticking
    return () => window.clearInterval(timer);
  }, [tick, hour, min, sec, ms, tz]);

  return (
    <div ref={clock}>
      <svg id="clock" viewBox="0 0 100 100" width={w} height={w}>
        <defs>
          <filter
            id="dropshadow"
            x="-33%"
            y="-33%"
            width="180%"
            height="180%"
            filterUnits="userSpaceOnUse"
          >
            <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
            <feOffset dx="5" dy="5" result="offsetblur" />
            <feOffset dx="-5" dy="-5" result="offsetblur" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <circle
          id="face"
          cx="50"
          cy="50"
          r="45"
          style={{ filter: 'url(#dropshadow)' }}
          fill="azure"
        />
        <g id="ticks">
          <line x1="50" y1="5.000" x2="50.00" y2="10.00" />
          <line x1="72.50" y1="11.03" x2="70.00" y2="15.36" />
          <line x1="88.97" y1="27.50" x2="84.64" y2="30.00" />
          <line x1="95.00" y1="50.00" x2="90.00" y2="50.00" />
          <line x1="88.97" y1="72.50" x2="84.64" y2="70.00" />
          <line x1="72.50" y1="88.97" x2="70.00" y2="84.64" />
          <line x1="50.00" y1="95.00" x2="50.00" y2="90.00" />
          <line x1="27.50" y1="88.97" x2="30.00" y2="84.64" />
          <line x1="11.03" y1="72.50" x2="15.36" y2="70.00" />
          <line x1="5.000" y1="50.00" x2="10.00" y2="50.00" />
          <line x1="11.03" y1="27.50" x2="15.36" y2="30.00" />
          <line x1="27.50" y1="11.03" x2="30.00" y2="15.36" />
        </g>
        <g id="numbers">
          <text x="50" y="18">
            12
          </text>
          <text x="85" y="53">
            3
          </text>
          <text x="50" y="88">
            6
          </text>
          <text x="15" y="53">
            9
          </text>
        </g>
        <g id="hands">
          <line id="hourhand" ref={hourhand} x1="50" y1="50" x2="50" y2="24" />
          <line
            id="minutehand"
            ref={minutehand}
            x1="50"
            y1="50"
            x2="50"
            y2="20"
          />
          <line
            id="secondhand"
            ref={secondhand}
            x1="50"
            y1="50"
            x2="50"
            y2="16"
          />
        </g>
      </svg>
    </div>
  );
}
