import React from 'react';
import { useTranslation } from 'react-i18next';

export default function UnderConstruction(props) {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div
        className="w-100 place-items-center"
        style={{
          background:
            "url('/img/undraw/undraw_under_construction_46pa.svg') center center / contain no-repeat",
          height: 360,
        }}
      >
        <h1 className="text-white text-center text-shadow">
          {t('Work-in-progress')}
        </h1>
      </div>
    </div>
  );
}
