import numeric from 'numeric'

export function linear(p,x) {
  const [a,b] = p
  return a*x + b
}

export function linest(p,x) {
  const [a,b,c,d] = p
  return a*x*x*x + b*x*x + c*x + d
}

// Usage: const [a,b,c,d] = solve(xs,ys)
function solve(xs,ys) {
  return numeric.uncmin(p=>{
    let s = 0.0
    for(let i=0;i<xs.length;i++) {
      const x=xs[i],y=ys[i]
      const delta = linest(p,x) - y
      s += delta*delta
    }
    return s
  }, [1,1,1,1]).solution
}

function sum(l) {
  if (Array.isArray(l)&&l.length>0) return l.filter(e=>typeof(e)==='number'&&!isNaN(e)).reduce((a,b)=>a+b,0)
  return 0
}

export default class Regression {

  static solve(xs,ys) {
    if (Array.isArray(xs)&&xs.length>0&&Array.isArray(ys)&&ys.length>0) {
      if (xs.length>ys.length) {
        return solve(xs.slice(0,ys.length),ys)
      } else {
        return solve(xs,ys)
      }
    }
    return null
  }

  static linear(xs,ys) {
    if (Array.isArray(xs)&&xs.length>0&&Array.isArray(ys)&&ys.length>0) {
      if (xs.length>=ys.length) {
        const n = ys.length
        const xss = xs.slice(0,n)

        const sumX = sum(xss)
        const sumY = sum(ys)
        const sumXY = sum(xss.map((x,i)=>x*ys[i]))
        const sumX2 = sum(xss.map(x=>x*x))
        //const sumY2 = sum(ys.map(y=>y*y))

        const a = (n*sumXY-sumX*sumY)/(n*sumX2-sumX*sumX)
        const b = (sumY*sumX2-sumX*sumXY)/(n*sumX2-(sumX*sumX))

        /*const fx = xs.slice();
        const fy = ys.slice();
        (new Array(xs.length-ys.length)).fill(0).forEach((e,i)=>{
          const x = xs[ys.length+i]
          const y = linear([a,b],x)
          fy.push(y)
        })
        return [fx,fy]*/

        return [xs,xs.map(x=>linear([a,b],x))]
      }
    }
    return [xs,ys]
  }

  static linest(xs,ys) {
    if (Array.isArray(xs)&&xs.length>0&&Array.isArray(ys)&&ys.length>0) {
      if (xs.length>=ys.length) {
        const [a,b,c,d] = solve(xs.slice(0,ys.length),ys)
        /*const fx = xs.slice();
        const fy = ys.slice();
        (new Array(xs.length-ys.length)).fill(0).forEach((e,i)=>{
          const x = xs[ys.length+i]
          const y = linest([a,b,c,d],x)
          fy.push(y)
        })
        return [fx,fy]*/
        return [xs,xs.map(x=>linest([a,b,c,d],x))]
      }
    }
    return [xs,ys]
  }
}
