import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsPanel, InputSwitch } from '../util/';
import { lipsum } from '../Util';

const USAGE = `<Tabs>
  <TabsPanel name={t('First')}>
    <h1>{t('First Tab')}</h1>
    <p className="lead">{lipsum()}</p>
    <p>{lipsum(50)}</p>
  </TabsPanel>
  <TabsPanel name={t('Second')}>
    <h1>{t('Second Tab')}</h1>
    <p className="lead">{lipsum()}</p>
    <p>{lipsum(50)}</p>
  </TabsPanel>
</Tabs>`;

export default function TabsDemo(props) {
  const { t } = useTranslation();

  const [isPills, setIsPills] = React.useState(false);

  return (
    <div className="container-lg">
      <div className="text-center">
        <h1>{t('React Tabs Demo')}</h1>
      </div>

      <div className="mb-4">
        <details>
          <summary>{t('Usage')}</summary>
          <pre className="p-3 bg-dark text-warning">
            <code>{USAGE}</code>
          </pre>
        </details>
      </div>

      <div className="text-right mb-4">
        <InputSwitch
          label={t('Use pills')}
          value={isPills}
          onChange={setIsPills}
        />
      </div>

      <Tabs pills={isPills}>
        <TabsPanel name={t('First')}>
          <h1>{t('First Tab')}</h1>
          <p className="lead">{lipsum()}</p>
          <p>{lipsum(50)}</p>
        </TabsPanel>
        <TabsPanel name={t('Second')}>
          <h1>{t('Second Tab')}</h1>
          <p className="lead">{lipsum()}</p>
          <p>{lipsum(50)}</p>
        </TabsPanel>
      </Tabs>
    </div>
  );
}
