export const HOME_PATHS = [
  {
    icon: '💬',
    name: 'Lipsum',
    description: 'Generate random string with "lorem ipsum..."',
    path: '/lipsum',
    component: require('../lipsum/Lipsum').default,
  },
  {
    icon: '⚛',
    name: 'React 16 x Bootstrap 4',
    description: 'When React meets Bootstrap',
    path: '/react-x-bootstrap',
    component: require('../page/ReactXBootstrap').default,
  },
  {
    icon: '⚛',
    name: 'React x CSS3',
    description: 'React powered by CSS 3',
    path: '/react-x-css',
    component: require('../page/ReactXCss').default,
  },
  {
    icon: '⚛',
    name: 'React Analog Clock',
    description: 'React component to display time with analog clock with SVG',
    path: '/clock',
    component: require('../page/ReactClock').default,
  },

  {
    icon: '\u{1f340}',
    name: 'isPrime',
    path: '/prime',
    description: 'Checking a number is a prime in JavaScript',
    component: require('../page/IsPrime').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'Color',
    path: '/color',
    description: 'JavaScript class Color built-in with inverse & luminance',
    component: require('../page/ColorDemo').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'Web Colors',
    path: '/web-color',
    description: 'Find and use web colors by their names.',
    component: require('../page/WebColors').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'Random Colors',
    path: '/random-color',
    description: 'Random color schemes',
    component: require('../random-color/RandomColor').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'Gradient Color Pairing Tool',
    path: '/gradient',
    description: 'A tool to choose your preferred gradient color',
    component: require('../page/GradientColorPair').default,
  },
  {
    icon: '\u{1f58b}',
    name: 'Font Explorer',
    path: '/font',
    description: 'Find and use fonts that readily supported by client platform',
    component: require('../page/FontExplorer').default,
  },
  {
    icon: '\u{1f6e1}',
    name: 'Unicode Explorer',
    path: '/unicode-explorer',
    description: 'Find and use the right unicode character for your thing',
    component: require('../page/UnicodeExplorer').default,
  },
  {
    icon: '\u{1f6e1}',
    name: 'Unicode Library',
    path: '/unicode',
    description: 'Categorizing the unicode for quick reference',
    component: require('../page/UnicodeLibrary').default,
  },
  {
    icon: '\u{1f6e1}',
    name: 'Unicode Country Flags',
    path: '/unicode-flag',
    description: 'World country flags unicode',
    component: require('../page/UnicodeFlag').default,
  },
  {
    icon: '\u{1f501}',
    name: 'RGB to Hex',
    path: '/rgb-to-hex',
    description:
      'Help to convert RGB in decimals to a Hexadicimal string value',
    component: require('../page/Rgb2Hex').default,
  },
  {
    icon: '\u{1f3b2}',
    name: 'Word Jumbler',
    path: '/jumble',
    description:
      'Jumble a word to another word with the same characters in a random order',
    component: require('../page/WordJumbler').default,
  },
  {
    icon: '\u{1f3b2}',
    name: 'Tic Tac Toe',
    path: '/tictactoe',
    description: 'Classic pen and paper game, made in React',
    component: require('../page/TicTacToe').default,
  },
  {
    icon: '\u{1f3b2}',
    name: 'Tic Tac Toe version 3',
    path: '/tictactoe3',
    description: 'Tic Tac Toe in React Hooks',
    component: require('../page/TicTacToe3').default,
  },
  {
    icon: '\u{1f3b2}',
    name: 'Game of Fortune',
    path: '/fortune',
    description: 'Word Guessing game built in React',
    component: require('../game-of-fortune/GameOfFortune').default,
  },
  {
    icon: '\u{1f488}',
    name: 'Text Processor',
    path: '/text-processor',
    description: 'Processing text into another form of structured data',
    component: require('../page/TextProcessor').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'Super Simple TODO List with React Hooks',
    path: '/todo',
    description: 'React Hooks implementation of TODO list',
    component: require('../page/Todo').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'Tablesify',
    path: '/tablesify',
    description: 'Turn JSON Array into HTML table',
    component: require('../page/TablesifyDemo').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'JSON Formatter',
    path: '/json-formatter',
    description: 'Format JSON to readable form',
    component: require('../page/JsonFormatter').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'JSON Editor',
    path: '/json-editor',
    description: 'JSON Editor written in React',
    component: require('../page/JsonEditor').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'JSON Prettify',
    path: '/json-prettify',
    description: 'JSON Editor with prettify toggle',
    component: require('../page/JsonPrettify').default,
  },
  {
    icon: '\u{1f4c8}',
    name: 'Quadratic Formula',
    path: '/quadratic-formula',
    description: 'Solving quadratic equation with quadratic formula',
    component: require('../page/QuadraticFormula').default,
  },
  {
    icon: '\u269b',
    name: 'Preview Image on Upload',
    path: '/preview',
    description: 'Preview image file on selecting an image before uploading',
    component: require('../page/PreviewImageOnUpload').default,
  },
  {
    icon: '\u{1f5e3}',
    name: 'Text to Speech',
    path: '/tts',
    description:
      'An experimental app to explore a text-to-speech feature built in modern browsers',
    component: require('../page/Text2SpeechApp').default,
  },
  {
    icon: '\u269b',
    name: 'Text with Glowing Effect',
    path: '/glowing-text',
    description: 'Make a text looks glowing',
    component: require('../page/GlowingText').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'ColorSelector with Preview box',
    path: '/color-selector',
    description: 'Preview a color on selecting them',
    component: require('../page/ColorSelectorApp').default,
  },
  {
    icon: '\u{1f488}',
    name: 'Translator',
    path: '/translator',
    description: 'Translate a text into a specific language',
    component: require('../page/Translator').default,
  },
  {
    icon: '\u{1f488}',
    name: 'Object value translator',
    path: '/obj-translator',
    description: 'Translate value of a key from an object',
    component: require('../page/ObjectTranslator').default,
  },
  {
    icon: '\u{1f488}',
    name: 'Hex-to-Dec',
    path: '/hex-to-dec',
    description:
      'Converting hexadecimal (base-16) value to decimal number (base-10)',
    component: require('../page/Hex2Dec').default,
  },
  {
    icon: '\u{1f488}',
    name: 'Dec-to-Hex',
    path: '/dec-to-hex',
    description:
      'Converting decimal (base-10) value to hexadecimal number (base-16)',
    component: require('../page/Dec2Hex').default,
  },
  {
    icon: '\u{1f5c2}',
    name: 'CSV Editor',
    path: '/csv-editor',
    description: 'Editing CSV online',
    component: require('../page/CsvEditorPage').default,
  },
  {
    icon: '\u{1f5c2}',
    name: 'Forecast',
    path: '/forecast',
    description: 'Forecasting',
    component: require('../page/Forecast').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'HTML String Encoder',
    path: '/html-encoder',
    description: 'Encode HTML string online',
    component: require('../page/HTMLEncoder').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'Auto Resize HTML Textarea',
    path: '/autosize-textarea',
    description:
      'Automatically resize HTML width and height based on its content value',
    component: require('../page/AutoResizeTextarea').default,
  },
  {
    icon: '\u{1f4dd}',
    name: 'Click-to-Edit App',
    path: '/cte',
    description: 'Experimental app on click-to-edit component',
    component: require('../cte/ClickToEditApp').default,
  },
  {
    icon: '⚛',
    name: 'React Tabs',
    description: 'Simple tabs build in React',
    path: '/react-tabs',
    component: require('../tabs/TabsDemo').default,
  },
  {
    icon: '⚛',
    name: 'Timezone Selector',
    description: 'Timezone Selector for React',
    path: '/timezone',
    component: require('../timezone/TimezoneSelector').default,
  },
  {
    icon: '⚛',
    name: 'Date Formatting',
    description: 'Formatting date value in JS with moment.js',
    path: '/date-format',
    component: require('../date-format/DateFormatting').default,
  },
  {
    icon: '⚛',
    name: 'Weight Measurement',
    description: 'Weight measurement tool (kg, lbs, oz)',
    path: '/weight-measurement',
    component: require('../weight/WeightMeasurement').default,
  },
  {
    icon: '⚛',
    name: 'Currency List',
    description: 'List of currency from all countries',
    path: '/currency',
    component: require('../currency/CurrencyList').default,
  },
  {
    icon: '\u{1f3a8}',
    name: 'Color Palette',
    path: '/color-palette',
    description: 'Color Palette in CSS',
    component: require('../color-palette/ColorPalette').default,
  },
  {
    icon: '⚛',
    name: 'Bootstrap Icons',
    description: 'Official open source SVG icon library for Bootstrap',
    path: '/bootstrap-icons',
    component: require('../bootstrap-icons/BootstrapIcons').default,
  },
  {
    icon: '⚛',
    name: 'Dynamic Form',
    description: 'Render form dynamically with JSON data',
    path: '/dynamic-form',
    component: require('../dynamic-form/DynamicForm').default,
  },
  {
    icon: '⚛',
    name: 'Lottie Demo',
    description: 'Lottie animation / interactive design',
    path: '/lottie',
    component: require('../lottie/LottieDemo').default,
  },
  {
    icon: '⚛',
    name: 'Raya 2021 Countdown',
    description: 'Countdown to Raya 2021',
    path: '/raya2021',
    component: require('../raya/Raya2021').default,
  },
  {
    icon: '⚛',
    name: 'HTML to image',
    description: 'Capture / screenshot HTML element into image file',
    path: '/htmltoimage',
    component: require('../html-to-image/HTMLtoImage').default,
  },
];
