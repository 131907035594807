import React from 'react';

export default function GameOfFortuneChar(props) {
  const { value, reveal } = props;
  const style = {
    width: '2.5rem',
    height: '2.5rem',
    color: 'white',
  };
  if (/[A-Za-z]/.test(value)) {
    if (reveal) {
      return (
        <span
          className="animated flipInY faster bg-dark h5 p-2 m-1 shadow rounded text-center text-shine font-weight-bold"
          style={style}
        >
          {value}
        </span>
      );
    }
    return (
      <span
        className="animated lightSpeedIn faster bg-secondary h5 p-2 m-1 shadow rounded text-center text-shadow"
        style={style}
      >
        ?
      </span>
    );
  }
  if (value === ' ') {
    return <span className="h5 p-2 m-1"></span>;
  }
  return (
    <span
      className="animated heartBeat faster bg-dark h5 p-2 m-1 shadow rounded text-center text-shine font-weight-bold"
      style={style}
    >
      {value}
    </span>
  );
}
