import React from 'react';
import { PreCode, lipsum } from '../Util';
import CTE from '../cte/CTE';

function randomValue() {
  return {
    title: lipsum(3),
    lead: lipsum(10),
    description: lipsum(50),
  };
}

export default function ClickToEditApp(props) {
  const [value, setValue] = React.useState(randomValue());

  const [k, setK] = React.useState();
  const [v, setV] = React.useState();

  function handleBlur(e, k, v) {
    if (v !== (value[k] || '')) {
      e.target.focus();
      confirm(k, e.target.value);
    }
  }

  function confirm(k, v) {
    setK(k);
    setV(v);
  }

  function cancel(e) {
    //setValue(value);
    setK();
    setV();
  }

  function confirmChanges(e) {
    setValue({ ...value, [k]: v });
    setK();
    setV();
  }

  return (
    <React.Fragment>
      <div className="container-lg my-3">
        <h2 className="text-center mb-4">{'\u{1f4dd}'} Click-to-Edit (App)</h2>

        <div className="text-center mb-3">
          <button
            className="btn btn-primary rounded-pill px-4 m-1 shadow-sm"
            onClick={(e) => setValue(randomValue())}
          >
            Randomize values
          </button>
          <button
            className="btn btn-light rounded-pill px-4 m-1 shadow-sm"
            onClick={(e) => setValue({})}
          >
            Clear all values
          </button>
        </div>

        <p className="lead text-center">
          Click and edit any text in the white box below
        </p>

        <small className="text-center">
          <ul className="list-unstyled">
            <li className="mb-1">
              Click <kbd>ENTER</kbd> to save changes
            </li>
            <li className="mb-1">
              Click <kbd>ESC</kbd> to cancel changes
            </li>
            <li className="mb-1">
              Click <kbd>TAB</kbd> to change focus to the next input
            </li>
          </ul>
        </small>

        <div className="container bg-light p-3 rounded-lg shadow mb-3">
          <h1 className="text-center">
            <CTE
              k="title"
              value={value}
              onChange={setValue}
              cancel={cancel}
              onBlur={handleBlur}
              editK={k}
            />
          </h1>
          <p className="lead text-center">
            <CTE
              k="lead"
              value={value}
              onChange={setValue}
              cancel={cancel}
              onBlur={handleBlur}
              editK={k}
            />
          </p>
          <p className="">
            <CTE
              k="description"
              value={value}
              onChange={setValue}
              cancel={cancel}
              onBlur={handleBlur}
              editK={k}
            />
          </p>
        </div>

        <h5 className="text-center">JSON Value Preview</h5>

        <div className="container">
          <PreCode>{value}</PreCode>
        </div>
      </div>

      {k && v && v !== (value[k] || '') && (
        <div className="sticky-bottom w-100">
          <div
            className="p-3 text-light text-center"
            style={{ background: 'rgba(0,0,0,.5)' }}
          >
            <small className="font-weight-bold">Confirm changes?</small>
            <div className="row">
              <div className="col-5 text-right">
                {value[k] || <span className="badge badge-dark">EMPTY</span>}
              </div>
              <div className="col-2 mx-auto place-items-center">&#x2192;</div>
              <div className="col-5 text-left font-weight-bold">{v}</div>
            </div>
          </div>
          <div className="d-flex justify-content-between p-3">
            <button
              className="btn btn-danger rounded-circle"
              style={{ width: 48, height: 48 }}
              onClick={cancel}
            >
              &#x2718;
            </button>
            <button
              className="btn btn-success rounded-circle"
              style={{ width: 48, height: 48 }}
              onClick={confirmChanges}
            >
              &#x2714;
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
