import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Details, Alert } from '../Util';
import Regression from '../component/Regression';
import ApexChart from '../component/ApexChart';
//import DataService from '../component/DataService'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VALUE = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [140, 160, 170, 185],
];

//const Month = ['January','February','March','April','May','June','July','August','September','October','November','December']

const OPTS = {
  chart: {
    height: 350,
    type: 'line',
  },
  fill: {
    type: 'solid',
  },
  markers: { size: 6 },
  tooltip: {
    shared: false,
    intersect: true,
  },
  legend: {
    show: true,
  },
  //dataLabels: {enabled: true,},
  //stroke: {curve:'smooth',},
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  /*animations: {
    enabled: false,
    animateGradually: {
      enabled: false,
    },
    dynamicAnimation: {
      enabled: false,
    },
  },*/
  xaxis: {
    type: 'number',
    min: 0,
    //max: 11,
    /*labels: {
      formatter: v=>Month[v].slice(0,3)
    }*/
  },
  yaxis: {
    labels: {
      formatter: (v) => {
        if (v >= 1000000) return `${(v / 1000000).toFixed(0)}m`;
        if (v >= 1000) return `${(v / 1000).toFixed(0)}k`;
        return v.toFixed(0);
      },
    },
    type: 'numeric',
    min: 0,
    //max: 5111462367,
    //max: 5200000000,
  },
};

function min(a, b) {
  if (typeof a === 'number' && !isNaN(a)) {
    if (typeof b === 'number' && !isNaN(b)) {
      return a < b ? a : b;
    }
    return a;
  }
  return -1;
}

function max(l) {
  if (Array.isArray(l) && l.length > 0) {
    let m;
    l.forEach((e) => {
      if (typeof e === 'number' && !isNaN(e))
        if (typeof m === 'number')
          if (e > m) m = e;
          else m = e;
    });
    return m;
  }
  return 0;
}

function toData(xs, ys) {
  if (
    Array.isArray(xs) &&
    xs.length > 0 &&
    Array.isArray(ys) &&
    ys.length > 0
  ) {
    return [...Array(min(xs.length, ys.length)).keys()].map((i) => {
      const x = xs[i];
      const y = ys[i];
      return { x, y };
    });
  }
  return [];
}

function initValue(query) {
  if (typeof query === 'object' && query !== null) {
    const value = query.get('value');
    if (typeof value === 'string' && value.length > 0) return value;

    const y = query.get('y');
    if (typeof y === 'string' && y.length > 0) return y;
  }
  return JSON.stringify(VALUE);
}

function parse(value, cbe) {
  if (typeof value === 'string' && value.length > 0)
    try {
      const data = JSON.parse(value);
      if (Array.isArray(data) && data.length > 0) {
        const [xs, ys] = data;
        if (
          Array.isArray(xs) &&
          xs.length > 0 &&
          Array.isArray(ys) &&
          ys.length > 0
        )
          return data;
        return [[...Array(12).keys()], data];
      }
    } catch (err) {
      if (typeof cbe === 'function') cbe();
      else console.warn(`Error parsing value`);
    }
  return [[], []];
}

export default function Forecast(props) {
  const { t } = useTranslation();
  const query = useQuery();

  const [value, setValue] = React.useState(initValue(query));
  const [chart, setChart] = React.useState();
  const [msg, setMsg] = React.useState();

  function handleErr(err) {
    setMsg({ type: 'warning', value: `${err}` });
  }

  React.useEffect(() => {
    if (typeof value === 'string' && value.length > 0) {
      /*try {
        const [xs,ys] = parse(value,handleErr)
        DataService.linear([xs,ys],data=>{
          setResult(data)
          setChart()
          setMsg()
        }, handleErr)
      } catch(err) {
        if (typeof(value)==='string'&&value.length>0) {
          setMsg({type:'warning',value:`${err}`})
        }
      }*/
      try {
        const data = parse(value, handleErr);

        if (Array.isArray(data) && data.length > 0) {
          const [xs, ys] = data;
          const results = [
            Regression.linest(xs, ys),
            Regression.linear(xs, ys),
          ];

          setMsg();
          setChart({
            ...OPTS,
            series: [
              {
                name: 'Actual',
                type: 'line',
                data: toData(xs, ys),
              },
              {
                name: 'Multiple Linear Regression',
                type: 'line',
                data: toData(results[0][0], results[0][1]),
              },
              {
                name: 'Linear Regression',
                type: 'line',
                data: toData(results[1][0], results[1][1]),
              },
            ],
            xaxis: {
              ...OPTS.xaxis,
              max: max([max(results[0][0]), max(results[1][0])]),
              labels: {
                //formatter: v=>Month[v].slice(0,3)
                formatter: (v) => {
                  try {
                    return v.toFixed(0);
                  } catch (error) {
                    return 0;
                  }
                },
              },
            },
            yaxis: {
              ...OPTS.yaxis,
              max: max([max(results[0][1]), max(results[1][1])]),
            },
          });
        } else {
          setMsg({ type: 'warning', value: `Invalid data ${data}` });
          setChart();
        }
      } catch (err) {
        if (typeof value === 'string' && value.length > 0) {
          setMsg({ type: 'warning', value: `${err}` });
          setChart();
        }
      }
    } else {
      setMsg({ type: 'warning', value: `Invalid value ${value}` });
      setChart();
    }
  }, [value]);

  function handleChange(e) {
    setValue(e.target.value);
  }

  function reset() {
    try {
      const value = initValue(query);
      const data = JSON.parse(value);
      if (Array.isArray(data) && data.length > 0) {
        setValue(value);
      } else {
        setValue(JSON.stringify(VALUE));
      }
    } catch (err) {
      setValue(JSON.stringify(VALUE));
    }
  }

  function generateXs() {
    if (typeof value === 'string' && value.length > 0) {
      try {
        const data = JSON.parse(value);
        if (Array.isArray(data) && data.length > 0) {
          const [xs] = data;
          if (Array.isArray(xs) && xs.length > 0) {
            setMsg({ type: 'success', value: `X-axis ${xs}` });
          } else {
            setValue(`[${JSON.stringify([...Array(12).keys()])},${value}]`);
          }
        } else {
          setMsg({ type: 'warning', value: `Invalid data ${data}` });
        }
      } catch (err) {
        setMsg({ type: 'warning', value: `Error parsing ${value}` });
      }
    } else {
      setMsg({ type: 'warning', value: `Invalid value ${value}` });
    }
  }

  return (
    <div className="container-lg animated bounceInRight">
      <h1 className="text-white text-center text-shadow mb-3 animated faster fadeInDown delay-1s">
        {t('Forecast')}
      </h1>

      <div className="container p-3 bg-white rounded-lg shadow">
        {msg && <Alert {...msg} />}

        <Details summary={t('Data')} open className="mb-3">
          <div className="mt-2">
            <div className="text-right">
              <button
                className="btn btn-light btn-sm rounded-pill pl-3 pr-3 shadow mb-2 mr-2"
                onClick={generateXs}
              >
                {t('Generate x-axis')}
              </button>
              <button
                className="btn btn-light btn-sm rounded-pill pl-3 pr-3 shadow mb-2 mr-2"
                onClick={reset}
              >
                {t('Reset')}
              </button>
            </div>
            <div className="mb-1">
              <div>
                <label>
                  {t(
                    "Input y's and x's or just y's for time series forecasting",
                  )}
                </label>
              </div>
              <div>
                <label>{t("x's size should be larger or equals to y's")}</label>
              </div>
              <div>
                <label>
                  {t("If input is only y's, x's is generated as 12 units")}
                </label>
              </div>
            </div>
            <textarea
              value={value}
              onChange={handleChange}
              className="form-control shadow-inset"
              rows={5}
            />
          </div>
        </Details>

        {/*<PreCode>{{value,result}}</PreCode>*/}

        {chart && (
          <div>
            <ApexChart opts={chart} />
          </div>
        )}
      </div>
    </div>
  );
}
