import React from 'react';
import ColorBox from './ColorBox';
import { COLOR, A, Badge } from '../Util';

export default function ColorExplorer(props) {
  const Map = {
    /*Pink: {color:'HotPink', lumin:'white'},
    Red: {color:'Crimson', lumin:'white'},
    Orange: {color:'Orange', lumin:'white'},
    Yellow: {color:'Gold', lumin:'black'},
    Brown: {color:'SaddleBrown', lumin:'white'},
    Green: {color:'MediumSpringGreen', lumin:'black'},
    Cyan: {color:'Aqua', lumin:'black'},
    Blue: {color:'DeepSkyBlue', lumin:'white'},
    Purple: {color:'DarkMagenta', lumin:'white'},
    White: {color:'MintCream', lumin:'black'},
    Gray: {color:'DarkSlateGray', lumin:'white'},*/
    Pink: { color: 'LavenderBlush', lumin: 'DeepPink' },
    Red: { color: 'MistyRose', lumin: 'DarkRed' },
    Orange: { color: 'AntiqueWhite', lumin: 'OrangeRed' },
    Yellow: { color: 'Beige', lumin: 'DarkKhaki' },
    Brown: { color: 'Seashell', lumin: 'SaddleBrown' },
    Green: { color: 'Honeydew', lumin: 'DarkGreen' },
    Cyan: { color: 'Azure', lumin: 'Teal' },
    Blue: { color: 'AliceBlue', lumin: 'Navy' },
    Purple: { color: 'GhostWhite', lumin: 'DarkSlateBlue' },
    White: { color: 'Snow', lumin: 'DarkGray' },
    Gray: { color: 'WhiteSmoke', lumin: 'DarkSlateGray' },
  };

  const config = (e) => {
    if (typeof e === 'string' && e.length > 0) {
      const c = Map[e];
      if (typeof c === 'object' && c !== null) return c;
    }
    return {
      color: 'inherit',
      lumin: 'inherit',
    };
  };

  return (
    <div className="ColorExplorer container-lg mb-5">
      <div className="text-center mb-3">
        <h1>{'\u{1f3a8}'} Web Colors</h1>
        <h3>
          <small>Name, #, RGB, HSL</small>
        </h3>
        <p className="lead">
          Just use the color name. They are well supported in most web browsers
          (IE, Firefox, Chrome, Safari).
        </p>
        <p>
          <A
            href="https://en.wikipedia.org/wiki/Web_colors"
            className="h6 p-1"
            blank
            title="Open in a new tab"
          >
            Wikipedia: Web Colors
          </A>
        </p>
      </div>
      <hr />
      <div className="row">
        <div className="col-2">
          <div className="list-group sidebar w-100">
            <h6 className="lead text-center mb-3 pt-3">Jump to</h6>
            {Object.keys(COLOR).map((e, i) => {
              const c = config(e);
              const style = {
                backgroundColor: c.color,
                color: c.lumin,
              };
              return (
                <A
                  key={i}
                  shadow
                  className="list-group-item list-group-item-action border-0"
                  href={`#${e}`}
                  style={style}
                >
                  {e}{' '}
                  {/*<Badge dark pill className="ml-1">{COLOR[e].length}</Badge>*/}
                </A>
              );
            })}
          </div>
        </div>
        <div className="col-10">
          {Object.keys(COLOR).map((e, i) => {
            return (
              <div key={i} id={e} className="clear-both pt-5">
                <div className="text-center mb-3 pt-3">
                  <h3>
                    <span className="mr-3 mb-3">{e}</span>
                    <Badge dark pill className="shadow">
                      {COLOR[e].length}
                    </Badge>
                  </h3>
                </div>
                <div className="row no-gutters">
                  {COLOR[e].map((e, i) => {
                    return (
                      <ColorBox
                        key={i}
                        name={e}
                        className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-2 shadow text-center v-100"
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
