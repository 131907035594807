import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './cte.css';

// (Fixed by handling onBlur in parent) IMPORTANT IDEA TO EXPERIMENT: decide on save, cancel on blur; everything will be handle on blur

// (FIXED) FIXME: on TAB or ENTER; the value is changed correctly; yet the input is still on focus
// (FIXED) FIXME: on TAB or ENTER; despite value is change, confirmation keep showing up
// (FIXED) FIXME: on confirming; clicking CANCEL is not restoring to the original value

// BELOW can be considered a feature
// FIXME: on confirming; clicking CANCEL restoring to original value yet still on focus; should be on blur

export default function CTE(props) {
  const { k, value, onChange, className = '', cancel, onBlur, editK } = props;

  const [v, setV] = React.useState(value[k] || '');
  const el = React.useRef();

  React.useEffect(() => {
    setV(value[k] || '');

    // blur on any confirm changes
    el.current.blur();
  }, [value, k]);

  // restore v into original value on editK is reset (editK is parent's k)
  React.useEffect(() => {
    if (!editK) {
      setV(value[k] || '');

      // FIXME: will cause TAB not working as expected, will focus then blur
      // blur on timeout due to waiting for v is restored
      /*setTimeout(() => {
        el.current.blur();
      }, 100);*/
    }
  }, [editK, value, k]);

  function handleChange(e) {
    onChange({ ...value, [k]: e.target.value });
    //e.target.blur();
  }

  function handleBlur(e) {
    // TODO: check if there is change
    // if change, ask for confirmation

    //console.log('handleBlur', e.keyCode);

    /*if (v !== (value[k] || '')) {
      e.target.focus();
      confirm(k, e.target.value);
    }*/

    onBlur(e, k, v);
  }

  function handleKeyDown(e) {
    // 9: TAB, 13: ENTER, 27: ESC
    switch (e.keyCode) {
      case 9:
        // below will prevent from changing focus
        //e.preventDefault();
        handleChange(e);
        break;
      case 13:
        e.preventDefault();
        handleChange(e);

        // FIXME: next line causing to trigger onBlur, yet the value compared are old value
        //e.target.blur();
        break;
      case 27:
        setV(value[k] || '');
        //e.target.blur();
        cancel();
        break;
      default:
        break;
    }
  }

  return (
    <TextareaAutosize
      ref={el}
      value={v}
      onChange={(e) => setV(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      className={`CTE ${className}`}
      placeholder={`click to edit ${k}`}
    />
  );
}
