import React from 'react';
import {
  Container,
  Row,
  Col,
  P,
  Badge,
  Input,
  Details,
  Button,
  PreCode,
} from '../Util';

const GOOGLE_TRANSLATE_URL = 'https://translate.google.com';
const GOOGLE_SEARCH_TRANSLATE_URL =
  'https://www.google.com/search?q=google+translate';

const INSTRUCTION = `Go to ${GOOGLE_TRANSLATE_URL} or ${GOOGLE_SEARCH_TRANSLATE_URL} and translate the value, copy the translated value and paste it below`;

const SAMPLE = `apple, alligator, apron, anchor, avocado, ball, bear, bicycle, bird, book, box, broccoli, burger, butterfly, buoy, cabbage, camera, car, cart, cheese, cherry, chicken, cloud, coffee, compass, corn, cow, crab, cucumber, cupcake, chick, cinnamon, coin, doctor, dog, dolphin, donut, dragon, duck, daisy, diamond, dice, dragonfly, egg, eggplant, elephant, fire, frog, fish, flip-flop, field, flamingo, fox, garlic, ginger, grape, guitar, giraffe, goldbar, gift, hammer, hamster, hat, hibiscus, heart, hippo, icecream, island, jam, jelly, jellyfish, jump, jar, kettle, kite, key, ladder, ladle, lamp, lantern, mask, match, mouse, net, nest, nut, onion, owl, pan, panda, pig, pool, pear, pepper, pot, pumpkin, pancake, park, peach, penguin, peppermint, quail, queen, quill, rabbit, rainbow, reindeer, ribbon, rope, screw, screwdriver, shell, shoe, shopping, sing, sleep, snake, spatula, sponge, sprayer, squirrel, strawberry, sun, sunflower, seal, snowman, star, sunglass, teapot, telephone, tie, tent, tiger, tooth, tomato, tree, truck, tank, toucan, towel, train, umbrella, unicorn, vacuum, vegetable, watch, watermelon, whisk, wrench, walrus, x-ray, xylophone, box, fox, yak, yam, yarn, yo-yo, zebra, zucchini`;

const TRANSLATION = `apel, buaya, celemek, jangkar, alpukat, bola, beruang, sepeda, burung, buku, kotak, brokoli, burger, kupu-kupu, pelampung, kubis, kamera, mobil, gerobak, keju, ceri, ayam, awan, kopi, kompas, jagung, sapi, kepiting, mentimun, cupcake, cewek, kayu manis, koin, dokter, anjing, lumba-lumba, donat, naga, bebek, daisy, berlian, dadu, capung, telur, terong, gajah, api, katak, ikan, flip- gagal, lapangan, flamingo, rubah, bawang putih, jahe, anggur, gitar, jerapah, goldbar, hadiah, palu, hamster, topi, kembang sepatu, jantung, kuda nil, es krim, pulau, selai, jeli, ubur-ubur, lompat, toples, ketel, layang-layang, kunci, tangga, sendok, lampu, lentera, topeng, korek api, tikus, jaring, sarang, kacang, bawang, burung hantu, panci, panda, babi, kolam, pir, merica, pot, labu, pancake, taman, persik, penguin, peppermint, puyuh, ratu, pena bulu, kelinci, pelangi, rusa kutub, pita, tali, sekrup, obeng, kulit, sepatu, belanja, bernyanyi, tidur, ular, spatula, spons, penyemprot, tupai, stroberi, matahari, bunga matahari, meterai, manusia salju, bintang, sunglass, teko, telepon, dasi, tenda, tig eh, gigi, tomat, pohon, truk, tank, toucan, handuk, kereta api, payung, unicorn, vakum, sayur, arloji, semangka, mengocok, kunci pas, walrus, x-ray, gambang, kotak, rubah, yak, yam, benang, yo-yo, zebra, timun Jepang`;

export default function Translator(props) {
  const [value, setValue] = React.useState(SAMPLE.trim());
  const [translation, setTranslation] = React.useState(TRANSLATION.trim());
  const [out, setOut] = React.useState('');

  const process = () => {
    if (typeof value === 'string' && value.length > 0) {
      if (typeof translation === 'string' && translation.length > 0) {
        const out = {};
        const translations = translation.split(',').map((e) => e.trim());
        value.split(',').forEach((e, i) => {
          const k = e.trim();
          out[k] = translations[i];
        });
        setOut(out);
      }
    }
  };

  React.useEffect(() => {
    process();
    // eslint-disable-next-line
  }, [value]);

  const el = React.useRef();
  const copy = (e) => {
    e.preventDefault();
    if (typeof el === 'object' && el !== null) {
      const { current } = el;
      if (typeof current === 'object' && current !== null) {
        current.select();
        //current.setSelectionRange(0,value.length)
        document.execCommand('copy');
      }
    }
  };

  const lineCount = value.split('\n').length;
  const rows = Math.max(Math.min(lineCount, 14), 7);

  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f488}'} Translator</h1>
        <p className="lead"></p>
      </div>
      <hr />

      <Row>
        <Col s="6">
          <Container gradient dark rounded shadow className="p-3">
            <small className="float-right">
              Length <Badge info>{value.length}</Badge>
              <br /># of Line <Badge info>{lineCount}</Badge>
            </small>

            <h3>Value</h3>
            <Input
              textarea
              rows={rows}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              className="mb-3"
            />

            <Details lead="Instruction">
              <P>{INSTRUCTION}</P>
            </Details>

            <h3>Translation</h3>
            <Input
              textarea
              rows={rows}
              onChange={(e) => setTranslation(e.target.value)}
              value={translation}
            />
          </Container>
        </Col>
        <Col s="6">
          {value && (
            <Container gradient rounded shadow className="mb-3 p-3">
              <Details lead="See Input">
                <PreCode className="none mt-3">{value}</PreCode>
              </Details>
            </Container>
          )}

          <Container gradient dark rounded shadow className="p-3 mb-3">
            <h5>Output</h5>
            <Button light shadow sm block onClick={copy} className="mb-2">
              {'\u{1f4cb}'} Copy to Clipboard
            </Button>
            <textarea
              ref={el}
              className="form-control"
              value={JSON.stringify(out)}
              rows={1}
              readOnly
            />
            <Badge dark pill>
              read-only
            </Badge>
          </Container>

          <PreCode className="font-sm">{out}</PreCode>
        </Col>
      </Row>
    </div>
  );
}
