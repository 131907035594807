export const COLOR = {
  '#0a3410': {
    name: 'Sap Green',
    hex: '#0a3410',
    rgb: 'rgb(10, 52, 16)',
    hsl: 'hsl(128.6, 67.7%, 12.2%)',
    lumin: 'white',
  },
  '#4e1500': {
    name: 'Alizarin Crimson',
    hex: '#4e1500',
    rgb: 'rgb(78, 21, 0)',
    hsl: 'hsl(16.2, 100%, 15.3%)',
    lumin: 'white',
  },
  '#221b15': {
    name: 'Van Dyke Brown',
    hex: '#221b15',
    rgb: 'rgb(34, 27, 21)',
    hsl: 'hsl(27.7, 23.6%, 10.8%)',
    lumin: 'white',
  },
  '#5f2e1f': {
    name: 'Dark Sienna',
    hex: '#5f2e1f',
    rgb: 'rgb(95, 46, 31)',
    hsl: 'hsl(14.1, 50.8%, 24.7%)',
    lumin: 'white',
  },
  '#000000': {
    name: 'Midnight Black',
    hex: '#000000',
    rgb: 'rgb(0, 0, 0)',
    hsl: 'hsl(0, 0%, 0%)',
    lumin: 'white',
  },
  '#021e44': {
    name: 'Prussian Blue',
    hex: '#021e44',
    rgb: 'rgb(2, 30, 68)',
    hsl: 'hsl(214.5, 94.3%, 13.7%)',
    lumin: 'white',
  },
  '#0c0040': {
    name: 'Phthalo Blue',
    hex: '#0c0040',
    rgb: 'rgb(12, 0, 64)',
    hsl: 'hsl(251.3, 100%, 12.5%)',
    lumin: 'white',
  },
  '#102e3c': {
    name: 'Phthalo Green',
    hex: '#102e3c',
    rgb: 'rgb(16, 46, 60)',
    hsl: 'hsl(199.1, 57.9%, 14.9%)',
    lumin: 'white',
  },
  '#ffec00': {
    name: 'Cadmium Yellow',
    hex: '#ffec00',
    rgb: 'rgb(255, 236, 0)',
    hsl: 'hsl(55.5, 100%, 50%)',
  },
  '#c79b00': {
    name: 'Yellow Ochre',
    hex: '#c79b00',
    rgb: 'rgb(199, 155, 0)',
    hsl: 'hsl(46.7, 100%, 39%)',
  },
  '#ffb800': {
    name: 'Indian Yellow',
    hex: '#ffb800',
    rgb: 'rgb(255, 184, 0)',
    hsl: 'hsl(43.3, 100%, 50%)',
  },
  '#db0000': {
    name: 'Bright Red',
    hex: '#db0000',
    rgb: 'rgb(219, 0, 0)',
    hsl: 'hsl(0, 100%, 42.9%)',
    lumin: 'white',
  },
  '#ffffff': {
    name: 'Titanium White',
    hex: '#ffffff',
    rgb: 'rgb(255, 255, 255)',
    hsl: 'hsl(0, 0%, 100%)',
  },
};

export const COLOR_PALETTE = {
  'Bob Ross': {
    url: 'https://en.wikipedia.org/wiki/Bob_Ross',
    description: 'Simplifying the technique by limiting the tools',
    colors: [
      COLOR['#0a3410'],
      COLOR['#4e1500'],
      COLOR['#221b15'],
      COLOR['#5f2e1f'],
      COLOR['#000000'],
      COLOR['#021e44'],
      COLOR['#0c0040'],
      COLOR['#102e3c'],
      COLOR['#ffec00'],
      COLOR['#c79b00'],
      COLOR['#ffb800'],
      COLOR['#db0000'],
      COLOR['#ffffff'],
    ],
  },
};
