export const categories = ['thing','vehicle','place','sport','plant','insect','animal','cloth','tech','emoji','food','hand','love','astrology','weather','sign','office','ui','clock','music']

export function extractFrom(sources) {
  const list = []
  if (Array.isArray(sources)&&sources.length>0) {
    sources.forEach((e,j)=>{
      const div = document.createElement('div')
      div.innerHTML = e

      const marks = div.getElementsByTagName('mark')

      for (let mark of marks) {
        const title = mark.getAttribute('title')
        const splits = title.split(':')
        const value = splits[0].split('+')[1].trim()
        const code = parseInt(value,16)
        const name = splits[1].trim().toLowerCase()
        const category = categories[j]

        list.push({value,code,name,category})
      }
    })
  }
  return list
}

export const sources = [
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F485: NAIL POLISH" data-char-info="U+1F485: NAIL POLISH">💅</mark>
<mark class="unicode" title="U+1F6D2: SHOPPING TROLLEY" data-char-info="U+1F6D2: SHOPPING TROLLEY">🛒</mark>
<mark class="unicode" title="U+1F52E: CRYSTAL BALL" data-char-info="U+1F52E: CRYSTAL BALL">🔮</mark>
<mark class="unicode" title="U+1F48E: GEM STONE" data-char-info="U+1F48E: GEM STONE">💎</mark>
<mark class="unicode" title="U+1F48D: RING" data-char-info="U+1F48D: RING">💍</mark>
<mark class="unicode" title="U+1F380: RIBBON" data-char-info="U+1F380: RIBBON">🎀</mark>
</div>

<div>
<mark class="unicode" title="U+1F56F: CANDLE" data-char-info="U+1F56F: CANDLE">🕯</mark>
<mark class="unicode" title="U+1F526: ELECTRIC TORCH" data-char-info="U+1F526: ELECTRIC TORCH">🔦</mark>
<mark class="unicode" title="U+1F514: BELL" data-char-info="U+1F514: BELL">🔔</mark>
<mark class="unicode" title="U+1F56D: RINGING BELL" data-char-info="U+1F56D: RINGING BELL">🕭</mark>
<mark class="unicode" title="U+231A: WATCH" data-char-info="U+231A: WATCH">⌚</mark>
<mark class="unicode" title="U+231B: HOURGLASS" data-char-info="U+231B: HOURGLASS">⌛</mark>
<mark class="unicode" title="U+23F3: HOURGLASS WITH FLOWING SAND" data-char-info="U+23F3: HOURGLASS WITH FLOWING SAND">⏳</mark>
</div>

<div>
lock and key<br>
<mark class="unicode" title="U+26BF: SQUARED KEY">⚿</mark>
<mark class="unicode" title="U+1F5DD: OLD KEY">🗝</mark>
<mark class="unicode" title="U+1F511: KEY">🔑</mark>
<mark class="unicode" title="U+1F50F: LOCK WITH INK PEN" data-char-info="U+1F50F: LOCK WITH INK PEN">🔏</mark>
<mark class="unicode" title="U+1F510: CLOSED LOCK WITH KEY" data-char-info="U+1F510: CLOSED LOCK WITH KEY">🔐</mark>
<mark class="unicode" title="U+1F512: LOCK">🔒</mark>
<mark class="unicode" title="U+1F513: OPEN LOCK" data-char-info="U+1F513: OPEN LOCK">🔓</mark>
</div>

<div>
<mark class="unicode" title="U+1F3A8: ARTIST PALETTE" data-char-info="U+1F3A8: ARTIST PALETTE">🎨</mark>
</div>

<div>
medal, ribbon<br>
<mark class="unicode" title="U+1F396: MILITARY MEDAL">🎖</mark>
<mark class="unicode" title="U+1F397: REMINDER RIBBON">🎗</mark>
</div>

<div>
<mark class="unicode" title="U+1F48A: PILL">💊</mark>
<mark class="unicode" title="U+1F489: SYRINGE">💉</mark>
<mark class="unicode" title="U+1F52A: HOCHO">🔪</mark>
<mark class="unicode" title="U+1F5E1: DAGGER KNIFE" data-char-info="U+1F5E1: DAGGER KNIFE">🗡</mark>
<mark class="unicode" title="U+2694: CROSSED SWORDS" data-char-info="U+2694: CROSSED SWORDS">⚔</mark>
<mark class="unicode" title="U+1F52B: PISTOL" data-char-info="U+1F52B: PISTOL">🔫</mark>
<mark class="unicode" title="U+1F946: RIFLE" data-char-info="U+1F946: RIFLE">🥆</mark>
<mark class="unicode" title="U+1F4A3: BOMB" data-char-info="U+1F4A3: BOMB">💣</mark>
<mark class="unicode" title="U+1F9E8: FIRECRACKER" data-char-info="U+1F9E8: FIRECRACKER">🧨</mark>
</div>

<div>
party, holiday, celebration<br>
<mark class="unicode" title="U+1F383: JACK-O-LANTERN" data-char-info="U+1F383: JACK-O-LANTERN">🎃</mark>
<mark class="unicode" title="U+1F381: WRAPPED PRESENT" data-char-info="U+1F381: WRAPPED PRESENT">🎁</mark>
<mark class="unicode" title="U+1F382: BIRTHDAY CAKE" data-char-info="U+1F382: BIRTHDAY CAKE">🎂</mark>
<mark class="unicode" title="U+1F388: BALLOON" data-char-info="U+1F388: BALLOON">🎈</mark>
<mark class="unicode" title="U+1F389: PARTY POPPER" data-char-info="U+1F389: PARTY POPPER">🎉</mark>
<mark class="unicode" title="U+1F38A: CONFETTI BALL" data-char-info="U+1F38A: CONFETTI BALL">🎊</mark>
</div>

<div>
movies<br>
<mark class="unicode" title="U+1F3AC: CLAPPER BOARD">🎬</mark>
<mark class="unicode" title="U+1F3AD: PERFORMING ARTS" data-char-info="U+1F3AD: PERFORMING ARTS">🎭</mark>
<mark class="unicode" title="U+1F3AB: TICKET" data-char-info="U+1F3AB: TICKET">🎫</mark>
<mark class="unicode" title="U+1F39E: FILM FRAMES" data-char-info="U+1F39E: FILM FRAMES">🎞</mark>
<mark class="unicode" title="U+1F39F: ADMISSION TICKETS" data-char-info="U+1F39F: ADMISSION TICKETS">🎟</mark>
</div>

<div>
money<br>
<mark class="unicode" title="U+1F4B0: MONEY BAG">💰</mark>
<mark class="unicode" title="U+1F4B1: CURRENCY EXCHANGE">💱</mark>
<mark class="unicode" title="U+1F4B2: HEAVY DOLLAR SIGN">💲</mark>
<mark class="unicode" title="U+1F4B3: CREDIT CARD" data-char-info="U+1F4B3: CREDIT CARD">💳</mark>
<mark class="unicode" title="U+1F4B4: BANKNOTE WITH YEN SIGN">💴</mark>
<mark class="unicode" title="U+1F4B5: BANKNOTE WITH DOLLAR SIGN" data-char-info="U+1F4B5: BANKNOTE WITH DOLLAR SIGN">💵</mark>
<mark class="unicode" title="U+1F4B6: BANKNOTE WITH EURO SIGN" data-char-info="U+1F4B6: BANKNOTE WITH EURO SIGN">💶</mark>
<mark class="unicode" title="U+1F4B7: BANKNOTE WITH POUND SIGN" data-char-info="U+1F4B7: BANKNOTE WITH POUND SIGN">💷</mark>
<mark class="unicode" title="U+1F4B8: MONEY WITH WINGS" data-char-info="U+1F4B8: MONEY WITH WINGS">💸</mark>
<mark class="unicode" title="U+1F9FE: RECEIPT">🧾</mark>
</div>

<div>
bathroom<br>
<mark class="unicode" title="U+1F6AA: DOOR">🚪</mark>
<mark class="unicode" title="U+1F6BF: SHOWER">🚿</mark>
<mark class="unicode" title="U+1F6C0: BATH">🛀</mark>
<mark class="unicode" title="U+1F6C1: BATHTUB">🛁</mark>
<mark class="unicode" title="U+1F6BD: TOILET">🚽</mark>
<mark class="unicode" title="U+1F9FB: ROLL OF PAPER" data-char-info="U+1F9FB: ROLL OF PAPER">🧻</mark>
<mark class="unicode" title="U+1F9FC: BAR OF SOAP">🧼</mark>
<mark class="unicode" title="U+1F9FD: SPONGE">🧽</mark>
<mark class="unicode" title="U+1F9F4: LOTION BOTTLE">🧴</mark>
</div>

<div>
<mark class="unicode" title="U+1F9F9: BROOM">🧹</mark>
<mark class="unicode" title="U+1F9FA: BASKET">🧺</mark>
</div>

<div>
<mark class="unicode" title="U+1F4BA: SEAT">💺</mark>
<mark class="unicode" title="U+1F9F3: LUGGAGE">🧳</mark>
<mark class="unicode" title="U+1F5FA: WORLD MAP">🗺</mark>
</div>

<div>
science<br>
<mark class="unicode" title="U+1F9EE: ABACUS">🧮</mark>
<mark class="unicode" title="U+1F52C: MICROSCOPE">🔬</mark>
<mark class="unicode" title="U+1F52D: TELESCOPE">🔭</mark>
<mark class="unicode" title="U+1F9EA: TEST TUBE">🧪</mark>
<mark class="unicode" title="U+1F9EB: PETRI DISH">🧫</mark>
<mark class="unicode" title="U+2696: SCALES">⚖</mark>
<mark class="unicode" title="U+2697: ALEMBIC">⚗</mark>
<mark class="unicode" title="U+1F9EC: DNA DOUBLE HELIX">🧬</mark>
</div>

<div>
tools<br>
<mark class="unicode" title="U+1F9F0: TOOLBOX">🧰</mark>
<mark class="unicode" title="U+1F9F2: MAGNET">🧲</mark>
<mark class="unicode" title="U+1F529: NUT AND BOLT">🔩</mark>
<mark class="unicode" title="U+26D3: CHAINS">⛓</mark>
<mark class="unicode" title="U+1F528: HAMMER">🔨</mark>
<mark class="unicode" title="U+26CF: PICK" data-char-info="U+26CF: PICK">⛏</mark>
<mark class="unicode" title="U+1F527: WRENCH">🔧</mark>
<mark class="unicode" title="U+2699: GEAR">⚙</mark>
<mark class="unicode" title="U+26ED: GEAR WITHOUT HUB">⛭</mark>
<mark class="unicode" title="U+26EE: GEAR WITH HANDLES">⛮</mark>
<mark class="unicode" title="U+1F5DC: COMPRESSION">🗜</mark>
<mark class="unicode" title="U+1F9F1: BRICK">🧱</mark>
</div>

<div>
teddy, doll<br>
<mark class="unicode" title="U+1F9F8: TEDDY BEAR">🧸</mark>
<mark class="unicode" title="U+1F38E: JAPANESE DOLLS" data-char-info="U+1F38E: JAPANESE DOLLS">🎎</mark>
</div>

<div>
<mark class="unicode" title="U+1F573: HOLE">🕳</mark>
<mark class="unicode" title="U+1F38D: PINE DECORATION">🎍</mark>
</div>

<div>
<mark class="unicode" title="U+1F3EE: IZAKAYA LANTERN">🏮</mark>
<mark class="unicode" title="U+1F38F: CARP STREAMER">🎏</mark>
<mark class="unicode" title="U+1F390: WIND CHIME">🎐</mark>
</div>

<div>
<mark class="unicode" title="U+26B0: COFFIN">⚰</mark>
<mark class="unicode" title="U+26B1: FUNERAL URN">⚱</mark>
<mark class="unicode" title="U+1F3FA: AMPHORA">🏺</mark>
<mark class="unicode" title="U+2693: ANCHOR">⚓</mark>
</div>

<div>
Religious<br>
<mark class="unicode" title="U+1F54E: MENORAH WITH NINE BRANCHES" data-char-info="U+1F54E: MENORAH WITH NINE BRANCHES">🕎</mark>
<mark class="unicode" title="U+1F4FF: PRAYER BEADS">📿</mark>
<mark class="unicode" title="U+1F9FF: NAZAR AMULET">🧿</mark>
</div>

<div>
<mark class="unicode" title="U+1F71A: ALCHEMICAL SYMBOL FOR GOLD">🜚</mark>
</div>

<div>
From Unicode 11<br>
<mark class="unicode" title="U+1F9E7: RED GIFT ENVELOPE">🧧</mark> RED GIFT ENVELOPE
<mark class="unicode" title="U+1F9E9: JIGSAW PUZZLE PIECE" data-char-info="U+1F9E9: JIGSAW PUZZLE PIECE">🧩</mark> JIGSAW PUZZLE PIECE
<mark class="unicode" title="U+1F9EF: FIRE EXTINGUISHER">🧯</mark> FIRE EXTINGUISHER
<mark class="unicode" title="U+1F9F5: SPOOL OF THREAD" data-char-info="U+1F9F5: SPOOL OF THREAD">🧵</mark> SPOOL OF THREAD
<mark class="unicode" title="U+1F9F6: BALL OF YARN">🧶</mark> BALL OF YARN
<mark class="unicode" title="U+1F9F7: SAFETY PIN">🧷</mark> SAFETY PIN
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F6B2: BICYCLE" data-char-info="U+1F6B2: BICYCLE">🚲</mark>
<mark class="unicode" title="U+1F6F4: SCOOTER">🛴</mark>
<mark class="unicode" title="U+1F6F5: MOTOR SCOOTER">🛵</mark>
<mark class="unicode" title="U+1F3CD: RACING MOTORCYCLE" data-char-info="U+1F3CD: RACING MOTORCYCLE">🏍</mark>
</div>

<div>
<mark class="unicode" title="U+1F695: TAXI" data-char-info="U+1F695: TAXI">🚕</mark>
<mark class="unicode" title="U+1F696: ONCOMING TAXI" data-char-info="U+1F696: ONCOMING TAXI">🚖</mark>
</div>

<div>
<mark class="unicode" title="U+1F697: AUTOMOBILE">🚗</mark>
<mark class="unicode" title="U+1F698: ONCOMING AUTOMOBILE">🚘</mark>
</div>

<div>
<mark class="unicode" title="U+1F694: ONCOMING POLICE CAR" data-char-info="U+1F694: ONCOMING POLICE CAR">🚔</mark>
<mark class="unicode" title="U+1F693: POLICE CAR">🚓</mark>
</div>

<div>
<mark class="unicode" title="U+1F699: RECREATIONAL VEHICLE" data-char-info="U+1F699: RECREATIONAL VEHICLE">🚙</mark>
<mark class="unicode" title="U+1F69C: TRACTOR">🚜</mark>
</div>

<div>
<mark class="unicode" title="U+1F691: AMBULANCE">🚑</mark>
<mark class="unicode" title="U+1F692: FIRE ENGINE">🚒</mark>
</div>

<div>
<mark class="unicode" title="U+1F3CE: RACING CAR" data-char-info="U+1F3CE: RACING CAR">🏎</mark>
</div>

<div>
<mark class="unicode" title="U+26DF: BLACK TRUCK" data-char-info="U+26DF: BLACK TRUCK">⛟</mark>
<mark class="unicode" title="U+1F69A: DELIVERY TRUCK">🚚</mark>
<mark class="unicode" title="U+1F69B: ARTICULATED LORRY" data-char-info="U+1F69B: ARTICULATED LORRY">🚛</mark>
</div>

<div>
<mark class="unicode" title="U+1F68F: BUS STOP" data-char-info="U+1F68F: BUS STOP">🚏</mark>
<mark class="unicode" title="U+1F68C: BUS" data-char-info="U+1F68C: BUS">🚌</mark>
<mark class="unicode" title="U+1F68D: ONCOMING BUS" data-char-info="U+1F68D: ONCOMING BUS">🚍</mark>
<mark class="unicode" title="U+1F68E: TROLLEYBUS">🚎</mark>
<mark class="unicode" title="U+1F690: MINIBUS" data-char-info="U+1F690: MINIBUS">🚐</mark>
</div>

<div>
<mark class="unicode" title="U+1F689: STATION">🚉</mark>
<mark class="unicode" title="U+1F682: STEAM LOCOMOTIVE" data-char-info="U+1F682: STEAM LOCOMOTIVE">🚂</mark>
<mark class="unicode" title="U+1F686: TRAIN">🚆</mark>
<mark class="unicode" title="U+1F684: HIGH-SPEED TRAIN">🚄</mark>
<mark class="unicode" title="U+1F685: HIGH-SPEED TRAIN WITH BULLET NOSE">🚅</mark>
</div>

<div>
<mark class="unicode" title="U+1F683: RAILWAY CAR">🚃</mark>
<mark class="unicode" title="U+1F687: METRO">🚇</mark>
<mark class="unicode" title="U+1F688: LIGHT RAIL" data-char-info="U+1F688: LIGHT RAIL">🚈</mark>
<mark class="unicode" title="U+1F68A: TRAM" data-char-info="U+1F68A: TRAM">🚊</mark>
<mark class="unicode" title="U+1F68B: TRAM CAR">🚋</mark>
<mark class="unicode" title="U+1F69D: MONORAIL">🚝</mark>
<mark class="unicode" title="U+1F69E: MOUNTAIN RAILWAY">🚞</mark>
</div>

<div>
<mark class="unicode" title="U+1F69F: SUSPENSION RAILWAY">🚟</mark>
<mark class="unicode" title="U+1F6A0: MOUNTAIN CABLEWAY">🚠</mark>
<mark class="unicode" title="U+1F6A1: AERIAL TRAMWAY">🚡</mark>
</div>

<div>
<mark class="unicode" title="U+26F4: FERRY" data-char-info="U+26F4: FERRY">⛴</mark>
<mark class="unicode" title="U+1F6A2: SHIP" data-char-info="U+1F6A2: SHIP">🚢</mark>
<mark class="unicode" title="U+26F5: SAILBOAT" data-char-info="U+26F5: SAILBOAT">⛵</mark>
<mark class="unicode" title="U+1F6A3: ROWBOAT" data-char-info="U+1F6A3: ROWBOAT">🚣</mark>
<mark class="unicode" title="U+1F6A4: SPEEDBOAT">🚤</mark>
<mark class="unicode" title="U+1F6E5: MOTOR BOAT">🛥</mark>
</div>

<div>
<mark class="unicode" title="U+1F6EB: AIRPLANE DEPARTURE">🛫</mark>
<mark class="unicode" title="U+1F6EC: AIRPLANE ARRIVING">🛬</mark>
<mark class="unicode" title="U+1F6E9: SMALL AIRPLANE">🛩</mark>
</div>

<div>
<mark class="unicode" title="U+2708: AIRPLANE">✈</mark>
<mark class="unicode" title="U+1F6E7: UP-POINTING AIRPLANE">🛧</mark>
<mark class="unicode" title="U+1F6EA: NORTHEAST-POINTING AIRPLANE">🛪</mark>
<mark class="unicode" title="U+1F6E8: UP-POINTING SMALL AIRPLANE">🛨</mark>
<mark class="unicode" title="U+1F6E6: UP-POINTING MILITARY AIRPLANE">🛦</mark>
</div>

<div>
<mark class="unicode" title="U+1F681: HELICOPTER">🚁</mark>
<mark class="unicode" title="U+1F680: ROCKET">🚀</mark>
<mark class="unicode" title="U+1F6F8: FLYING SAUCER">🛸</mark>
</div>

<div>
<mark class="unicode" title="U+1F66C: LEFTWARDS ROCKET">🙬</mark>
<mark class="unicode" title="U+1F66D: UPWARDS ROCKET">🙭</mark>
<mark class="unicode" title="U+1F66E: RIGHTWARDS ROCKET">🙮</mark>
<mark class="unicode" title="U+1F66F: DOWNWARDS ROCKET">🙯</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F3E8: HOTEL" data-char-info="U+1F3E8: HOTEL">🏨</mark>
<mark class="unicode" title="U+1F3E9: LOVE HOTEL" data-char-info="U+1F3E9: LOVE HOTEL">🏩</mark>
</div>

<div>
<mark class="unicode" title="U+1F3E5: HOSPITAL" data-char-info="U+1F3E5: HOSPITAL">🏥</mark>
<mark class="unicode" title="U+1F3E6: BANK" data-char-info="U+1F3E6: BANK">🏦</mark>
<mark class="unicode" title="U+1F3E7: AUTOMATED TELLER MACHINE" data-char-info="U+1F3E7: AUTOMATED TELLER MACHINE">🏧</mark>
</div>

<div>
<mark class="unicode" title="U+1F3EB: SCHOOL" data-char-info="U+1F3EB: SCHOOL">🏫</mark>
<mark class="unicode" title="U+1F3E2: OFFICE BUILDING" data-char-info="U+1F3E2: OFFICE BUILDING">🏢</mark>
</div>

<div>
<mark class="unicode" title="U+1F3EA: CONVENIENCE STORE" data-char-info="U+1F3EA: CONVENIENCE STORE">🏪</mark>
<mark class="unicode" title="U+1F3EC: DEPARTMENT STORE" data-char-info="U+1F3EC: DEPARTMENT STORE">🏬</mark>
</div>

<div>
<mark class="unicode" title="U+1F3ED: FACTORY" data-char-info="U+1F3ED: FACTORY">🏭</mark>
<mark class="unicode" title="U+1F3DB: CLASSICAL BUILDING" data-char-info="U+1F3DB: CLASSICAL BUILDING">🏛</mark>
</div>

<div>
<mark class="unicode" title="U+1F3E3: JAPANESE POST OFFICE" data-char-info="U+1F3E3: JAPANESE POST OFFICE">🏣</mark>
<mark class="unicode" title="U+1F3E4: EUROPEAN POST OFFICE" data-char-info="U+1F3E4: EUROPEAN POST OFFICE">🏤</mark>
</div>

<div>
<mark class="unicode" title="U+1F3E0: HOUSE BUILDING" data-char-info="U+1F3E0: HOUSE BUILDING">🏠</mark>
<mark class="unicode" title="U+1F3E1: HOUSE WITH GARDEN" data-char-info="U+1F3E1: HOUSE WITH GARDEN">🏡</mark>
<mark class="unicode" title="U+1F3D8: HOUSE BUILDINGS" data-char-info="U+1F3D8: HOUSE BUILDINGS">🏘</mark>
<mark class="unicode" title="U+1F3DA: DERELICT HOUSE BUILDING" data-char-info="U+1F3DA: DERELICT HOUSE BUILDING">🏚</mark>
</div>


<div>
<mark class="unicode" title="U+1F3D7: BUILDING CONSTRUCTION" data-char-info="U+1F3D7: BUILDING CONSTRUCTION">🏗</mark>
</div>

<div>
<mark class="unicode" title="U+26EA: CHURCH" data-char-info="U+26EA: CHURCH">⛪</mark>
<mark class="unicode" title="U+26E9: SHINTO SHRINE" data-char-info="U+26E9: SHINTO SHRINE">⛩</mark>
<mark class="unicode" title="U+1F54C: MOSQUE" data-char-info="U+1F54C: MOSQUE">🕌</mark>
<mark class="unicode" title="U+1F54D: SYNAGOGUE" data-char-info="U+1F54D: SYNAGOGUE">🕍</mark>
<mark class="unicode" title="U+1F54B: KAABA" data-char-info="U+1F54B: KAABA">🕋</mark>
<mark class="unicode" title="U+1F6D0: PLACE OF WORSHIP" data-char-info="U+1F6D0: PLACE OF WORSHIP">🛐</mark>
</div>

<div>
<mark class="unicode" title="U+1F3AA: CIRCUS TENT" data-char-info="U+1F3AA: CIRCUS TENT">🎪</mark>
<mark class="unicode" title="U+1F3A0: CAROUSEL HORSE" data-char-info="U+1F3A0: CAROUSEL HORSE">🎠</mark>
<mark class="unicode" title="U+1F3A1: FERRIS WHEEL" data-char-info="U+1F3A1: FERRIS WHEEL">🎡</mark>
<mark class="unicode" title="U+1F3A2: ROLLER COASTER" data-char-info="U+1F3A2: ROLLER COASTER">🎢</mark>
</div>

<div>
<mark class="unicode" title="U+1F3A6: CINEMA" data-char-info="U+1F3A6: CINEMA">🎦</mark>
<mark class="unicode" title="U+2668: HOT SPRINGS" data-char-info="U+2668: HOT SPRINGS">♨</mark>
<mark class="unicode" title="U+26EB: CASTLE" data-char-info="U+26EB: CASTLE">⛫</mark>
<mark class="unicode" title="U+1F3EF: JAPANESE CASTLE" data-char-info="U+1F3EF: JAPANESE CASTLE">🏯</mark>
<mark class="unicode" title="U+1F3F0: EUROPEAN CASTLE" data-char-info="U+1F3F0: EUROPEAN CASTLE">🏰</mark>
</div>

<div>
<mark class="unicode" title="U+1F3D9: CITYSCAPE">🏙</mark>
<mark class="unicode" title="U+1F306: CITYSCAPE AT DUSK">🌆</mark>
<mark class="unicode" title="U+1F307: SUNSET OVER BUILDINGS">🌇</mark>
<mark class="unicode" title="U+1F309: BRIDGE AT NIGHT" data-char-info="U+1F309: BRIDGE AT NIGHT">🌉</mark>
</div>

<div>
<mark class="unicode" title="U+1F303: NIGHT WITH STARS" data-char-info="U+1F303: NIGHT WITH STARS">🌃</mark>
<mark class="unicode" title="U+1F304: SUNRISE OVER MOUNTAINS" data-char-info="U+1F304: SUNRISE OVER MOUNTAINS">🌄</mark>
<mark class="unicode" title="U+1F305: SUNRISE" data-char-info="U+1F305: SUNRISE">🌅</mark>
<mark class="unicode" title="U+1F30C: MILKY WAY" data-char-info="U+1F30C: MILKY WAY">🌌</mark>
</div>

<div>
<mark class="unicode" title="U+1F391: MOON VIEWING CEREMONY">🎑</mark>
<mark class="unicode" title="U+1F386: FIREWORKS">🎆</mark>
<mark class="unicode" title="U+1F387: FIREWORK SPARKLER" data-char-info="U+1F387: FIREWORK SPARKLER">🎇</mark>
</div>

<div>
<mark class="unicode" title="U+26F2: FOUNTAIN" data-char-info="U+26F2: FOUNTAIN">⛲</mark>
<mark class="unicode" title="U+1F30A: WATER WAVE" data-char-info="U+1F30A: WATER WAVE">🌊</mark>
<mark class="unicode" title="U+1F3D6: BEACH WITH UMBRELLA" data-char-info="U+1F3D6: BEACH WITH UMBRELLA">🏖</mark>
<mark class="unicode" title="U+1F3DD: DESERT ISLAND" data-char-info="U+1F3DD: DESERT ISLAND">🏝</mark>
</div>

<div>
<mark class="unicode" title="U+1F3DE: NATIONAL PARK">🏞</mark>
<mark class="unicode" title="U+1F3D5: CAMPING" data-char-info="U+1F3D5: CAMPING">🏕</mark>
</div>

<div>
<mark class="unicode" title="U+1F3DC: DESERT" data-char-info="U+1F3DC: DESERT">🏜</mark>
</div>

<div>
<mark class="unicode" title="U+26F0: MOUNTAIN" data-char-info="U+26F0: MOUNTAIN">⛰</mark>
<mark class="unicode" title="U+1F3D4: SNOW CAPPED MOUNTAIN" data-char-info="U+1F3D4: SNOW CAPPED MOUNTAIN">🏔</mark>
<mark class="unicode" title="U+1F5FB: MOUNT FUJI" data-char-info="U+1F5FB: MOUNT FUJI">🗻</mark>
<mark class="unicode" title="U+1F30B: VOLCANO" data-char-info="U+1F30B: VOLCANO">🌋</mark>
</div>

<div>
<mark class="unicode" title="U+1F5FC: TOKYO TOWER" data-char-info="U+1F5FC: TOKYO TOWER">🗼</mark>
<mark class="unicode" title="U+1F5FD: STATUE OF LIBERTY" data-char-info="U+1F5FD: STATUE OF LIBERTY">🗽</mark>
<mark class="unicode" title="U+1F5FE: SILHOUETTE OF JAPAN" data-char-info="U+1F5FE: SILHOUETTE OF JAPAN">🗾</mark>
<mark class="unicode" title="U+1F5FF: MOYAI" data-char-info="U+1F5FF: MOYAI">🗿</mark>
</div>

<div>
<mark class="unicode" title="U+1F5FA: WORLD MAP">🗺</mark>
<mark class="unicode" title="U+1F30D: EARTH GLOBE EUROPE-AFRICA">🌍</mark>
<mark class="unicode" title="U+1F30E: EARTH GLOBE AMERICAS">🌎</mark>
<mark class="unicode" title="U+1F30F: EARTH GLOBE ASIA-AUSTRALIA">🌏</mark>
<mark class="unicode" title="U+1F310: GLOBE WITH MERIDIANS">🌐</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+26BD: SOCCER BALL" data-char-info="U+26BD: SOCCER BALL">⚽</mark>
<mark class="unicode" title="U+26BE: BASEBALL" data-char-info="U+26BE: BASEBALL">⚾</mark>
<mark class="unicode" title="U+1F3C0: BASKETBALL AND HOOP" data-char-info="U+1F3C0: BASKETBALL AND HOOP">🏀</mark>
<mark class="unicode" title="U+1F3D0: VOLLEYBALL" data-char-info="U+1F3D0: VOLLEYBALL">🏐</mark>
<mark class="unicode" title="U+1F3C8: AMERICAN FOOTBALL" data-char-info="U+1F3C8: AMERICAN FOOTBALL">🏈</mark>
<mark class="unicode" title="U+1F3C9: RUGBY FOOTBALL" data-char-info="U+1F3C9: RUGBY FOOTBALL">🏉</mark>
</div>

<div>
<mark class="unicode" title="U+26F9: PERSON WITH BALL">⛹</mark>
<mark class="unicode" title="U+1F93E: HANDBALL" data-char-info="U+1F93E: HANDBALL">🤾</mark>
<mark class="unicode" title="U+1F94E: SOFTBALL" data-char-info="U+1F94E: SOFTBALL">🥎</mark>
<mark class="unicode" title="U+1F3CF: CRICKET BAT AND BALL" data-char-info="U+1F3CF: CRICKET BAT AND BALL">🏏</mark>
<mark class="unicode" title="U+1F3D1: FIELD HOCKEY STICK AND BALL" data-char-info="U+1F3D1: FIELD HOCKEY STICK AND BALL">🏑</mark>
<mark class="unicode" title="U+1F3D2: ICE HOCKEY STICK AND PUCK" data-char-info="U+1F3D2: ICE HOCKEY STICK AND PUCK">🏒</mark>
<mark class="unicode" title="U+1F945: GOAL NET" data-char-info="U+1F945: GOAL NET">🥅</mark>
<mark class="unicode" title="U+1F94D: LACROSSE STICK AND BALL" data-char-info="U+1F94D: LACROSSE STICK AND BALL">🥍</mark>
</div>

<div>
<mark class="unicode" title="U+1F3D3: TABLE TENNIS PADDLE AND BALL" data-char-info="U+1F3D3: TABLE TENNIS PADDLE AND BALL">🏓</mark>
<mark class="unicode" title="U+1F3BE: TENNIS RACQUET AND BALL" data-char-info="U+1F3BE: TENNIS RACQUET AND BALL">🎾</mark>
<mark class="unicode" title="U+1F3F8: BADMINTON RACQUET AND SHUTTLECOCK" data-char-info="U+1F3F8: BADMINTON RACQUET AND SHUTTLECOCK">🏸</mark>
</div>

<div>
<mark class="unicode" title="U+1F94A: BOXING GLOVE">🥊</mark>
<mark class="unicode" title="U+1F94B: MARTIAL ARTS UNIFORM" data-char-info="U+1F94B: MARTIAL ARTS UNIFORM">🥋</mark>
<mark class="unicode" title="U+1F93A: FENCER" data-char-info="U+1F93A: FENCER">🤺</mark>
<mark class="unicode" title="U+1F93C: WRESTLERS" data-char-info="U+1F93C: WRESTLERS">🤼</mark>
</div>

<div>
<mark class="unicode" title="U+1F3C3: RUNNER">🏃</mark>
<mark class="unicode" title="U+1F3C7: HORSE RACING">🏇</mark>
<mark class="unicode" title="U+1F3CB: WEIGHT LIFTER">🏋</mark>
<mark class="unicode" title="U+1F3F9: BOW AND ARROW" data-char-info="U+1F3F9: BOW AND ARROW">🏹</mark>
<mark class="unicode" title="U+1F6F6: CANOE" data-char-info="U+1F6F6: CANOE">🛶</mark>
</div>

<div>
<mark class="unicode" title="U+1F938: PERSON DOING CARTWHEEL">🤸</mark>
<mark class="unicode" title="U+1F939: JUGGLING" data-char-info="U+1F939: JUGGLING">🤹</mark>
<mark class="unicode" title="U+1F6F9: SKATEBOARD" data-char-info="U+1F6F9: SKATEBOARD">🛹</mark>
<mark class="unicode" title="U+1F94F: FLYING DISC" data-char-info="U+1F94F: FLYING DISC">🥏</mark>
<mark class="unicode" title="U+1F3B3: BOWLING" data-char-info="U+1F3B3: BOWLING">🎳</mark>
</div>

<div>
<mark class="unicode" title="U+1F3CA: SWIMMER" data-char-info="U+1F3CA: SWIMMER">🏊</mark>
<mark class="unicode" title="U+1F3C4: SURFER">🏄</mark>
<mark class="unicode" title="U+1F93D: WATER POLO">🤽</mark>
</div>

<div>
<mark class="unicode" title="U+1F3BF: SKI AND SKI BOOT" data-char-info="U+1F3BF: SKI AND SKI BOOT">🎿</mark>
<mark class="unicode" title="U+26F8: ICE SKATE" data-char-info="U+26F8: ICE SKATE">⛸</mark>
<mark class="unicode" title="U+26F7: SKIER" data-char-info="U+26F7: SKIER">⛷</mark>
<mark class="unicode" title="U+1F3C2: SNOWBOARDER">🏂</mark>
<mark class="unicode" title="U+1F6F7: SLED" data-char-info="U+1F6F7: SLED">🛷</mark>
<mark class="unicode" title="U+1F94C: CURLING STONE" data-char-info="U+1F94C: CURLING STONE">🥌</mark>
</div>

<div>
<mark class="unicode" title="U+1F3CC: GOLFER" data-char-info="U+1F3CC: GOLFER">🏌</mark>
<mark class="unicode" title="U+26F3: FLAG IN HOLE" data-char-info="U+26F3: FLAG IN HOLE">⛳</mark>
</div>

<div>
<mark class="unicode" title="U+1F9ED: COMPASS" data-char-info="U+1F9ED: COMPASS">🧭</mark>
<mark class="unicode" title="U+26FA: TENT">⛺</mark>
<mark class="unicode" title="U+1F3A3: FISHING POLE AND FISH">🎣</mark>
<mark class="unicode" title="U+1F9D7: PERSON CLIMBING">🧗</mark>
</div>

<div>
<mark class="unicode" title="U+1F3C6: TROPHY">🏆</mark>
<mark class="unicode" title="U+1F3C5: SPORTS MEDAL">🏅</mark>
<mark class="unicode" title="U+1F947: FIRST PLACE MEDAL">🥇</mark>
<mark class="unicode" title="U+1F948: SECOND PLACE MEDAL">🥈</mark>
<mark class="unicode" title="U+1F949: THIRD PLACE MEDAL">🥉</mark>
</div>

<div>
<mark class="unicode" title="U+1F3C1: CHEQUERED FLAG">🏁</mark>
<mark class="unicode" title="U+2690: WHITE FLAG">⚐</mark>
<mark class="unicode" title="U+2691: BLACK FLAG">⚑</mark>
<mark class="unicode" title="U+26FF: WHITE FLAG WITH HORIZONTAL MIDDLE BLACK STRIPE">⛿</mark>
<mark class="unicode" title="U+1F6A9: TRIANGULAR FLAG ON POST">🚩</mark>
<mark class="unicode" title="U+1F3F1: WHITE PENNANT" data-char-info="U+1F3F1: WHITE PENNANT">🏱</mark>
<mark class="unicode" title="U+1F3F2: BLACK PENNANT" data-char-info="U+1F3F2: BLACK PENNANT">🏲</mark>
<mark class="unicode" title="U+1F3F3: WAVING WHITE FLAG" data-char-info="U+1F3F3: WAVING WHITE FLAG">🏳</mark>
</div>

<div>
<mark class="unicode" title="U+1F3AF: DIRECT HIT">🎯</mark>
<mark class="unicode" title="U+1F3B1: BILLIARDS">🎱</mark>
<mark class="unicode" title="U+1F3B0: SLOT MACHINE">🎰</mark>
<mark class="unicode" title="U+1F3B2: GAME DIE">🎲</mark>
</div>

<div>
<mark class="unicode" title="U+1F0CF: PLAYING CARD BLACK JOKER">🃏</mark>
<mark class="unicode" title="U+1F0DF: PLAYING CARD WHITE JOKER">🃟</mark>
<mark class="unicode" title="U+1F0BF: PLAYING CARD RED JOKER">🂿</mark>
<mark class="unicode" title="U+1F3B4: FLOWER PLAYING CARDS">🎴</mark>
<mark class="unicode" title="U+1F004: MAHJONG TILE RED DRAGON">🀄</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F4AE: WHITE FLOWER" data-char-info="U+1F4AE: WHITE FLOWER">💮</mark>
<mark class="unicode" title="U+1F338: CHERRY BLOSSOM" data-char-info="U+1F338: CHERRY BLOSSOM">🌸</mark>
<mark class="unicode" title="U+1F337: TULIP" data-char-info="U+1F337: TULIP">🌷</mark>
<mark class="unicode" title="U+1F339: ROSE" data-char-info="U+1F339: ROSE">🌹</mark>
</div>

<div>
<mark class="unicode" title="U+1F33A: HIBISCUS" data-char-info="U+1F33A: HIBISCUS">🌺</mark>
<mark class="unicode" title="U+1F33B: SUNFLOWER" data-char-info="U+1F33B: SUNFLOWER">🌻</mark>
<mark class="unicode" title="U+1F33C: BLOSSOM" data-char-info="U+1F33C: BLOSSOM">🌼</mark>
<mark class="unicode" title="U+1F940: WILTED FLOWER" data-char-info="U+1F940: WILTED FLOWER">🥀</mark>
</div>

<div>
<mark class="unicode" title="U+1F395: BOUQUET OF FLOWERS">🎕</mark>
<mark class="unicode" title="U+2698: FLOWER">⚘</mark>
<mark class="unicode" title="U+1F3F5: ROSETTE" data-char-info="U+1F3F5: ROSETTE">🏵</mark>
<mark class="unicode" title="U+1F3F6: BLACK ROSETTE" data-char-info="U+1F3F6: BLACK ROSETTE">🏶</mark>
</div>

<div>
<mark class="unicode" title="U+1F331: SEEDLING">🌱</mark>
<mark class="unicode" title="U+1F33D: EAR OF MAIZE">🌽</mark>
<mark class="unicode" title="U+1F33E: EAR OF RICE" data-char-info="U+1F33E: EAR OF RICE">🌾</mark>
</div>

<div>
<mark class="unicode" title="U+2E19: PALM BRANCH" data-char-info="U+2E19: PALM BRANCH">⸙</mark>
<mark class="unicode" title="U+1F330: CHESTNUT" data-char-info="U+1F330: CHESTNUT">🌰</mark>
<mark class="unicode" title="U+1F33F: HERB">🌿</mark>
</div>

<div>
<mark class="unicode" title="U+1F340: FOUR LEAF CLOVER">🍀</mark>
<mark class="unicode" title="U+1F341: MAPLE LEAF" data-char-info="U+1F341: MAPLE LEAF">🍁</mark>
<mark class="unicode" title="U+1F342: FALLEN LEAF" data-char-info="U+1F342: FALLEN LEAF">🍂</mark>
<mark class="unicode" title="U+1F343: LEAF FLUTTERING IN WIND" data-char-info="U+1F343: LEAF FLUTTERING IN WIND">🍃</mark>
</div>

<div>
<mark class="unicode" title="U+1F335: CACTUS" data-char-info="U+1F335: CACTUS">🌵</mark>
<mark class="unicode" title="U+1F334: PALM TREE" data-char-info="U+1F334: PALM TREE">🌴</mark>
</div>

<div>
<mark class="unicode" title="U+1F38B: TANABATA TREE" data-char-info="U+1F38B: TANABATA TREE">🎋</mark>
<mark class="unicode" title="U+1F384: CHRISTMAS TREE" data-char-info="U+1F384: CHRISTMAS TREE">🎄</mark>
<mark class="unicode" title="U+1F332: EVERGREEN TREE" data-char-info="U+1F332: EVERGREEN TREE">🌲</mark>
<mark class="unicode" title="U+1F333: DECIDΑCPS TREE" data-char-info="U+1F333: DECIDΑCPS TREE">🌳</mark>
</div>

</div>`,
  `<div>
<mark class="unicode" title="U+1F98B: BUTTERFLY" data-char-info="U+1F98B: BUTTERFLY">🦋</mark>
<mark class="unicode" title="U+1F41B: BUG">🐛</mark>
<mark class="unicode" title="U+1F41D: HONEYBEE">🐝</mark>
<mark class="unicode" title="U+1F41E: LADY BEETLE">🐞</mark>
<mark class="unicode" title="U+1F41C: ANT">🐜</mark>
<mark class="unicode" title="U+1F577: SPIDER">🕷</mark>
<mark class="unicode" title="U+1F578: SPIDER WEB">🕸</mark>
<mark class="unicode" title="U+1F982: SCORPION">🦂</mark>
<mark class="unicode" title="U+1F997: CRICKET" data-char-info="U+1F997: CRICKET">🦗</mark>
<mark class="unicode" title="U+1F99F: MOSQUITO" data-char-info="U+1F99F: MOSQUITO">🦟</mark>
</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F42D: MOUSE FACE" data-char-info="U+1F42D: MOUSE FACE">🐭</mark>
<mark class="unicode" title="U+1F439: HAMSTER FACE" data-char-info="U+1F439: HAMSTER FACE">🐹</mark>
<mark class="unicode" title="U+1F430: RABBIT FACE" data-char-info="U+1F430: RABBIT FACE">🐰</mark>
</div>

<div>
<mark class="unicode" title="U+1F436: DOG FACE" data-char-info="U+1F436: DOG FACE">🐶</mark>
<mark class="unicode" title="U+1F43A: WOLF FACE" data-char-info="U+1F43A: WOLF FACE">🐺</mark>
<mark class="unicode" title="U+1F98A: FOX FACE" data-char-info="U+1F98A: FOX FACE">🦊</mark>
</div>

<div>
<mark class="unicode" title="U+1F42F: TIGER FACE" data-char-info="U+1F42F: TIGER FACE">🐯</mark>
<mark class="unicode" title="U+1F981: LION FACE" data-char-info="U+1F981: LION FACE">🦁</mark>
</div>

<div>
<mark class="unicode" title="U+1F992: GIRAFFE FACE">🦒</mark>
<mark class="unicode" title="U+1F993: ZEBRA FACE">🦓</mark>
<mark class="unicode" title="U+1F434: HORSE FACE">🐴</mark>
</div>

<div>
<mark class="unicode" title="U+1F42E: COW FACE">🐮</mark>
<mark class="unicode" title="U+1F437: PIG FACE">🐷</mark>
<mark class="unicode" title="U+1F43D: PIG NOSE">🐽</mark>
</div>

<div>
<mark class="unicode" title="U+1F43B: BEAR FACE">🐻</mark>
<mark class="unicode" title="U+1F43C: PANDA FACE">🐼</mark>
</div>

<div>
<mark class="unicode" title="U+1F438: FROG FACE">🐸</mark>
</div>

<div>
<mark class="unicode" title="U+1F432: DRAGON FACE">🐲</mark>
<mark class="unicode" title="U+1F984: UNICORN FACE">🦄</mark>
</div>

<div>
<mark class="unicode" title="U+1F435: MONKEY FACE">🐵</mark>
<mark class="unicode" title="U+1F648: SEE-NO-EVIL MONKEY" data-char-info="U+1F648: SEE-NO-EVIL MONKEY">🙈</mark>
<mark class="unicode" title="U+1F649: HEAR-NO-EVIL MONKEY">🙉</mark>
<mark class="unicode" title="U+1F64A: SPEAK-NO-EVIL MONKEY">🙊</mark>
</div>

<div>
<mark class="unicode" title="U+1F431: CAT FACE">🐱</mark>
<mark class="unicode" title="U+1F43E: PAW PRINTS">🐾</mark>
</div>

<div>
<mark class="unicode" title="U+1F638: GRINNING CAT FACE WITH SMILING EYES">😸</mark>
<mark class="unicode" title="U+1F639: CAT FACE WITH TEARS OF JOY">😹</mark>
<mark class="unicode" title="U+1F63A: SMILING CAT FACE WITH OPEN MOUTH">😺</mark>
<mark class="unicode" title="U+1F63C: CAT FACE WITH WRY SMILE">😼</mark>
<mark class="unicode" title="U+1F63B: SMILING CAT FACE WITH HEART-SHAPED EYES">😻</mark>
<mark class="unicode" title="U+1F63D: KISSING CAT FACE WITH CLOSED EYES" data-char-info="U+1F63D: KISSING CAT FACE WITH CLOSED EYES">😽</mark>
</div>

<div>
<mark class="unicode" title="U+1F63E: POUTING CAT FACE">😾</mark>
<mark class="unicode" title="U+1F63F: CRYING CAT FACE">😿</mark>
<mark class="unicode" title="U+1F640: WEARY CAT FACE">🙀</mark>
</div>

<div>
<mark class="unicode" title="U+1F405: TIGER">🐅</mark>
<mark class="unicode" title="U+1F406: LEOPARD" data-char-info="U+1F406: LEOPARD">🐆</mark>
</div>

<div>
<mark class="unicode" title="U+1F418: ELEPHANT" data-char-info="U+1F418: ELEPHANT">🐘</mark>
<mark class="unicode" title="U+1F98F: RHINOCEROS" data-char-info="U+1F98F: RHINOCEROS">🦏</mark>
<mark class="unicode" title="U+1F99B: HIPPOPOTAMUS" data-char-info="U+1F99B: HIPPOPOTAMUS">🦛</mark>
</div>

<div>
<mark class="unicode" title="U+1F403: WATER BUFFALO">🐃</mark>
<mark class="unicode" title="U+1F402: OX">🐂</mark>
<mark class="unicode" title="U+1F404: COW">🐄</mark>
</div>

<div>
<mark class="unicode" title="U+1F98D: GORILLA">🦍</mark>
<mark class="unicode" title="U+1F412: MONKEY">🐒</mark>
<mark class="unicode" title="U+1F998: KANGAROO">🦘</mark>
</div>

<div>
<mark class="unicode" title="U+1F98C: DEER">🦌</mark>
<mark class="unicode" title="U+1F410: GOAT">🐐</mark>
<mark class="unicode" title="U+1F40F: RAM">🐏</mark>
<mark class="unicode" title="U+1F411: SHEEP">🐑</mark>
</div>

<div>
<mark class="unicode" title="U+1F40E: HORSE">🐎</mark>
<mark class="unicode" title="U+1F999: LLAMA">🦙</mark>
<mark class="unicode" title="U+1F42A: DROMEDARY CAMEL">🐪</mark>
<mark class="unicode" title="U+1F42B: BACTRIAN CAMEL">🐫</mark>
</div>

<div>
<mark class="unicode" title="U+1F417: BOAR">🐗</mark>
<mark class="unicode" title="U+1F416: PIG">🐖</mark>
</div>

<div>
<mark class="unicode" title="U+1F409: DRAGON">🐉</mark>
<mark class="unicode" title="U+1F996: T-REX">🦖</mark>
<mark class="unicode" title="U+1F995: SAUROPOD">🦕</mark>
</div>

<div>
<mark class="unicode" title="U+1F408: CAT">🐈</mark>
</div>

<div>
<mark class="unicode" title="U+1F415: DOG">🐕</mark>
<mark class="unicode" title="U+1F429: POODLE">🐩</mark>
</div>

<div>
<mark class="unicode" title="U+1F428: KOALA">🐨</mark>
<mark class="unicode" title="U+1F407: RABBIT">🐇</mark>
<mark class="unicode" title="U+1F43F: CHIPMUNK">🐿</mark>
<mark class="unicode" title="U+1F99D: RACCOON">🦝</mark>
<mark class="unicode" title="U+1F9A1: BADGER">🦡</mark>
<mark class="unicode" title="U+1F994: HEDGEHOG">🦔</mark>
</div>

<div>
<mark class="unicode" title="U+1F400: RAT">🐀</mark>
<mark class="unicode" title="U+1F401: MOUSE">🐁</mark>
</div>

<div>
<mark class="unicode" title="U+1F987: BAT">🦇</mark>
<mark class="unicode" title="U+1F40D: SNAKE">🐍</mark>
</div>

<div>
<mark class="unicode" title="U+1F463: FOOTPRINTS">👣</mark>
</div>

<div>
<mark class="unicode" title="U+1F985: EAGLE">🦅</mark>
<mark class="unicode" title="U+1F989: OWL">🦉</mark>
<mark class="unicode" title="U+1F99C: PARROT">🦜</mark>
<mark class="unicode" title="U+1F54A: DOVE OF PEACE">🕊</mark>
<mark class="unicode" title="U+1F426: BIRD">🐦</mark>
<mark class="unicode" title="U+1F427: PENGUIN">🐧</mark>
</div>

<div>
<mark class="unicode" title="U+1F99A: PEACOCK">🦚</mark>
<mark class="unicode" title="U+1F983: TURKEY">🦃</mark>
<mark class="unicode" title="U+1F9A2: SWAN">🦢</mark>
<mark class="unicode" title="U+1F986: DUCK">🦆</mark>
</div>

<div>
<mark class="unicode" title="U+1F413: ROOSTER">🐓</mark>
<mark class="unicode" title="U+1F414: CHICKEN">🐔</mark>
<mark class="unicode" title="U+1F423: HATCHING CHICK">🐣</mark>
<mark class="unicode" title="U+1F424: BABY CHICK">🐤</mark>
<mark class="unicode" title="U+1F425: FRONT-FACING BABY CHICK">🐥</mark>
</div>

<div>
<mark class="unicode" title="U+1F40B: WHALE">🐋</mark>
<mark class="unicode" title="U+1F433: SPOUTING WHALE">🐳</mark>
<mark class="unicode" title="U+1F42C: DOLPHIN">🐬</mark>
<mark class="unicode" title="U+1F988: SHARK">🦈</mark>
</div>

<div>
<mark class="unicode" title="U+1F41F: FISH">🐟</mark>
<mark class="unicode" title="U+1F420: TROPICAL FISH">🐠</mark>
<mark class="unicode" title="U+1F421: BLOWFISH">🐡</mark>
<mark class="unicode" title="U+1F419: OCTOPUS">🐙</mark>
<mark class="unicode" title="U+1F991: SQUID">🦑</mark>
</div>

<div>
<mark class="unicode" title="U+1F990: SHRIMP">🦐</mark>
<mark class="unicode" title="U+1F980: CRAB">🦀</mark>
<mark class="unicode" title="U+1F41A: SPIRAL SHELL">🐚</mark>
<mark class="unicode" title="U+1F40C: SNAIL">🐌</mark>
<mark class="unicode" title="U+1F99E: LOBSTER">🦞</mark>
</div>

<div>
<mark class="unicode" title="U+1F422: TURTLE">🐢</mark>
<mark class="unicode" title="U+1F98E: LIZARD">🦎</mark>
<mark class="unicode" title="U+1F40A: CROCODILE">🐊</mark>
</div>

<div>
<mark class="unicode" title="U+1F3C7: HORSE RACING">🏇</mark>
<mark class="unicode" title="U+1F3A0: CAROUSEL HORSE">🎠</mark>
<mark class="unicode" title="U+2658: WHITE CHESS KNIGHT">♘</mark>
<mark class="unicode" title="U+265E: BLACK CHESS KNIGHT">♞</mark>
</div>

<div>
Chinese Zodiac<br>
<mark class="unicode" title="U+1F400: RAT">🐀</mark>
<mark class="unicode" title="U+1F403: WATER BUFFALO">🐃</mark>
<mark class="unicode" title="U+1F405: TIGER">🐅</mark>
<mark class="unicode" title="U+1F407: RABBIT">🐇</mark>
<mark class="unicode" title="U+1F409: DRAGON">🐉</mark>
<mark class="unicode" title="U+1F40D: SNAKE">🐍</mark>
<mark class="unicode" title="U+1F40E: HORSE">🐎</mark>
<mark class="unicode" title="U+1F410: GOAT">🐐</mark>
<mark class="unicode" title="U+1F412: MONKEY">🐒</mark>
<mark class="unicode" title="U+1F413: ROOSTER">🐓</mark>
<mark class="unicode" title="U+1F415: DOG">🐕</mark>
<mark class="unicode" title="U+1F416: PIG">🐖</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F459: BIKINI" data-char-info="U+1F459: BIKINI">👙</mark>
<mark class="unicode" title="U+1F457: DRESS" data-char-info="U+1F457: DRESS">👗</mark>
<mark class="unicode" title="U+1F458: KIMONO">👘</mark>
<mark class="unicode" title="U+1F45A: WOMANS CLOTHES" data-char-info="U+1F45A: WOMANS CLOTHES">👚</mark>
<mark class="unicode" title="U+1F380: RIBBON" data-char-info="U+1F380: RIBBON">🎀</mark>
</div>

<div>
<mark class="unicode" title="U+1F454: NECKTIE">👔</mark>
<mark class="unicode" title="U+1F455: T-SHIRT" data-char-info="U+1F455: T-SHIRT">👕</mark>
<mark class="unicode" title="U+1F3BD: RUNNING SHIRT WITH SASH" data-char-info="U+1F3BD: RUNNING SHIRT WITH SASH">🎽</mark>
<mark class="unicode" title="U+1F456: JEANS">👖</mark>
</div>

<div>
<mark class="unicode" title="U+1F9E3: SCARF">🧣</mark>
<mark class="unicode" title="U+1F9E4: GLOVES">🧤</mark>
<mark class="unicode" title="U+1F9E5: COAT">🧥</mark>
<mark class="unicode" title="U+1F9E6: SOCKS">🧦</mark>
</div>

<div>
<mark class="unicode" title="U+1F452: WOMANS HAT">👒</mark>
<mark class="unicode" title="U+1F9E2: BILLED CAP">🧢</mark>
<mark class="unicode" title="U+1F451: CROWN">👑</mark>
</div>

<div>
<mark class="unicode" title="U+1F3A9: TOP HAT">🎩</mark>
<mark class="unicode" title="U+1F393: GRADUATION CAP" data-char-info="U+1F393: GRADUATION CAP">🎓</mark>
<mark class="unicode" title="U+26D1: HELMET WITH WHITE CROSS" data-char-info="U+26D1: HELMET WITH WHITE CROSS">⛑</mark>
<mark class="unicode" title="U+1F97C: LAB COAT">🥼</mark>
</div>

<div>
<mark class="unicode" title="U+1F460: HIGH-HEELED SHOE">👠</mark>
<mark class="unicode" title="U+1F461: WOMANS SANDAL">👡</mark>
<mark class="unicode" title="U+1F462: WOMANS BOOTS">👢</mark>
<mark class="unicode" title="U+1F97F: FLAT SHOE">🥿</mark>
</div>

<div>
<mark class="unicode" title="U+1F45E: MANS SHOE">👞</mark>
<mark class="unicode" title="U+1F45F: ATHLETIC SHOE">👟</mark>
<mark class="unicode" title="U+1F97E: HIKING BOOT">🥾</mark>
</div>

<div>
<mark class="unicode" title="U+1F45B: PURSE">👛</mark>
<mark class="unicode" title="U+1F45C: HANDBAG">👜</mark>
<mark class="unicode" title="U+1F6CD: SHOPPING BAGS">🛍</mark>
<mark class="unicode" title="U+1F45D: POUCH">👝</mark>
<mark class="unicode" title="U+1F392: SCHOOL SATCHEL">🎒</mark>
</div>

<div>
<mark class="unicode" title="U+1F453: EYEGLASSES">👓</mark>
<mark class="unicode" title="U+1F576: DARK SUNGLASSES">🕶</mark>
<mark class="unicode" title="U+1F97D: GOGGLES">🥽</mark>
</div>

<div>
<mark class="unicode" title="U+1F485: NAIL POLISH">💅</mark>
<mark class="unicode" title="U+1F48E: GEM STONE">💎</mark>
<mark class="unicode" title="U+1F48D: RING">💍</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+1F4F1: MOBILE PHONE" data-char-info="U+1F4F1: MOBILE PHONE">📱</mark>
<mark class="unicode" title="U+1F4F2: MOBILE PHONE WITH RIGHTWARDS ARROW AT LEFT" data-char-info="U+1F4F2: MOBILE PHONE WITH RIGHTWARDS ARROW AT LEFT">📲</mark>
<mark class="unicode" title="U+1F581: CLAMSHELL MOBILE PHONE">🖁</mark>
</div>

<div>
<mark class="unicode" title="U+1F4F3: VIBRATION MODE">📳</mark>
<mark class="unicode" title="U+1F4F4: MOBILE PHONE OFF">📴</mark>
<mark class="unicode" title="U+1F4F5: NO MOBILE PHONES">📵</mark>
<mark class="unicode" title="U+1F4F6: ANTENNA WITH BARS">📶</mark>
</div>

<div>
<mark class="unicode" title="U+1F4DE: TELEPHONE RECEIVER">📞</mark>
<mark class="unicode" title="U+260E: BLACK TELEPHONE">☎</mark>
<mark class="unicode" title="U+260F: WHITE TELEPHONE" data-char-info="U+260F: WHITE TELEPHONE">☏</mark>
<mark class="unicode" title="U+1F57F: BLACK TOUCHTONE TELEPHONE" data-char-info="U+1F57F: BLACK TOUCHTONE TELEPHONE">🕿</mark>
<mark class="unicode" title="U+1F580: TELEPHONE ON TOP OF MODEM" data-char-info="U+1F580: TELEPHONE ON TOP OF MODEM">🖀</mark>
</div>

<div>
<mark class="unicode" title="U+2706: TELEPHONE LOCATION SIGN" data-char-info="U+2706: TELEPHONE LOCATION SIGN">✆</mark>
<mark class="unicode" title="U+1F57B: LEFT HAND TELEPHONE RECEIVER">🕻</mark>
<mark class="unicode" title="U+1F57C: TELEPHONE RECEIVER WITH PAGE" data-char-info="U+1F57C: TELEPHONE RECEIVER WITH PAGE">🕼</mark>
<mark class="unicode" title="U+1F57D: RIGHT HAND TELEPHONE RECEIVER" data-char-info="U+1F57D: RIGHT HAND TELEPHONE RECEIVER">🕽</mark>
<mark class="unicode" title="U+2121: TELEPHONE SIGN" data-char-info="U+2121: TELEPHONE SIGN">℡</mark>
</div>

<div>
<mark class="unicode" title="U+1F4DF: PAGER">📟</mark>
<mark class="unicode" title="U+1F4E0: FAX MACHINE">📠</mark>
</div>

<div>
<mark class="unicode" title="U+1F4F7: CAMERA">📷</mark>
<mark class="unicode" title="U+1F4F8: CAMERA WITH FLASH">📸</mark>
</div>

<div>
<mark class="unicode" title="U+1F4F9: VIDEO CAMERA">📹</mark>
<mark class="unicode" title="U+1F4FC: VIDEOCASSETTE" data-char-info="U+1F4FC: VIDEOCASSETTE">📼</mark>
</div>

<div>
<mark class="unicode" title="U+1F3A5: MOVIE CAMERA">🎥</mark>
<mark class="unicode" title="U+1F4FD: FILM PROJECTOR" data-char-info="U+1F4FD: FILM PROJECTOR">📽</mark>
<mark class="unicode" title="U+1F39E: FILM FRAMES" data-char-info="U+1F39E: FILM FRAMES">🎞</mark>
</div>

<div>
<mark class="unicode" title="U+1F3A7: HEADPHONE">🎧</mark>
<mark class="unicode" title="U+1F3A4: MICROPHONE">🎤</mark>
<mark class="unicode" title="U+1F399: STUDIO MICROPHONE">🎙</mark>
</div>

<div>
<mark class="unicode" title="U+1F4E2: PUBLIC ADDRESS LOUDSPEAKER">📢</mark>
<mark class="unicode" title="U+1F4E3: CHEERING MEGAPHONE">📣</mark>
<mark class="unicode" title="U+1F56B: BULLHORN">🕫</mark>
<mark class="unicode" title="U+1F56C: BULLHORN WITH SOUND WAVES">🕬</mark>
</div>

<div>
<mark class="unicode" title="U+1F4FA: TELEVISION">📺</mark>
<mark class="unicode" title="U+1F4FB: RADIO" data-char-info="U+1F4FB: RADIO">📻</mark>
<mark class="unicode" title="U+1F4FE: PORTABLE STEREO">📾</mark>
<mark class="unicode" title="U+1F4E1: SATELLITE ANTENNA">📡</mark>
</div>

<div>
<mark class="unicode" title="U+1F5A9: POCKET CALCULATOR">🖩</mark>
<mark class="unicode" title="U+1F5A8: PRINTER">🖨</mark>
<mark class="unicode" title="U+1F5A7: THREE NETWORKED COMPUTERS">🖧</mark>
</div>

<div>
<mark class="unicode" title="U+1F5B3: OLD PERSONAL COMPUTER">🖳</mark>
<mark class="unicode" title="U+1F4BB: PERSONAL COMPUTER">💻</mark>
<mark class="unicode" title="U+1F5A5: DESKTOP COMPUTER">🖥</mark>
</div>

<div>
<mark class="unicode" title="U+2328: KEYBOARD">⌨</mark>
<mark class="unicode" title="U+1F5AE: WIRED KEYBOARD">🖮</mark>
<mark class="unicode" title="U+1F5A6: KEYBOARD AND MOUSE">🖦</mark>
</div>

<div>
<mark class="unicode" title="U+1F5AF: ONE BUTTON MOUSE">🖯</mark>
<mark class="unicode" title="U+1F5B0: TWO BUTTON MOUSE">🖰</mark>
<mark class="unicode" title="U+1F5B1: THREE BUTTON MOUSE">🖱</mark>
<mark class="unicode" title="U+1F5B2: TRACKBALL">🖲</mark>
</div>

<div>
<mark class="unicode" title="U+1F579: JOYSTICK">🕹</mark>
<mark class="unicode" title="U+1F3AE: VIDEO GAME">🎮</mark>
</div>

<div>
<mark class="unicode" title="U+1F3B9: MUSICAL KEYBOARD">🎹</mark>
<mark class="unicode" title="U+1F398: MUSICAL KEYBOARD WITH JACKS">🎘</mark>
<mark class="unicode" title="U+1F39A: LEVEL SLIDER">🎚</mark>
<mark class="unicode" title="U+1F39B: CONTROL KNOBS">🎛</mark>
</div>

<div>
<mark class="unicode" title="U+1F5B4: HARD DISK" data-char-info="U+1F5B4: HARD DISK">🖴</mark>
<mark class="unicode" title="U+1F4C0: DVD">📀</mark>
<mark class="unicode" title="U+1F4BD: MINIDISC" data-char-info="U+1F4BD: MINIDISC">💽</mark>
<mark class="unicode" title="U+1F4BF: OPTICAL DISC" data-char-info="U+1F4BF: OPTICAL DISC">💿</mark>
<mark class="unicode" title="U+1F5B8: OPTICAL DISC ICON" data-char-info="U+1F5B8: OPTICAL DISC ICON">🖸</mark>
</div>

<div>
<mark class="unicode" title="U+1F4BE: FLOPPY DISK">💾</mark>
<mark class="unicode" title="U+1F5AA: BLACK HARD SHELL FLOPPY DISK" data-char-info="U+1F5AA: BLACK HARD SHELL FLOPPY DISK">🖪</mark>
<mark class="unicode" title="U+1F5AB: WHITE HARD SHELL FLOPPY DISK">🖫</mark>
<mark class="unicode" title="U+1F5AC: SOFT SHELL FLOPPY DISK" data-char-info="U+1F5AC: SOFT SHELL FLOPPY DISK">🖬</mark>
</div>

<div>
<mark class="unicode" title="U+2707: TAPE DRIVE">✇</mark>
<mark class="unicode" title="U+1F5AD: TAPE CARTRIDGE">🖭</mark>
</div>

<div>
<mark class="unicode" title="U+1F50B: BATTERY" data-char-info="U+1F50B: BATTERY">🔋</mark>
<mark class="unicode" title="U+1F50C: ELECTRIC PLUG">🔌</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
<mark class="unicode" title="U+263A: WHITE SMILING FACE">☺</mark>
<mark class="unicode" title="U+263B: BLACK SMILING FACE">☻</mark>
</div>

<div>
<mark class="unicode" title="U+1F603: SMILING FACE WITH OPEN MOUTH">😃</mark>
<mark class="unicode" title="U+1F604: SMILING FACE WITH OPEN MOUTH AND SMILING EYES">😄</mark>
<mark class="unicode" title="U+1F605: SMILING FACE WITH OPEN MOUTH AND COLD SWEAT">😅</mark>
<mark class="unicode" title="U+1F606: SMILING FACE WITH OPEN MOUTH AND TIGHTLY-CLOSED EYES">😆</mark>
<mark class="unicode" title="U+1F60A: SMILING FACE WITH SMILING EYES">😊</mark>
</div>

<div>
<mark class="unicode" title="U+1F60E: SMILING FACE WITH SUNGLASSES">😎</mark>
<mark class="unicode" title="U+1F607: SMILING FACE WITH HALO">😇</mark>
<mark class="unicode" title="U+1F608: SMILING FACE WITH HORNS">😈</mark>
</div>

<div>
<mark class="unicode" title="U+1F60F: SMIRKING FACE">😏</mark>
<mark class="unicode" title="U+1F923: ROLLING ON THE FLOOR LAUGHING">🤣</mark>
<mark class="unicode" title="U+1F929: GRINNING FACE WITH STAR EYES">🤩</mark>
<mark class="unicode" title="U+1F92A: GRINNING FACE WITH ONE LARGE AND ONE SMALL EYE" data-char-info="U+1F92A: GRINNING FACE WITH ONE LARGE AND ONE SMALL EYE">🤪</mark>
<mark class="unicode" title="U+1F973: FACE WITH PARTY HORN AND PARTY HAT">🥳</mark>
</div>

<div>
<mark class="unicode" title="U+1F601: GRINNING FACE WITH SMILING EYES">😁</mark>
<mark class="unicode" title="U+1F600: GRINNING FACE" data-char-info="U+1F600: GRINNING FACE">😀</mark>
<mark class="unicode" title="U+1F602: FACE WITH TEARS OF JOY">😂</mark>
</div>

<div>
<mark class="unicode" title="U+1F920: FACE WITH COWBOY HAT">🤠</mark>
<mark class="unicode" title="U+1F921: CLOWN FACE">🤡</mark>
<mark class="unicode" title="U+1F911: MONEY-MOUTH FACE">🤑</mark>
<mark class="unicode" title="U+1F913: NERD FACE">🤓</mark>
<mark class="unicode" title="U+1F916: ROBOT FACE">🤖</mark>
</div>

<div>
kiss<br>
<mark class="unicode" title="U+1F617: KISSING FACE">😗</mark>
<mark class="unicode" title="U+1F61A: KISSING FACE WITH CLOSED EYES">😚</mark>
<mark class="unicode" title="U+1F618: FACE THROWING A KISS">😘</mark>
<mark class="unicode" title="U+1F619: KISSING FACE WITH SMILING EYES">😙</mark>
</div>

<div>
flirting<br>
<mark class="unicode" title="U+1F609: WINKING FACE">😉</mark>
<mark class="unicode" title="U+1F917: HUGGING FACE">🤗</mark>
<mark class="unicode" title="U+1F60D: SMILING FACE WITH HEART-SHAPED EYES">😍</mark>
<mark class="unicode" title="U+1F970: SMILING FACE WITH SMILING EYES AND THREE HEARTS">🥰</mark>
</div>

<div>
<mark class="unicode" title="U+1F924: DROOLING FACE">🤤</mark>
<mark class="unicode" title="U+1F60B: FACE SAVOURING DELICIOUS FOOD">😋</mark>
</div>

<div>
<mark class="unicode" title="U+1F61B: FACE WITH STUCK-OUT TONGUE">😛</mark>
<mark class="unicode" title="U+1F61C: FACE WITH STUCK-OUT TONGUE AND WINKING EYE" data-char-info="U+1F61C: FACE WITH STUCK-OUT TONGUE AND WINKING EYE">😜</mark>
<mark class="unicode" title="U+1F61D: FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES" data-char-info="U+1F61D: FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES">😝</mark>
</div>

<div>
neutral<br>
<mark class="unicode" title="U+1F636: FACE WITHOUT MOUTH" data-char-info="U+1F636: FACE WITHOUT MOUTH">😶</mark>
<mark class="unicode" title="U+1F643: UPSIDE-DOWN FACE" data-char-info="U+1F643: UPSIDE-DOWN FACE">🙃</mark>
<mark class="unicode" title="U+1F610: NEUTRAL FACE" data-char-info="U+1F610: NEUTRAL FACE">😐</mark>
<mark class="unicode" title="U+1F611: EXPRESSIONLESS FACE" data-char-info="U+1F611: EXPRESSIONLESS FACE">😑</mark>
</div>

<div>
<mark class="unicode" title="U+1F914: THINKING FACE">🤔</mark>
<mark class="unicode" title="U+1F928: FACE WITH ONE EYEBROW RAISED">🤨</mark>
<mark class="unicode" title="U+1F9D0: FACE WITH MONOCLE">🧐</mark>
</div>

<div>
hush<br>
<mark class="unicode" title="U+1F92D: SMILING FACE WITH SMILING EYES AND HAND COVERING MOUTH">🤭</mark>
<mark class="unicode" title="U+1F92B: FACE WITH FINGER COVERING CLOSED LIPS">🤫</mark>
<mark class="unicode" title="U+1F62F: HUSHED FACE">😯</mark>
<mark class="unicode" title="U+1F910: ZIPPER-MOUTH FACE">🤐</mark>
</div>

<div>
<mark class="unicode" title="U+1F60C: RELIEVED FACE">😌</mark>
<mark class="unicode" title="U+1F616: CONFOUNDED FACE">😖</mark>
<mark class="unicode" title="U+1F615: CONFUSED FACE">😕</mark>
<mark class="unicode" title="U+1F633: FLUSHED FACE">😳</mark>
<mark class="unicode" title="U+1F614: PENSIVE FACE">😔</mark>
<mark class="unicode" title="U+1F925: LYING FACE">🤥</mark>
<mark class="unicode" title="U+1F974: FACE WITH UNEVEN EYES AND WAVY MOUTH" data-char-info="U+1F974: FACE WITH UNEVEN EYES AND WAVY MOUTH">🥴</mark>
</div>

<div>
shocked<br>
<mark class="unicode" title="U+1F62E: FACE WITH OPEN MOUTH">😮</mark>
<mark class="unicode" title="U+1F632: ASTONISHED FACE">😲</mark>
<mark class="unicode" title="U+1F92F: SHOCKED FACE WITH EXPLODING HEAD">🤯</mark>
</div>

<div>
tired<br>
<mark class="unicode" title="U+1F629: WEARY FACE">😩</mark>
<mark class="unicode" title="U+1F62B: TIRED FACE">😫</mark>
<mark class="unicode" title="U+1F62A: SLEEPY FACE">😪</mark>
<mark class="unicode" title="U+1F634: SLEEPING FACE">😴</mark>
<mark class="unicode" title="U+1F635: DIZZY FACE">😵</mark>
</div>

<div>
sad<br>
<mark class="unicode" title="U+2639: WHITE FROWNING FACE">☹</mark>
<mark class="unicode" title="U+1F626: FROWNING FACE WITH OPEN MOUTH">😦</mark>
<mark class="unicode" title="U+1F61E: DISAPPOINTED FACE">😞</mark>
<mark class="unicode" title="U+1F625: DISAPPOINTED BUT RELIEVED FACE">😥</mark>
<mark class="unicode" title="U+1F61F: WORRIED FACE" data-char-info="U+1F61F: WORRIED FACE">😟</mark>
</div>

<div>
cry<br>
<mark class="unicode" title="U+1F622: CRYING FACE" data-char-info="U+1F622: CRYING FACE">😢</mark>
<mark class="unicode" title="U+1F62D: LOUDLY CRYING FACE" data-char-info="U+1F62D: LOUDLY CRYING FACE">😭</mark>
</div>

<div>
sick<br>
<mark class="unicode" title="U+1F922: NAUSEATED FACE">🤢</mark>
<mark class="unicode" title="U+1F92E: FACE WITH OPEN MOUTH VOMITING">🤮</mark>
<mark class="unicode" title="U+1F637: FACE WITH MEDICAL MASK">😷</mark>
<mark class="unicode" title="U+1F912: FACE WITH THERMOMETER">🤒</mark>
<mark class="unicode" title="U+1F915: FACE WITH HEAD-BANDAGE">🤕</mark>
<mark class="unicode" title="U+1F975: OVERHEATED FACE">🥵</mark>
<mark class="unicode" title="U+1F976: FREEZING FACE">🥶</mark>
</div>

<div>
fear<br>
<mark class="unicode" title="U+1F97A: FACE WITH PLEADING EYES">🥺</mark>
<mark class="unicode" title="U+1F62C: GRIMACING FACE">😬</mark>
<mark class="unicode" title="U+1F613: FACE WITH COLD SWEAT">😓</mark>
<mark class="unicode" title="U+1F630: FACE WITH OPEN MOUTH AND COLD SWEAT">😰</mark>
<mark class="unicode" title="U+1F628: FEARFUL FACE">😨</mark>
<mark class="unicode" title="U+1F631: FACE SCREAMING IN FEAR">😱</mark>
</div>

<div>
angry<br>
<mark class="unicode" title="U+1F612: UNAMUSED FACE">😒</mark>
<mark class="unicode" title="U+1F620: ANGRY FACE">😠</mark>
<mark class="unicode" title="U+1F621: POUTING FACE">😡</mark>
<mark class="unicode" title="U+1F624: FACE WITH LOOK OF TRIUMPH">😤</mark>
<mark class="unicode" title="U+1F623: PERSEVERING FACE">😣</mark>
<mark class="unicode" title="U+1F627: ANGUISHED FACE">😧</mark>
<mark class="unicode" title="U+1F92C: SERIOUS FACE WITH SYMBOLS COVERING MOUTH">🤬</mark>
</div>

<div>
<mark class="unicode" title="U+1F638: GRINNING CAT FACE WITH SMILING EYES">😸</mark>
<mark class="unicode" title="U+1F639: CAT FACE WITH TEARS OF JOY">😹</mark>
<mark class="unicode" title="U+1F63A: SMILING CAT FACE WITH OPEN MOUTH">😺</mark>
<mark class="unicode" title="U+1F63B: SMILING CAT FACE WITH HEART-SHAPED EYES">😻</mark>
<mark class="unicode" title="U+1F63C: CAT FACE WITH WRY SMILE">😼</mark>
<mark class="unicode" title="U+1F63D: KISSING CAT FACE WITH CLOSED EYES">😽</mark>
<mark class="unicode" title="U+1F63E: POUTING CAT FACE">😾</mark>
<mark class="unicode" title="U+1F63F: CRYING CAT FACE">😿</mark>
<mark class="unicode" title="U+1F640: WEARY CAT FACE">🙀</mark>
</div>

<div>
<mark class="unicode" title="U+1F648: SEE-NO-EVIL MONKEY">🙈</mark>
<mark class="unicode" title="U+1F649: HEAR-NO-EVIL MONKEY">🙉</mark>
<mark class="unicode" title="U+1F64A: SPEAK-NO-EVIL MONKEY">🙊</mark>
<br>
[see <a href="unicode_animals.html">Animal Emoji 🐰</a>]
</div>

<div>
gesture<br>
<mark class="unicode" title="U+1F926: FACE PALM">🤦</mark>
<mark class="unicode" title="U+1F937: SHRUG">🤷</mark>
<mark class="unicode" title="U+1F645: FACE WITH NO GOOD GESTURE">🙅</mark>
<mark class="unicode" title="U+1F646: FACE WITH OK GESTURE">🙆</mark>
<mark class="unicode" title="U+1F64B: HAPPY PERSON RAISING ONE HAND">🙋</mark>
<mark class="unicode" title="U+1F64C: PERSON RAISING BOTH HANDS IN CELEBRATION">🙌</mark>
<mark class="unicode" title="U+1F64D: PERSON FROWNING">🙍</mark>
<mark class="unicode" title="U+1F64E: PERSON WITH POUTING FACE">🙎</mark>
<mark class="unicode" title="U+1F647: PERSON BOWING DEEPLY">🙇</mark>
<mark class="unicode" title="U+1F64F: PERSON WITH FOLDED HANDS">🙏</mark>
</div>

<div>
activity<br>
<mark class="unicode" title="U+1F46F: WOMAN WITH BUNNY EARS">👯</mark>
<mark class="unicode" title="U+1F483: DANCER">💃</mark>
<mark class="unicode" title="U+1F57A: MAN DANCING">🕺</mark>
</div>

<div>
<mark class="unicode" title="U+1F933: SELFIE">🤳</mark>
<mark class="unicode" title="U+1F487: HAIRCUT">💇</mark>
<mark class="unicode" title="U+1F488: BARBER POLE">💈</mark>
<mark class="unicode" title="U+1F486: FACE MASSAGE">💆</mark>
<mark class="unicode" title="U+1F9D6: PERSON IN STEAMY ROOM">🧖</mark>
<mark class="unicode" title="U+1F9D8: PERSON IN LOTUS POSITION">🧘</mark>
</div>

<div>
<mark class="unicode" title="U+1F470: BRIDE WITH VEIL">👰</mark>
<mark class="unicode" title="U+1F930: PREGNANT WOMAN">🤰</mark>
<mark class="unicode" title="U+1F931: BREAST-FEEDING">🤱</mark>
</div>

<div>
faces<br>
<mark class="unicode" title="U+1F476: BABY">👶</mark>
<mark class="unicode" title="U+1F9D2: CHILD">🧒</mark>
<mark class="unicode" title="U+1F466: BOY">👦</mark>
<mark class="unicode" title="U+1F467: GIRL">👧</mark>
</div>

<div>
<mark class="unicode" title="U+1F469: WOMAN">👩</mark>
<mark class="unicode" title="U+1F468: MAN">👨</mark>
</div>

<div>
<mark class="unicode" title="U+1F9D4: BEARDED PERSON">🧔</mark>
<mark class="unicode" title="U+1F9D1: ADULT">🧑</mark>
</div>

<div>
<mark class="unicode" title="U+1F9D3: OLDER ADULT">🧓</mark>
<mark class="unicode" title="U+1F474: OLDER MAN">👴</mark>
<mark class="unicode" title="U+1F475: OLDER WOMAN">👵</mark>
</div>

<div>
<mark class="unicode" title="U+1F464: BUST IN SILHOUETTE">👤</mark>
<mark class="unicode" title="U+1F465: BUSTS IN SILHOUETTE">👥</mark>
</div>

<div>
<mark class="unicode" title="U+1F46A: FAMILY">👪</mark>
<mark class="unicode" title="U+1F46B: MAN AND WOMAN HOLDING HANDS">👫</mark>
<mark class="unicode" title="U+1F46C: TWO MEN HOLDING HANDS">👬</mark>
<mark class="unicode" title="U+1F46D: TWO WOMEN HOLDING HANDS">👭</mark>
</div>

<div>
<mark class="unicode" title="U+1F472: MAN WITH GUA PI MAO">👲</mark>
<mark class="unicode" title="U+1F473: MAN WITH TURBAN">👳</mark>
<mark class="unicode" title="U+1F9D5: PERSON WITH HEADSCARF">🧕</mark>
<mark class="unicode" title="U+1F471: PERSON WITH BLOND HAIR">👱</mark>
</div>

<div>
<mark class="unicode" title="U+1F478: PRINCESS">👸</mark>
<mark class="unicode" title="U+1F934: PRINCE">🤴</mark>
</div>

<div>
<mark class="unicode" title="U+1F385: FATHER CHRISTMAS">🎅</mark>
<mark class="unicode" title="U+1F936: MOTHER CHRISTMAS">🤶</mark>
</div>

<div>
<mark class="unicode" title="U+1F935: MAN IN TUXEDO">🤵</mark>
<mark class="unicode" title="U+1F46E: POLICE OFFICER">👮</mark>
<mark class="unicode" title="U+1F477: CONSTRUCTION WORKER">👷</mark>
<mark class="unicode" title="U+1F481: INFORMATION DESK PERSON">💁</mark>
<mark class="unicode" title="U+1F482: GUARDSMAN">💂</mark>
<mark class="unicode" title="U+1F574: MAN IN BUSINESS SUIT LEVITATING">🕴</mark>
<mark class="unicode" title="U+1F575: SLEUTH OR SPY">🕵</mark>
</div>

<div>
<mark class="unicode" title="U+1F47C: BABY ANGEL">👼</mark>
<mark class="unicode" title="U+1F47F: IMP">👿</mark>
<mark class="unicode" title="U+1F47B: GHOST">👻</mark>
</div>

<div>
<mark class="unicode" title="U+1F9D9: MAGE">🧙</mark>
<mark class="unicode" title="U+1F9DA: FAIRY">🧚</mark>
<mark class="unicode" title="U+1F9DC: MERPERSON">🧜</mark>
<mark class="unicode" title="U+1F9DD: ELF">🧝</mark>
<mark class="unicode" title="U+1F9DE: GENIE">🧞</mark>
</div>

<div>
<mark class="unicode" title="U+1F9DB: VAMPIRE">🧛</mark>
<mark class="unicode" title="U+1F9DF: ZOMBIE">🧟</mark>
</div>

<div>
<mark class="unicode" title="U+1F47D: EXTRATERRESTRIAL ALIEN">👽</mark>
<mark class="unicode" title="U+1F47E: ALIEN MONSTER">👾</mark>
<mark class="unicode" title="U+1F6F8: FLYING SAUCER">🛸</mark>
</div>

<div>
<mark class="unicode" title="U+1F479: JAPANESE OGRE">👹</mark>
<mark class="unicode" title="U+1F47A: JAPANESE GOBLIN">👺</mark>
</div>

<div>
<mark class="unicode" title="U+1F480: SKULL">💀</mark>
<mark class="unicode" title="U+2620: SKULL AND CROSSBONES">☠</mark>
<mark class="unicode" title="U+1F571: BLACK SKULL AND CROSSBONES">🕱</mark>
</div>

<div>
<mark class="unicode" title="U+1F9B8: SUPERHERO">🦸</mark>
<mark class="unicode" title="U+1F9B9: SUPERVILLAIN">🦹</mark>
</div>

<div>
<mark class="unicode" title="U+1F9E0: BRAIN">🧠</mark>
<mark class="unicode" title="U+1F9B4: BONE">🦴</mark>
</div>

<div>
<mark class="unicode" title="U+1F441: EYE">👁</mark>
<mark class="unicode" title="U+1F440: EYES">👀</mark>
</div>

<div>
<mark class="unicode" title="U+1F442: EAR">👂</mark>
<mark class="unicode" title="U+1F443: NOSE">👃</mark>
<mark class="unicode" title="U+1F444: MOUTH">👄</mark>
<mark class="unicode" title="U+1F5E2: LIPS">🗢</mark>
<mark class="unicode" title="U+1F445: TONGUE">👅</mark>
<mark class="unicode" title="U+1F9B7: TOOTH">🦷</mark>
</div>

<div>
<mark class="unicode" title="U+1F9B5: LEG">🦵</mark>
<mark class="unicode" title="U+1F9B6: FOOT">🦶</mark>
</div>

<div>
<mark class="unicode" title="U+1F4AD: THOUGHT BALLOON">💭</mark>
<mark class="unicode" title="U+1F5EC: LEFT THOUGHT BUBBLE">🗬</mark>
<mark class="unicode" title="U+1F5ED: RIGHT THOUGHT BUBBLE">🗭</mark>
</div>

<div>
<mark class="unicode" title="U+1F4AC: SPEECH BALLOON">💬</mark>
<mark class="unicode" title="U+1F5E8: LEFT SPEECH BUBBLE">🗨</mark>
<mark class="unicode" title="U+1F5E9: RIGHT SPEECH BUBBLE">🗩</mark>
</div>

<div>
<mark class="unicode" title="U+1F5EA: TWO SPEECH BUBBLES">🗪</mark>
<mark class="unicode" title="U+1F5EB: THREE SPEECH BUBBLES">🗫</mark>
</div>

<div>
<mark class="unicode" title="U+1F5F0: MOOD BUBBLE">🗰</mark>
<mark class="unicode" title="U+1F5F1: LIGHTNING MOOD BUBBLE">🗱</mark>
<mark class="unicode" title="U+1F5EE: LEFT ANGER BUBBLE">🗮</mark>
<mark class="unicode" title="U+1F5EF: RIGHT ANGER BUBBLE">🗯</mark>
</div>

<div>
<mark class="unicode" title="U+1F5E3: SPEAKING HEAD IN SILHOUETTE">🗣</mark>
<mark class="unicode" title="U+1F5E4: THREE RAYS ABOVE">🗤</mark>
<mark class="unicode" title="U+1F5E5: THREE RAYS BELOW">🗥</mark>
<mark class="unicode" title="U+1F5E6: THREE RAYS LEFT">🗦</mark>
<mark class="unicode" title="U+1F5E7: THREE RAYS RIGHT">🗧</mark>
</div>

<div>
<mark class="unicode" title="U+1F4A6: SPLASHING SWEAT SYMBOL">💦</mark>
<mark class="unicode" title="U+1F4A7: DROPLET">💧</mark>
<mark class="unicode" title="U+1F4A2: ANGER SYMBOL">💢</mark>
</div>

<div>
<mark class="unicode" title="U+1F4AB: DIZZY SYMBOL">💫</mark>
<mark class="unicode" title="U+1F4A4: SLEEPING SYMBOL">💤</mark>
</div>

<div>
<mark class="unicode" title="U+1F4A8: DASH SYMBOL">💨</mark>
<mark class="unicode" title="U+1F4A5: COLLISION SYMBOL">💥</mark>
</div>

<div>
<mark class="unicode" title="U+1F4AA: FLEXED BICEPS">💪</mark>
<mark class="unicode" title="U+1F5F2: LIGHTNING MOOD">🗲</mark>
<mark class="unicode" title="U+1F525: FIRE">🔥</mark>
<mark class="unicode" title="U+1F4A1: ELECTRIC LIGHT BULB">💡</mark>
<mark class="unicode" title="U+1F4A9: PILE OF POO">💩</mark>
</div>

<div>
<mark class="unicode" title="U+1F4AF: HUNDRED POINTS SYMBOL">💯</mark>
<mark class="unicode" title="U+1F51F: KEYCAP TEN">🔟</mark>
<mark class="unicode" title="U+1F530: JAPANESE SYMBOL FOR BEGINNER">🔰</mark>
</div>

<div>
<mark class="unicode" title="U+1F572: NO PIRACY">🕲</mark>
</div>

</div>`,
  `<div class="box00710">

<div>
breakfast<br>
<mark class="unicode" title="U+1F9C0: CHEESE WEDGE">🧀</mark>
<mark class="unicode" title="U+1F95A: EGG">🥚</mark>
<mark class="unicode" title="U+1F373: COOKING">🍳</mark>
<mark class="unicode" title="U+1F95E: PANCAKES">🥞</mark>
</div>

<div>
bread<br>
<mark class="unicode" title="U+1F35E: BREAD">🍞</mark>
<mark class="unicode" title="U+1F950: CROISSANT">🥐</mark>
<mark class="unicode" title="U+1F96F: BAGEL">🥯</mark>
<mark class="unicode" title="U+1F956: BAGUETTE BREAD">🥖</mark>
<mark class="unicode" title="U+1F968: PRETZEL">🥨</mark>
</div>

<div>
<mark class="unicode" title="U+1F354: HAMBURGER">🍔</mark>
<mark class="unicode" title="U+1F35F: FRENCH FRIES">🍟</mark>
<mark class="unicode" title="U+1F32D: HOT DOG">🌭</mark>
<mark class="unicode" title="U+1F355: SLICE OF PIZZA">🍕</mark>
</div>

<div>
<mark class="unicode" title="U+1F96A: SANDWICH">🥪</mark>
<mark class="unicode" title="U+1F32E: TACO">🌮</mark>
<mark class="unicode" title="U+1F32F: BURRITO">🌯</mark>
<mark class="unicode" title="U+1F959: STUFFED FLATBREAD">🥙</mark>
</div>

<div>
<mark class="unicode" title="U+1F35D: SPAGHETTI">🍝</mark>
<mark class="unicode" title="U+1F35B: CURRY AND RICE">🍛</mark>
<mark class="unicode" title="U+1F958: SHALLOW PAN OF FOOD">🥘</mark>
<mark class="unicode" title="U+1F372: POT OF FOOD">🍲</mark>
</div>

<div>
<mark class="unicode" title="U+1F364: FRIED SHRIMP">🍤</mark>
<mark class="unicode" title="U+1F957: GREEN SALAD">🥗</mark>
<mark class="unicode" title="U+1F96B: CANNED FOOD" data-char-info="U+1F96B: CANNED FOOD">🥫</mark>
</div>

<div>
Meat<br>
<mark class="unicode" title="U+1F953: BACON" data-char-info="U+1F953: BACON">🥓</mark>
<mark class="unicode" title="U+1F356: MEAT ON BONE" data-char-info="U+1F356: MEAT ON BONE">🍖</mark>
<mark class="unicode" title="U+1F357: POULTRY LEG" data-char-info="U+1F357: POULTRY LEG">🍗</mark>
<mark class="unicode" title="U+1F969: CUT OF MEAT" data-char-info="U+1F969: CUT OF MEAT">🥩</mark>
</div>

<div>
Chinese<br>
<mark class="unicode" title="U+1F962: CHOPSTICKS">🥢</mark>
<mark class="unicode" title="U+1F961: TAKEOUT BOX">🥡</mark>
<mark class="unicode" title="U+1F95F: DUMPLING">🥟</mark>
<mark class="unicode" title="U+1F35A: COOKED RICE">🍚</mark>
<mark class="unicode" title="U+1F35C: STEAMING BOWL">🍜</mark>
<mark class="unicode" title="U+1F960: FORTUNE COOKIE">🥠</mark>
<mark class="unicode" title="U+1F96E: MOON CAKE">🥮</mark>
</div>

<div>
Japaneses<br>
<mark class="unicode" title="U+1F358: RICE CRACKER">🍘</mark>
<mark class="unicode" title="U+1F359: RICE BALL">🍙</mark>
<mark class="unicode" title="U+1F363: SUSHI" data-char-info="U+1F363: SUSHI">🍣</mark>
<mark class="unicode" title="U+1F365: FISH CAKE WITH SWIRL DESIGN">🍥</mark>
<mark class="unicode" title="U+1F371: BENTO BOX">🍱</mark>
<mark class="unicode" title="U+1F361: DANGO">🍡</mark>
<mark class="unicode" title="U+1F362: ODEN">🍢</mark>
</div>

<div>
Fruit<br>
<mark class="unicode" title="U+1F347: GRAPES">🍇</mark>
<mark class="unicode" title="U+1F351: PEACH">🍑</mark>
<mark class="unicode" title="U+1F352: CHERRIES">🍒</mark>
<mark class="unicode" title="U+1F353: STRAWBERRY">🍓</mark>
<mark class="unicode" title="U+1F95D: KIWIFRUIT">🥝</mark>
</div>

<div>
<mark class="unicode" title="U+1F349: WATERMELON">🍉</mark>
<mark class="unicode" title="U+1F348: MELON">🍈</mark>
</div>

<div>
<mark class="unicode" title="U+1F34A: TANGERINE" data-char-info="U+1F34A: TANGERINE">🍊</mark>
<mark class="unicode" title="U+1F34B: LEMON" data-char-info="U+1F34B: LEMON">🍋</mark>
</div>

<div>
<mark class="unicode" title="U+1F34C: BANANA">🍌</mark>
<mark class="unicode" title="U+1F34D: PINEAPPLE">🍍</mark>
<mark class="unicode" title="U+1F96D: MANGO">🥭</mark>
<mark class="unicode" title="U+1F965: COCONUT">🥥</mark>
</div>

<div>
<mark class="unicode" title="U+1F34E: RED APPLE">🍎</mark>
<mark class="unicode" title="U+1F34F: GREEN APPLE" data-char-info="U+1F34F: GREEN APPLE">🍏</mark>
<mark class="unicode" title="U+1F350: PEAR" data-char-info="U+1F350: PEAR">🍐</mark>
</div>

<div>
Veggie<br>
<mark class="unicode" title="U+1F96C: LEAFY GREEN" data-char-info="U+1F96C: LEAFY GREEN">🥬</mark>
<mark class="unicode" title="U+1F966: BROCCOLI">🥦</mark>
<mark class="unicode" title="U+1F344: MUSHROOM">🍄</mark>
<mark class="unicode" title="U+1F345: TOMATO">🍅</mark>
<mark class="unicode" title="U+1F955: CARROT">🥕</mark>
<mark class="unicode" title="U+1F336: HOT PEPPER">🌶</mark>
</div>

<div>
<mark class="unicode" title="U+1F346: AUBERGINE">🍆</mark>
<mark class="unicode" title="U+1F952: CUCUMBER">🥒</mark>
<mark class="unicode" title="U+1F951: AVOCADO">🥑</mark>
</div>

<div>
<mark class="unicode" title="U+1F954: POTATO">🥔</mark>
<mark class="unicode" title="U+1F360: ROASTED SWEET POTATO">🍠</mark>
<mark class="unicode" title="U+1F95C: PEANUTS">🥜</mark>
</div>

<div>
cake<br>
<mark class="unicode" title="U+1F370: SHORTCAKE">🍰</mark>
<mark class="unicode" title="U+1F382: BIRTHDAY CAKE">🎂</mark>
<mark class="unicode" title="U+1F9C1: CUPCAKE">🧁</mark>
<mark class="unicode" title="U+1F967: PIE">🥧</mark>
</div>

<div>
sweets<br>
<mark class="unicode" title="U+1F369: DOUGHNUT">🍩</mark>
<mark class="unicode" title="U+1F36A: COOKIE">🍪</mark>
<mark class="unicode" title="U+1F37F: POPCORN">🍿</mark>
<mark class="unicode" title="U+1F36E: CUSTARD">🍮</mark>
<mark class="unicode" title="U+1F36F: HONEY POT">🍯</mark>
</div>

<div>
ice cream<br>
<mark class="unicode" title="U+1F366: SOFT ICE CREAM">🍦</mark>
<mark class="unicode" title="U+1F368: ICE CREAM">🍨</mark>
<mark class="unicode" title="U+1F367: SHAVED ICE">🍧</mark>
</div>

<div>
<mark class="unicode" title="U+1F36B: CHOCOLATE BAR">🍫</mark>
<mark class="unicode" title="U+1F36C: CANDY">🍬</mark>
<mark class="unicode" title="U+1F36D: LOLLIPOP">🍭</mark>
</div>

<div>
beer<br>
<mark class="unicode" title="U+1F37A: BEER MUG">🍺</mark>
<mark class="unicode" title="U+1F37B: CLINKING BEER MUGS">🍻</mark>
</div>

<div>
wine<br>
<mark class="unicode" title="U+1F942: CLINKING GLASSES">🥂</mark>
<mark class="unicode" title="U+1F377: WINE GLASS">🍷</mark>
<mark class="unicode" title="U+1F378: COCKTAIL GLASS">🍸</mark>
<mark class="unicode" title="U+1F379: TROPICAL DRINK">🍹</mark>
<mark class="unicode" title="U+1F376: SAKE BOTTLE AND CUP">🍶</mark>
<mark class="unicode" title="U+1F943: TUMBLER GLASS">🥃</mark>
<mark class="unicode" title="U+1F37E: BOTTLE WITH POPPING CORK">🍾</mark>
</div>

<div>
coffee, milk, tea<br>
<mark class="unicode" title="U+2615: HOT BEVERAGE">☕</mark>
<mark class="unicode" title="U+1F375: TEACUP WITHOUT HANDLE">🍵</mark>
<mark class="unicode" title="U+1F95B: GLASS OF MILK">🥛</mark>
<mark class="unicode" title="U+1F37C: BABY BOTTLE">🍼</mark>
<mark class="unicode" title="U+1F964: CUP WITH STRAW">🥤</mark>
</div>

<div>
Utensils<br>
<mark class="unicode" title="U+1F374: FORK AND KNIFE">🍴</mark>
<mark class="unicode" title="U+1F37D: FORK AND KNIFE WITH PLATE">🍽</mark>
<mark class="unicode" title="U+1F963: BOWL WITH SPOON">🥣</mark>
<mark class="unicode" title="U+1F944: SPOON">🥄</mark>
<mark class="unicode" title="U+1F9C2: SALT SHAKER">🧂</mark>
</div>

<div>
<mark class="unicode" title="U+1F924: DROOLING FACE">🤤</mark>
<mark class="unicode" title="U+1F60B: FACE SAVOURING DELICIOUS FOOD">😋</mark>
</div>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+1F44D: THUMBS UP SIGN" data-char-info="U+1F44D: THUMBS UP SIGN">👍</mark>
<mark class="unicode" title="U+1F44E: THUMBS DOWN SIGN" data-char-info="U+1F44E: THUMBS DOWN SIGN">👎</mark>
<mark class="unicode" title="U+1F592: REVERSED THUMBS UP SIGN" data-char-info="U+1F592: REVERSED THUMBS UP SIGN">🖒</mark>
<mark class="unicode" title="U+1F593: REVERSED THUMBS DOWN SIGN" data-char-info="U+1F593: REVERSED THUMBS DOWN SIGN">🖓</mark>
</div>

<div>
<mark class="unicode" title="U+1F44C: OK HAND SIGN" data-char-info="U+1F44C: OK HAND SIGN">👌</mark>
<mark class="unicode" title="U+1F58F: TURNED OK HAND SIGN" data-char-info="U+1F58F: TURNED OK HAND SIGN">🖏</mark>
</div>

<div>
<mark class="unicode" title="U+270C: VICTORY HAND">✌</mark>
<mark class="unicode" title="U+1F594: REVERSED VICTORY HAND">🖔</mark>
</div>

<div>
<mark class="unicode" title="U+1F44B: WAVING HAND SIGN">👋</mark>
<mark class="unicode" title="U+1F91D: HANDSHAKE">🤝</mark>
</div>

<div>
<mark class="unicode" title="U+1F44F: CLAPPING HANDS SIGN" data-char-info="U+1F44F: CLAPPING HANDS SIGN">👏</mark>
<mark class="unicode" title="U+1F918: SIGN OF THE HORNS" data-char-info="U+1F918: SIGN OF THE HORNS">🤘</mark>
<mark class="unicode" title="U+1F91F: I LOVE YOU HAND SIGN" data-char-info="U+1F91F: I LOVE YOU HAND SIGN">🤟</mark>
<mark class="unicode" title="U+1F919: CALL ME HAND" data-char-info="U+1F919: CALL ME HAND">🤙</mark>
</div>

<div>
<mark class="unicode" title="U+1F596: RAISED HAND WITH PART BETWEEN MIDDLE AND RING FINGERS">🖖</mark>
<mark class="unicode" title="U+1F91E: HAND WITH INDEX AND MIDDLE FINGERS CROSSED">🤞</mark>
</div>

<div>
<mark class="unicode" title="U+270B: RAISED HAND">✋</mark>
<mark class="unicode" title="U+1F91A: RAISED BACK OF HAND">🤚</mark>
<mark class="unicode" title="U+1F590: RAISED HAND WITH FINGERS SPLAYED">🖐</mark>
<mark class="unicode" title="U+1F591: REVERSED RAISED HAND WITH FINGERS SPLAYED">🖑</mark>
</div>

<div>
<mark class="unicode" title="U+1F932: PALMS UP TOGETHER">🤲</mark>
<mark class="unicode" title="U+1F450: OPEN HANDS SIGN">👐</mark>
</div>

<div>
<mark class="unicode" title="U+1F44A: FISTED HAND SIGN">👊</mark>
<mark class="unicode" title="U+1F91B: LEFT-FACING FIST">🤛</mark>
<mark class="unicode" title="U+1F91C: RIGHT-FACING FIST">🤜</mark>
<mark class="unicode" title="U+1F595: REVERSED HAND WITH MIDDLE FINGER EXTENDED">🖕</mark>
</div>

<div>
<mark class="unicode" title="U+261C: WHITE LEFT POINTING INDEX">☜</mark>
<mark class="unicode" title="U+261E: WHITE RIGHT POINTING INDEX">☞</mark>
<mark class="unicode" title="U+261D: WHITE UP POINTING INDEX">☝</mark>
<mark class="unicode" title="U+261F: WHITE DOWN POINTING INDEX">☟</mark>
<mark class="unicode" title="U+1F597: WHITE DOWN POINTING LEFT HAND INDEX">🖗</mark>
</div>

<div>
<mark class="unicode" title="U+1F448: WHITE LEFT POINTING BACKHAND INDEX">👈</mark>
<mark class="unicode" title="U+1F449: WHITE RIGHT POINTING BACKHAND INDEX">👉</mark>
<mark class="unicode" title="U+1F446: WHITE UP POINTING BACKHAND INDEX">👆</mark>
<mark class="unicode" title="U+1F447: WHITE DOWN POINTING BACKHAND INDEX">👇</mark>
</div>

<div>
<mark class="unicode" title="U+1F598: SIDEWAYS WHITE LEFT POINTING INDEX">🖘</mark>
<mark class="unicode" title="U+1F599: SIDEWAYS WHITE RIGHT POINTING INDEX">🖙</mark>
<mark class="unicode" title="U+1F59E: SIDEWAYS WHITE UP POINTING INDEX">🖞</mark>
<mark class="unicode" title="U+1F59F: SIDEWAYS WHITE DOWN POINTING INDEX">🖟</mark>
</div>

<div>
<mark class="unicode" title="U+261A: BLACK LEFT POINTING INDEX">☚</mark>
<mark class="unicode" title="U+261B: BLACK RIGHT POINTING INDEX">☛</mark>
</div>

<div>
<mark class="unicode" title="U+1F59C: BLACK LEFT POINTING BACKHAND INDEX">🖜</mark>
<mark class="unicode" title="U+1F59D: BLACK RIGHT POINTING BACKHAND INDEX">🖝</mark>
<mark class="unicode" title="U+1F5A2: BLACK UP POINTING BACKHAND INDEX">🖢</mark>
<mark class="unicode" title="U+1F5A3: BLACK DOWN POINTING BACKHAND INDEX">🖣</mark>
</div>

<div>
<mark class="unicode" title="U+1F59A: SIDEWAYS BLACK LEFT POINTING INDEX">🖚</mark>
<mark class="unicode" title="U+1F59B: SIDEWAYS BLACK RIGHT POINTING INDEX">🖛</mark>
<mark class="unicode" title="U+1F5A0: SIDEWAYS BLACK UP POINTING INDEX">🖠</mark>
<mark class="unicode" title="U+1F5A1: SIDEWAYS BLACK DOWN POINTING INDEX">🖡</mark>
</div>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+1F617: KISSING FACE">😗</mark>
<mark class="unicode" title="U+1F61A: KISSING FACE WITH CLOSED EYES">😚</mark>
<mark class="unicode" title="U+1F618: FACE THROWING A KISS">😘</mark>
<mark class="unicode" title="U+1F619: KISSING FACE WITH SMILING EYES">😙</mark>
</div>

<div>
<mark class="unicode" title="U+1F633: FLUSHED FACE">😳</mark>
<mark class="unicode" title="U+1F60D: SMILING FACE WITH HEART-SHAPED EYES">😍</mark>
<mark class="unicode" title="U+1F63B: SMILING CAT FACE WITH HEART-SHAPED EYES">😻</mark>
<mark class="unicode" title="U+1F924: DROOLING FACE">🤤</mark>
<mark class="unicode" title="U+1F917: HUGGING FACE">🤗</mark>
</div>

<div>
<mark class="unicode" title="U+1F61B: FACE WITH STUCK-OUT TONGUE">😛</mark>
<mark class="unicode" title="U+1F61C: FACE WITH STUCK-OUT TONGUE AND WINKING EYE">😜</mark>
<mark class="unicode" title="U+1F61D: FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES">😝</mark>
</div>

<div>
<mark class="unicode" title="U+1F394: HEART WITH TIP ON THE LEFT" data-char-info="U+1F394: HEART WITH TIP ON THE LEFT">🎔</mark>
<mark class="unicode" title="U+1F495: TWO HEARTS" data-char-info="U+1F495: TWO HEARTS">💕</mark>
<mark class="unicode" title="U+1F493: BEATING HEART" data-char-info="U+1F493: BEATING HEART">💓</mark>
<mark class="unicode" title="U+1F496: SPARKLING HEART" data-char-info="U+1F496: SPARKLING HEART">💖</mark>
<mark class="unicode" title="U+1F497: GROWING HEART" data-char-info="U+1F497: GROWING HEART">💗</mark>
<mark class="unicode" title="U+1F498: HEART WITH ARROW" data-char-info="U+1F498: HEART WITH ARROW">💘</mark>
<mark class="unicode" title="U+1F49D: HEART WITH RIBBON" data-char-info="U+1F49D: HEART WITH RIBBON">💝</mark>
<mark class="unicode" title="U+1F49E: REVOLVING HEARTS">💞</mark>
<mark class="unicode" title="U+1F49F: HEART DECORATION">💟</mark>
</div>

<div>
<mark class="unicode" title="U+1F9E1: ORANGE HEART" data-char-info="U+1F9E1: ORANGE HEART">🧡</mark>
<mark class="unicode" title="U+1F49B: YELLOW HEART" data-char-info="U+1F49B: YELLOW HEART">💛</mark>
<mark class="unicode" title="U+1F49A: GREEN HEART">💚</mark>
<mark class="unicode" title="U+1F499: BLUE HEART">💙</mark>
<mark class="unicode" title="U+1F49C: PURPLE HEART">💜</mark>
<mark class="unicode" title="U+1F5A4: BLACK HEART">🖤</mark>
<mark class="unicode" title="U+2764: HEAVY BLACK HEART">❤</mark>
</div>

<div>
<mark class="unicode" title="U+2661: WHITE HEART SUIT">♡</mark>
<mark class="unicode" title="U+2665: BLACK HEART SUIT">♥</mark>
</div>

<div>
<mark class="unicode" title="U+1F494: BROKEN HEART">💔</mark>
<mark class="unicode" title="U+1F940: WILTED FLOWER">🥀</mark>
</div>

<div>
<mark class="unicode" title="U+1F459: BIKINI">👙</mark>
<mark class="unicode" title="U+1F460: HIGH-HEELED SHOE">👠</mark>
<mark class="unicode" title="U+1F461: WOMANS SANDAL">👡</mark>
<mark class="unicode" title="U+1F484: LIPSTICK">💄</mark>
<mark class="unicode" title="U+1F485: NAIL POLISH">💅</mark>
<mark class="unicode" title="U+1F52E: CRYSTAL BALL">🔮</mark>
</div>

<div>
<mark class="unicode" title="U+1F395: BOUQUET OF FLOWERS">🎕</mark>
<mark class="unicode" title="U+1F490: BOUQUET">💐</mark>
<mark class="unicode" title="U+1F339: ROSE">🌹</mark>
<mark class="unicode" title="U+1F36B: CHOCOLATE BAR">🍫</mark>
<mark class="unicode" title="U+1F36D: LOLLIPOP">🍭</mark>
</div>

<div>
<mark class="unicode" title="U+1F48E: GEM STONE">💎</mark>
<mark class="unicode" title="U+1F48D: RING">💍</mark>
<mark class="unicode" title="U+1F492: WEDDING">💒</mark>
<mark class="unicode" title="U+1F470: BRIDE WITH VEIL">👰</mark>
</div>

<div>
<mark class="unicode" title="U+1F933: SELFIE">🤳</mark>
<mark class="unicode" title="U+1F48B: KISS MARK">💋</mark>
<mark class="unicode" title="U+1F48C: LOVE LETTER">💌</mark>
<mark class="unicode" title="U+1F48F: KISS">💏</mark>
<mark class="unicode" title="U+1F491: COUPLE WITH HEART">💑</mark>
</div>

<div>
<mark class="unicode" title="U+1F46F: WOMAN WITH BUNNY EARS">👯</mark>
<mark class="unicode" title="U+1F483: DANCER">💃</mark>
<mark class="unicode" title="U+1F930: PREGNANT WOMAN">🤰</mark>
</div>

<div>
<mark class="unicode" title="U+1F525: FIRE">🔥</mark>
<mark class="unicode" title="U+1F51E: NO ONE UNDER EIGHTEEN SYMBOL">🔞</mark>
<mark class="unicode" title="U+1F3E9: LOVE HOTEL">🏩</mark>
<mark class="unicode" title="U+1F4A6: SPLASHING SWEAT SYMBOL">💦</mark>
<mark class="unicode" title="U+1F4A7: DROPLET">💧</mark>
</div>

<div>
<mark class="unicode" title="U+1F34E: RED APPLE">🍎</mark>
<mark class="unicode" title="U+1F351: PEACH">🍑</mark>
<mark class="unicode" title="U+1F352: CHERRIES">🍒</mark>
<mark class="unicode" title="U+1F34C: BANANA">🍌</mark>
<mark class="unicode" title="U+1F952: CUCUMBER">🥒</mark>
<mark class="unicode" title="U+1F346: AUBERGINE">🍆</mark>
</div>

<div>
<mark class="unicode" title="U+1F919: CALL ME HAND">🤙</mark>
<mark class="unicode" title="U+1F595: REVERSED HAND WITH MIDDLE FINGER EXTENDED">🖕</mark>
<mark class="unicode" title="U+1F449: WHITE RIGHT POINTING BACKHAND INDEX">👉</mark>
<mark class="unicode" title="U+1F58F: TURNED OK HAND SIGN">🖏</mark>
<mark class="unicode" title="U+1F44C: OK HAND SIGN">👌</mark>
<mark class="unicode" title="U+1F448: WHITE LEFT POINTING BACKHAND INDEX">👈</mark>
</div>

</div>`,

`<div class="box00710">

<div>astrology<br>
<mark class="unicode" title="U+2648: ARIES">♈</mark>
<mark class="unicode" title="U+2649: TAURUS">♉</mark>
<mark class="unicode" title="U+264A: GEMINI">♊</mark>
<mark class="unicode" title="U+264B: CANCER">♋</mark>
<mark class="unicode" title="U+264C: LEO">♌</mark>
<mark class="unicode" title="U+264D: VIRGO" data-char-info="U+264D: VIRGO">♍</mark>
<mark class="unicode" title="U+264E: LIBRA">♎</mark>
<mark class="unicode" title="U+264F: SCORPIUS" data-char-info="U+264F: SCORPIUS">♏</mark>
<mark class="unicode" title="U+2650: SAGITTARIUS" data-char-info="U+2650: SAGITTARIUS">♐</mark>
<mark class="unicode" title="U+2651: CAPRICORN" data-char-info="U+2651: CAPRICORN">♑</mark>
<mark class="unicode" title="U+2652: AQUARIUS">♒</mark>
<mark class="unicode" title="U+2653: PISCES">♓</mark>
</div>

<div>
<mark class="unicode" title="U+2600: BLACK SUN WITH RAYS">☀</mark>
<mark class="unicode" title="U+263C: WHITE SUN WITH RAYS">☼</mark>
<mark class="unicode" title="U+1F31E: SUN WITH FACE">🌞</mark>
</div>

<div>moon<br>
<mark class="unicode" title="U+1F311: NEW MOON SYMBOL">🌑</mark>
<mark class="unicode" title="U+1F312: WAXING CRESCENT MOON SYMBOL">🌒</mark>
<mark class="unicode" title="U+1F313: FIRST QUARTER MOON SYMBOL">🌓</mark>
<mark class="unicode" title="U+1F314: WAXING GIBBOUS MOON SYMBOL">🌔</mark>
<mark class="unicode" title="U+1F315: FULL MOON SYMBOL" data-char-info="U+1F315: FULL MOON SYMBOL">🌕</mark>
<mark class="unicode" title="U+1F316: WANING GIBBOUS MOON SYMBOL" data-char-info="U+1F316: WANING GIBBOUS MOON SYMBOL">🌖</mark>
<mark class="unicode" title="U+1F317: LAST QUARTER MOON SYMBOL" data-char-info="U+1F317: LAST QUARTER MOON SYMBOL">🌗</mark>
<mark class="unicode" title="U+1F318: WANING CRESCENT MOON SYMBOL" data-char-info="U+1F318: WANING CRESCENT MOON SYMBOL">🌘</mark>
</div>

<div>
<mark class="unicode" title="U+1F319: CRESCENT MOON">🌙</mark>
<mark class="unicode" title="U+263E: LAST QUARTER MOON">☾</mark>
<mark class="unicode" title="U+1F31B: FIRST QUARTER MOON WITH FACE">🌛</mark>
<mark class="unicode" title="U+1F31C: LAST QUARTER MOON WITH FACE" data-char-info="U+1F31C: LAST QUARTER MOON WITH FACE">🌜</mark>
<mark class="unicode" title="U+1F31D: FULL MOON WITH FACE" data-char-info="U+1F31D: FULL MOON WITH FACE">🌝</mark>
<mark class="unicode" title="U+1F31A: NEW MOON WITH FACE">🌚</mark>
</div>

<div>
<mark class="unicode" title="U+2609: SUN">☉</mark>
<mark class="unicode" title="U+263D: FIRST QUARTER MOON">☽</mark>
</div>

<div>planets<br>
<mark class="unicode" title="U+263F: MERCURY">☿</mark>
<mark class="unicode" title="U+2640: FEMALE SIGN">♀</mark>
<mark class="unicode" title="U+2641: EARTH">♁</mark>
<mark class="unicode" title="U+2642: MALE SIGN">♂</mark>
<mark class="unicode" title="U+2643: JUPITER">♃</mark>
<mark class="unicode" title="U+2644: SATURN">♄</mark>
<mark class="unicode" title="U+2645: URANUS">♅</mark>
<mark class="unicode" title="U+26E2: ASTRONOMICAL SYMBOL FOR URANUS">⛢</mark>
<mark class="unicode" title="U+2646: NEPTUNE">♆</mark>
</div>

<div>Pluto<br>
<mark class="unicode" title="U+2647: PLUTO">♇</mark>
<mark class="unicode" title="U+2BD3: PLUTO FORM TWO">⯓</mark>
<mark class="unicode" title="U+2BD4: PLUTO FORM THREE">⯔</mark>
<mark class="unicode" title="U+2BD5: PLUTO FORM FOUR">⯕</mark>
<mark class="unicode" title="U+2BD6: PLUTO FORM FIVE">⯖</mark>
<mark class="unicode" title="U+2BD7: TRANSPLUTO">⯗</mark>
</div>

<div>
<mark class="unicode" title="U+26B3: CERES">⚳</mark>
<mark class="unicode" title="U+26B4: PALLAS">⚴</mark>
<mark class="unicode" title="U+26B5: JUNO">⚵</mark>
<mark class="unicode" title="U+26B6: VESTA">⚶</mark>
<mark class="unicode" title="U+26B7: CHIRON">⚷</mark>
<mark class="unicode" title="U+26CE: OPHIUCHUS">⛎</mark>
<mark class="unicode" title="U+2604: COMET">☄</mark>
<mark class="unicode" title="U+26B8: BLACK MOON LILITH">⚸</mark>
</div>

<div>
<mark class="unicode" title="U+2BE0: CUPIDO">⯠</mark>
<mark class="unicode" title="U+2BE1: HADES">⯡</mark>
<mark class="unicode" title="U+2BE2: ZEUS">⯢</mark>
<mark class="unicode" title="U+2BE3: KRONOS">⯣</mark>
<mark class="unicode" title="U+2BE4: APOLLON">⯤</mark>
<mark class="unicode" title="U+2BE5: ADMETOS">⯥</mark>
<mark class="unicode" title="U+2BE6: VULCANUS">⯦</mark>
<mark class="unicode" title="U+2BE7: POSEIDON">⯧</mark>
</div>

<div>
<mark class="unicode" title="U+26B9: SEXTILE">⚹</mark> (60°)
<mark class="unicode" title="U+26BA: SEMISEXTILE">⚺</mark> (30°)
<mark class="unicode" title="U+26BC: SESQUIQUADRATE">⚼</mark> (135°)
<mark class="unicode" title="U+26BB: QUINCUNX">⚻</mark> (cross in square position <mark class="unicode" title="U+2684: DIE FACE-5">⚄</mark>)
</div>

<div>
<mark class="unicode" title="U+260A: ASCENDING NODE">☊</mark>
<mark class="unicode" title="U+260B: DESCENDING NODE">☋</mark>
<mark class="unicode" title="U+260C: CONJUNCTION">☌</mark>
<mark class="unicode" title="U+260D: OPPOSITION">☍</mark>
</div>


</div>`,

`<div class="box00710">

<div>
sunny<br>
<mark class="unicode" title="U+1F323: WHITE SUN">🌣</mark>
<mark class="unicode" title="U+1F31E: SUN WITH FACE">🌞</mark>
<mark class="unicode" title="U+26C5: SUN BEHIND CLOUD">⛅</mark>
<mark class="unicode" title="U+1F324: WHITE SUN WITH SMALL CLOUD">🌤</mark>
<mark class="unicode" title="U+1F325: WHITE SUN BEHIND CLOUD">🌥</mark>
<mark class="unicode" title="U+26F1: UMBRELLA ON GROUND">⛱</mark>
</div>

<div>
rainy<br>
<mark class="unicode" title="U+1F326: WHITE SUN BEHIND CLOUD WITH RAIN">🌦</mark>
<mark class="unicode" title="U+1F327: CLOUD WITH RAIN">🌧</mark>
<mark class="unicode" title="U+26C6: RAIN">⛆</mark>
<mark class="unicode" title="U+1F322: BLACK DROPLET">🌢</mark>
<mark class="unicode" title="U+2602: UMBRELLA">☂</mark>
<mark class="unicode" title="U+2614: UMBRELLA WITH RAIN DROPS">☔</mark>
<mark class="unicode" title="U+1F302: CLOSED UMBRELLA">🌂</mark>
</div>

<div>
snow<br>
<mark class="unicode" title="U+1F328: CLOUD WITH SNOW">🌨</mark>
<mark class="unicode" title="U+2603: SNOWMAN">☃</mark>
<mark class="unicode" title="U+26C4: SNOWMAN WITHOUT SNOW">⛄</mark>
<mark class="unicode" title="U+26C7: BLACK SNOWMAN">⛇</mark>
</div>

<div>
<mark class="unicode" title="U+2744: SNOWFLAKE">❄</mark>
<mark class="unicode" title="U+2745: TIGHT TRIFOLIATE SNOWFLAKE">❅</mark>
<mark class="unicode" title="U+2746: HEAVY CHEVRON SNOWFLAKE">❆</mark>
</div>

<div>
windy<br>
<mark class="unicode" title="U+1F32C: WIND BLOWING FACE" data-char-info="U+1F32C: WIND BLOWING FACE">🌬</mark>
<mark class="unicode" title="U+1F38F: CARP STREAMER" data-char-info="U+1F38F: CARP STREAMER">🎏</mark>
<mark class="unicode" title="U+1F390: WIND CHIME" data-char-info="U+1F390: WIND CHIME">🎐</mark>
</div>

<div>
foggy<br>
<mark class="unicode" title="U+2601: CLOUD">☁</mark>
<mark class="unicode" title="U+1F32B: FOG">🌫</mark>
<mark class="unicode" title="U+1F301: FOGGY">🌁</mark>
</div>

<div>
stormy<br>
<mark class="unicode" title="U+1F329: CLOUD WITH LIGHTNING" data-char-info="U+1F329: CLOUD WITH LIGHTNING">🌩</mark>
<mark class="unicode" title="U+26C8: THUNDER CLOUD AND RAIN" data-char-info="U+26C8: THUNDER CLOUD AND RAIN">⛈</mark>
<mark class="unicode" title="U+2607: LIGHTNING" data-char-info="U+2607: LIGHTNING">☇</mark>
<mark class="unicode" title="U+2608: THUNDERSTORM">☈</mark>
</div>

<div>
tornado<br>
<mark class="unicode" title="U+1F32A: CLOUD WITH TORNADO">🌪</mark>
<mark class="unicode" title="U+1F300: CYCLONE">🌀</mark>
</div>

<div>
<mark class="unicode" title="U+1F308: RAINBOW">🌈</mark>
</div>

</div>`,

`<div class="box00710">

<h2>Road Signs</h2>

<div>
<mark class="unicode" title="U+1F6D1: OCTAGONAL SIGN">🛑</mark>
<mark class="unicode" title="U+26D4: NO ENTRY">⛔</mark>
<mark class="unicode" title="U+1F6AB: NO ENTRY SIGN">🚫</mark>
<mark class="unicode" title="U+26A0: WARNING SIGN">⚠</mark>
<mark class="unicode" title="U+2621: CAUTION SIGN">☡</mark>
</div>

<div>
<mark class="unicode" title="U+26D2: CIRCLED CROSSING LANES">⛒</mark>
<mark class="unicode" title="U+26CC: CROSSING LANES">⛌</mark>
<mark class="unicode" title="U+26D0: CAR SLIDING">⛐</mark>
<mark class="unicode" title="U+26CD: DISABLED CAR">⛍</mark>
<mark class="unicode" title="U+26DB: HEAVY WHITE DOWN-POINTING TRIANGLE">⛛</mark>
</div>

<div>
<mark class="unicode" title="U+1F6B6: PEDESTRIAN">🚶</mark>
<mark class="unicode" title="U+1F6B7: NO PEDESTRIANS">🚷</mark>
<mark class="unicode" title="U+1F6B8: CHILDREN CROSSING">🚸</mark>
</div>

<div>
<mark class="unicode" title="U+26D6: BLACK TWO-WAY LEFT WAY TRAFFIC">⛖</mark>
<mark class="unicode" title="U+26D7: WHITE TWO-WAY LEFT WAY TRAFFIC">⛗</mark>
</div>

<div>
<mark class="unicode" title="U+26D8: BLACK LEFT LANE MERGE">⛘</mark>
<mark class="unicode" title="U+26D9: WHITE LEFT LANE MERGE">⛙</mark>
<mark class="unicode" title="U+26DC: LEFT CLOSED ENTRY">⛜</mark>
<mark class="unicode" title="U+26E0: RESTRICTED LEFT ENTRY-1">⛠</mark>
</div>

<div>
<mark class="unicode" title="U+26E1: RESTRICTED LEFT ENTRY-2">⛡</mark>
<mark class="unicode" title="U+26D5: ALTERNATE ONE-WAY LEFT WAY TRAFFIC">⛕</mark>
<mark class="unicode" title="U+26DA: DRIVE SLOW SIGN">⛚</mark>
</div>

<h2>Public Signs</h2>

<div>
<mark class="unicode" title="U+267F: WHEELCHAIR SYMBOL">♿</mark>
<mark class="unicode" title="U+2708: AIRPLANE">✈</mark>
<mark class="unicode" title="U+2706: TELEPHONE LOCATION SIGN">✆</mark>
<mark class="unicode" title="U+260E: BLACK TELEPHONE">☎</mark>
<mark class="unicode" title="U+260F: WHITE TELEPHONE">☏</mark>
<mark class="unicode" title="U+26FD: FUEL PUMP">⛽</mark>
<mark class="unicode" title="U+26FE: CUP ON BLACK SQUARE" data-char-info="U+26FE: CUP ON BLACK SQUARE">⛾</mark>
</div>

<div>
<mark class="unicode" title="U+1F6A5: HORIZONTAL TRAFFIC LIGHT">🚥</mark>
<mark class="unicode" title="U+1F6A6: VERTICAL TRAFFIC LIGHT">🚦</mark>
<mark class="unicode" title="U+1F6A7: CONSTRUCTION SIGN">🚧</mark>
<mark class="unicode" title="U+1F6A8: POLICE CARS REVOLVING LIGHT" data-char-info="U+1F6A8: POLICE CARS REVOLVING LIGHT">🚨</mark>
</div>

<div>
<mark class="unicode" title="U+1F6AC: SMOKING SYMBOL" data-char-info="U+1F6AC: SMOKING SYMBOL">🚬</mark>
<mark class="unicode" title="U+1F6AD: NO SMOKING SYMBOL" data-char-info="U+1F6AD: NO SMOKING SYMBOL">🚭</mark>
</div>

<div>
<mark class="unicode" title="U+1F6AE: PUT LITTER IN ITS PLACE SYMBOL">🚮</mark>
<mark class="unicode" title="U+1F6AF: DO NOT LITTER SYMBOL">🚯</mark>
</div>

<div>
<mark class="unicode" title="U+1F6B0: POTABLE WATER SYMBOL">🚰</mark>
<mark class="unicode" title="U+1F6B1: NON-POTABLE WATER SYMBOL">🚱</mark>
</div>

<div>
<mark class="unicode" title="U+1F6B2: BICYCLE">🚲</mark>
<mark class="unicode" title="U+1F6B3: NO BICYCLES" data-char-info="U+1F6B3: NO BICYCLES">🚳</mark>
<mark class="unicode" title="U+1F6B4: BICYCLIST">🚴</mark>
<mark class="unicode" title="U+1F6B5: MOUNTAIN BICYCLIST">🚵</mark>
</div>

<h2>Industrial Signs</h2>

<div>
<mark class="unicode" title="U+269B: ATOM SYMBOL">⚛</mark>
<mark class="unicode" title="U+2620: SKULL AND CROSSBONES">☠</mark>
<mark class="unicode" title="U+2622: RADIOACTIVE SIGN">☢</mark>
<mark class="unicode" title="U+2623: BIOHAZARD SIGN" data-char-info="U+2623: BIOHAZARD SIGN">☣</mark>
<mark class="unicode" title="U+26A1: HIGH VOLTAGE SIGN">⚡</mark>
</div>

<div>
<mark class="unicode" title="U+267B: BLACK UNIVERSAL RECYCLING SYMBOL">♻</mark>
<mark class="unicode" title="U+267C: RECYCLED PAPER SYMBOL">♼</mark>
<mark class="unicode" title="U+267D: PARTIALLY-RECYCLED PAPER SYMBOL">♽</mark>
<mark class="unicode" title="U+2672: UNIVERSAL RECYCLING SYMBOL">♲</mark>
<mark class="unicode" title="U+267E: PERMANENT PAPER SIGN">♾</mark>
</div>

<div>
<mark class="unicode" title="U+267A: RECYCLING SYMBOL FOR GENERIC MATERIALS">♺</mark>
<mark class="unicode" title="U+2673: RECYCLING SYMBOL FOR TYPE-1 PLASTICS">♳</mark>
<mark class="unicode" title="U+2674: RECYCLING SYMBOL FOR TYPE-2 PLASTICS">♴</mark>
<mark class="unicode" title="U+2675: RECYCLING SYMBOL FOR TYPE-3 PLASTICS" data-char-info="U+2675: RECYCLING SYMBOL FOR TYPE-3 PLASTICS">♵</mark>
<mark class="unicode" title="U+2676: RECYCLING SYMBOL FOR TYPE-4 PLASTICS">♶</mark>
<mark class="unicode" title="U+2677: RECYCLING SYMBOL FOR TYPE-5 PLASTICS">♷</mark>
<mark class="unicode" title="U+2678: RECYCLING SYMBOL FOR TYPE-6 PLASTICS">♸</mark>
<mark class="unicode" title="U+2679: RECYCLING SYMBOL FOR TYPE-7 PLASTICS">♹</mark>
</div>

<h2>Bathroom Signs</h2>

<div>
<mark class="unicode" title="U+1F6B9: MENS SYMBOL">🚹</mark>
<mark class="unicode" title="U+1F6BA: WOMENS SYMBOL">🚺</mark>
<mark class="unicode" title="U+1F6BB: RESTROOM">🚻</mark>
<mark class="unicode" title="U+1F6BC: BABY SYMBOL">🚼</mark>
<mark class="unicode" title="U+1F6BD: TOILET">🚽</mark>
<mark class="unicode" title="U+1F6BE: WATER CLOSET">🚾</mark>
</div>

<h2>Airport Signs</h2>

<div>
<mark class="unicode" title="U+1F6C2: PASSPORT CONTROL">🛂</mark>
<mark class="unicode" title="U+1F6C3: CUSTOMS">🛃</mark>
<mark class="unicode" title="U+1F6C4: BAGGAGE CLAIM">🛄</mark>
<mark class="unicode" title="U+1F6C5: LEFT LUGGAGE">🛅</mark>
</div>

<h2>Map Signs</h2>

<div>
<mark class="unicode" title="U+2668: HOT SPRINGS">♨</mark>
<mark class="unicode" title="U+26EB: CASTLE">⛫</mark>
<mark class="unicode" title="U+26EC: HISTORIC SITE">⛬</mark>
<mark class="unicode" title="U+26FC: HEADSTONE GRAVEYARD SYMBOL">⛼</mark>
<mark class="unicode" title="U+26FB: JAPANESE BANK SYMBOL">⛻</mark>
<mark class="unicode" title="U+26EF: MAP SYMBOL FOR LIGHTHOUSE">⛯</mark>
</div>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+1F4E7: E-MAIL SYMBOL">📧</mark>
<mark class="unicode" title="U+2709: ENVELOPE">✉</mark>
<mark class="unicode" title="U+1F582: BACK OF ENVELOPE">🖂</mark>
<mark class="unicode" title="U+1F583: STAMPED ENVELOPE">🖃</mark>
<mark class="unicode" title="U+1F584: ENVELOPE WITH LIGHTNING">🖄</mark>
<mark class="unicode" title="U+1F585: FLYING ENVELOPE">🖅</mark>
<mark class="unicode" title="U+1F586: PEN OVER STAMPED ENVELOPE">🖆</mark>
</div>

<div>
<mark class="unicode" title="U+1F4E8: INCOMING ENVELOPE">📨</mark>
<mark class="unicode" title="U+1F4E4: OUTBOX TRAY">📤</mark>
<mark class="unicode" title="U+1F4E5: INBOX TRAY">📥</mark>
<mark class="unicode" title="U+1F4E9: ENVELOPE WITH DOWNWARDS ARROW ABOVE">📩</mark>
</div>

<div>
<mark class="unicode" title="U+1F4EA: CLOSED MAILBOX WITH LOWERED FLAG">📪</mark>
<mark class="unicode" title="U+1F4EB: CLOSED MAILBOX WITH RAISED FLAG">📫</mark>
<mark class="unicode" title="U+1F4EC: OPEN MAILBOX WITH RAISED FLAG">📬</mark>
<mark class="unicode" title="U+1F4ED: OPEN MAILBOX WITH LOWERED FLAG">📭</mark>
<mark class="unicode" title="U+1F4EE: POSTBOX">📮</mark>
</div>

<div>
<mark class="unicode" title="U+1F4E6: PACKAGE">📦</mark>
<mark class="unicode" title="U+1F4EF: POSTAL HORN">📯</mark>
</div>

<div>
<mark class="unicode" title="U+1F4DC: SCROLL">📜</mark>
<mark class="unicode" title="U+1F4C3: PAGE WITH CURL">📃</mark>
<mark class="unicode" title="U+1F4C4: PAGE FACING UP" data-char-info="U+1F4C4: PAGE FACING UP">📄</mark>
<mark class="unicode" title="U+1F5CE: DOCUMENT" data-char-info="U+1F5CE: DOCUMENT">🗎</mark>
<mark class="unicode" title="U+1F5CF: PAGE">🗏</mark>
<mark class="unicode" title="U+1F5D0: PAGES" data-char-info="U+1F5D0: PAGES">🗐</mark>
</div>

<div>
<mark class="unicode" title="U+1F5C5: EMPTY NOTE" data-char-info="U+1F5C5: EMPTY NOTE">🗅</mark>
<mark class="unicode" title="U+1F5C6: EMPTY NOTE PAGE" data-char-info="U+1F5C6: EMPTY NOTE PAGE">🗆</mark>
<mark class="unicode" title="U+1F5C7: EMPTY NOTE PAD">🗇</mark>
<mark class="unicode" title="U+1F5C8: NOTE">🗈</mark>
<mark class="unicode" title="U+1F5C9: NOTE PAGE">🗉</mark>
<mark class="unicode" title="U+1F5CA: NOTE PAD">🗊</mark>
<mark class="unicode" title="U+1F5CB: EMPTY DOCUMENT">🗋</mark>
<mark class="unicode" title="U+1F5CC: EMPTY PAGE">🗌</mark>
<mark class="unicode" title="U+1F5CD: EMPTY PAGES">🗍</mark>
</div>

<div>
<mark class="unicode" title="U+1F5D2: SPIRAL NOTE PAD">🗒</mark>
<mark class="unicode" title="U+1F4D3: NOTEBOOK">📓</mark>
<mark class="unicode" title="U+1F4D4: NOTEBOOK WITH DECORATIVE COVER">📔</mark>
<mark class="unicode" title="U+1F4DD: MEMO">📝</mark>
<mark class="unicode" title="U+1F4D2: LEDGER">📒</mark>
<mark class="unicode" title="U+1F4CB: CLIPBOARD">📋</mark>
</div>

<div>
<mark class="unicode" title="U+1F56E: BOOK">🕮</mark>
<mark class="unicode" title="U+1F4D6: OPEN BOOK">📖</mark>
<mark class="unicode" title="U+1F4D5: CLOSED BOOK">📕</mark>
<mark class="unicode" title="U+1F4D7: GREEN BOOK">📗</mark>
<mark class="unicode" title="U+1F4D8: BLUE BOOK">📘</mark>
<mark class="unicode" title="U+1F4D9: ORANGE BOOK">📙</mark>
<mark class="unicode" title="U+1F4DA: BOOKS">📚</mark>
</div>

<div>
<mark class="unicode" title="U+1F4F0: NEWSPAPER">📰</mark>
<mark class="unicode" title="U+1F5DE: ROLLED-UP NEWSPAPER">🗞</mark>
<mark class="unicode" title="U+1F5DF: PAGE WITH CIRCLED TEXT">🗟</mark>
</div>

<div>
<mark class="unicode" title="U+1F4C1: FILE FOLDER">📁</mark>
<mark class="unicode" title="U+1F4C2: OPEN FILE FOLDER">📂</mark>
<mark class="unicode" title="U+1F5BF: BLACK FOLDER">🖿</mark>
<mark class="unicode" title="U+1F5C0: FOLDER">🗀</mark>
<mark class="unicode" title="U+1F5C1: OPEN FOLDER">🗁</mark>
</div>

<div>
<mark class="unicode" title="U+1F5C2: CARD INDEX DIVIDERS">🗂</mark>
<mark class="unicode" title="U+1F5C3: CARD FILE BOX">🗃</mark>
<mark class="unicode" title="U+1F5C4: FILE CABINET">🗄</mark>
</div>

<div>
<mark class="unicode" title="U+1F4D1: BOOKMARK TABS">📑</mark>
<mark class="unicode" title="U+1F516: BOOKMARK">🔖</mark>
<mark class="unicode" title="U+1F517: LINK SYMBOL">🔗</mark>
</div>

<div>
<mark class="unicode" title="U+1F5D3: SPIRAL CALENDAR PAD">🗓</mark>
<mark class="unicode" title="U+1F4C5: CALENDAR">📅</mark>
<mark class="unicode" title="U+1F4C6: TEAR-OFF CALENDAR">📆</mark>
<mark class="unicode" title="U+1F4C7: CARD INDEX">📇</mark>
</div>

<div>
<mark class="unicode" title="U+270D: WRITING HAND">✍</mark>
<mark class="unicode" title="U+1F58E: LEFT WRITING HAND">🖎</mark>
</div>

<div>
<mark class="unicode" title="U+1F58A: LOWER LEFT BALLPOINT PEN">🖊</mark>
<mark class="unicode" title="U+1F58B: LOWER LEFT FOUNTAIN PEN">🖋</mark>
<mark class="unicode" title="U+1F58C: LOWER LEFT PAINTBRUSH">🖌</mark>
<mark class="unicode" title="U+1F58D: LOWER LEFT CRAYON">🖍</mark>
</div>

<div>
<mark class="unicode" title="U+1F589: LOWER LEFT PENCIL">🖉</mark>
<mark class="unicode" title="U+270F: PENCIL">✏</mark>
<mark class="unicode" title="U+2710: UPPER RIGHT PENCIL">✐</mark>
<mark class="unicode" title="U+270E: LOWER RIGHT PENCIL">✎</mark>
</div>

<div>
<mark class="unicode" title="U+2711: WHITE NIB">✑</mark>
<mark class="unicode" title="U+2712: BLACK NIB">✒</mark>
</div>

<div>
<mark class="unicode" title="U+1F4CC: PUSHPIN">📌</mark>
<mark class="unicode" title="U+1F588: BLACK PUSHPIN">🖈</mark>
<mark class="unicode" title="U+1F4CD: ROUND PUSHPIN">📍</mark>
</div>

<div>
<mark class="unicode" title="U+1F4CE: PAPERCLIP">📎</mark>
<mark class="unicode" title="U+1F587: LINKED PAPERCLIPS">🖇</mark>
</div>

<div>
<mark class="unicode" title="U+1F4CF: STRAIGHT RULER">📏</mark>
<mark class="unicode" title="U+1F4D0: TRIANGULAR RULER">📐</mark>
</div>

<div>
<mark class="unicode" title="U+2702: BLACK SCISSORS">✂</mark>
<mark class="unicode" title="U+2704: WHITE SCISSORS">✄</mark>
<mark class="unicode" title="U+2701: UPPER BLADE SCISSORS">✁</mark>
<mark class="unicode" title="U+2703: LOWER BLADE SCISSORS">✃</mark>
</div>

<div>
<mark class="unicode" title="U+1F4BC: BRIEFCASE">💼</mark>
<mark class="unicode" title="U+1F4DB: NAME BADGE">📛</mark>
</div>

<div>
<mark class="unicode" title="U+1F4B9: CHART WITH UPWARDS TREND AND YEN SIGN">💹</mark>
<mark class="unicode" title="U+1F4C8: CHART WITH UPWARDS TREND">📈</mark>
<mark class="unicode" title="U+1F4C9: CHART WITH DOWNWARDS TREND">📉</mark>
<mark class="unicode" title="U+1F4CA: BAR CHART">📊</mark>
<mark class="unicode" title="U+1F5E0: STOCK CHART">🗠</mark>
</div>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+2316: POSITION INDICATOR">⌖</mark>
<mark class="unicode" title="U+2BD0: SQUARE POSITION INDICATOR">⯐</mark>
<mark class="unicode" title="U+25AE: BLACK VERTICAL RECTANGLE">▮</mark>
<mark class="unicode" title="U+25AF: WHITE VERTICAL RECTANGLE">▯</mark>
<br>
[see <a href="unicode_geometric_shapes.html">Geometric Shapes ■</a>]
</div>

<div>
<mark class="unicode" title="U+2758: LIGHT VERTICAL BAR">❘</mark>
<mark class="unicode" title="U+2759: MEDIUM VERTICAL BAR">❙</mark>
<mark class="unicode" title="U+275A: HEAVY VERTICAL BAR">❚</mark>
<mark class="unicode" title="U+2336: APL FUNCTIONAL SYMBOL I-BEAM">⌶</mark>
</div>

<div>
<mark class="unicode" title="U+231A: WATCH">⌚</mark>
<mark class="unicode" title="U+231B: HOURGLASS">⌛</mark>
<mark class="unicode" title="U+23F3: HOURGLASS WITH FLOWING SAND">⏳</mark>
<mark class="unicode" title="U+29D7: BLACK HOURGLASS">⧗</mark>
<mark class="unicode" title="U+29D6: WHITE HOURGLASS">⧖</mark>
</div>

<div>
<mark class="unicode" title="U+1F58E: LEFT WRITING HAND">🖎</mark>
<mark class="unicode" title="U+270D: WRITING HAND">✍</mark>
<mark class="unicode" title="U+270E: LOWER RIGHT PENCIL">✎</mark>
<mark class="unicode" title="U+270F: PENCIL">✏</mark>
<mark class="unicode" title="U+2710: UPPER RIGHT PENCIL">✐</mark>
<br>
[see <a href="unicode_office_icons.html">Office Emoji 📌</a>]
</div>

<div>
<mark class="unicode" title="U+1F532: BLACK SQUARE BUTTON">🔲</mark>
<mark class="unicode" title="U+1F533: WHITE SQUARE BUTTON">🔳</mark>
<mark class="unicode" title="U+1F518: RADIO BUTTON">🔘</mark>
</div>

<div>
<mark class="unicode" title="U+1F500: TWISTED RIGHTWARDS ARROWS">🔀</mark>
<mark class="unicode" title="U+1F501: CLOCKWISE RIGHTWARDS AND LEFTWARDS OPEN CIRCLE ARROWS">🔁</mark>
<mark class="unicode" title="U+1F502: CLOCKWISE RIGHTWARDS AND LEFTWARDS OPEN CIRCLE ARROWS WITH CIRCLED ONE OVERLAY">🔂</mark>
<mark class="unicode" title="U+1F503: CLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS" data-char-info="U+1F503: CLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS">🔃</mark>
<mark class="unicode" title="U+1F504: ANTICLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS" data-char-info="U+1F504: ANTICLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS">🔄</mark>
<mark class="unicode" title="U+1F5D8: CLOCKWISE RIGHT AND LEFT SEMICIRCLE ARROWS" data-char-info="U+1F5D8: CLOCKWISE RIGHT AND LEFT SEMICIRCLE ARROWS">🗘</mark>
</div>

<div>
<mark class="unicode" title="U+1F514: BELL">🔔</mark>
<mark class="unicode" title="U+1F56D: RINGING BELL">🕭</mark>
<mark class="unicode" title="U+1F515: BELL WITH CANCELLATION STROKE">🔕</mark>
</div>

<div>
<mark class="unicode" title="U+26BF: SQUARED KEY" data-char-info="U+26BF: SQUARED KEY">⚿</mark>
<mark class="unicode" title="U+1F5DD: OLD KEY" data-char-info="U+1F5DD: OLD KEY">🗝</mark>
<mark class="unicode" title="U+1F511: KEY" data-char-info="U+1F511: KEY">🔑</mark>
<mark class="unicode" title="U+1F50F: LOCK WITH INK PEN">🔏</mark>
<mark class="unicode" title="U+1F510: CLOSED LOCK WITH KEY">🔐</mark>
<mark class="unicode" title="U+1F512: LOCK">🔒</mark>
<mark class="unicode" title="U+1F513: OPEN LOCK">🔓</mark>
</div>

<div>
<mark class="unicode" title="U+1F4F6: ANTENNA WITH BARS">📶</mark>
</div>

<div>
<mark class="unicode" title="U+1F5BC: FRAME WITH PICTURE">🖼</mark>
<mark class="unicode" title="U+1F5BD: FRAME WITH TILES">🖽</mark>
<mark class="unicode" title="U+1F5BE: FRAME WITH AN X">🖾</mark>
<mark class="unicode" title="U+1F5D4: DESKTOP WINDOW">🗔</mark>
<mark class="unicode" title="U+1F5D5: MINIMIZE">🗕</mark>
<mark class="unicode" title="U+1F5D6: MAXIMIZE">🗖</mark>
<mark class="unicode" title="U+1F5D7: OVERLAP">🗗</mark>
<mark class="unicode" title="U+239A: CLEAR SCREEN SYMBOL">⎚</mark>
<mark class="unicode" title="U+1F5D9: CANCELLATION X">🗙</mark>
<mark class="unicode" title="U+2327: X IN A RECTANGLE BOX">⌧</mark>
</div>

<div>
<mark class="unicode" title="U+1F3F7: LABEL">🏷</mark>
</div>

<div>
<mark class="unicode" title="U+1F441: EYE">👁</mark>
</div>

<div>
<mark class="unicode" title="U+1F5B5: SCREEN">🖵</mark>
<mark class="unicode" title="U+1F5B6: PRINTER ICON">🖶</mark>
<mark class="unicode" title="U+1F5B7: FAX ICON">🖷</mark>
<mark class="unicode" title="U+1F5B9: DOCUMENT WITH TEXT">🖹</mark>
<mark class="unicode" title="U+1F5BA: DOCUMENT WITH TEXT AND PICTURE">🖺</mark>
<mark class="unicode" title="U+1F5BB: DOCUMENT WITH PICTURE">🖻</mark>
</div>

<div>
<mark class="unicode" title="U+1F5D1: WASTEBASKET">🗑</mark>
<mark class="unicode" title="U+1F5DC: COMPRESSION">🗜</mark>
</div>

<div>
<mark class="unicode" title="U+1F5DA: INCREASE FONT SIZE SYMBOL">🗚</mark>
<mark class="unicode" title="U+1F5DB: DECREASE FONT SIZE SYMBOL">🗛</mark>
</div>

<div>
<mark class="unicode" title="U+1F517: LINK SYMBOL">🔗</mark>
<mark class="unicode" title="U+2693: ANCHOR">⚓</mark>
</div>

<p><a href="unicode_computing_symbols.html">Unicode Keyboard Symbols ⌘ ⏎ ⌫</a></p>

<div>
Electricity<br>
<mark class="unicode" title="U+2393: DIRECT CURRENT SYMBOL FORM TWO">⎓</mark>
<mark class="unicode" title="U+23DA: EARTH GROUND">⏚</mark>
<mark class="unicode" title="U+23DB: FUSE">⏛</mark>
<mark class="unicode" title="U+23E6: AC CURRENT">⏦</mark>
<mark class="unicode" title="U+23E7: ELECTRICAL INTERSECTION">⏧</mark>
<mark class="unicode" title="U+238F: OPEN-CIRCUIT-OUTPUT H-TYPE SYMBOL">⎏</mark>
<mark class="unicode" title="U+2390: OPEN-CIRCUIT-OUTPUT L-TYPE SYMBOL">⎐</mark>
</div>

<div>
map and earth<br>
<mark class="unicode" title="U+1F5FA: WORLD MAP">🗺</mark>
<mark class="unicode" title="U+1F30D: EARTH GLOBE EUROPE-AFRICA">🌍</mark>
<mark class="unicode" title="U+1F30E: EARTH GLOBE AMERICAS">🌎</mark>
<mark class="unicode" title="U+1F30F: EARTH GLOBE ASIA-AUSTRALIA">🌏</mark>
<mark class="unicode" title="U+1F310: GLOBE WITH MERIDIANS">🌐</mark>
</div>

<h2>Character Representation, ASCII Non-Printable Symbols ␀</h2>

<p><a href="unicode_character_representation.html">ASCII Characters ␀ ␣ ¶</a></p>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+23F0: ALARM CLOCK">⏰</mark>
<mark class="unicode" title="U+1F570: MANTELPIECE CLOCK">🕰</mark>
</div>

<div>
<mark class="unicode" title="U+231A: WATCH">⌚</mark>
<mark class="unicode" title="U+23F1: STOPWATCH">⏱</mark>
<mark class="unicode" title="U+23F2: TIMER CLOCK">⏲</mark>
</div>

<div>
<mark class="unicode" title="U+231B: HOURGLASS">⌛</mark>
<mark class="unicode" title="U+23F3: HOURGLASS WITH FLOWING SAND">⏳</mark>
<mark class="unicode" title="U+29D7: BLACK HOURGLASS">⧗</mark>
<mark class="unicode" title="U+29D6: WHITE HOURGLASS">⧖</mark>
<mark class="unicode" title="U+1F76E: ALCHEMICAL SYMBOL FOR HOUR">🝮</mark>
</div>

<div>
<mark class="unicode" title="U+1F550: CLOCK FACE ONE OCLOCK">🕐</mark>
<mark class="unicode" title="U+1F551: CLOCK FACE TWO OCLOCK">🕑</mark>
<mark class="unicode" title="U+1F552: CLOCK FACE THREE OCLOCK">🕒</mark>
<mark class="unicode" title="U+1F553: CLOCK FACE FOUR OCLOCK">🕓</mark>
<mark class="unicode" title="U+1F554: CLOCK FACE FIVE OCLOCK">🕔</mark>
<mark class="unicode" title="U+1F555: CLOCK FACE SIX OCLOCK">🕕</mark>
</div>

<div>
<mark class="unicode" title="U+1F556: CLOCK FACE SEVEN OCLOCK">🕖</mark>
<mark class="unicode" title="U+1F557: CLOCK FACE EIGHT OCLOCK">🕗</mark>
<mark class="unicode" title="U+1F558: CLOCK FACE NINE OCLOCK">🕘</mark>
<mark class="unicode" title="U+1F559: CLOCK FACE TEN OCLOCK">🕙</mark>
<mark class="unicode" title="U+1F55A: CLOCK FACE ELEVEN OCLOCK">🕚</mark>
<mark class="unicode" title="U+1F55B: CLOCK FACE TWELVE OCLOCK">🕛</mark>
</div>

<div>
<mark class="unicode" title="U+1F55C: CLOCK FACE ONE-THIRTY">🕜</mark>
<mark class="unicode" title="U+1F55D: CLOCK FACE TWO-THIRTY">🕝</mark>
<mark class="unicode" title="U+1F55E: CLOCK FACE THREE-THIRTY">🕞</mark>
<mark class="unicode" title="U+1F55F: CLOCK FACE FOUR-THIRTY">🕟</mark>
<mark class="unicode" title="U+1F560: CLOCK FACE FIVE-THIRTY">🕠</mark>
<mark class="unicode" title="U+1F561: CLOCK FACE SIX-THIRTY">🕡</mark>
</div>

<div>
<mark class="unicode" title="U+1F562: CLOCK FACE SEVEN-THIRTY">🕢</mark>
<mark class="unicode" title="U+1F563: CLOCK FACE EIGHT-THIRTY">🕣</mark>
<mark class="unicode" title="U+1F564: CLOCK FACE NINE-THIRTY">🕤</mark>
<mark class="unicode" title="U+1F565: CLOCK FACE TEN-THIRTY">🕥</mark>
<mark class="unicode" title="U+1F566: CLOCK FACE ELEVEN-THIRTY">🕦</mark>
<mark class="unicode" title="U+1F567: CLOCK FACE TWELVE-THIRTY">🕧</mark>
</div>

</div>`,

`<div class="box00710">

<div>
<mark class="unicode" title="U+1F3B9: MUSICAL KEYBOARD">🎹</mark>
<mark class="unicode" title="U+1F3BB: VIOLIN">🎻</mark>
<mark class="unicode" title="U+1F3B7: SAXOPHONE">🎷</mark>
<mark class="unicode" title="U+1F3BA: TRUMPET">🎺</mark>
<mark class="unicode" title="U+1F3B8: GUITAR">🎸</mark>
<mark class="unicode" title="U+1F941: DRUM WITH DRUMSTICKS">🥁</mark>
</div>

<div>
<mark class="unicode" title="U+1F3B5: MUSICAL NOTE">🎵</mark>
<mark class="unicode" title="U+1F3B6: MULTIPLE MUSICAL NOTES">🎶</mark>
<mark class="unicode" title="U+1F3BC: MUSICAL SCORE">🎼</mark>
</div>

<div>
<mark class="unicode" title="U+1F39C: BEAMED ASCENDING MUSICAL NOTES">🎜</mark>
<mark class="unicode" title="U+1F39D: BEAMED DESCENDING MUSICAL NOTES">🎝</mark>
</div>

<div>
<mark class="unicode" title="U+2669: QUARTER NOTE">♩</mark>
<mark class="unicode" title="U+266A: EIGHTH NOTE">♪</mark>
<mark class="unicode" title="U+266B: BEAMED EIGHTH NOTES">♫</mark>
<mark class="unicode" title="U+266C: BEAMED SIXTEENTH NOTES">♬</mark>
</div>

<div>
<mark class="unicode" title="U+1D15C: MUSICAL SYMBOL BREVE">𝅜</mark>
<mark class="unicode" title="U+1D15D: MUSICAL SYMBOL WHOLE NOTE">𝅝</mark>
<mark class="unicode" title="U+1D15E: MUSICAL SYMBOL HALF NOTE">𝅗𝅥</mark>
<mark class="unicode" title="U+1D15F: MUSICAL SYMBOL QUARTER NOTE">𝅘𝅥</mark>
<mark class="unicode" title="U+1D160: MUSICAL SYMBOL EIGHTH NOTE">𝅘𝅥𝅮</mark>
<mark class="unicode" title="U+1D161: MUSICAL SYMBOL SIXTEENTH NOTE">𝅘𝅥𝅯</mark>
<mark class="unicode" title="U+1D162: MUSICAL SYMBOL THIRTY-SECOND NOTE">𝅘𝅥𝅰</mark>
<mark class="unicode" title="U+1D163: MUSICAL SYMBOL SIXTY-FOURTH NOTE">𝅘𝅥𝅱</mark>
<mark class="unicode" title="U+1D164: MUSICAL SYMBOL ONE HUNDRED TWENTY-EIGHTH NOTE">𝅘𝅥𝅲</mark>
</div>

<div>
<mark class="unicode" title="U+1D13A: MUSICAL SYMBOL MULTI REST">𝄺</mark>
<mark class="unicode" title="U+1D129: MUSICAL SYMBOL MULTIPLE MEASURE REST">𝄩</mark>
</div>

<div>
<mark class="unicode" title="U+1D13B: MUSICAL SYMBOL WHOLE REST">𝄻</mark>
<mark class="unicode" title="U+1D13C: MUSICAL SYMBOL HALF REST">𝄼</mark>
<mark class="unicode" title="U+1D13D: MUSICAL SYMBOL QUARTER REST">𝄽</mark>
<mark class="unicode" title="U+1D13E: MUSICAL SYMBOL EIGHTH REST">𝄾</mark>
<mark class="unicode" title="U+1D13F: MUSICAL SYMBOL SIXTEENTH REST">𝄿</mark>
<mark class="unicode" title="U+1D140: MUSICAL SYMBOL THIRTY-SECOND REST">𝅀</mark>
<mark class="unicode" title="U+1D141: MUSICAL SYMBOL SIXTY-FOURTH REST">𝅁</mark>
<mark class="unicode" title="U+1D142: MUSICAL SYMBOL ONE HUNDRED TWENTY-EIGHTH REST">𝅂</mark>
</div>

<div>
<mark class="unicode" title="U+1D112: MUSICAL SYMBOL BREATH MARK">𝄒</mark>
<mark class="unicode" title="U+1D113: MUSICAL SYMBOL CAESURA">𝄓</mark>
<mark class="unicode" title="U+1D110: MUSICAL SYMBOL FERMATA">𝄐</mark>
<mark class="unicode" title="U+1D111: MUSICAL SYMBOL FERMATA BELOW">𝄑</mark>
</div>

<div>
<mark class="unicode" title="U+1D18F: MUSICAL SYMBOL PIANO">𝆏</mark>
<mark class="unicode" title="U+1D190: MUSICAL SYMBOL MEZZO" data-char-info="U+1D190: MUSICAL SYMBOL MEZZO">𝆐</mark>
<mark class="unicode" title="U+1D191: MUSICAL SYMBOL FORTE" data-char-info="U+1D191: MUSICAL SYMBOL FORTE">𝆑</mark>
<mark class="unicode" title="U+1D192: MUSICAL SYMBOL CRESCENDO" data-char-info="U+1D192: MUSICAL SYMBOL CRESCENDO">𝆒</mark>
<mark class="unicode" title="U+1D193: MUSICAL SYMBOL DECRESCENDO" data-char-info="U+1D193: MUSICAL SYMBOL DECRESCENDO">𝆓</mark>
</div>

<div>
<mark class="unicode" title="U+1D100: MUSICAL SYMBOL SINGLE BARLINE">𝄀</mark>
<mark class="unicode" title="U+1D101: MUSICAL SYMBOL DOUBLE BARLINE" data-char-info="U+1D101: MUSICAL SYMBOL DOUBLE BARLINE">𝄁</mark>
<mark class="unicode" title="U+1D102: MUSICAL SYMBOL FINAL BARLINE" data-char-info="U+1D102: MUSICAL SYMBOL FINAL BARLINE">𝄂</mark>
<mark class="unicode" title="U+1D103: MUSICAL SYMBOL REVERSE FINAL BARLINE" data-char-info="U+1D103: MUSICAL SYMBOL REVERSE FINAL BARLINE">𝄃</mark>
<mark class="unicode" title="U+1D104: MUSICAL SYMBOL DASHED BARLINE" data-char-info="U+1D104: MUSICAL SYMBOL DASHED BARLINE">𝄄</mark>
<mark class="unicode" title="U+1D105: MUSICAL SYMBOL SHORT BARLINE" data-char-info="U+1D105: MUSICAL SYMBOL SHORT BARLINE">𝄅</mark>
<mark class="unicode" title="U+1D114: MUSICAL SYMBOL BRACE" data-char-info="U+1D114: MUSICAL SYMBOL BRACE">𝄔</mark>
<mark class="unicode" title="U+1D115: MUSICAL SYMBOL BRACKET" data-char-info="U+1D115: MUSICAL SYMBOL BRACKET">𝄕</mark>
</div>

<div>
<mark class="unicode" title="U+1D106: MUSICAL SYMBOL LEFT REPEAT SIGN">𝄆</mark>
<mark class="unicode" title="U+1D107: MUSICAL SYMBOL RIGHT REPEAT SIGN">𝄇</mark>
<mark class="unicode" title="U+1D108: MUSICAL SYMBOL REPEAT DOTS">𝄈</mark>
<mark class="unicode" title="U+1D109: MUSICAL SYMBOL DAL SEGNO">𝄉</mark>
<mark class="unicode" title="U+1D10A: MUSICAL SYMBOL DA CAPO">𝄊</mark>
<mark class="unicode" title="U+1D10B: MUSICAL SYMBOL SEGNO">𝄋</mark>
<mark class="unicode" title="U+1D10C: MUSICAL SYMBOL CODA">𝄌</mark>
<mark class="unicode" title="U+1D10D: MUSICAL SYMBOL REPEATED FIGURE-1">𝄍</mark>
<mark class="unicode" title="U+1D10E: MUSICAL SYMBOL REPEATED FIGURE-2">𝄎</mark>
<mark class="unicode" title="U+1D10F: MUSICAL SYMBOL REPEATED FIGURE-3">𝄏</mark>
</div>

<div>
<mark class="unicode" title="U+1D116: MUSICAL SYMBOL ONE-LINE STAFF">𝄖</mark>
<mark class="unicode" title="U+1D117: MUSICAL SYMBOL TWO-LINE STAFF">𝄗</mark>
<mark class="unicode" title="U+1D118: MUSICAL SYMBOL THREE-LINE STAFF">𝄘</mark>
<mark class="unicode" title="U+1D119: MUSICAL SYMBOL FOUR-LINE STAFF">𝄙</mark>
<mark class="unicode" title="U+1D11A: MUSICAL SYMBOL FIVE-LINE STAFF">𝄚</mark>
<mark class="unicode" title="U+1D11B: MUSICAL SYMBOL SIX-LINE STAFF">𝄛</mark>
</div>

<div>
<mark class="unicode" title="U+1D11E: MUSICAL SYMBOL G CLEF">𝄚𝄞</mark>
<mark class="unicode" title="U+1D121: MUSICAL SYMBOL C CLEF">𝄚𝄡</mark>
<mark class="unicode" title="U+1D122: MUSICAL SYMBOL F CLEF">𝄚𝄢</mark>
</div>

<div>
<mark class="unicode" title="U+1D11F: MUSICAL SYMBOL G CLEF OTTAVA ALTA">𝄚𝄟</mark>
<mark class="unicode" title="U+1D120: MUSICAL SYMBOL G CLEF OTTAVA BASSA">𝄚𝄠</mark>
<mark class="unicode" title="U+1D123: MUSICAL SYMBOL F CLEF OTTAVA ALTA">𝄚𝄣</mark>
<mark class="unicode" title="U+1D124: MUSICAL SYMBOL F CLEF OTTAVA BASSA">𝄚𝄤</mark>
<mark class="unicode" title="U+1D125: MUSICAL SYMBOL DRUM CLEF-1">𝄚𝄥</mark>
<mark class="unicode" title="U+1D126: MUSICAL SYMBOL DRUM CLEF-2">𝄚𝄦</mark>
</div>

<div>
<mark class="unicode" title="U+266F: MUSIC SHARP SIGN">♯</mark>
<mark class="unicode" title="U+1D12A: MUSICAL SYMBOL DOUBLE SHARP">𝄪</mark>
<mark class="unicode" title="U+1D130: MUSICAL SYMBOL SHARP UP">𝄰</mark>
<mark class="unicode" title="U+1D131: MUSICAL SYMBOL SHARP DOWN">𝄱</mark>
<mark class="unicode" title="U+1D132: MUSICAL SYMBOL QUARTER TONE SHARP">𝄲</mark>
</div>

<div>
<mark class="unicode" title="U+266D: MUSIC FLAT SIGN">♭</mark>
<mark class="unicode" title="U+1D12B: MUSICAL SYMBOL DOUBLE FLAT">𝄫</mark>
<mark class="unicode" title="U+1D12C: MUSICAL SYMBOL FLAT UP">𝄬</mark>
<mark class="unicode" title="U+1D12D: MUSICAL SYMBOL FLAT DOWN">𝄭</mark>
<mark class="unicode" title="U+1D133: MUSICAL SYMBOL QUARTER TONE FLAT">𝄳</mark>
</div>

<div>
<mark class="unicode" title="U+266E: MUSIC NATURAL SIGN">♮</mark>
<mark class="unicode" title="U+1D12E: MUSICAL SYMBOL NATURAL UP">𝄮</mark>
<mark class="unicode" title="U+1D12F: MUSICAL SYMBOL NATURAL DOWN">𝄯</mark>
</div>

<div>
<mark class="unicode" title="U+1D136: MUSICAL SYMBOL OTTAVA ALTA">𝄶</mark>
<mark class="unicode" title="U+1D137: MUSICAL SYMBOL OTTAVA BASSA">𝄷</mark>
<mark class="unicode" title="U+1D138: MUSICAL SYMBOL QUINDICESIMA ALTA">𝄸</mark>
<mark class="unicode" title="U+1D139: MUSICAL SYMBOL QUINDICESIMA BASSA">𝄹</mark>
</div>

<div>
<mark class="unicode" title="U+1D134: MUSICAL SYMBOL COMMON TIME">𝄴</mark>
<mark class="unicode" title="U+1D135: MUSICAL SYMBOL CUT TIME">𝄵</mark>
</div>

<div>
<mark class="unicode" title="U+1D18C: MUSICAL SYMBOL RINFORZANDO">𝆌</mark>
<mark class="unicode" title="U+1D18D: MUSICAL SYMBOL SUBITO">𝆍</mark>
<mark class="unicode" title="U+1D18E: MUSICAL SYMBOL Z">𝆎</mark>
</div>

<div>
<mark class="unicode" title="U+1D194: MUSICAL SYMBOL GRACE NOTE SLASH">𝆔</mark>
<mark class="unicode" title="U+1D195: MUSICAL SYMBOL GRACE NOTE NO SLASH">𝆕</mark>
<mark class="unicode" title="U+1D196: MUSICAL SYMBOL TR">𝆖</mark>
<mark class="unicode" title="U+1D19B: MUSICAL SYMBOL ORNAMENT STROKE-1">𝆛</mark>
<mark class="unicode" title="U+1D19C: MUSICAL SYMBOL ORNAMENT STROKE-2">𝆜</mark>
<mark class="unicode" title="U+1D19D: MUSICAL SYMBOL ORNAMENT STROKE-3">𝆝</mark>
<mark class="unicode" title="U+1D19E: MUSICAL SYMBOL ORNAMENT STROKE-4">𝆞</mark>
<mark class="unicode" title="U+1D19F: MUSICAL SYMBOL ORNAMENT STROKE-5">𝆟</mark>
<mark class="unicode" title="U+1D1A0: MUSICAL SYMBOL ORNAMENT STROKE-6">𝆠</mark>
<mark class="unicode" title="U+1D1A1: MUSICAL SYMBOL ORNAMENT STROKE-7">𝆡</mark>
<mark class="unicode" title="U+1D1A2: MUSICAL SYMBOL ORNAMENT STROKE-8">𝆢</mark>
<mark class="unicode" title="U+1D1A3: MUSICAL SYMBOL ORNAMENT STROKE-9">𝆣</mark>
<mark class="unicode" title="U+1D1A4: MUSICAL SYMBOL ORNAMENT STROKE-10">𝆤</mark>
<mark class="unicode" title="U+1D1A5: MUSICAL SYMBOL ORNAMENT STROKE-11">𝆥</mark>
</div>

<div>
<mark class="unicode" title="U+1D183: MUSICAL SYMBOL ARPEGGIATO UP">𝆃</mark>
<mark class="unicode" title="U+1D184: MUSICAL SYMBOL ARPEGGIATO DOWN">𝆄</mark>
<mark class="unicode" title="U+1D1B1: MUSICAL SYMBOL GLISSANDO UP">𝆱</mark>
<mark class="unicode" title="U+1D1B2: MUSICAL SYMBOL GLISSANDO DOWN">𝆲</mark>
</div>

<div>
<mark class="unicode" title="U+1D197: MUSICAL SYMBOL TURN">𝆗</mark>
<mark class="unicode" title="U+1D198: MUSICAL SYMBOL INVERTED TURN">𝆘</mark>
<mark class="unicode" title="U+1D199: MUSICAL SYMBOL TURN SLASH">𝆙</mark>
<mark class="unicode" title="U+1D19A: MUSICAL SYMBOL TURN UP">𝆚</mark>
</div>

<div>
<mark class="unicode" title="U+1D1A6: MUSICAL SYMBOL HAUPTSTIMME">𝆦</mark>
<mark class="unicode" title="U+1D1A7: MUSICAL SYMBOL NEBENSTIMME">𝆧</mark>
<mark class="unicode" title="U+1D1A8: MUSICAL SYMBOL END OF STIMME">𝆨</mark>
<mark class="unicode" title="U+1D1A9: MUSICAL SYMBOL DEGREE SLASH">𝆩</mark>
</div>

<div>
<mark class="unicode" title="U+1D1AE: MUSICAL SYMBOL PEDAL MARK">𝆮</mark>
<mark class="unicode" title="U+1D1AF: MUSICAL SYMBOL PEDAL UP MARK">𝆯</mark>
<mark class="unicode" title="U+1D1B0: MUSICAL SYMBOL HALF PEDAL MARK">𝆰</mark>
</div>

<div>
<mark class="unicode" title="U+1D11C: MUSICAL SYMBOL SIX-STRING FRETBOARD">𝄜</mark>
<mark class="unicode" title="U+1D11D: MUSICAL SYMBOL FOUR-STRING FRETBOARD">𝄝</mark>
</div>

<div>
<mark class="unicode" title="U+1D1B6: MUSICAL SYMBOL MAXIMA">𝆶</mark>
<mark class="unicode" title="U+1D1B7: MUSICAL SYMBOL LONGA">𝆷</mark>
<mark class="unicode" title="U+1D1B8: MUSICAL SYMBOL BREVIS">𝆸</mark>
</div>

<div>
<mark class="unicode" title="U+1D1BA: MUSICAL SYMBOL SEMIBREVIS BLACK">𝆺</mark>
<mark class="unicode" title="U+1D1BC: MUSICAL SYMBOL MINIMA BLACK">𝆺𝅥</mark>
<mark class="unicode" title="U+1D1BE: MUSICAL SYMBOL SEMIMINIMA BLACK">𝆺𝅥𝅮</mark>
<mark class="unicode" title="U+1D1C0: MUSICAL SYMBOL FUSA BLACK">𝆺𝅥𝅯</mark>
</div>

<div>
<mark class="unicode" title="U+1D1B9: MUSICAL SYMBOL SEMIBREVIS WHITE">𝆹</mark>
<mark class="unicode" title="U+1D1BB: MUSICAL SYMBOL MINIMA">𝆹𝅥</mark>
<mark class="unicode" title="U+1D1BD: MUSICAL SYMBOL SEMIMINIMA WHITE">𝆹𝅥𝅮</mark>
<mark class="unicode" title="U+1D1BF: MUSICAL SYMBOL FUSA WHITE">𝆹𝅥𝅯</mark>
</div>

<div>
<mark class="unicode" title="U+1D1C1: MUSICAL SYMBOL LONGA PERFECTA REST">𝇁</mark>
<mark class="unicode" title="U+1D1C2: MUSICAL SYMBOL LONGA IMPERFECTA REST">𝇂</mark>
<mark class="unicode" title="U+1D1C3: MUSICAL SYMBOL BREVIS REST">𝇃</mark>
<mark class="unicode" title="U+1D1C4: MUSICAL SYMBOL SEMIBREVIS REST">𝇄</mark>
<mark class="unicode" title="U+1D1C5: MUSICAL SYMBOL MINIMA REST">𝇅</mark>
<mark class="unicode" title="U+1D1C6: MUSICAL SYMBOL SEMIMINIMA REST">𝇆</mark>
<mark class="unicode" title="U+1D1CF: MUSICAL SYMBOL CROIX">𝇏</mark>
</div>

<div>
<mark class="unicode" title="U+1D1C7: MUSICAL SYMBOL TEMPUS PERFECTUM CUM PROLATIONE PERFECTA">𝇇</mark>
<mark class="unicode" title="U+1D1C8: MUSICAL SYMBOL TEMPUS PERFECTUM CUM PROLATIONE IMPERFECTA">𝇈</mark>
<mark class="unicode" title="U+1D1C9: MUSICAL SYMBOL TEMPUS PERFECTUM CUM PROLATIONE PERFECTA DIMINUTION-1">𝇉</mark>
<mark class="unicode" title="U+1D1CA: MUSICAL SYMBOL TEMPUS IMPERFECTUM CUM PROLATIONE PERFECTA">𝇊</mark>
<mark class="unicode" title="U+1D1CB: MUSICAL SYMBOL TEMPUS IMPERFECTUM CUM PROLATIONE IMPERFECTA">𝇋</mark>
<mark class="unicode" title="U+1D1CC: MUSICAL SYMBOL TEMPUS IMPERFECTUM CUM PROLATIONE IMPERFECTA DIMINUTION-1">𝇌</mark>
<mark class="unicode" title="U+1D1CD: MUSICAL SYMBOL TEMPUS IMPERFECTUM CUM PROLATIONE IMPERFECTA DIMINUTION-2">𝇍</mark>
<mark class="unicode" title="U+1D1CE: MUSICAL SYMBOL TEMPUS IMPERFECTUM CUM PROLATIONE IMPERFECTA DIMINUTION-3">𝇎</mark>
</div>

<div>
<mark class="unicode" title="U+1D1D0: MUSICAL SYMBOL GREGORIAN C CLEF">𝇐</mark>
<mark class="unicode" title="U+1D1D1: MUSICAL SYMBOL GREGORIAN F CLEF">𝇑</mark>
<mark class="unicode" title="U+1D1D2: MUSICAL SYMBOL SQUARE B">𝇒</mark>
<mark class="unicode" title="U+1D1D3: MUSICAL SYMBOL VIRGA">𝇓</mark>
<mark class="unicode" title="U+1D1D4: MUSICAL SYMBOL PODATUS">𝇔</mark>
<mark class="unicode" title="U+1D1D5: MUSICAL SYMBOL CLIVIS">𝇕</mark>
<mark class="unicode" title="U+1D1D6: MUSICAL SYMBOL SCANDICUS">𝇖</mark>
<mark class="unicode" title="U+1D1D7: MUSICAL SYMBOL CLIMACUS">𝇗</mark>
<mark class="unicode" title="U+1D1D8: MUSICAL SYMBOL TORCULUS">𝇘</mark>
<mark class="unicode" title="U+1D1D9: MUSICAL SYMBOL PORRECTUS">𝇙</mark>
<mark class="unicode" title="U+1D1DA: MUSICAL SYMBOL PORRECTUS FLEXUS">𝇚</mark>
<mark class="unicode" title="U+1D1DB: MUSICAL SYMBOL SCANDICUS FLEXUS">𝇛</mark>
<mark class="unicode" title="U+1D1DC: MUSICAL SYMBOL TORCULUS RESUPINUS">𝇜</mark>
<mark class="unicode" title="U+1D1DD: MUSICAL SYMBOL PES SUBPUNCTIS">𝇝</mark>
</div>

<div>
<mark class="unicode" title="U+1D1B3: MUSICAL SYMBOL WITH FINGERNAILS">𝆳</mark>
<mark class="unicode" title="U+1D1B4: MUSICAL SYMBOL DAMP">𝆴</mark>
<mark class="unicode" title="U+1D1B5: MUSICAL SYMBOL DAMP ALL">𝆵</mark>
</div>

<div>
<mark class="unicode" title="U+1D143: MUSICAL SYMBOL X NOTEHEAD">𝅃</mark>
<mark class="unicode" title="U+1D144: MUSICAL SYMBOL PLUS NOTEHEAD">𝅄</mark>
<mark class="unicode" title="U+1D145: MUSICAL SYMBOL CIRCLE X NOTEHEAD">𝅅</mark>
<mark class="unicode" title="U+1D156: MUSICAL SYMBOL PARENTHESIS NOTEHEAD">𝅖</mark>
<mark class="unicode" title="U+1D159: MUSICAL SYMBOL NULL NOTEHEAD">𝅙</mark>
</div>

<div>
<mark class="unicode" title="U+1D146: MUSICAL SYMBOL SQUARE NOTEHEAD WHITE">𝅆</mark>
<mark class="unicode" title="U+1D148: MUSICAL SYMBOL TRIANGLE NOTEHEAD UP WHITE">𝅈</mark>
<mark class="unicode" title="U+1D14A: MUSICAL SYMBOL TRIANGLE NOTEHEAD LEFT WHITE">𝅊</mark>
<mark class="unicode" title="U+1D14C: MUSICAL SYMBOL TRIANGLE NOTEHEAD RIGHT WHITE">𝅌</mark>
<mark class="unicode" title="U+1D14E: MUSICAL SYMBOL TRIANGLE NOTEHEAD DOWN WHITE">𝅎</mark>
<mark class="unicode" title="U+1D150: MUSICAL SYMBOL TRIANGLE NOTEHEAD UP RIGHT WHITE">𝅐</mark>
<mark class="unicode" title="U+1D152: MUSICAL SYMBOL MOON NOTEHEAD WHITE">𝅒</mark>
<mark class="unicode" title="U+1D154: MUSICAL SYMBOL TRIANGLE-ROUND NOTEHEAD DOWN WHITE">𝅔</mark>
<mark class="unicode" title="U+1D157: MUSICAL SYMBOL VOID NOTEHEAD">𝅗</mark>
<mark class="unicode" title="U+1D15A: MUSICAL SYMBOL CLUSTER NOTEHEAD WHITE">𝅚</mark>
</div>

<div>
<mark class="unicode" title="U+1D147: MUSICAL SYMBOL SQUARE NOTEHEAD BLACK">𝅇</mark>
<mark class="unicode" title="U+1D149: MUSICAL SYMBOL TRIANGLE NOTEHEAD UP BLACK">𝅉</mark>
<mark class="unicode" title="U+1D14B: MUSICAL SYMBOL TRIANGLE NOTEHEAD LEFT BLACK">𝅋</mark>
<mark class="unicode" title="U+1D14D: MUSICAL SYMBOL TRIANGLE NOTEHEAD RIGHT BLACK">𝅍</mark>
<mark class="unicode" title="U+1D14F: MUSICAL SYMBOL TRIANGLE NOTEHEAD DOWN BLACK">𝅏</mark>
<mark class="unicode" title="U+1D151: MUSICAL SYMBOL TRIANGLE NOTEHEAD UP RIGHT BLACK">𝅑</mark>
<mark class="unicode" title="U+1D153: MUSICAL SYMBOL MOON NOTEHEAD BLACK">𝅓</mark>
<mark class="unicode" title="U+1D155: MUSICAL SYMBOL TRIANGLE-ROUND NOTEHEAD DOWN BLACK">𝅕</mark>
<mark class="unicode" title="U+1D158: MUSICAL SYMBOL NOTEHEAD BLACK">𝅘</mark>
<mark class="unicode" title="U+1D15B: MUSICAL SYMBOL CLUSTER NOTEHEAD BLACK">𝅛</mark>
</div>

<div>
<mark class="unicode" title="U+1D16A: MUSICAL SYMBOL FINGERED TREMOLO-1">𝅪</mark>
<mark class="unicode" title="U+1D16B: MUSICAL SYMBOL FINGERED TREMOLO-2">𝅫</mark>
<mark class="unicode" title="U+1D16C: MUSICAL SYMBOL FINGERED TREMOLO-3">𝅬</mark>
</div>

<div>stem
<mark class="unicode" title="U+1D165: MUSICAL SYMBOL COMBINING STEM">𝅥</mark>
<mark class="unicode" title="U+1D166: MUSICAL SYMBOL COMBINING SPRECHGESANG STEM">𝅦</mark>
</div>

</div>`,

]
