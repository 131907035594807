import React from 'react';
import {
  Badge,
  Details,
  PreCode,
  isPrime,
  randomBgGrandientDarkStyle,
} from '../Util';
import PrimeTester from '../tool/PrimeTester';

function query(props) {
  const { location } = props;
  if (typeof location === 'object' && location !== null) {
    let { search } = location;
    if (typeof search === 'string' && search.length > 0) {
      if (search.startsWith('?') && search.length > 1) {
        search = search.slice(1);
      }
      if (search.length > 0) {
        const n = parseInt(search);
        if (typeof n === 'number') {
          return n;
        }
      }
    }
  }
  return null;
}

export default function IsPrime(props) {
  const [n] = React.useState(query(props));
  return (
    <div className="container-lg mb-5">
      <div className="pt-2 text-center">
        <h1>{'\u{1f340}'} isPrime</h1>
        <p className="lead">To check a number is a prime</p>
      </div>

      <div className="mb-5">
        <div
          className="p-3 shadow rounded text-white"
          style={{ background: randomBgGrandientDarkStyle() }}
        >
          <h3 className="text-center">Prime Number Tester</h3>
          <PrimeTester n={n} />
        </div>
      </div>

      <div className="mb-5">
        <Details lead="The Code" className="h3">
          <PreCode>{`
function isPrime(n) {
  if (typeof(n)==='number') {
    for (let i=2;i<=Math.sqrt(n);i++) {
      if (n%i===0) {
        return false
      }
    }
    return n>1
  }
  return false
}`}</PreCode>
        </Details>
      </div>

      <div className="mb-5">
        <h5>Usage</h5>
        <PreCode>{`isPrime(13) // true`}</PreCode>
        <PreCode>{`isPrime(17) // true`}</PreCode>
        <PreCode>{`isPrime(10) // false`}</PreCode>
      </div>

      <div className="mb-5">
        <h3>Examples</h3>
        <Examples />
      </div>
    </div>
  );
}

function Examples(props) {
  return (
    <div className="">
      {new Array(100).fill(0).map((e, i) => {
        const n = i + 1;
        const code = `isPrime(${n})`;
        return (
          <div key={i} className="row">
            <div className="col-5 text-center">
              <PreCode>{code}</PreCode>
            </div>
            <div className="col-3 text-center h4">
              {isPrime(n) ? (
                <Badge success pill shadow>
                  true
                </Badge>
              ) : (
                <Badge danger pill shadow>
                  false
                </Badge>
              )}
            </div>
            <div className="col-4 text-center h4">
              {`${n} is`}{' '}
              {isPrime(n) ? (
                <Badge primary pill shadow>
                  Prime
                </Badge>
              ) : (
                <Badge light pill shadow className="strike-through">
                  Prime
                </Badge>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
