import BloggingBW from './BloggingBW.json';
import BloggingColored from './BloggingColored.json';
import BodyMovin from './BodyMovin.json';
import LoginBW from './LoginBW.json';
import LoginColored from './LoginColored.json';

const AnimationData = [
  BloggingBW,
  BloggingColored,
  LoginBW,
  LoginColored,
  BodyMovin,
];

export default AnimationData;
