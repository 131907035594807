import React from 'react'
import ApexCharts from 'apexcharts'

export default function ApexChart(props) {

  const {opts} = props

  const [chart,setChart] = React.useState()
  const [rendered,setRendered] = React.useState(false)
  const [init,setInit] = React.useState(false)

  const el = React.useRef()

  React.useEffect(()=>{
    if (init&&!rendered) {
      chart.render()
      setRendered(true)
    }
  }, [init,rendered,chart])

  React.useEffect(()=>{
    if (init) {
      if (typeof(opts)==='object'&&opts!==null) {
        const {series} = opts
        if (Array.isArray(series)&&series.length>0) {
          chart.updateSeries(series)
        }
      }
    } else {
      if (typeof(opts)==='object'&&opts!==null) {
        if (typeof(el)==='object'&&el!==null) {
          const {current} = el
          if (typeof(current)==='object'&&current!==null) {
            setChart(new ApexCharts(current, opts))
            setInit(true)
          }
        }
      }
    }
  }, [opts,chart,init,rendered])

  if (typeof(opts)==='undefined'||opts===null) return null

  return (
    <div ref={el}></div>
  )
}
