import React from 'react';
import { useTranslation } from 'react-i18next';
import Constant from '../Constant';
import { isUrl, capFirstChar, A, randomBgGrandientDarkStyle } from '../Util';
import AboutApps from './AboutApps';

const { ABOUT } = Constant;

const Label = {
  blog: 'Personal Blog',
  codepen: 'CodePen',
  linkedin: 'LinkedIn',
};

export default function About(props) {
  const { t } = useTranslation();
  //const { about, Label, apps } = Constant;
  return (
    <div
      className={`About py-5 container-lg animate__animated animate__faster animate__fadeInRight`}
    >
      <h1 className="mb-5 text-center text-shadow">
        <span className="mr-5">{'\u24D8'}</span>
        {t('About')}
      </h1>
      <div
        className="p-5 rounded shadow text-white"
        style={{ background: randomBgGrandientDarkStyle() }}
      >
        <AboutApps />

        {Object.keys(ABOUT).map((e, i) => {
          const v = ABOUT[e];
          const l = Label[e] || capFirstChar(e);
          return (
            <div key={i} className="row mb-3">
              <div className="col-sm-4 text-sm-right text-center">
                <small>{l}</small>
              </div>
              <div className="col-sm-8 text-sm-left text-center lead">
                {isUrl(v) ? (
                  <A href={v} blank style={{ color: 'Azure' }}>
                    {v}
                  </A>
                ) : (
                  v
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
