import React from 'react'
import {A,Details,PreCode,Callout,lipsum} from '../Util'


export default function ReactXBootstrapDetails(props) {
  return (
    <div className="container-fluid pt-5 mb-5" id="Details">
      <h1 className="text-center pt-3">Details</h1>
      <p className="lead text-center">Underlyingly using html5 <A href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details" blank>details</A> elment. Pretty useful in showing brief important thing first, giving user the control to view the details further.</p>

      <div>
        <Details lead="The Code" className="h3">
          <PreCode>{`
function Details(props) {
  const {className='',lead='',children} = props
  return (
    <details>
      <summary><span className={className}>{lead}</span></summary>{children}
    </details>
  )
}
          `}</PreCode>
        </Details>
      </div>

      <div>
        <h3>Usage</h3>
        <PreCode>{`
<Details lead="Read more...">
  <p>...</p>
</Details>
        `}</PreCode>
        <h5>Preview</h5>
        <div className="mb-3">
          <Details lead="Read more...">
            <p className="p-5 shadow">{lipsum(256,true)}</p>
          </Details>
        </div>
        <Callout shadow info>Notice the content of the details are pretty long.</Callout>
        <Callout shadow warning>Not supported in Edge and IE</Callout>
      </div>
    </div>
  )
}
