import React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from './CURRENCIES';
import CurrencyListCurrencies from './CurrencyListCurrencies';
import CountryFlag from './CountryFlag';

function filterBy(list = [], keyword) {
  if (!keyword || list.length <= 0) {
    return list;
  }

  const regex = new RegExp(keyword, 'i');

  return list.filter(({ name, cca2, currencies }) => {
    const { common } = name;
    if (common.search(regex) >= 0) return true;
    if (cca2.search(regex) >= 0) return true;
    if (typeof currencies === 'object' && currencies !== null) {
      const currencyCodes = Object.keys(currencies);
      for (let i in currencyCodes) {
        if (currencyCodes[i].search(regex) >= 0) return true;
        const { name, symbol } = currencies[currencyCodes[i]];
        if (name.search(regex) >= 0) return true;
        if (symbol.search(regex) >= 0) return true;
      }
    }
    return false;
  });
}

function CurrencyCount({ currencies }) {
  if (typeof currencies === 'object' && currencies !== null) {
    const count = Object.keys(currencies).length;

    if (count === 0) {
      return <span className="badge badge-dark badge-pill">{count}</span>;
    }
    if (count === 1) {
      return <span className="badge badge-info badge-pill">{count}</span>;
    }
    if (count === 2) {
      return <span className="badge badge-success badge-pill">{count}</span>;
    }
    if (count === 3) {
      return <span className="badge badge-warning badge-pill">{count}</span>;
    }
    if (count > 3) {
      return <span className="badge badge-danger badge-pill">{count}</span>;
    }
    return <span className="badge badge-primary badge-pill">{count}</span>;
  }
  return <span className="badge badge-dark badge-pill">0</span>;
}

export default function CurrencyList(props) {
  const { t } = useTranslation();

  const [keyword, setKeyword] = React.useState();

  const filteredCurrencies = filterBy(CURRENCIES, keyword);

  return (
    <div className="container-lg">
      <div className="text-center mb-4">
        <h1>{t('Currency List')}</h1>
        <p className="lead">
          {t('List of currency from all countries in the whole wide world')}
        </p>
      </div>

      <details>
        <summary>
          {t('List of countries with thier currencies in JSON')}
        </summary>
        <pre className="p-3 bg-dark text-light rounded-lg">
          <code>{JSON.stringify(CURRENCIES, null, 2)}</code>
        </pre>
      </details>

      <div className="container bg-white p-3 rounded-lg shadow-sm mb-4">
        <div className="py-4">
          <input
            type="search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="w-100 form-control rounded-pill px-3"
            placeholder={t('Filter by...')}
          />
        </div>

        <div className="text-center mb-4">
          <p
            className={`text-secondary small animate__animated animate__faster animate__${
              keyword ? 'fadeInDown' : 'fadeOutUp'
            }`}
          >
            {t('Displaying {{count}} out of total {{total}}', {
              count: filteredCurrencies.length,
              total: CURRENCIES.length,
            })}
          </p>
        </div>

        <div className="form-row">
          <div className="col-6 text-center small">
            <span className="font-weight-bold">{t('Country')}</span>
            <div>
              <span className="badge badge-light border badge-pill mr-1">
                {t('Code')}
              </span>
              <span className="mr-1">{t('Name')}</span>
              <span className="badge badge-light">{t('Flag')}</span>
            </div>
          </div>
          <div className="col-1 text-center small font-weight-bold">
            {t('Currency count')}
          </div>
          <div className="col-5 text-center small font-weight-bold">
            {t('Currency')}
            <div>
              <small className="font-weight-bold">{t('Code')}</small>
              <span className="badge badge-success px-2 mx-2">
                {t('Symbol')}
              </span>
              <small>{t('Name')}</small>
            </div>
          </div>

          <div className="col-12 mb-3 py-1 shadow-sm" />

          {filteredCurrencies.length <= 0 && (
            <div className="col-12">
              <div className="place-items-center py-5 text-secondary small">
                {t(`Can't find anything with {{keyword}}`, { keyword })}
              </div>
            </div>
          )}

          {filteredCurrencies.map(({ name, cca2, currencies }, i) => (
            <React.Fragment key={cca2}>
              <div className={`col-6 ${i % 2 === 0 ? '' : 'bg-light'}`}>
                <span className="badge badge-light border badge-pill mr-2">
                  {cca2}
                </span>
                <span className="mr-2">{name.common}</span>
                <CountryFlag countryCode={cca2} />
              </div>
              <div
                className={`col-1 text-center ${i % 2 === 0 ? '' : 'bg-light'}`}
              >
                <CurrencyCount currencies={currencies} />
              </div>
              <div className={`col-5 ${i % 2 === 0 ? '' : 'bg-light'}`}>
                <CurrencyListCurrencies currencies={currencies} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
